import {
	connect
} from 'react-redux'
import EmergencyEventsComponent from '../components/EmergencyEventsComponent.jsx';
import {
	changeEventValue,
	changeEventSideListValue,
	clearEventAlert,
	initEvent,
	addEvent,
	editEvent,
	findEvent,
	findEvents,
	deleteEvent,
	updateEventActive
} from '../actions';

const mapStateToProps = (state) => ({
	event: state.getIn(['emergencyEventReducer', 'event']),
	eventValidation: state.getIn(['emergencyEventReducer', 'eventValidation']),
	alertStyle: state.getIn(['emergencyEventReducer', 'alertStyle']),
	alertMessage: state.getIn(['emergencyEventReducer', 'alertMessage']),
	list: state.getIn(['emergencyEventReducer', 'list']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	setValue: (id, value) => {
		dispatch(changeEventValue({
			id: id,
			value: value
		}))
	},
	setListValue: (sideId, id, value) => {
		dispatch(changeEventSideListValue({
			sideId: sideId,
			id: id,
			value: value
		}))
	},
	clearAlert: () => {
		dispatch(clearEventAlert());
	},
	initEvent: (corpId) => {
		dispatch(initEvent(corpId));
	},
	addEvent: (event) => {
		dispatch(addEvent(event));
	},
	editEvent: (event) => {
		dispatch(editEvent(event));
	},
	findEvent: (eventId) => {
		dispatch(findEvent(eventId));
	},
	findEvents: () => {
		dispatch(findEvents());
	},
	deleteEvent: (eventId) => {
		dispatch(deleteEvent(eventId));
	},
	updateEventActive: (eventId, isActive) => {
		dispatch(updateEventActive(eventId, isActive));
	}
})

const EmergencyEventsContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(EmergencyEventsComponent)

export default EmergencyEventsContainer;