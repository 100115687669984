import React from 'react';

export default class HomeComponent extends React.Component {

	componentDidMount() {
		this.props.findAnnouncementList();
	}

	render() {
		let emailVerified = false;
		let userStatus = -1;
		// let userRole = -1;
		if (this.props.role) {
			emailVerified = this.props.role.get('emailVerified');
			userStatus = this.props.role.get('status');
			// userRole = this.props.role.get('role');
		}
		let content = null;
		if (emailVerified === true && userStatus === 1) {
			content = (
				<div>
				</div>
			);
		} else if (userStatus === -1) {
			content = (
				<span>您的帳號尚未審核通過, 請靜待系統管理員審核</span>
			);
		} else if (userStatus === 2 || userStatus === 9) {
			content = (
				<span>您的帳號已被停用, 請恰系統管理員</span>
			);
		}

		let annContentObj = [];
		let i = 0;
		for (let ann of this.props.announcementList) {
			if (i > 0) {
				annContentObj.push((<hr key={"key" + i} />));
			}
			let annContent = ann.get('annContent');
			let updateDate = ann.get('updateDate');
			annContentObj.push(annContent)
			annContentObj.push((<br key={"br1" + i} />));
			annContentObj.push((<br key={"br2" + i} />));
			annContentObj.push((<span key={"span" + i} style={{ color: '#808080' }}>({updateDate})</span>))
			i++;
		}

		let showAnn = 'none';
		if (this.props.announcementList.size) {
			showAnn = 'block';
		}

		return (
			<div style={{ marginLeft: 20 }}>
				<div style={{ display: showAnn, marginBottom: 20 }}>
					<h4 style={{ fontWeight: 'bold' }}>系統公告</h4>
					<pre style={{ backgroundColor: 'white' }}>
						{annContentObj}
					</pre>
				</div>
				{content}
			</div>
		)
	}
}