import React from 'react';
import { Row, Col, Button, Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { findCurrentUser } from '../constants/awsCognito.js';

import AlertGroup from './common/AlertGroup.jsx';

export default class ForgotPwdComponent extends React.Component {

	componentDidMount() {
		if (findCurrentUser() != null) {
			this.props.toSignOut();
			this.props.router.push('/forgotPwd');
		}
		this.setState({
			username: '',
			confirmationCode: '',
			newPassword: '',
			confirmPassword: ''
		})
	}

	render() {
		let { toResetPassword, toSendPwdComfirmationCode, alertStyle, alertMessage,
			loading, showResetPwd } = this.props;

		let onUserNameChange = (e) => {
			this.setState({ username: e.target.value });
		};
		let onConfirmationCodeChange = (e) => {
			this.setState({ confirmationCode: e.target.value });
		};
		let onNewPasswordChange = (e) => {
			this.setState({ newPassword: e.target.value });
		};
		let onConfirmPasswordChange = (e) => {
			this.setState({ confirmPassword: e.target.value });
		};

		let onResetPassword = () => {
			let { username, confirmationCode, newPassword, confirmPassword } = this.state;
			toResetPassword(username, confirmationCode, newPassword, confirmPassword);
		};
		let onSendPwdComfirmationCode = () => {
			let { username } = this.state;
			toSendPwdComfirmationCode(username);
		};

		let showLoading = (loading) ? '' : 'none';

		let toLoginPage = () => {
			this.props.router.push('/login');
		};

		return (
			<div className="card center-block" style={{ width: "30rem" }}>
				<div className="card-body">
					<Row>
						<Col xs={12}><h3><b>忘記密碼</b></h3></Col>
						<Col xs={12}>
							<AlertGroup
								alertStyle={alertStyle}
								alertMessage={alertMessage}
							/>
						</Col>
						<Col xs={12}><hr /></Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Form>
								<FormGroup controlId="userName">
									<ControlLabel>帳號</ControlLabel>
									<FormControl type="text" placeholder="UserName" onChange={onUserNameChange} />
								</FormGroup>
								<FormGroup>
									<Button block bsStyle="default" onClick={onSendPwdComfirmationCode}>
										發認證碼
                					</Button>
								</FormGroup>
								<div style={{ display: showResetPwd }}>
									<h5>系統已將認證碼寄至您的信箱，請輸入認證碼及新密碼以重設密碼!!</h5>
									<FormGroup controlId="confirmationCode">
										<ControlLabel>認證碼</ControlLabel>
										<FormControl type="text" placeholder="Confirmation Code" onChange={onConfirmationCodeChange} />
									</FormGroup>
									<FormGroup controlId="newPassword">
										<ControlLabel>新密碼</ControlLabel>
										<FormControl type="password" placeholder="New Password" onChange={onNewPasswordChange} />
									</FormGroup>
									<FormGroup controlId="confirmPassword">
										<ControlLabel>確認新密碼</ControlLabel>
										<FormControl type="password" placeholder="Confirm Password" onChange={onConfirmPasswordChange} />
									</FormGroup>
									<FormGroup>
										<Button block bsStyle="primary" disabled={loading} onClick={onResetPassword}>
											重設密碼<span style={{ display: showLoading }} className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
										</Button>
									</FormGroup>
								</div>
							</Form>
						</Col>
						<Col xs={6}></Col>
						<Col xs={6}>
							<div style={{ textAlign: 'right' }}>
								<span style={{ cursor: "pointer" }} onClick={toLoginPage}>登入</span>
							</div>
						</Col>
					</Row>
				</div>
			</div >
		)
	}
}