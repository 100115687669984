import {
	connect
} from 'react-redux'
import MRecordEditSearchComponent from '../components/MRecordEditSearchComponent.jsx';
import {
	clearSearch1Input
} from '../actions';

const mapStateToProps = (state) => ({
	filter: state.getIn(['itemSearchListReducer', 'filter']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	clearSearchInput: () => {
		dispatch(clearSearch1Input());
	}
})

const MRecordEditSearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MRecordEditSearchComponent)

export default MRecordEditSearchContainer;