import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findGearList,
	deleteGear,
	openGearModal,
	assignGear,
	findGear,
	clearGearListAlert
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
	data: state.getIn(['gearListReducer', 'list']),
	activePage: state.getIn(['gearListReducer', 'activePage']),
	itemPerPage: state.getIn(['gearListReducer', 'itemPerPage']),
	totalPage: state.getIn(['gearListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: findFields(ownProps),
	keys: ['gear_id'],
	showCheckBox: true,
	alertStyle: state.getIn(['gearListReducer', 'alertStyle']),
	alertMessage: state.getIn(['gearListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
		if (ownProps.filter) {
			dispatch(findGearList(pagingData, ownProps.filter));
		}
	},
	changeActivepage: (pagingData) => {
		dispatch(findGearList(pagingData, ownProps.filter));
	},
	deleteItems: (deleteKey, pagingData) => {
		dispatch(deleteGear(deleteKey, pagingData, ownProps.filter));
	},
	handleAdd: () => {
		dispatch(assignGear(ownProps.filter));
		dispatch(openGearModal({ forInsert: true }));
	},
	handleEdit: (pk) => {
		dispatch(findGear(pk));
		dispatch(openGearModal({ forInsert: false }));
	},
	clearAlert: () => {
		dispatch(clearGearListAlert());
	}
})

const GearListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

function findFields(ownProps) {
	return {
		gear_name: '裝備類別名稱',
		gear_type: ''
	}
}

export default GearListContainer;