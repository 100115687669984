import React from 'react';
import { Modal } from 'react-bootstrap';

import CorpListContainer from '../containers/CorpListContainer.jsx';
import CorpEditContainer from '../containers/CorpEditContainer.jsx';

export default class CorpsComponent extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
			<div>
				<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;基本資料管理&nbsp;&gt;&gt;&nbsp;單位管理</span>
				<CorpListContainer />
				<Modal show={this.props.showModal} onHide={this.props.closeModal}>
					<Modal.Header closeButton />
					<Modal.Body>
						<CorpEditContainer />
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}