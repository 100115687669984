import {
	connect
} from 'react-redux'
import RegisterComponent from '../components/RegisterComponent.jsx';
import {
	changeRegisterUserValue,
	findAllCorps,
	findAllManufacturers,
	changeShowCorpPage,
	changeShowManufacturer,
	registerUser,
	confirmRegisteredUser,
	signOut
} from '../actions';

const mapStateToProps = (state) => ({
	user: state.getIn(['registerReducer', 'user']),
	userValidation: state.getIn(['registerReducer', 'userValidation']),
	alertStyle: state.getIn(['registerReducer', 'alertStyle']),
	alertMessage: state.getIn(['registerReducer', 'alertMessage']),
	corps: state.getIn(['commonReducer', 'corps']),
	manufacturers: state.getIn(['commonReducer', 'manufacturers']),
	showCorp: state.getIn(['registerReducer', 'showCorp']),
	showManufacturer: state.getIn(['registerReducer', 'showManufacturer']),
	showConfirmationArea: state.getIn(['registerReducer', 'showConfirmationArea']),
	loading: state.getIn(['registerReducer', 'loading']),
	loading2: state.getIn(['registerReducer', 'loading2']),
});

const mapDispatchToProps = (dispatch) => ({
	setValue: (id, value) => {
		dispatch(changeRegisterUserValue({
			id: id,
			value: value
		}))
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	findAllManufacturers: () => {
		dispatch(findAllManufacturers());
	},
	changeShowCorpPage: (payload) => {
		dispatch(changeShowCorpPage(payload));
	},
	changeShowManufacturer: (payload) => {
		dispatch(changeShowManufacturer(payload));
	},
	registerUser: (user) => {
		dispatch(registerUser(user));
	},
	confirmRegisteredUser: (user) => {
		let userId = user.get('userId');
		let confirmationCode = user.get('confirmationCode');
		dispatch(confirmRegisteredUser(userId, confirmationCode, 'register'));
	},
	toSignOut: () => {
		dispatch(signOut());
	}
});

const RegisterContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(RegisterComponent)

export default RegisterContainer;