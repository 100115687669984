import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	list: [],
	activePage: 1,
	itemPerPage: 10,
	totalPage: 1,
	alertStyle: null,
	alertMessage: null
});

const warrantyListReducer = handleActions({
	FIND_WARRANTY_LIST: (state, { payload }) => {
		return state.merge({
			list: payload.data.dataTableData,
			activePage: payload.data.activePage,
			totalPage: payload.data.totalPage,
			itemPerPage: payload.data.itemPerPage,
		})
	},
	DELETE_WARRANTY: (state, { payload }) => {
		let statusCode = payload.statusCode;
		if (statusCode && statusCode !== "0") {
			return state.merge({
				alertStyle: payload.data.alertStyle,
				alertMessage: payload.data.alertMessage
			})
		} else {
			return state;
		}
	},
	CLEAR_WARRANTY_LIST_ALERT: (state, { payload }) => {
		return state.merge({
			alertStyle: null,
			alertMessage: null
		})
	}
}, initState);

export default warrantyListReducer;