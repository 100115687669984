import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	searchMRecord,
	findMRecord,
	deleteMRecord,
	clearMRecordSearchAlert
} from '../actions';

function findFields(type) {
	if (type === 'byItem') {
		return {
			status_display: '狀態',
			maintenance_type_display: '種類',
			batch_no: '梯次編號',
			squa_name: '維護時單位',
			firefighter_name: '維護時姓名',
			maintenance_date_display: '維護日期',
			suggest_maintain_display: '是否維護',
			suggest_scrap_display: '建議報廢',
			create_record_user: '登錄者'
		}
	} else {
		return {
			status_display: '狀態',
			maintenance_type_display: '種類',
			batch_no: '梯次編號',
			now_squa_name: '目前單位',	//byItem不顯示
			squa_name: '維護時單位',
			firefighter_name: '維護時姓名',
			item_no: '裝備編號',	//byItem不顯示
			gear_name: '裝備名稱',	//byItem不顯示
			maintenance_date_display: '維護日期',
			suggest_maintain_display: '是否維護',
			suggest_scrap_display: '建議報廢',
			create_record_user: '登錄者'
		}
	}
}

const mapStateToProps = (state, ownProps) => ({
	data: state.getIn(['mRecordListReducer', 'list']),
	activePage: state.getIn(['mRecordListReducer', 'activePage']),
	itemPerPage: state.getIn(['mRecordListReducer', 'itemPerPage']),
	totalPage: state.getIn(['mRecordListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: findFields(ownProps.type),
	keys: ['maintenance_record_id', 'item_id'],
	disableAdd: true,
	showCheckBox: true,
	edit0: {
		className: 'glyphicon glyphicon-th-list',
		showText: '維護明細'
	},
	alertStyle: state.getIn(['mRecordListReducer', 'alertStyle']),
	alertMessage: state.getIn(['mRecordListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
	},
	handleEdit: (pk) => {
		dispatch(findMRecord(pk));
	},
	deleteItems: (deleteKey, pagingData) => {
		dispatch(deleteMRecord(deleteKey, pagingData, ownProps.filter));
	},
	changeActivepage: (pagingData) => {
		dispatch(searchMRecord(ownProps.filter, pagingData));
	},
	clearAlert: () => {
		dispatch(clearMRecordSearchAlert());
	}
})

const MRecordListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default MRecordListContainer;