import React from 'react';
import { Row, Col, Button, Form, FormGroup, FormControl, ControlLabel, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

export default class ChangePwdComponent extends React.Component {

	componentDidMount() {
		this.props.clearAlert();
		this.setState({
			oldPassword: '',
			newPassword: '',
			confirmPassword: ''
		})
	}

	render() {
		let { toChangePwd, alertStyle, alertMessage, loading } = this.props;

		let onOldPasswordChange = (e) => {
			this.setState({ oldPassword: e.target.value });
		};
		let onNewPasswordChange = (e) => {
			this.setState({ newPassword: e.target.value });
		};
		let onConfirmPasswordChange = (e) => {
			this.setState({ confirmPassword: e.target.value });
		};
		let onChangePwd = () => {
			let { oldPassword, newPassword, confirmPassword } = this.state;
			toChangePwd(oldPassword, newPassword, confirmPassword);
		};
		let showLoading = (loading) ? '' : 'none';

		let alertDiv = null;
		if (alertStyle || alertMessage) {
			alertDiv = (
				<Row>
					<Alert bsStyle={alertStyle} onDismiss={this.props.clearAlert}>
						{alertMessage}
					</Alert>
				</Row>
			);
		}

		return (
			<div>
				<Row style={{ marginBottom: 30 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;更換密碼</span>
				</Row>
				<div className="card center-block" style={{ width: "30rem" }}>
					<div className="card-body">
						{alertDiv}
						<Row>
							<Col xs={12}>
								<Form>
									<FormGroup controlId="oldPassword">
										<ControlLabel>舊密碼</ControlLabel>
										<FormControl type="password" placeholder="Old Password" onChange={onOldPasswordChange} />
									</FormGroup>
									<FormGroup controlId="newPassword">
										<ControlLabel>新密碼</ControlLabel>
										<FormControl type="password" placeholder="New Password" onChange={onNewPasswordChange} />
									</FormGroup>
									<FormGroup controlId="confirmPassword">
										<ControlLabel>確認新密碼</ControlLabel>
										<FormControl type="password" placeholder="Confirm Password" onChange={onConfirmPasswordChange} />
									</FormGroup>
									<FormGroup>
										<Button block bsStyle="primary" disabled={loading} onClick={onChangePwd}>
											更換密碼<span style={{ display: showLoading }} className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
										</Button>
									</FormGroup>
								</Form>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		)
	}
}