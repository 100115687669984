import {
	connect
} from 'react-redux'
import ForgotPwdComponent from '../components/ForgotPwdComponent.jsx';
import {
	confirmPassword,
	forgotPassword,
	signOut
} from '../actions';

const mapStateToProps = (state) => ({
	alertStyle: state.getIn(['forgotPwdReducer', 'alertStyle']),
	alertMessage: state.getIn(['forgotPwdReducer', 'alertMessage']),
	showResetPwd: state.getIn(['forgotPwdReducer', 'showResetPwd']),
	loading: state.getIn(['forgotPwdReducer', 'loading'])
});

const mapDispatchToProps = (dispatch) => ({
	toResetPassword: (username, confirmationCode, newPassword, confirmPwd) => {
		dispatch(confirmPassword(username, confirmationCode, newPassword, confirmPwd));
	},
	toSendPwdComfirmationCode: (username) => {
		dispatch(forgotPassword(username));
	},
	toSignOut: () => {
		dispatch(signOut());
	}
});

const ForgotPwdContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ForgotPwdComponent)

export default ForgotPwdContainer;