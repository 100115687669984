import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const findMainSquaList = (payload, uiType) => (
  (dispatch) => {
    let corpId = payload.corpId;
    let url = '/squas/findAll/corps/' + corpId + '/parent/0';
    let dispatchType = '';
    if (uiType === 'SEARCH1') {
      dispatchType = 'FIND_MAIN_SQUA_LIST_SEARCH1';
    } else if (uiType === 'EDIT') {
      dispatchType = 'FIND_MAIN_SQUA_LIST_EDIT';
    } else if (uiType === 'USER') {
      dispatchType = 'FIND_MAIN_SQUA_LIST_USER';
    } else if (uiType === 'SQUA_LIST') {
      dispatchType = 'FIND_MAIN_SQUA_LIST_SQUA_LIST';
    } else if (uiType === 'LOCATION_SEARCH') {
      dispatchType = 'FIND_MAIN_SQUA_LIST_LOCATION_SEARCH';
    } else if (uiType === 'LOCATION_EDIT') {
      dispatchType = 'FIND_MAIN_SQUA_LIST_LOCATION_EDIT';
    } else if (uiType === 'CHIP_MAPPING') {
      dispatchType = 'FIND_MAIN_SQUA_LIST_CHIP_MAPPING';
    } else if (uiType === 'M_RECORD_SEARCH') {
      dispatchType = 'FIND_MAIN_SQUA_LIST_M_RECORD_SEARCH';
    }
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: dispatchType,
        payload: json
      });
    });
  }
);

export const findSubSquaList = (payload, uiType) => (
  (dispatch) => {
    let corpId = payload.corpId;
    let parentId = payload.parentId;
    let url = '/squas/findAll/corps/' + corpId + '/parent/' + parentId;
    let dispatchType = '';
    if (uiType === 'SEARCH1') {
      dispatchType = 'FIND_SUB_SQUA_LIST_SEARCH1';
    } else if (uiType === 'EDIT') {
      dispatchType = 'FIND_SUB_SQUA_LIST_EDIT';
    } else if (uiType === 'USER') {
      dispatchType = 'FIND_SUB_SQUA_LIST_USER';
    } else if (uiType === 'LOCATION_SEARCH') {
      dispatchType = 'FIND_SUB_SQUA_LIST_LOCATION_SEARCH';
    } else if (uiType === 'LOCATION_EDIT') {
      dispatchType = 'FIND_SUB_SQUA_LIST_LOCATION_EDIT';
    } else if (uiType === 'SQUA_LIST') {
      dispatchType = 'FIND_SUB_SQUA_LIST_SQUA_LIST';
    } else if (uiType === 'CHIP_MAPPING') {
      dispatchType = 'FIND_SUB_SQUA_LIST_CHIP_MAPPING';
    } else if (uiType === 'M_RECORD_SEARCH') {
      dispatchType = 'FIND_SUB_SQUA_LIST_M_RECORD_SEARCH';
    }
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: dispatchType,
        payload: json
      });
    });
  }
);

export const openSquaModal = createAction('OPEN_SQUA_MODAL');
export const closeSquaModal = createAction('CLOSE_SQUA_MODAL');
export const changeSquaValue = createAction('CHANGE_SQUA_VALUE');
export const changeSelectedMainSqua = createAction('CHANGE_SELECTED_MAIN_SQUA');
export const clearSelectedMainSqua = createAction('CLEAR_SELECTED_MAIN_SQUA');

export const deleteSqua = (deleteKey, payload) => (
  (dispatch) => {
    let url = '/squas';
    authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
      dispatch({
        type: 'DELETE_SQUA',
        payload: json
      });
      if (payload.parentId === 0) {
        dispatch(findMainSquaList(payload, 'SQUA_LIST'));
        dispatch(clearSelectedMainSqua());
      } else {
        dispatch(findSubSquaList(payload, 'SQUA_LIST'));
        dispatch(findMainSquaList({ corpId: payload.corpId, parentId: 0 }, 'SQUA_LIST'));
      }
    });
  }
);

export const addSqua = (squa) => (
  (dispatch) => {
    let payload = { corpId: squa.get('corpId'), parentId: squa.get('parentId') };
    let url = '/squas';
    authFetch('POST', url, JSON.stringify(squa), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_SQUA',
        payload: json
      });
      if (squa.get('parentId') === 0) {
        dispatch(findMainSquaList(payload, 'SQUA_LIST'));
      } else {
        dispatch(findSubSquaList(payload, 'SQUA_LIST'));
        dispatch(findMainSquaList({ corpId: squa.get('corpId'), parentId: 0 }, 'SQUA_LIST'));
      }
    });
  }
);

export const editSqua = (squa) => (
  (dispatch) => {
    let payload = { corpId: squa.get('corpId'), parentId: squa.get('parentId') };
    let url = '/squas/' + squa.get('squaId');
    authFetch('PUT', url, JSON.stringify(squa), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_SQUA',
        payload: json
      });
      if (squa.get('parentId') === 0) {
        dispatch(findMainSquaList(payload, 'SQUA_LIST'));
      } else {
        dispatch(findSubSquaList(payload, 'SQUA_LIST'));
      }
    });
  }
);

export const findSqua = (pk) => (
  (dispatch) => {
    let url = '/squas/' + pk.squa_id;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_SQUA',
        payload: json
      });
    });
  }
);

export const assignSqua = createAction('ASSIGN_SQUA');
export const clearSqua2Alert = createAction('CLEAR_SQUA2_ALERT');