import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	message: emptyMessage(),
	showModal: false
});

const messageReducer = handleActions({
	OPEN_MESSAGE_MODAL: (state, { payload }) => {
		return state.merge({
			showModal: true
		})
	},
	CLOSE_MESSAGE_MODAL: (state, { payload }) => {
		return state.merge({
			message: emptyMessage(),
			showModal: false
		})
	},
	FIND_MESSAGE: (state, { payload }) => {
		return state.merge({
			message: payload.data
		})
	}
}, initState);

function emptyMessage() {
	return {
	}
}

export default messageReducer;