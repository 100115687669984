import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const changeUserMessageValue = createAction('CHANGE_USER_MESSAGE_VALUE');
export const clearUserMessageAlert = createAction('CLEAR_USER_MESSAGE_ALERT');

export const findUserMessageList = (pagingData) => (
    (dispatch) => {
        let url = '/userMessages?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_USER_MESSAGE_LIST',
                payload: json
            });
        });
    }
);

export const deleteUserMessage = (deleteKey, pagingData) => (
    (dispatch) => {
        let url = '/userMessages';
        authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
            dispatch({
                type: 'DELETE_USER_MESSAGE',
                payload: json
            });
            dispatch(findUserMessageList(pagingData));
            dispatch(findUserMessageUnreadCount());
        });
    }
);

export const findUserMessageUnreadCount = () => (
    (dispatch) => {
        let url = '/userMessages/unreadCount';
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_USER_MESSAGE_UNREAD_COUNT',
                payload: json
            });
        });
    }
);

export const changeUserMessageStatusToRead = (userMessageId, pagingData) => (
    (dispatch) => {
        let url = '/userMessages/read/' + userMessageId;
        authFetch('PUT', url, null, (json) => {
            dispatch(findUserMessageList(pagingData));
            dispatch(findUserMessageUnreadCount());
        });
    }
);

export const findUserMessage = (payload, pagingData) => (
    (dispatch) => {
        let url = '/userMessages/' + payload.user_message_id;
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_USER_MESSAGE',
                payload: json
            });
            if (json.data.messageStatus === 0) { // 未讀
                // 改為已讀
                dispatch(changeUserMessageStatusToRead(payload.user_message_id, pagingData));
            }
        });
    }
);

export const openUserMessageModal = createAction('OPEN_USER_MESSAGE_MODAL');
export const closeUserMessageModal = createAction('CLOSE_USER_MESSAGE_MODAL');
export const clearUserMessageListAlert = createAction('CLEAR_USER_MESSAGE_LIST_ALERT');