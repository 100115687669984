import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	itemStatusChangeList: [],
	activePage: 1,
	itemPerPage: 10,
	totalPage: 1,
	filter: null	
});

const itemStatusChangeListReducer = handleActions({
	FIND_ITEM_STATUS_CHANGE_LIST: (state, { payload }) => {
		return state.merge({
			itemStatusChangeList: payload.data.dataTableData,
			activePage: payload.data.activePage,
			totalPage: payload.data.totalPage,
			itemPerPage: payload.data.itemPerPage,
			filter: payload.data.filter
		})
	}
}, initState);

export default itemStatusChangeListReducer;