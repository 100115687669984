import { authFetch } from '../AuthFetch';

export const findAuthRole = () => (
  (dispatch) => {
    let url = '/auth/role';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_AUTH_ROLE',
        payload: json
      });
    });
  }
);

export const findAuthRoleWithSelectCorp = (selectCorpId) => (
  (dispatch) => {
    let url = '/auth/role';
    if (selectCorpId) {
      url = url + '?selectCorpId=' + selectCorpId;
    }
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_AUTH_ROLE',
        payload: json
      });
    });
  }
);