import React from 'react';
import { Modal } from 'react-bootstrap';

import MessageListContainer from '../containers/MessageListContainer.jsx';
import MessageViewContainer from '../containers/MessageViewContainer.jsx';

export default class MessagesComponent extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
			<div>
				<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;基本資料管理&nbsp;&gt;&gt;&nbsp;訊息管理</span>
				<MessageListContainer />
				<Modal show={this.props.showModal} onHide={this.props.closeModal}>
					<Modal.Header closeButton />
					<Modal.Body>
						<MessageViewContainer />
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}