import React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

export default class UserEditComponent extends React.Component {

	componentDidMount() {
		let userRole = this.props.role.get('role');
		let userType = this.props.role.get('user_type');
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
		}

		// 系統管理者 or 廠商
		if (userRole === 0 || userType === 2) {
		}
		// 無資料
		else if (!userRole) {
			hashHistory.push("/");
		}
		// 單位管理者 & 一般使用者
		else {
			if (!corpId) {
				hashHistory.push("/");
			}
		}
	}

	editItem() {
		this.props.editItem(this.props.user, {
			activePage: this.props.activePage,
			itemPerPage: this.props.itemPerPage
		}, this.props.forInsert);
	}

	render() {
		let userType = this.props.user.get('userType');
		let showCorp = (userType === 1) ? "block" : "none";

		let userRole = this.props.role.get('role');
		let roleList = [{ label: "單位管理者", value: 1 }, { label: "一般使用者", value: 2 }];
		// 系統管理者
		if (userRole === 0) {
			roleList = [{ label: "系統管理者", value: 0 }, { label: "單位管理者", value: 1 }, { label: "一般使用者", value: 2 }];
			// 廠商: 沒有單位管理者
			if (userType === 2) {
				roleList = [{ label: "系統管理者", value: 0 }, { label: "一般使用者", value: 2 }];
			}
		}

		return (
			<div>
				<Row>
					<Col xs={2} sm={2}>
						<Button onClick={(event) => {
							this.editItem();
						}} bsStyle="primary">
							送出
					    </Button>
					</Col>
					<Col xs={10} sm={10}>
						<Alert bsStyle={this.props.alertStyle}>
							{this.props.alertMessage}
						</Alert>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} sm={12}>
						<FieldGroup
							id="userId"
							type="text"
							label="帳號"
							value={this.props.user.get('userId')}
							placeholder=""
							disabled={true}
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.userValidation.get('userId')}
						/>
						<FieldGroup
							id="userName"
							type="text"
							label="名稱"
							value={this.props.user.get('userName')}
							placeholder=""
							required="true"
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.userValidation.get('userName')}
						/>
						<div style={{ display: showCorp }}>
							<DropDownInput ref="mainSquaIdDdi"
								id="mainSquaId"
								label="大隊"
								options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
								selected={[{ label: (this.props.user.get('mainSquaName') != null ? this.props.user.get('mainSquaName') : ''), value: this.props.user.get('mainSquaId') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
									this.props.setValue('mainSquaName', label);
									if (value) {
										this.props.findSubSquaList({ corpId: this.props.user.get('corpId'), parentId: value });
									} else {
										this.props.findSubSquaList({ corpId: this.props.user.get('corpId'), parentId: -1 });
									}
								}}
								validation={this.props.userValidation.get('mainSquaId')}
								extraClear={() => {
									// this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
									this.props.setValue('subSquaId', null);
									this.props.setValue('subSquaName', null);
								}}
							/>
							<DropDownInput ref="subSquaIdDdi"
								id="subSquaId"
								label="分隊"
								options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
								selected={[{ label: (this.props.user.get('subSquaName') != null ? this.props.user.get('subSquaName') : ''), value: this.props.user.get('subSquaId') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
									this.props.setValue('subSquaName', label);
								}}
								validation={this.props.userValidation.get('subSquaId')}
							/>
						</div>
						<RadioGroup
							id="role"
							label="身份"
							radioList={roleList}
							value={this.props.user.get('role')}
							onChange={(id, value) => {
								this.props.setValue(id, parseInt(value, 10));
							}}
							validation={this.props.userValidation.get('role')}
						/>
						<RadioGroup
							id="status"
							label="狀態"
							radioList={[{ label: "啟用", value: 1 }, { label: "停用", value: 2 }]}
							value={this.props.user.get('status')}
							onChange={(id, value) => {
								this.props.setValue(id, parseInt(value, 10));
							}}
							validation={this.props.userValidation.get('status')}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}