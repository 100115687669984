import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	event: initEvent(),
	eventValidation: {},
	list: [],
	alertStyle: null,
	alertMessage: null,
});

const emergencyEventReducer = handleActions({
	CHANGE_EVENT_VALUE: (state, { payload }) => {
		let event = state.get('event');
		return state.merge({
			event: event.set(payload.id, payload.value)
		})
	},
	CHANGE_EVENT_SIDE_LIST_VALUE: (state, { payload }) => {
		let event = state.get('event');
		let list = event.get(payload.sideId);
		let i = 0;
		for (let side of list.toJS()) {
			if (side.areaId === payload.id) {
				break;
			}
			i++;
		}
		let bean = list.get(i);
		bean = bean.set('areaName', payload.value);
		list = list.set(i, bean);
		return state.merge({
			event: event.set(payload.sideId, list)
		})
	},
	CLEAR_EVENT_ALERT: (state, { payload }) => {
		return state.merge({
			eventValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	INIT_EVENT: (state, { payload }) => {
		return state.merge({
			event: initEvent(payload),
			eventValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	FORM_SUBMIT_EVENT: (state, { payload }) => {
		return state.merge({
			event: payload.data.bean,
			eventValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage
		})
	},
	FIND_EVENTS: (state, { payload }) => {
		return state.merge({
			list: payload.data,
		})
	},
	FIND_EVENT: (state, { payload }) => {
		return state.merge({
			event: payload.data
		})
	}
}, initState);

function initEvent(payload) {
	let corpId = null;
	if (payload) {
		corpId = payload
	}
	return {
		eventId: null,
		eventName: null,
		corpId: corpId,
		side0List: [{ areaId: 'a10', areaName: '前進指揮所' }, { areaId: 'a90', areaName: '休息區' }],
		side1List: [],
		side2List: [],
		side3List: [],
		side4List: []
	}
}

export default emergencyEventReducer;