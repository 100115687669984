import {
	connect
} from 'react-redux'
import ItemTransferSearchComponent from '../components/ItemTransferSearchComponent.jsx';
import {
	clearSearch1Input
} from '../actions';

const mapStateToProps = (state) => ({
	filter: state.getIn(['itemSearchListReducer', 'filter']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	clearSearchInput: () => {
		dispatch(clearSearch1Input());
	}
})

const ItemTransferSearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemTransferSearchComponent)

export default ItemTransferSearchContainer;