import {
	connect
} from 'react-redux'
import MRecordViewComponent from '../components/MRecordViewComponent.jsx';
import {
	changeItemEventKey,
	searchMRecord
} from '../actions';

const mapStateToProps = (state) => ({
	item: state.getIn(['mRecordReducer', 'item']),
	record: state.getIn(['mRecordReducer', 'record']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	changeItemEventKey: (payload) => {
		dispatch(changeItemEventKey(payload));
	},
	searchMRecord: (payload, pagingData) => {
		dispatch(searchMRecord(payload, pagingData));
	}
})

const MRecordViewContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MRecordViewComponent)

export default MRecordViewContainer;