import React from 'react';
import { Row, Col, Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { findCurrentUser } from '../constants/awsCognito.js';
import AlertGroup from './common/AlertGroup.jsx';

export default class LoginComponent extends React.Component {

	componentDidMount() {
		if (findCurrentUser() != null) {
			this.props.router.push('/');
		}
		this.setState({
			username: '',
			password: ''
		})
	}

	render() {
		let { toAuthenticateUser, alertStyle, alertMessage, loading } = this.props;

		let onUserNameChange = (e) => {
			this.setState({ username: e.target.value });
		};
		let onPasswordChange = (e) => {
			this.setState({ password: e.target.value });
		};
		let onAuthenticateUser = () => {
			let { username, password } = this.state;
			toAuthenticateUser(username, password);
		};
		let toRegisterPage = () => {
			this.props.router.push('/register');
		};
		let toConfirmationPage = () => {
			this.props.router.push('/confirmation');
		};
		let toForgotPwdPage = () => {
			this.props.router.push('/forgotPwd');
		};
		let showLoading = (loading) ? '' : 'none';

		return (
			<div className="card center-block" style={{ width: "30rem" }}>
				<div className="card-body">
					<Row>
						<Col xs={12}><h3><b>登入</b></h3></Col>
						<Col xs={12}>
							<AlertGroup
								alertStyle={alertStyle}
								alertMessage={alertMessage}
							/>
						</Col>
						<Col xs={12}><hr /></Col>
					</Row>
					<Row>
						<Col xs={12}>
							<FormGroup controlId="loginUserName">
								<ControlLabel>帳號</ControlLabel>
								<FormControl type="text" placeholder="UserName" onChange={onUserNameChange} />
							</FormGroup>
							<FormGroup controlId="loginPassword">
								<ControlLabel>密碼</ControlLabel>
								<FormControl type="password" placeholder="Password" onChange={onPasswordChange} />
							</FormGroup>
						</Col>
						<Col xs={12}>
							<Button block bsStyle="primary" disabled={loading} onClick={onAuthenticateUser}>
								登入<span style={{ display: showLoading }} className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
							</Button>
						</Col>
						<Col xs={6}>
							<div style={{ textAlign: 'left', paddingTop: 10 }}>
								<span style={{ cursor: "pointer" }} onClick={toRegisterPage}>建立帳號</span>
							</div>
						</Col>
						<Col xs={6}>
							<div style={{ textAlign: 'right', paddingTop: 10 }}>
								<span style={{ cursor: "pointer" }} onClick={toForgotPwdPage}>忘記密碼</span>
							</div>
						</Col>
						<Col xs={6}></Col>
						<Col xs={6}>
							<div style={{ textAlign: 'right', paddingTop: 10 }}>
								<span style={{ cursor: "pointer" }} onClick={toConfirmationPage}>重新認證</span>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		)
	}
}