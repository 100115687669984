import React from 'react';
import { Modal, Row, Col, FormGroup, Button } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import DropDownInput from './common/DropDownInput.jsx';

import UserListContainer from '../containers/UserListContainer.jsx';
import UserEditContainer from '../containers/UserEditContainer.jsx';

export default class UsersComponent extends React.Component {

	componentDidMount() {

		let userRole = this.props.role.get('role');
		let userType = this.props.role.get('user_type');
		let corpId = this.props.role.get('corp_id');
		// 系統管理者 or 廠商
		if (userRole === 0 || userType === 2) {
			this.props.findAllCorps();
			this.props.findAllManufacturers();
		}
		// 無資料
		else if (!userRole) {
			hashHistory.push("/");
		}
		// 單位管理者 & 一般使用者
		else {
			if (corpId) {
				this.props.setValue("userType", userType);
				this.props.setValue("corpId", corpId);
				this.props.findUnVerifiedCount({ userType: userType, corpId: corpId });
				this.props.searchUser({
					userType: userType,
					corpId: corpId,
					verified: null
				})
			} else {
				hashHistory.push("/");
			}
		}
	}

	searchUser() {
		this.props.searchUser(this.props.searchBean.toJS());
	}

	render() {
		let verifiedList = [{ label: '已審核', value: 'Y' }, { label: '未審核', value: 'N' }];

		let searchDiv = null;
		let userRole = -1;
		if (this.props.role) {
			userRole = this.props.role.get('role');
		}
		if (userRole === 0) {
			searchDiv = (
				<div className="well col-xs-12 col-sm-12">
					<Row>
						<Col xs={3} sm={3}>
							<div style={{ paddingTop: 25 }}>
								<FormGroup>
									<span style={{ paddingRight: 10 }}>
										<input type="radio" name="userType" value='1' onChange={(event) => {
											this.props.setValue("userType", parseInt(event.target.value, 10));
											this.props.changeShowCorp('block');
											this.props.changeShowManufacturer('none');
										}} checked={this.props.searchBean.get('userType') === 1} />消防員</span>
									<span style={{ paddingRight: 10 }}>
										<input type="radio" name="userType" value='2' onChange={(event) => {
											this.props.setValue("userType", parseInt(event.target.value, 10));
											this.props.changeShowCorp('none');
											this.props.changeShowManufacturer('block');
										}} checked={this.props.searchBean.get('userType') === 2} />廠商</span>
								</FormGroup>
							</div>
						</Col>
						<Col xs={3} sm={3}>
							<div style={{ display: this.props.showCorp }}>
								<DropDownInput id="corpId"
									label="單位"
									options={this.props.corps.map((value, key) => ({ label: value.get('corp_name'), value: value.get('corp_id') })).toJS()}
									selected={[{ label: (this.props.searchBean.get('corpName') != null ? this.props.searchBean.get('corpName') : ''), value: this.props.searchBean.get('corpId') }]}
									onChange={(id, value, label) => {
										this.props.setValue(id, value);
										this.props.setValue('corpName', label);
									}}
								/>
							</div>
							<div style={{ display: this.props.showManufacturer }}>
								<DropDownInput id="manufacturerId"
									label="廠商"
									options={this.props.manufacturers.map((value, key) => ({ label: value.get('manufacturer_name'), value: value.get('manufacturer_id') })).toJS()}
									selected={[{ label: (this.props.searchBean.get('manufacturerName') != null ? this.props.searchBean.get('manufacturerName') : ''), value: this.props.searchBean.get('manufacturerId') }]}
									onChange={(id, value, label) => {
										this.props.setValue(id, value);
										this.props.setValue('manufacturerName', label);
									}}
								/>
							</div>
						</Col>
						<Col xs={3} sm={3}>
							<DropDownInput id="verified"
								label="是否審核"
								options={verifiedList}
								selected={[{ label: (this.props.searchBean.get('verifiedDesc') != null ? this.props.searchBean.get('verifiedDesc') : ''), value: this.props.searchBean.get('verified') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
									this.props.setValue('verifiedDesc', label);
								}}
							/>
						</Col>
						<Col xs={3} sm={3}>
							<div style={{ paddingTop: 25 }}>
								<Button onClick={(event) => {
									this.searchUser();
								}} bsStyle="default">
									<span className="glyphicon glyphicon-search" style={{ marginRight: 5 }} /> 搜尋
					    		</Button>
							</div>
						</Col>
					</Row>
				</div>
			);
		} else if (userRole === 1) {
			let unVerifiedCountMsg = null;
			let showMsg = 'none';
			if (this.props.unVerifiedCount) {
				unVerifiedCountMsg = ('尚有 ' + this.props.unVerifiedCount + ' 筆帳號未審核');
				showMsg = 'block';
			}
			searchDiv = (
				<div className="well col-xs-12 col-sm-12" style={{ display: showMsg, marginTop: 10 }}>
					<Row>
						<Col xs={12} sm={12}>
							<span style={{ color: 'red' }}>{unVerifiedCountMsg}</span>
						</Col>
					</Row>
				</div>
			);
		}

		return (
			<div>
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;基本資料管理&nbsp;&gt;&gt;&nbsp;帳號管理</span>
				</Row>
				{searchDiv}
				<UserListContainer filter={this.props.filter} />
				<Modal show={this.props.showModal} onHide={this.props.closeModal}>
					<Modal.Header closeButton />
					<Modal.Body>
						<UserEditContainer />
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}