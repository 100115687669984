import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
  alertStyle: null,
  alertMessage: null,
  loading: false,
  showResetPwd: 'none'
});

const loginReducer = handleActions({
  AWS_COGNITO_FORGOT_PASSWORD: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: null
    });
  },
  AWS_COGNITO_FORGOT_PASSWORD_SUCCESS: (state, { payload }) => {
    return state.merge({
      alertStyle: 'success',
      alertMessage: '發送認證碼成功',
      showResetPwd: 'block'
    });
  },
  AWS_COGNITO_FORGOT_PASSWORD_ERROR: (state, { payload }) => {
    return state.merge({
      alertStyle: 'danger',
      alertMessage: payload,
    });
  },
  AWS_COGNITO_CONFITM_PASSWORD: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: null,
      loading: true
    });
  },
  AWS_COGNITO_CONFITM_PASSWORD_SUCCESS: (state, { payload }) => {
    return state.merge({
      alertStyle: 'success',
      alertMessage: '重設密碼成功',
      loading: false
    });
  },
  AWS_COGNITO_CONFITM_PASSWORD_ERROR: (state, { payload }) => {
    return state.merge({
      alertStyle: 'danger',
      alertMessage: payload,
      loading: false
    });
  }
}, initState);

export default loginReducer;