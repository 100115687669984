import React from 'react';
import { connect } from 'react-redux'
import { hashHistory } from 'react-router';

class CheckSelectCorp extends React.Component {
	componentDidMount() {
		let userRole = this.props.role.get('role');
		let corpId = this.props.role.get('corp_id');
		let userType = this.props.role.get('user_type');
		// 系統管理者 or 廠商
		if (userRole === 0 || userType === 2) {
			if (!corpId) {
				hashHistory.push("/choiceCorp");
			}
		}
		// 無資料
		else if (!userRole) {
			hashHistory.push("/");
		}
		// 單位管理者 & 一般使用者
		else {
			if (!corpId) {
				hashHistory.push("/");
			}
		}
	}

	render() {
		return (
			<div></div>
		)
	}
}

const mapStateToProps = (state) => ({
	role: state.getIn(['authReducer', 'role'])
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckSelectCorp)