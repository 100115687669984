import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findWarrantyList,
	openWarrantyModal,
	findWarranty
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['warrantyListReducer', 'list']),
	activePage: state.getIn(['warrantyListReducer', 'activePage']),
	itemPerPage: state.getIn(['warrantyListReducer', 'itemPerPage']),
	totalPage: state.getIn(['warrantyListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		corp_name: '單位名稱',
		warranty_year: '保固年限',
		replacement_year: '汰換年限',
		remind_display: '提醒'
	},
	keys: ['corp_id'],
	disableAdd: true,
	showCheckBox: false,
	alertStyle: state.getIn(['warrantyListReducer', 'alertStyle']),
	alertMessage: state.getIn(['warrantyListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch) => ({
	refresh: (pagingData) => {
		dispatch(findWarrantyList(pagingData));
	},
	changeActivepage: (pagingData) => {
		dispatch(findWarrantyList(pagingData));
	},
	handleEdit: (pk) => {
		dispatch(findWarranty(pk));
		dispatch(openWarrantyModal({ forInsert: false }));
	},
	clearAlert: () => {
	}
})

const WarrantyListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default WarrantyListContainer;