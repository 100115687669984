import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findItemStatusChangeList
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['itemStatusChangeListReducer', 'itemStatusChangeList']),
	activePage: state.getIn(['itemStatusChangeListReducer', 'activePage']),
	itemPerPage: state.getIn(['itemStatusChangeListReducer', 'itemPerPage']),
	totalPage: state.getIn(['itemStatusChangeListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		status_display: '更改後狀態',
		lend_squa_name: '借入分隊',
		s_change_status_time: '狀態更改日期',
		create_user: '登錄者'
	},
	keys: ['change_id'],
	disableAdd: true,
	disableEdit: true
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
	},
	changeActivepage: (pagingData) => {
		dispatch(findItemStatusChangeList(ownProps.filter, pagingData));
	},
	deleteItems: (deleteKey, pagingData) => {
	},
	handleAdd: () => {
	},
	handleEdit: (pk) => {
	}
})

const ItemStatusChangeListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default ItemStatusChangeListContainer;