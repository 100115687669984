import { createAction } from 'redux-actions';
import { authFetch, authUpload } from '../AuthFetch';

export const findLocationBatchRecords = () => (
  (dispatch) => {
    let url = '/locationBatch/batchRecords';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_LOCATION_BATCH_RECORDS',
        payload: json
      });
    });
  }
);

export const uploadLocationData = (uploadFile, corpId) => (
  (dispatch) => {
    let formData = new FormData();
    formData.append('uploadfile', uploadFile);
    if (uploadFile == null) {
      dispatch({
        type: 'AFTER_UPLOAD_LOCATION_DATA',
        payload: { statusCode: "102", displayMessage: "", data: { alertMessage: "未上傳任何檔案", alertStyle: "danger", bean: null } }
      });
      return;
    }

    dispatch({
      type: 'BEFORE_UPLOAD_LOCATION_DATA'
    });
    let url = '/locationBatch/uploadLocationData/corps/' + corpId;
    authUpload('POST', url, formData, (json) => {
      dispatch({
        type: 'AFTER_UPLOAD_LOCATION_DATA',
        payload: json
      });
      dispatch(findLocationBatchRecords());
    }, (err) => {
      dispatch({
        type: 'AFTER_UPLOAD_LOCATION_DATA',
        payload: { statusCode: "999", displayMessage: "", data: { alertMessage: err.stack, alertStyle: "danger", bean: null } }
      });
    });
  }
);

export const findKkOrders = () => (
  (dispatch) => {
    let url = '/tkkData/kkOrders/';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_KK_ORDERS',
        payload: json
      });
    });
  }
);

export const findKkOrdersByCorpId = (corpId) => (
  (dispatch) => {
    let url = '/tkkData/kkOrders/corps/' + corpId;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_KK_ORDERS',
        payload: json
      });
    });
  }
);

export const changeLocationExportValue = createAction('CHANGE_LOCATION_EXPORT_VALUE');
export const changeLocationExportBatchId = createAction('CHANGE_LOCATION_EXPORT_BATCH_ID');

export const exportLocationData = (payload) => (
  (dispatch) => {
    dispatch({
      type: 'BEFORE_UPLOAD_LOCATION_DATA'
    });
    let url = '/locationBatch/exportLocationData/';
    authFetch('PUT', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'GENERATE_LOCATION_XLS',
        payload: json
      });
    });
  }
);

export const clearLocationUploadInput = createAction('CLEAR_LOCATION_UPLOAD_INPUT');
export const clearLocationUploadAlert = createAction('CLEAR_LOCATION_UPLOAD_ALERT');

export const deleteLocationByBatchId = (payload) => (
  (dispatch) => {
    let url = '/locationBatch/deleteByBatchId';
    authFetch('DELETE', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'DELETE_LOCATIONS_BY_BATCHID',
        payload: json
      });
      dispatch(findLocationBatchRecords());
    });
  }
);