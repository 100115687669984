import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	record: emptyRecord(),
	recordValidation: {},
	item: {},			//ui將item獨立出來, clear不會清除, 等於後端的 MaintenanceRecordItem
	alertStyle: null,
	alertMessage: null,
	forInsert: true,
	mainSquas: [],
	subSquas: [],
	searchBean: emptySearchBean(),
	showSearchInput: true,
	unClosedCount: 0,
	maintenanceItems: [] // 維修項目列表
});

const corpReducer = handleActions({
	CHANGE_M_RECORD_VALUE: (state, { payload }) => {
		let record = state.get('record');
		return state.merge({
			record: record.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_M_RECORD: (state, { payload }) => {
		return state.merge({
			record: payload.data.bean,
			recordValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	FIND_M_RECORD: (state, { payload }) => {
		return state.merge({
			record: payload.data,
			item: payload.data.item	//ui將item獨立出來, clear不會清除, 等於後端的 MaintenanceRecordItem
		})
	},
	CLEAR_M_RECORD_INPUT: (state, { payload }) => {
		return state.merge({
			record: emptyRecord(),
			recordValidation: {},
			alertStyle: null,
			alertMessage: null,
		})
	},
	CLEAR_M_RECORD_ALERT: (state, { payload }) => {
		return state.merge({
			recordValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	CHANGE_M_RECORD_SEARCHBEAN_VALUE: (state, { payload }) => {
		let searchBean = state.get('searchBean');
		return state.merge({
			searchBean: searchBean.set(payload.id, payload.value),
		})
	},
	FIND_MAIN_SQUA_LIST_M_RECORD_SEARCH: (state, { payload }) => {
		return state.merge({
			mainSquas: payload.data
		})
	},
	FIND_SUB_SQUA_LIST_M_RECORD_SEARCH: (state, { payload }) => {
		return state.merge({
			subSquas: payload.data
		})
	},
	CLEAR_M_RECORD_SEARCH_INPUT: (state, { payload }) => {
		let searchBean = state.get('searchBean');
		return state.merge({
			searchBean: emptySearchBean(searchBean.toJS()),
			subSquas: [],
			showSearchInput: true
		})
	},
	CHANGE_M_RECORD_SHOW_SEARCH_INPUT: (state, { payload }) => {
		return state.merge({
			showSearchInput: payload
		})
	},
	FIND_M_RECORD_UNCLOSED_COUNT: (state, { payload }) => {
		return state.merge({
			unClosedCount: payload.data
		})
	},
	FIND_M_ITEMS: (state, { payload }) => {
		return state.merge({
			maintenanceItems: payload.data
		})
	}
}, initState);

function emptyRecord() {
	return {
		maintenanceRecordId: null,
		maintenanceType: null,
		manufacturerId: null,
		manufacturerName: '',
		batchNo: null,
		maintenanceDate: null,
		qaUser: null,
		maintenanceOrderId: null,
		closeMaintenanceDate: null,
		suggestMaintain: null,
		maintenanceItems: null,
		maintenanceItemList: null,
		maintenanceDesc: null,
		suggestScrap: null,
		scrapReason: null,
		note: null
	}
}

function emptySearchBean(orgSearchBean) {
	let corpId = null;
	if (orgSearchBean) {
		corpId = orgSearchBean.corpId;
	}
	return {
		corpId: corpId,
		mainSquaId: null,
		mainSquaName: '',
		subSquaId: null,
		subSquaName: '',
		maintenanceRecordId: null,
		batchNo: null,
		itemId: null,
		itemNo: null,
		firefighterName: null,
		maintenanceType: null,
		status: null,
		fromMaintenanceDate: null,
		toMaintenanceDate: null
	}
}

export default corpReducer;