import {
	connect
} from 'react-redux'
import ItemSearch1Component from '../components/ItemSearch1Component.jsx';

const mapStateToProps = (state) => ({
	filter: state.getIn(['itemSearchListReducer', 'filter']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
})

const ItemSearch1Container = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemSearch1Component)

export default ItemSearch1Container;