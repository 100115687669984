import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	zoom: 16,
	initialCenter: null,
	markerArr: [],
	keepRefreshing: false,
	initialCenterIndex: 0,
	showNoRecordMsg: false
});

const emergencyUserLocationReducer = handleActions({
	FIND_ACTIVE_LOCATIONS: (state, { payload }) => {
		let initialCenter = null;
		if (payload.data.initialCenter) {
			initialCenter = payload.data.initialCenter;
		}
		return state.merge({
			initialCenter: initialCenter,
			markerArr: payload.data.markerArr,
			showNoRecordMsg: payload.data.showNoRecordMsg
		})
	},
	CHANGE_KEEP_REFRESH_LOCATION: (state, { payload }) => {
		return state.merge({
			keepRefreshing: payload
		})
	},
	CHANGE_INITIAL_CENTER_INDEX: (state, { payload }) => {
		return state.merge({
			initialCenterIndex: payload
		})
	}
}, initState);

export default emergencyUserLocationReducer;