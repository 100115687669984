import React from 'react';
import { Button, Checkbox, Pagination, Row, Col, DropdownButton, MenuItem, FormControl, Alert } from 'react-bootstrap';

export default class DataTable extends React.Component {

  constructor(props) {
    super(props);

    this.handleChangeActivepage = this.handleChangeActivepage.bind(this);
    this.handleChangeItemPerPage = this.handleChangeItemPerPage.bind(this);

    this.checkBoxMap = new Map();
    this.searchInput = '';
  }

  componentDidMount() {
    if (this.props.refresh) {
      this.props.refresh({ activePage: this.props.activePage, itemPerPage: this.props.itemPerPage }, this.props.filter);
    }
  }

  componentWillUnmount() {
    if (this.props.clearAlert) {
      this.props.clearAlert();
    }
  }

  handleAdd() {
    this.props.handleAdd();
  }

  handleEdit(pk) {
    this.props.handleEdit(pk, { activePage: this.props.activePage, itemPerPage: this.props.itemPerPage }, this.props.filter);
  }

  handleEdit1(pk) {
    this.props.handleEdit1(pk, { activePage: this.props.activePage, itemPerPage: this.props.itemPerPage }, this.props.filter);
  }

  handleEdit2(pk) {
    this.props.handleEdit2(pk, { activePage: this.props.activePage, itemPerPage: this.props.itemPerPage }, this.props.filter);
  }

  handleDelete() {
    let r = window.confirm('確認刪除 ?');
    if (r === true) {
      let deleteKey = [];
      for (let key of this.checkBoxMap.keys()) {
        if (this.checkBoxMap.get(key)) {
          if (this.checkBoxMap.get(key).checked) {
            deleteKey.push(key);
          }
        }
      }
      this.props.deleteItems(deleteKey, { activePage: this.props.activePage, itemPerPage: this.props.itemPerPage }, this.props.filter);
    }
  }

  handleChangeActivepage(eventKey) {
    if (this.props.changeActivepage) {
      this.props.changeActivepage({
        activePage: eventKey,
        itemPerPage: this.props.itemPerPage
      }, this.props.filter);
    }
  }

  handleSearch(searchWord) {
    if (this.props.searchColumns) {
      let filter = {};
      if (searchWord) {
        for (let column of this.props.searchColumns) {
          filter[column] = searchWord;
        }
      }
      this.props.search({
        activePage: 1,
        itemPerPage: this.props.itemPerPage
      }, filter);
    }
  }

  handleChangeItemPerPage(event) {
    if (this.props.changeActivepage) {
      this.props.changeActivepage({
        activePage: 1,
        itemPerPage: event.target.value
      }, this.props.filter);
    }
  }

  render() {

    let deleteBtn = (
      <Button bsStyle="default" onClick={(event) => {
        this.handleDelete();
      }}>
        <span className="glyphicon glyphicon-trash" style={{ color: '#a94442', marginRight: 10 }} />
        <span style={{ color: '#a94442' }}>刪除</span>
      </Button>
    );

    let tableHeader = [];
    if (this.props.showCheckBox) {
      if (!this.props.disableEdit) {
        tableHeader.push((
          <th key='checkBoxes' colSpan={2} style={{ width: 1, textAlign: 'center' }}>{deleteBtn}</th>
        ));
      }
      else {
        tableHeader.push((
          <th key='checkBoxes' style={{ width: 1, textAlign: 'center' }}>{deleteBtn}</th>
        ));
      }
    } else {
      if (!this.props.disableEdit) {
        tableHeader.push((
          <th key='moreTd' style={{ width: 1, textAlign: 'center' }}></th>
        ));
      } else {
      }
    }

    for (let fieldName in this.props.fields) {
      if (this.props.fields.hasOwnProperty(fieldName)) {
        tableHeader.push((
          <th key={fieldName}>{this.props.fields[fieldName]}</th>
        ));
      }
    }

    let tableRows = [];
    if (this.props.data) {
      //for (let i in this.props.data) {
      for (let i = 0; i < this.props.data.size; i++) {
        //let row = this.props.data[i];
        let row = this.props.data.get(i);
        let pkStr = i;  // default key
        // primary key object
        let pk = {};
        if (this.props.keys) {
          for (let i = 0; i < this.props.keys.length; i++) {
            let keyField = this.props.keys[i];
            //pk[keyField] = row[keyField];
            pk[keyField] = row.get(keyField);
          }
          // primary key as a string
          pkStr = JSON.stringify(pk);
        }

        let editBtn = null;
        let defaultEditClassName = "glyphicon glyphicon-edit";
        let defaultEditShowText = "修改";
        if (this.props.edit0) {
          defaultEditClassName = this.props.edit0.className;
          defaultEditShowText = this.props.edit0.showText;
        }

        // List 捉 "buttonDisabled", "buttonText" 兩個欄位置換
        let buttonDisabled = false;
        if (row.get('buttonDisabled') === 'Y') {
          buttonDisabled = true;
        }
        if (row.get('buttonText')) {
          defaultEditShowText = row.get('buttonText');
        }

        // List 捉 "is_new", "show_is_new_column" 顯示 "new" label
        let isNew = row.get('is_new');
        let showIsNewColumn = row.get('show_is_new_column');

        // edit button 兩個以上
        if (this.props.showEditMore) {
          let dropdownBtn0 = null;
          let dropdownBtn1 = null;
          let dropdownBtn2 = null;

          dropdownBtn0 = (<MenuItem eventKey="0" onClick={(event) => {
            this.handleEdit(pk);
          }}><span className={defaultEditClassName} style={{ marginRight: 10 }} />{defaultEditShowText} </MenuItem>);
          if (this.props.edit1) {
            dropdownBtn1 = (<MenuItem eventKey="1" onClick={(event) => {
              this.handleEdit1(pk);
            }}><span className={this.props.edit1.className} style={{ marginRight: 10 }} />{this.props.edit1.showText} </MenuItem>);
          }
          if (this.props.edit2) {
            dropdownBtn2 = (<MenuItem eventKey="2" onClick={(event) => {
              this.handleEdit2(pk);
            }}><span className={this.props.edit2.className} style={{ marginRight: 10 }} />{this.props.edit2.showText} </MenuItem>);
          }

          editBtn = (
            <DropdownButton title="More" id="moreBtn">
              {dropdownBtn0}
              {dropdownBtn1}
              {dropdownBtn2}
            </DropdownButton>
          );
        }
        // edit button 只有一個 
        else {
          editBtn = (
            <Button disabled={buttonDisabled} bsStyle="default" onClick={(event) => {
              this.handleEdit(pk);
            }}><span className={defaultEditClassName} style={{ marginRight: 10 }} />{defaultEditShowText}</Button>
          );
        }

        let rowObj = [];
        if (this.props.showCheckBox) {
          rowObj.push((
            <td key='checkBoxes' className="rwdtdbutton">
              <Checkbox style={{ marginTop: -20 }} inline disabled={buttonDisabled} inputRef={(ref) => this.checkBoxMap.set(pk, ref)} />
            </td>
          ));
        }
        if (!this.props.disableEdit) {
          rowObj.push((
            <td key='edits' className="rwdtdbutton">
              {editBtn}
            </td>
          ));
        }
        for (let fieldName in this.props.fields) {
          if (this.props.fields.hasOwnProperty(fieldName)) {
            let showIsNew = (isNew && fieldName === showIsNewColumn) ? '' : 'none';
            rowObj.push((
              <td key={fieldName} data-label={this.props.fields[fieldName]}>
                <span className="tableHiddenInWeb">:&nbsp;&nbsp;</span>
                <span className="label label-danger" style={{ display: showIsNew, marginRight: 5 }}>New</span>
                {row.get(fieldName)}
              </td>
            ));
          }
        }

        tableRows.push((
          <tr key={pkStr} onClick={
            (event) => {
              if (this.props.onClick) {
                // this.props.onClick(pk);
              }
            }
          }>
            {rowObj}
          </tr>
        ));
      }
    }

    let showAlertArea = (this.props.alertStyle || this.props.alertMessage) ? '' : 'none';
    let showAddBtn = (this.props.disableAdd) ? 'none' : '';
    let showSearchBar = (this.props.searchColumns) ? '' : 'none';
    let searchPlaceholder = (this.props.searchPlaceholder) ? this.props.searchPlaceholder : 'Search';
    let showPaging = (this.props.hidePaging || this.props.data.size === 0) ? 'none' : '';
    let showDeleteBtn = (this.props.showCheckBox && this.props.data.size > 0) ? '' : 'none';
    let showTable = (this.props.data.size > 0) ? '' : 'none';

    return (
      <div style={{ width: '100%' }}>
        <Row style={{ marginLeft: -15, marginRight: -15, paddingTop: 10, paddingBottom: 10 }}>
          <Col xs={12} sm={12} style={{ display: showAlertArea }}>
            <Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
              {this.props.alertMessage}
            </Alert>
          </Col>
          <Col xs={4} sm={4}>
            <div style={{ textAlign: 'left' }}>
              <span style={{ display: showDeleteBtn }} className="tableHiddenInWeb">
                {deleteBtn}{' '}
              </span>
              <span style={{ display: showAddBtn }}>
                <Button bsStyle="default" onClick={(event) => {
                  this.handleAdd();
                }}>
                  <span className="glyphicon glyphicon-plus" style={{ marginRight: 10 }} />新增
                </Button>
              </span>
            </div>
          </Col>
          <Col xs={8} sm={8}>
            <div className="search" style={{ display: showSearchBar }}>
              <FormControl type="text" className="searchTerm" placeholder={searchPlaceholder} inputRef={ref => { this.searchInput = ref; }}
                onKeyDown={(event) => {
                  // keyCode = 13 => eneter 鍵
                  if (event.keyCode === 13) {
                    this.handleSearch(this.searchInput.value);
                  }
                }}
                tabIndex="1" />
              <button className="searchButton" onClick={(event) => {
                this.handleSearch(this.searchInput.value);
              }} tabIndex="2">
                <i className="glyphicon glyphicon-search" />
              </button>
            </div>
          </Col>
        </Row>
        <table className="table rwdtable table-bordered table-condensed table-hover table-striped" style={{ display: showTable }}>
          <thead>
            <tr>
              {tableHeader}
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>
        <Row>
          <Col xs={3} sm={3}>
            <div className="form-inline" style={{ marginLeft: -15, display: showPaging }}>
              <label>每頁筆數:&nbsp;&nbsp;</label>
              <select className="form-control" id="foo" style={{ width: 65 }} onChange={this.handleChangeItemPerPage} value={this.props.itemPerPage}>
                <option value={10}>10</option>
                <option value={50}>50</option>
              </select>
            </div>
          </Col>
          <Col xs={9} sm={9}>
            <div style={{ textAlign: 'right', marginTop: -20, marginRight: -15, display: showPaging }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                items={this.props.totalPage}
                maxButtons={this.props.pagingMaxButtons}
                activePage={this.props.activePage}
                onSelect={this.handleChangeActivepage} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}