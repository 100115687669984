import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	searchItem2,
	findItem,
	clearItemSearchAlert
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['itemSearch2ListReducer', 'itemSearchList']),
	activePage: state.getIn(['itemSearch2ListReducer', 'activePage']),
	itemPerPage: state.getIn(['itemSearch2ListReducer', 'itemPerPage']),
	totalPage: state.getIn(['itemSearch2ListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		squa_name: '大隊分隊',
		item_no: '裝備編號',
		asset_no: '財產編號',
		gear_name: '裝備類別',
		model: '型式',
		color: '顏色',
		sizing: '尺寸',
		manufacture_year: '年份',
		location_name: '保管人員地點',
		expired_date: '使用期限',
		lend_squa_name: '借入分隊'
	},
	keys: ['item_id', 'corp_id'],
	disableAdd: true,
	edit0: {
		className: 'glyphicon glyphicon-eye-open',
		showText: '裝備總覽'
	},
	alertStyle: state.getIn(['itemSearch2ListReducer', 'alertStyle']),
	alertMessage: state.getIn(['itemSearch2ListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
	},
	changeActivepage: (pagingData) => {
		dispatch(searchItem2(ownProps.filter, pagingData));
	},
	handleEdit: (pk) => {
		dispatch(findItem(pk, ownProps.uiType));
	},
	clearAlert: () => {
		dispatch(clearItemSearchAlert());
	}
})

const ItemSearch2ListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default ItemSearch2ListContainer;