import React from 'react';
import { Row, Col, Alert, Button, Form } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';
import moment from 'moment';

export default class SendMessageComponent extends React.Component {

	componentDidMount() {
		this.props.findAllCorps();
		let defaultStart = moment().format('YYYY-MM-DD');
		let defaultEnd = moment().add(1, 'months').add(-1, 'days').format('YYYY-MM-DD');
		this.props.setValue('sDisplayTimeStart', defaultStart);
		this.props.setValue('sDisplayTimeEnd', defaultEnd);
	}

	sendMessage() {
		this.props.sendMessage(this.props.message);
	}

	componentWillUnmount() {
		this.props.clearAlert();
		this.props.clearMessage();
	}

	render() {

		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Col xs={12} sm={12}>
					<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
						{this.props.alertMessage}
					</Alert>
				</Col>
			);
		}

		return (
			<div>
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;基本資料管理&nbsp;&gt;&gt;&nbsp;發送訊息</span>
				</Row>
				<Row>
					{alertDiv}
					<Col xs={12} sm={12}>
						<Button onClick={(event) => {
							this.sendMessage();
						}} bsStyle="primary">
							發送訊息
					    </Button>
					</Col>
				</Row>
				<br />
				<Form>
					<Row>
						<Col xs={12} sm={12}>
							<DropDownInput id="sendToCorpId"
								label="To 單位"
								options={this.props.corps.map((value, key) => ({ label: value.get('corp_name'), value: value.get('corp_id') })).toJS()}
								selected={[{ label: (this.props.message.get('sendToCorpName') != null ? this.props.message.get('sendToCorpName') : ''), value: this.props.message.get('sendToCorpId') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
									this.props.setValue('sendToCorpName', label);
								}}
								validation={this.props.messageValidation.get('sendToCorpId')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12}>
							<FieldGroup
								id="messageTitle"
								type="text"
								label="訊息標題"
								value={this.props.message.get('messageTitle')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.messageValidation.get('messageTitle')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12}>
							<FieldGroup
								id="messageContent"
								type="text"
								label="訊息內容"
								value={this.props.message.get('messageContent')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								componentClass="textarea"
								rows="10"
								validation={this.props.messageValidation.get('messageContent')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="sDisplayTimeStart"
								type="date"
								label="顯示開始時間"
								value={this.props.message.get('sDisplayTimeStart')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.messageValidation.get('sDisplayTimeStart')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="sDisplayTimeEnd"
								type="date"
								label="顯示結束時間"
								value={this.props.message.get('sDisplayTimeEnd')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.messageValidation.get('sDisplayTimeEnd')}
							/>
						</Col>
					</Row>
				</Form>
			</div>
		)
	}
}