import React from 'react';
import { Row, Col, Button, Collapse, Form } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';
import MRecordListContainer from '../containers/MRecordListContainer.jsx';

export default class MRecordSearchComponent extends React.Component {

	componentDidMount() {
		// this.props.clearSearchInput();
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
		}
	}

	search() {
		this.props.search(this.props.searchBean.toJS());
	}

	componentWillUnmount() {
		// this.props.clearSearchInput();
	}

	clearSearchInput() {
		this.refs.mainSquaIdDdi.refs.typeahead.getInstance().clear();
		this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
		this.refs.maintenanceTypeDdi.refs.typeahead.getInstance().clear();
		this.props.clearSearchInput();
	}

	changeShowSearchInput() {
		if (this.props.showSearchInput) {
			this.props.changeShowSearchInput(false);
		} else {
			this.props.changeShowSearchInput(true);
		}
	}

	render() {
		let iconClassName = "glyphicon glyphicon-plus";
		if (this.props.showSearchInput) {
			iconClassName = "glyphicon glyphicon-minus";
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;維護記錄查詢</span>
				</Row>
				<div style={{ marginTop: 20 }}>
					<Button bsSize="xsmall" onClick={() => this.changeShowSearchInput()}>
						<span className={iconClassName} />
					</Button>
					<Collapse in={this.props.showSearchInput}>
						<div className="well col-xs-12 col-sm-12">
							<Form>
								<Row>
									<Col xs={10} sm={10}>
										<div style={{ marginBottom: 20 }}>
											<Button onClick={(event) => {
												this.search();
											}} bsStyle="default">
												<span className="glyphicon glyphicon-search" style={{ marginRight: 10 }} />搜尋
					    					</Button>
											<span style={{ marginLeft: 10 }}>組合式查詢，若不設條件可留空白</span>
										</div>
									</Col>
									<Col xs={2} sm={2}>
										<div style={{ marginBottom: 20, float: 'right' }}>
											<Button onClick={(event) => {
												this.clearSearchInput();
											}} bsStyle="default">
												清除
					    				</Button>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} sm={6}>
										<DropDownInput ref="mainSquaIdDdi"
											id="mainSquaId"
											label="大隊"
											options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
											selected={[{ label: (this.props.searchBean.get('mainSquaName') != null ? this.props.searchBean.get('mainSquaName') : ''), value: this.props.searchBean.get('mainSquaId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('mainSquaName', label);
												if (value) {
													this.props.findSubSquaList({ corpId: this.props.searchBean.get('corpId'), parentId: value });
												}
												else {
													this.props.findSubSquaList({ corpId: this.props.searchBean.get('corpId'), parentId: -1 });
												}
											}}
											extraClear={() => {
												// this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
												this.props.setValue('subSquaId', null);
												this.props.setValue('subSquaName', null);
											}}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<DropDownInput ref="subSquaIdDdi"
											id="subSquaId"
											label="分隊"
											options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
											selected={[{ label: (this.props.searchBean.get('subSquaName') != null ? this.props.searchBean.get('subSquaName') : ''), value: this.props.searchBean.get('subSquaId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('subSquaName', label);
											}}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="maintenanceRecordId"
											type="number"
											label="系統維護序號"
											value={this.props.searchBean.get('maintenanceRecordId')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="itemId"
											type="text"
											label="系統裝備序號"
											value={this.props.searchBean.get('itemId')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
								</Row>
								<Row>

									<Col xs={12} sm={6}>
										<FieldGroup
											id="itemNo"
											type="text"
											label="裝備編號"
											value={this.props.searchBean.get('itemNo')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="batchNo"
											type="text"
											label="梯次編號"
											value={this.props.searchBean.get('batchNo')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12} sm={6}>
										<DropDownInput ref="maintenanceTypeDdi"
											id="maintenanceType"
											label="維護種類"
											options={[{ label: "清洗", value: 1 }, { label: "維修", value: 2 }]}
											selected={[{ label: (this.props.searchBean.get('maintenanceTypeDisplay') != null ? this.props.searchBean.get('maintenanceTypeDisplay') : ''), value: this.props.searchBean.get('maintenanceType') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('maintenanceTypeDisplay', label);
											}}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="firefighterName"
											type="text"
											label="維護時人員 (關鍵字)"
											value={this.props.searchBean.get('firefighterName')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="fromMaintenanceDate"
											type="date"
											label="維護日期 (From)"
											value={this.props.searchBean.get('fromMaintenanceDate')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="toMaintenanceDate"
											type="date"
											label="維護日期 (To)"
											value={this.props.searchBean.get('toMaintenanceDate')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
								</Row>
							</Form>
						</div>
					</Collapse>
				</div>
				<MRecordListContainer filter={this.props.searchBean.toJS()} />
			</div>
		)
	}
}