import React from 'react';
import { Row, Col } from 'react-bootstrap';


export default class UserMessageViewComponent extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
			<Row>
				<Col>
					<table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
						<tbody>
							<tr>
								<td><label className="control-label">訊息標題:</label> {this.props.userMessage.get('messageTitle')}</td>
							</tr>
							<tr>
								<td><label className="control-label">訊息內容:</label>
									<pre style={{ border: 'none', backgroundColor: '#FFFFFF' }}>
										{this.props.userMessage.get('messageContent')}
									</pre>
								</td>
							</tr>
							<tr>
								<td><label className="control-label">發送時間:</label> {this.props.userMessage.get('sSendTime')}</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</Row>
		)
	}
}
