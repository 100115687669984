import {
	connect
} from 'react-redux'
import LocationsComponent from '../components/LocationsComponent.jsx';
import {
	closeLocationModal,
	changeSearchLocationValue,
	findLocationList,
	findAllCorps,
	findMainSquaList,
	findSubSquaList,
	findEnabledUsersByCorpId,
	changeShowLocationSearchInput,
	clearLocationSearchInput
} from '../actions';
import Immutable from 'immutable';

const mapStateToProps = (state) => ({
	showModal: state.getIn(['locationReducer', 'showModal']),
	searchBean: state.getIn(['locationReducer', 'searchBean']),
	corps: state.getIn(['commonReducer', 'corps']),
	mainSquas: state.getIn(['locationReducer', 'searchMainSquas']),
	subSquas: state.getIn(['locationReducer', 'searchSubSquas']),
	users: state.getIn(['locationReducer', 'searchUsers']),
	showSearchInput: state.getIn(['locationReducer', 'showSearchInput']),
	role: state.getIn(['authReducer', 'role']),
	filter: (state.getIn(['locationListReducer', 'filter'])) ? state.getIn(['locationListReducer', 'filter']) : Immutable.Map({})
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => {
		dispatch(closeLocationModal());
	},
	setValue: (id, value) => {
		dispatch(changeSearchLocationValue({
			id: id,
			value: value
		}))
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	searchLocation: (searchBean) => {
		dispatch(findLocationList({}, searchBean.toJS()));
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'LOCATION_SEARCH'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'LOCATION_SEARCH'));
	},
	findUserList: (payload) => {
		dispatch(findEnabledUsersByCorpId(payload, 'LOCATION_SEARCH'));
	},
	changeShowLocationSearchInput: (payload) => {
		dispatch(changeShowLocationSearchInput(payload));
	},
	clearSearchInput: () => {
		dispatch(clearLocationSearchInput());
	}
})

const LocationsContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(LocationsComponent)

export default LocationsContainer;