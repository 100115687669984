import {
	connect
} from 'react-redux'
import ItemEditSearchComponent from '../components/ItemEditSearchComponent.jsx';
import {
	clearSearch1Input
} from '../actions';

const mapStateToProps = (state) => ({
	filter: state.getIn(['itemSearchListReducer', 'filter']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	clearSearchInput: () => {
		dispatch(clearSearch1Input());
	}
})

const ItemEditSearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemEditSearchComponent)

export default ItemEditSearchContainer;