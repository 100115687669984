import { createAction } from 'redux-actions';
import { authFetch, authUpload } from '../AuthFetch';

export const findItemBatchRecords = () => (
  (dispatch) => {
    let url = '/itemBatch/batchRecords';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_ITEM_BATCH_RECORDS',
        payload: json
      });
    });
  }
);

export const uploadItemData = (uploadFile, corpId) => (
  (dispatch) => {
    let formData = new FormData();
    formData.append('uploadfile', uploadFile);
    if (uploadFile == null) {
      dispatch({
        type: 'AFTER_UPLOAD_ITEM_DATA',
        payload: { statusCode: "102", displayMessage: "", data: { alertMessage: "未上傳任何檔案", alertStyle: "danger", bean: null } }
      });
      return;
    }

    dispatch({
      type: 'BEFORE_UPLOAD_ITEM_DATA'
    });
    let url = '/itemBatch/uploadItemData/corps/' + corpId;
    authUpload('POST', url, formData, (json) => {
      dispatch({
        type: 'AFTER_UPLOAD_ITEM_DATA',
        payload: json
      });
      dispatch(findItemBatchRecords());
    }, (err) => {
      dispatch({
        type: 'AFTER_UPLOAD_ITEM_DATA',
        payload: { statusCode: "999", displayMessage: "", data: { alertMessage: err.stack, alertStyle: "danger", bean: null } }
      });
    });
  }
);

export const findKkOrders = () => (
  (dispatch) => {
    let url = '/tkkData/kkOrders/';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_KK_ORDERS',
        payload: json
      });
    });
  }
);

export const findKkOrdersByCorpId = (corpId) => (
  (dispatch) => {
    let url = '/tkkData/kkOrders/corps/' + corpId;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_KK_ORDERS',
        payload: json
      });
    });
  }
);

export const changeItemExportValue = createAction('CHANGE_ITEM_EXPORT_VALUE');
export const changeItemExportBatchId = createAction('CHANGE_ITEM_EXPORT_BATCH_ID');

export const exportItemData = (payload) => (
  (dispatch) => {
    dispatch({
      type: 'BEFORE_UPLOAD_ITEM_DATA'
    });
    let url = '/itemBatch/exportItemData/';
    authFetch('PUT', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'GENERATE_ITEM_XLS',
        payload: json
      });
    });
  }
);

export const clearItemUploadInput = createAction('CLEAR_ITEM_UPLOAD_INPUT');
export const clearItemUploadAlert = createAction('CLEAR_ITEM_UPLOAD_ALERT');

export const deleteItemByBatchId = (payload) => (
  (dispatch) => {
    let url = '/itemBatch/deleteByBatchId';
    authFetch('DELETE', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'DELETE_ITEMS_BY_BATCHID',
        payload: json
      });
      dispatch(findItemBatchRecords());
    });
  }
);