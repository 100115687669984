import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AuthenticationDetails } from 'amazon-cognito-identity-js';
import { hashHistory } from 'react-router';

import { findCurrentUser, findCognitoUser, findCognitoUserPool } from '../constants/awsCognito.js';
import { findAuthRole } from './authActions.js'
import { findUserMessageUnreadCount } from './userMessageActions.js'
import { addUser, updateLastLoginTime, passEmailValidationOnlyDisplay } from './userActions.js'
import { noAuthFetch } from '../AuthFetch';

import { createAction } from 'redux-actions';

export const registerUser = (user) => (
  (dispatch) => {
    dispatch({ type: 'AWS_COGNITO_REGISTER_USER' });
    let userId = user.get('userId');
    let password = user.get('password');
    let email = user.get('email');

    let url = '/noAuth/validateAddUser';
    noAuthFetch('POST', url, JSON.stringify(user), (json) => {
      let statusCode = json.statusCode;
      if (statusCode === '0') {
        let attributeList = [];
        let dataEmail = { Name: 'email', Value: email };
        let attributeEmail = new CognitoUserAttribute(dataEmail);
        attributeList.push(attributeEmail);
        let userPool = findCognitoUserPool();
        userPool.signUp(userId, password, attributeList, null, (err, result) => {
          if (err) {
            dispatch({ type: 'AWS_COGNITO_REGISTER_USER_ERROR', payload: err.message });
          } else {
            dispatch(addUser(user));
          }
        });
      }
      else {
        dispatch({
          type: 'FORM_SUBMIT_REGISTER_USER',
          payload: json
        });
      }
    });
  }
);

export const confirmRegisteredUser = (username, confirmationCode, uiType) => (
  (dispatch) => {
    if (uiType === 'register') {
      dispatch({ type: 'AWS_COGNITO_CONFIRM_REGISTERED_USER_REGISTER' });
    } else if (uiType === 'confirmation') {
      dispatch({ type: 'AWS_COGNITO_CONFIRM_REGISTERED_USER_CONFIRMATION' });
    }
    if (username === '' || confirmationCode === '') {
      if (uiType === 'confirmation') {
        dispatch({ type: 'AWS_COGNITO_CONFIRM_REGISTERED_USER_ERROR_CONFIRMATION', payload: "Please input username and confirmation code" });
      }
      return;
    }
    let cognitoUser = findCognitoUser(username);
    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        if (uiType === 'register') {
          dispatch({ type: 'AWS_COGNITO_CONFIRM_REGISTERED_USER_ERROR_REGISTER', payload: err.message });
        } else if (uiType === 'confirmation') {
          dispatch({ type: 'AWS_COGNITO_CONFIRM_REGISTERED_USER_ERROR_CONFIRMATION', payload: err.message });
        }
      } else {
        if (uiType === 'register') {
          dispatch({ type: 'AWS_COGNITO_CONFIRM_REGISTERED_USER_SUCCESS_REGISTER' });
        } else if (uiType === 'confirmation') {
          dispatch({ type: 'AWS_COGNITO_CONFIRM_REGISTERED_USER_SUCCESS_CONFIRMATION' });
        }
        // 更新email列表顯示為已認證
        dispatch(passEmailValidationOnlyDisplay({ userId: username }));
      }
    });
  }
);

export const resendConfirmationCode = (username) => (
  (dispatch) => {
    dispatch({ type: 'AWS_COGNITO_RESEND_CONFIRMATION_CODE' });
    if (username === '') {
      dispatch({ type: 'AWS_COGNITO_RESEND_CONFIRMATION_CODE_ERROR', payload: "Please input username" });
      return;
    }
    let cognitoUser = findCognitoUser(username);
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        dispatch({ type: 'AWS_COGNITO_RESEND_CONFIRMATION_CODE_ERROR', payload: err.message });
      } else {
        dispatch({ type: 'AWS_COGNITO_RESEND_CONFIRMATION_CODE_SUCCESS' });
      }
    });
  }
);

export const authenticateUser = (username, password) => (
  (dispatch) => {
    dispatch({ type: 'AWS_COGNITO_AUTHENTICATE_USER' });

    let authenticationData = {
      Username: username,
      Password: password
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let cognitoUser = findCognitoUser(username);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        // let token = result.getAccessToken().getJwtToken();
        // console.log('access token = ' + token);

        dispatch({ type: 'AWS_COGNITO_AUTHENTICATE_USER_SUCCESS' });
        dispatch(findAuthRole());
        dispatch(findUserMessageUnreadCount());
        dispatch(updateLastLoginTime());
        hashHistory.push("/");
      },
      onFailure: (err) => {
        dispatch({ type: 'AWS_COGNITO_AUTHENTICATE_USER_ERROR', payload: err.message });
      }
    });
  }
);

export const forgotPassword = (username) => (
  (dispatch) => {
    dispatch({ type: 'AWS_COGNITO_FORGOT_PASSWORD' });
    if (username === '') {
      dispatch({ type: 'AWS_COGNITO_FORGOT_PASSWORD_ERROR', payload: "Please input username" });
      return;
    }
    let cognitoUser = findCognitoUser(username);
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        dispatch({ type: 'AWS_COGNITO_FORGOT_PASSWORD_SUCCESS' });
      },
      onFailure: (err) => {
        dispatch({ type: 'AWS_COGNITO_FORGOT_PASSWORD_ERROR', payload: err.message });
      },
      // inputVerificationCode() {
      //   let verificationCode = prompt('Please input password reset code' ,'');
      //   cognitoUser.confirmPassword(verificationCode, newPassword, this);
      // }
    });
  }
);

export const confirmPassword = (username, verificationCode, newPassword, confirmPassword) => (
  (dispatch) => {
    dispatch({ type: 'AWS_COGNITO_CONFITM_PASSWORD' });
    if (newPassword !== confirmPassword) {
      dispatch({ type: 'AWS_COGNITO_CONFITM_PASSWORD_ERROR', payload: 'Password does not match confirmed password' });
      return;
    }
    let cognitoUser = findCognitoUser(username);
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        dispatch({ type: 'AWS_COGNITO_CONFITM_PASSWORD_SUCCESS' });
      },
      onFailure(err) {
        dispatch({ type: 'AWS_COGNITO_CONFITM_PASSWORD_ERROR', payload: err.message });
      }
    });
  }
);

export const changePassword = (oldPassword, newPassword, confirmPassword) => (
  (dispatch) => {
    dispatch({ type: 'AWS_COGNITO_PASSWORD_CHANGE' });
    if (newPassword !== confirmPassword) {
      dispatch({ type: 'AWS_COGNITO_PASSWORD_CHANGE_ERROR', payload: 'Password does not match confirmed password' });
      return;
    }
    let cognitoUser = findCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          dispatch({ type: 'AWS_COGNITO_PASSWORD_CHANGE_ERROR', payload: err.message });
          return;
        }
        if (session.isValid()) {
          // change password if session is valid.
          cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
            if (err) {
              dispatch({ type: 'AWS_COGNITO_PASSWORD_CHANGE_ERROR', payload: err.message });
              return;
            }
            dispatch({ type: 'AWS_COGNITO_PASSWORD_CHANGE_SUCCESS', payload: result });
          });
        } else {
          dispatch({ type: 'AWS_COGNITO_PASSWORD_CHANGE_ERROR', payload: 'User session expired. Please login again.' });
        }
      });
    } else {
      dispatch({ type: 'AWS_COGNITO_PASSWORD_CHANGE_ERROR', payload: 'User does not exist. Please login again.' });
    }
  }
);

export const signOut = () => (
  (dispatch) => {
    let currentUser = findCurrentUser();
    if (currentUser == null) {
      // dispatch({ type: 'AWS_COGNITO_RETRIEVE_CURRENT_USER', payload: null });
    } else {
      currentUser.signOut();
      // cognitoUser.globalSignOut(callback);
      // dispatch({ type: 'AWS_COGNITO_RETRIEVE_CURRENT_USER', payload: cognitoUser.username });
      dispatch({ type: 'AWS_COGNITO_SIGNOUT', payload: null });
      hashHistory.push("/login");
    }
  }
);

export const awsCognitoPasswordClear = createAction('AWS_COGNITO_PASSWORD_CLEAR');
