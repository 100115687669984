import React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

export default class LocationEditComponent extends React.Component {

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
			this.props.findUserList({ corpId: corpId });
		}
	}

	editItem() {
		this.props.editItem(this.props.location, {
			activePage: this.props.activePage,
			itemPerPage: this.props.itemPerPage
		}, this.props.forInsert);
	}

	render() {
		return (
			<div>
				<Row>
					<Col xs={2} sm={2}>
						<Button onClick={(event) => {
							this.editItem();
						}} bsStyle="primary">
							送出
					    </Button>
					</Col>
					<Col xs={10} sm={10}>
						<Alert bsStyle={this.props.alertStyle}>
							{this.props.alertMessage}
						</Alert>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} sm={12}>
						ID: {this.props.location.get('locationId')}
					</Col>
					<Col xs={12} sm={12}>
						<FieldGroup
							id="locationName"
							type="text"
							label="名稱"
							value={this.props.location.get('locationName')}
							placeholder=""
							required="true"
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.locationValidation.get('locationName')}
						/>
					</Col>
					<Col xs={12} sm={12}>
						<RadioGroup
							id="locationType"
							label="類別"
							required="true"
							radioList={[{ label: "人員", value: 1 }, { label: "車輛", value: 2 }, { label: "倉庫", value: 3 }, { label: "其他", value: 4 }]}
							value={this.props.location.get('locationType')}
							onChange={(id, value) => {
								this.props.setValue(id, parseInt(value, 10));
							}}
							validation={this.props.locationValidation.get('locationType')}
						/>
					</Col>
					<Col xs={12} sm={12}>
						<DropDownInput ref="mainSquaIdDdi"
							id="mainSquaId"
							label="大隊"
							required="true"
							options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
							selected={[{ label: (this.props.location.get('mainSquaName') != null ? this.props.location.get('mainSquaName') : ''), value: this.props.location.get('mainSquaId') }]}
							onChange={(id, value, label) => {
								this.props.setValue(id, value);
								this.props.setValue('mainSquaName', label);
								if (value) {
									this.props.findSubSquaList({ corpId: this.props.location.get('corpId'), parentId: value });
								} else {
									this.props.findSubSquaList({ corpId: this.props.location.get('corpId'), parentId: -1 });
								}
							}}
							validation={this.props.locationValidation.get('mainSquaId')}
							extraClear={() => {
								// this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
								this.props.setValue('subSquaId', null);
								this.props.setValue('subSquaName', null);
							}}
						/>
					</Col>
					<Col xs={12} sm={12}>
						<DropDownInput ref="subSquaIdDdi"
							id="subSquaId"
							label="分隊"
							required="true"
							options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
							selected={[{ label: (this.props.location.get('subSquaName') != null ? this.props.location.get('subSquaName') : ''), value: this.props.location.get('subSquaId') }]}
							onChange={(id, value, label) => {
								this.props.setValue(id, value);
								this.props.setValue('subSquaName', label);
							}}
							validation={this.props.locationValidation.get('subSquaId')}
						/>
					</Col>
					<Col xs={12} sm={12}>
						<FieldGroup
							id="locationNo"
							type="text"
							label="編號"
							value={this.props.location.get('locationNo')}
							placeholder=""
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.locationValidation.get('locationNo')}
						/>
					</Col>
					<Col xs={12} sm={12}>
						<DropDownInput id="userId"
							label="所屬帳號"
							options={this.props.users.map((value, key) => ({ label: value.get('user_id'), value: value.get('user_id') })).toJS()}
							selected={[{ label: (this.props.location.get('userId') != null ? this.props.location.get('userId') : ''), value: this.props.location.get('userId') }]}
							onChange={(id, value, label) => {
								this.props.setValue(id, value);
							}}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}