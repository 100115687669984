import { connect } from 'react-redux'
import LocationUploadComponent from '../components/LocationUploadComponent.jsx';
import {
  uploadLocationData,
  findKkOrders,
  findKkOrdersByCorpId,
  exportLocationData,
  changeLocationExportValue,
  changeLocationExportBatchId,
  clearLocationUploadAlert,
  clearLocationUploadInput,
  findLocationBatchRecords,
  deleteLocationByBatchId
} from '../actions';

const mapStateToProps = (state) => ({
  exportBean: state.getIn(['locationUploadReducer', 'exportBean']),
  exportBeanValidation: state.getIn(['locationUploadReducer', 'exportBeanValidation']),
  corps: state.getIn(['commonReducer', 'corps']),
  alertStyle: state.getIn(['locationUploadReducer', 'alertStyle']),
  alertMessage: state.getIn(['locationUploadReducer', 'alertMessage']),
  kkOrders: state.getIn(['tkkDataReducer', 'kkOrders']),
  batchDeleteBean: state.getIn(['locationUploadReducer', 'batchDeleteBean']),
  batchDeleteBeanValidation: state.getIn(['locationUploadReducer', 'batchDeleteBeanValidation']),
  batchRecords: state.getIn(['locationUploadReducer', 'batchRecords']),
  role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (uploadFile, corpId) => {
    dispatch(uploadLocationData(uploadFile, corpId));
  },
  findKkOrders: (corpId) => {
    if (corpId) {
      dispatch(findKkOrdersByCorpId(corpId));
    } else {
      dispatch(findKkOrders());
    }
  },
  setValue: (id, value) => {
    dispatch(changeLocationExportValue({
      id: id,
      value: value
    }))
  },
  changeBatchId: (value) => {
    dispatch(changeLocationExportBatchId(value));
  },
  exportFile: (exportBean) => {
    dispatch(exportLocationData(exportBean));
  },
  clearAlert: () => {
    dispatch(clearLocationUploadAlert());
  },
  clearInput: () => {
    dispatch(clearLocationUploadInput());
  },
  findBatchRecords: () => {
    dispatch(findLocationBatchRecords())
  },
  deleteByBatchId: (payload) => {
    dispatch(deleteLocationByBatchId(payload))
  }
})

const LocationUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationUploadComponent)

export default LocationUploadContainer;