import React from 'react';
import { Alert } from 'react-bootstrap';

export default class AlertGroup extends React.Component {

    render() {

        let showAlert = (this.props.alertStyle || this.props.alertMessage) ? '' : 'none';
        let showLoading = (this.props.loading) ? '' : 'none';

        return (
            <Alert bsStyle={this.props.alertStyle} style={{ display: showAlert }}>
                <span style={{ display: showLoading }}>
                    <img alt="loading" src="/loading.gif" width="30" height="30" />{'      '}
                </span>
                {this.props.alertMessage}
            </Alert>
        )
    }
}