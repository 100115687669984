import {
	connect
} from 'react-redux'
import ItemViewComponent from '../components/ItemViewComponent.jsx';
import {
	changeItemEventKey,
	findItemTransferList,
	findItemStatusChangeList,
	searchMRecord,
	findItem,
	findMRecordItem
} from '../actions';

const mapStateToProps = (state) => ({
	item: state.getIn(['itemReducer', 'item']),
	eventKey: state.getIn(['itemReducer', 'eventKey']),
	role: state.getIn(['authReducer', 'role']),
	transferFilter: (state.getIn(['itemTransferListReducer', 'filter'])) ? state.getIn(['itemTransferListReducer', 'filter']).toJS() : {},
	statusChangeFilter: (state.getIn(['itemStatusChangeListReducer', 'filter'])) ? state.getIn(['itemStatusChangeListReducer', 'filter']).toJS() : {}
})

const mapDispatchToProps = (dispatch) => ({
	changeItemEventKey: (payload) => {
		dispatch(changeItemEventKey(payload));
	},
	findItemTransferList: (payload, pagingData) => {
		dispatch(findItemTransferList(payload, pagingData));
	},
	findItemStatusChangeList: (payload, pagingData) => {
		dispatch(findItemStatusChangeList(payload, pagingData));
	},
	searchMRecord: (payload, pagingData) => {
		dispatch(searchMRecord(payload, pagingData));
	},
	findItem: (payload) => {
		dispatch(findItem({ item_id: payload.itemId, corp_id: payload.corpId }, 'VIEW'));
	},
	findMRecordItem: (payload) => {
		dispatch(findMRecordItem(payload));
	}
})

const ItemViewContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemViewComponent)

export default ItemViewContainer;