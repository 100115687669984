import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const changeCorpValue = createAction('CHANGE_CORP_VALUE');

export const findCorpList = (pagingData) => (
  (dispatch) => {
    let url = '/corps?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_CORP_LIST',
        payload: json
      });
    });
  }
);

export const addCorp = (corp, pagingData) => (
  (dispatch) => {
    let url = '/corps';
    authFetch('POST', url, JSON.stringify(corp), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_CORP',
        payload: json
      });
      dispatch(findCorpList(pagingData));
    });
  }
);

export const editCorp = (corp, pagingData) => (
  (dispatch) => {
    let url = '/corps/' + corp.get('corpId');
    authFetch('PUT', url, JSON.stringify(corp), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_CORP',
        payload: json
      });
      dispatch(findCorpList(pagingData));
    });
  }
);

export const deleteCorp = (deleteKey, pagingData) => (
  (dispatch) => {
    let url = '/corps';
    authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
      dispatch({
        type: 'DELETE_CORP',
        payload: json
      });
      dispatch(findCorpList(pagingData));
    });
  }
);

export const findCorp = (pk) => (
  (dispatch) => {
    let url = '/corps/' + pk.corp_id;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_CORP',
        payload: json
      });
    });
  }
);

export const openCorpModal = createAction('OPEN_CORP_MODAL');
export const closeCorpModal = createAction('CLOSE_CORP_MODAL');

export const findKkCustomers = () => (
  (dispatch) => {
    let url = '/tkkData/kkCustomers/';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_KK_CUSTOMERS',
        payload: json
      });
    });
  }
);

export const clearCorpListAlert = createAction('CLEAR_CORP_LIST_ALERT');