import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	searchItem1,
	findItem,
	clearItemSearchAlert
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
	data: state.getIn(['itemSearchListReducer', 'itemSearchList']),
	activePage: state.getIn(['itemSearchListReducer', 'activePage']),
	itemPerPage: state.getIn(['itemSearchListReducer', 'itemPerPage']),
	totalPage: state.getIn(['itemSearchListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		squa_name: '大隊分隊',
		firefighter_type_display: '警義消',
		item_no: '裝備編號',
		asset_no: '財產編號',
		gear_name: '裝備類別',
		model: '型式',
		color: '顏色',
		sizing: '尺寸',
		manufacture_year: '年份',
		location_name: '保管人員地點',
		expired_date_display: '使用期限',
		status_display: '使用狀態'
	},
	keys: ['item_id', 'corp_id'],
	disableAdd: true,
	edit0: findButtonIconType(ownProps.uiType),
	alertStyle: state.getIn(['itemSearchListReducer', 'alertStyle']),
	alertMessage: state.getIn(['itemSearchListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
		if (ownProps.filter) {
			dispatch(searchItem1(ownProps.filter, pagingData));
		}
	},
	changeActivepage: (pagingData) => {
		dispatch(searchItem1(ownProps.filter, pagingData));
	},
	handleEdit: (pk) => {
		dispatch(findItem(pk, ownProps.uiType));
	},
	clearAlert: () => {
		dispatch(clearItemSearchAlert());
	}
})

function findButtonIconType(uiType) {
	if (uiType === 'EDIT') {
		return {
			className: 'glyphicon glyphicon-edit',
			showText: '修改'
		}
	} else if (uiType === 'TRANSFER') {
		return {
			className: 'glyphicon glyphicon-transfer',
			showText: '移轉'
		}
	} else if (uiType === 'STATUS_CHANGE') {
		return {
			className: 'glyphicon glyphicon-adjust',
			showText: '狀態更改'
		}
	} else if (uiType === 'M_RECORD_EDIT') {
		return {
			className: 'glyphicon glyphicon-plus',
			showText: '新增維護'
		}
	} else if (uiType === 'VIEW') {
		return {
			className: 'glyphicon glyphicon-eye-open',
			showText: '裝備總覽'
		}
	}
}

const ItemSearchListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default ItemSearchListContainer;