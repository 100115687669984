import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const changeAnnouncementValue = createAction('CHANGE_ANNOUNCEMENT_VALUE');
export const clearAnnouncementAlert = createAction('CLEAR_ANNOUNCEMENT_ALERT');

export const editAnnouncement = (announcement) => (
    (dispatch) => {
        let url = '/announcements';
        authFetch('PUT', url, JSON.stringify(announcement), (json) => {
            dispatch({
                type: 'FORM_SUBMIT_ANNOUNCEMENT',
                payload: json
            });
        });
    }
);

export const findAnnouncement = (payload) => (
    (dispatch) => {
        let url = '/announcements/corpId/' + payload.corpId;
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_ANNOUNCEMENT',
                payload: json
            });
        });
    }
);

export const findAnnouncementList = () => (
    (dispatch) => {
        // 公告在首頁，要先取得權限
        let url = '/auth/role';
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_AUTH_ROLE',
                payload: json
            });
            let corpId = 0;
            if (json.data.corp_id) {
                corpId = json.data.corp_id;
            }
            // 取公告列表
            let url = '/announcements/findAll/corpId/' + corpId;
            authFetch('GET', url, null, (json) => {
                dispatch({
                    type: 'FIND_ANNOUNCEMENT_LIST',
                    payload: json
                });
            });
        });
    }
);
