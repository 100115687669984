import fetch from 'isomorphic-fetch';
import { apiHost } from './config';
import { findCurrentIdToken } from './constants/awsCognito.js';

const createParam = (method, header, body, token) => {
  let _headers = header;

  if (_headers === undefined) {
    _headers = new Headers({
      'Accept': 'application/json;charset=UTF-8',
      'Content-Type': 'application/json;charset=UTF-8'
    });
  }

  if (token) {
    _headers.append('Authorization', 'Bearer ' + token);
    // console.log(token);
  } else {
    console.err('No token!');
    return;
  }

  let _param = {};
  let _method = method.toUpperCase();
  if (_method === 'GET' || _method === 'HEAD') {
    _param = {
      method: _method,
      headers: _headers
    };
  } else {
    _param = {
      method: _method,
      headers: _headers,
      body: body
    };
  }
  return _param;
}

/**
 * Using default header; input JSON; output JSON.
 */
export const authFetch = (method, url, body, callback) => {

  findCurrentIdToken(token => {

    if (token == null) {
      console.error('no token !!');
      console.error(url);
      return;
    };
    // console.log(token);

    fetch(apiHost + url, createParam(method, undefined, body, token)).then((response) => {
      // console.log('response.status', response.status);
      // console.log('response.body', response.body);
      if (!response.ok) {
        throw Error(response.status, response.statusText);
      }
      return response.json();
    }).then((json) => {
      // console.log('json', json);
      if (callback) {
        callback(json);
      }
    }).catch(function (err) {
      console.log('Fetch Error: ', err);
    });

  });
};

export const noAuthFetch = (method, url, body, callback) => {

  let header = new Headers({
    'Accept': 'application/json;charset=UTF-8',
    'Content-Type': 'application/json;charset=UTF-8'
  });

  let _param = {};
  if (method === 'GET' || method === 'HEAD') {
    _param = {
      method: method,
      headers: header
    };
  } else {
    _param = {
      method: method,
      headers: header,
      body: body
    };
  }

  // console.log(apiHost + url);
  // console.log(_param);
  fetch(apiHost + url, _param).then((response) => {
    // console.log('response.status', response.status);
    // console.log('response.body', response.body);
    if (!response.ok) {
      throw Error(response.status, response.statusText);
    }
    return response.json();
  }).then((json) => {
    // console.log('JSON', json);
    if (callback) {
      callback(json);
    }
  }).catch(function (err) {
    console.log('Fetch Error: ', err);
  });
};

/**
 * File upload; input multipart/form-data (auto detect); output JSON.
 */
export const authUpload = (method, url, body, callback, callbackerror) => {

  findCurrentIdToken(token => {

    if (token == null) {
      console.error('no token !!');
      return;
    };

    let headers = new Headers({
      'Accept': 'application/json;charset=UTF-8'
    });

    fetch(apiHost + url, createParam(method, headers, body, token)).then((response) => {
      // console.log('response.status', response.status);
      // console.log('response.body', response.body);
      return response.json();
    }).then((json) => {
      // console.log('JSON', json);
      if (callback) {
        callback(json);
      }
    }).catch(function (err) {
      console.log('Fetch Error: ', err);
      if (callbackerror) {
        callbackerror(err);
      }
    });
  });

};