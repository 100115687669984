import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	eventKey: 0,
	searchBean1: emptySearch1Bean(),
	searchBean2: emptySearch2Bean(),
	searchBean3: emptySearch3Bean(),
	searchReplacementBean: emptySearchReplacementBean(),
	alertStyle1: null,
	alertMessage1: null,
	mainSquas: [],
	subSquas: [],
	locations: [],
	showSearchInput: true,
	showFilterInput: false
});

const itemSearchReducer = handleActions({
	CHANGE_ITEM_SEARCH_EVENT_KEY: (state, { payload }) => {
		return state.merge({
			eventKey: payload
		})
	},
	CHANGE_SEARCHBEAN1_VALUE: (state, { payload }) => {
		let searchBean1 = state.get('searchBean1');
		return state.merge({
			searchBean1: searchBean1.set(payload.id, payload.value),
		})
	},
	CHANGE_SEARCHBEAN2_VALUE: (state, { payload }) => {
		let searchBean2 = state.get('searchBean2');
		return state.merge({
			searchBean2: searchBean2.set(payload.id, payload.value),
		})
	},
	CHANGE_SEARCHBEAN3_VALUE: (state, { payload }) => {
		let searchBean3 = state.get('searchBean3');
		return state.merge({
			searchBean3: searchBean3.set(payload.id, payload.value),
		})
	},
	CHANGE_SEARCH_REPLACEMENT_BEAN_VALUE: (state, { payload }) => {
		let searchReplacementBean = state.get('searchReplacementBean');
		return state.merge({
			searchReplacementBean: searchReplacementBean.set(payload.id, payload.value),
		})
	},
	FIND_MAIN_SQUA_LIST_SEARCH1: (state, { payload }) => {
		return state.merge({
			mainSquas: payload.data
		})
	},
	FIND_SUB_SQUA_LIST_SEARCH1: (state, { payload }) => {
		return state.merge({
			subSquas: payload.data
		})
	},
	FIND_ALL_LOCATION_LIST_SEARCH1: (state, { payload }) => {
		return state.merge({
			locations: payload.data
		})
	},
	CHANGE_SHOW_SEARCH_INPUT: (state, { payload }) => {
		return state.merge({
			showSearchInput: payload
		})
	},
	CHANGE_SHOW_FILTER_INPUT: (state, { payload }) => {
		return state.merge({
			showFilterInput: payload
		})
	},
	CLEAR_SEARCH1_INPUT: (state, { payload }) => {
		let searchBean1 = state.get('searchBean1');
		return state.merge({
			searchBean1: emptySearch1Bean(searchBean1.toJS()),
			subSquas: [],
			locations: [],
			alertStyle1: null,
			alertMessage1: null,
			showSearchInput: true
		})
	},
	CLEAR_SEARCH2_INPUT: (state, { payload }) => {
		let searchBean2 = state.get('searchBean2');
		return state.merge({
			searchBean2: emptySearch2Bean(searchBean2.toJS()),
			subSquas: [],
			alertStyle1: null,
			alertMessage1: null,
			showSearchInput: true
		})
	},
	CLEAR_SEARCH3_INPUT: (state, { payload }) => {
		let searchBean3 = state.get('searchBean3');
		return state.merge({
			searchBean3: emptySearch3Bean(searchBean3.toJS()),
			alertStyle1: null,
			alertMessage1: null,
			showSearchInput: true
		})
	},
	CLEAR_SEARCH_REPLACEMENT_INPUT: (state, { payload }) => {
		let searchReplacementBean = state.get('searchReplacementBean');
		return state.merge({
			searchReplacementBean: emptySearchReplacementBean(searchReplacementBean.toJS()),
			showFilterInput: false
		})
	}
}, initState);

function emptySearch1Bean(orgSearchBean) {
	let corpId = null;
	let corpName = '';
	if (orgSearchBean) {
		corpId = orgSearchBean.corpId;
		corpName = orgSearchBean.corpName;
	}
	return {
		corpId: corpId,
		corpName: corpName,
		mainSquaId: null,
		mainSquaName: '',
		subSquaId: null,
		subSquaName: '',
		gearId: null,
		gearName: '',
		itemNo: null,
		assetNo: null,
		manufactureYear: null,
		warrantyExpired: null,
		firefighterType: null,
		locationType: null,
		locationId: null,
		locationName: '',
		status: null,
		statusDisplay: '',
		rfidNo: null
	}
}

function emptySearch2Bean(orgSearchBean) {
	let corpId = null;
	let corpName = '';
	if (orgSearchBean) {
		corpId = orgSearchBean.corpId;
		corpName = orgSearchBean.corpName;
	}
	return {
		corpId: corpId,
		corpName: corpName,
		lendMainSquaId: null,
		lendMainSquaName: '',
		lendSubSquaId: null,
		lendSubSquaName: '',
		gearId: null,
		gearName: ''
	}
}

function emptySearch3Bean(orgSearchBean) {
	let corpId = null;
	let corpName = '';
	if (orgSearchBean) {
		corpId = orgSearchBean.corpId;
		corpName = orgSearchBean.corpName;
	}
	return {
		corpId: corpId,
		corpName: corpName,
		gearId: null,
		gearName: '',
		minCnt: 1,
		maxCnt: null,
		status: null,
		statusDisplay: ''
	}
}

function emptySearchReplacementBean(orgSearchBean) {
	let corpId = null;
	let corpName = '';
	if (orgSearchBean) {
		corpId = orgSearchBean.corpId;
		corpName = orgSearchBean.corpName;
	}
	return {
		corpId: corpId,
		corpName: corpName,
		gearId: null,
		warrantyExpired: null
	}
}

export default itemSearchReducer;