import React from 'react';
import { Row, Col, Tabs, Tab, FormGroup, FormControl, Navbar, Button, Alert, Table, ControlLabel } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

export default class NfcChipsComponent extends React.Component {

	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
		}
	}

	componentWillUnmount() {
		this.props.clearChipDisplay();
		this.props.clearInput();
	}

	handleSelect(eventKey) {
		this.props.clearInput();
	}

	handleSearch(searchInput) {
		this.props.findChipByNfcTask(searchInput);
	}

	handleSearchChip() {
		this.props.findChipsByLocation(this.props.chip.toJS());
	}

	deleteChipRecord(nfcId) {
		let r = window.confirm('確認刪除 ?');
		if (r === true) {
			let corpId = this.props.chip.get("corpId");
			let sMappingType = this.props.chip.get("sMappingType");
			let sLocationNo = this.props.chip.get("sLocationNo");
			let sLocationId = this.props.chip.get("sLocationId");
			this.props.changeToUnactive(
				{ nfcId: nfcId, corpId: corpId, sMappingType: sMappingType, sLocationNo: sLocationNo, sLocationId: sLocationId });
		}
	}

	nfcMapping() {
		this.props.nfcMapping(this.props.chip.toJS());
	}

	render() {
		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
					{this.props.alertMessage}
				</Alert>
			);
		}

		let showArea1 = (this.props.chip.get("mappingType") === 1) ? '' : 'none';
		let showArea2 = (this.props.chip.get("mappingType") === 2) ? '' : 'none';
		let sShowArea1 = (this.props.chip.get("sMappingType") === 1) ? '' : 'none';
		let sShowArea2 = (this.props.chip.get("sMappingType") === 2) ? '' : 'none';

		let tableRows = [];
		if (this.props.chipRecordList) {
			for (let i = 0; i < this.props.chipRecordList.size; i++) {
				let row = this.props.chipRecordList.get(i);
				tableRows.push(
					<tr key={row.get('mapping_id')}>
						<td>
							<Button bsStyle="default" bsSize="xsmall" onClick={(event) => {
								this.deleteChipRecord(row.get('mapping_id'));
							}}><span className="glyphicon glyphicon-trash" style={{ color: '#a94442' }} />
							</Button>
						</td>
						<td>{row.get('nfc_text')}</td>
						<td>{row.get('location_no')}</td>
						<td>{row.get('location_name')}</td>
						<td>{row.get('mapping_time')}</td>
					</tr>
				);
			}
		}

		let chipDisplay = null;
		if (this.props.chipSearchResult) {
			let result = this.props.chipSearchResult.toJS();
			if (result.statusCode === '0') {
				let data = result.data;
				if (data.mapping_id) {
					chipDisplay = (
						<div>
							<span>NFC晶片: {data.nfc_text}</span><br />
							<span>綁定:</span><br />
							<span style={{ marginLeft: 28 }}>單位: {data.corp_name}</span><br />
							<span style={{ marginLeft: 28 }}>分隊: {data.squa_name}</span><br />
							<span style={{ marginLeft: 28 }}>{data.location_type_name}: {data.location_name}</span><br />
							<span style={{ marginLeft: 28 }}>編號: {data.location_no}</span>
						</div>
					);
				}
				else {
					chipDisplay = (
						<span style={{ color: '#FF0000' }}>NFC晶片 {data.nfc_text} 未綁定</span>
					);
				}
			} else {
				chipDisplay = (
					<span style={{ color: '#FF0000' }}>{result.displayMessage}</span>
				);
			}
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;火場管制&nbsp;&gt;&gt;&nbsp;晶片查詢設定</span>
				</Row>
				<Row>
					<Tabs id="searchTab" style={{ marginTop: 20 }} onSelect={this.handleSelect}>
						<Tab eventKey={0} title="晶片查詢人員">
							<Col>
								<Navbar.Form>
									<div style={{ marginTop: 20 }}>
										<FormGroup>
											<FormControl type="text" placeholder='請輸入NFC晶片代碼' inputRef={ref => { this.searchInput = ref; }} />
										</FormGroup>
										<span className="glyphicon glyphicon-search" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={(event) => {
											this.handleSearch(this.searchInput.value);
										}} />
									</div>
								</Navbar.Form>
							</Col>
							<Col>
								<div style={{ marginLeft: 20 }}>
									{chipDisplay}
								</div>
							</Col>
						</Tab>
						<Tab eventKey={1} title="人員查詢晶片">
							<Col xs={12} sm={12} style={{ marginTop: 20 }}>
								<Button onClick={(event) => {
									this.handleSearchChip();
								}} bsStyle="default">
									<span className="glyphicon glyphicon-search" style={{ marginRight: 10 }} />搜尋
					    		</Button>
							</Col>
							<Row>
								<Col xs={12} sm={6} >
									<RadioGroup
										id="sMappingType"
										label=""
										radioList={[{ label: "輸入編號", value: 1 }, { label: "選擇人員", value: 2 }]}
										value={this.props.chip.get('sMappingType')}
										onChange={(id, value) => {
											this.props.setValue(id, parseInt(value, 10));
										}}
										validation={this.props.chipValidation.get('mappingType')}
									/>
								</Col>
							</Row>
							<div style={{ display: sShowArea1, marginBottom: 25 }}>
								<Row>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="sLocationNo"
											type="text"
											label="編號"
											value={this.props.chip.get('sLocationNo')}
											placeholder=""
											required="true"
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
											validation={this.props.chipValidation.get('locationNo1')}
										/>
									</Col>
								</Row>
							</div>
							<div style={{ display: sShowArea2, marginBottom: 30 }}>
								<Row>
									<Col xs={12} sm={4}>
										<DropDownInput ref="sMainSquaIdDdi"
											id="sMainSquaId"
											label="大隊"
											required="true"
											options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
											selected={[{ label: (this.props.chip.get('sMainSquaName') != null ? this.props.chip.get('sMainSquaName') : ''), value: this.props.chip.get('sMainSquaId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('sMainSquaName', label);
												if (value) {
													this.props.findSubSquaList({ corpId: this.props.chip.get('corpId'), parentId: value });
												} else {
													this.props.findSubSquaList({ corpId: this.props.chip.get('corpId'), parentId: -1 });
													this.props.findLocationList({ corpId: this.props.chip.get('corpId'), locationType: 1, subSquaId: -1 });
												}
											}}
											validation={this.props.chipValidation.get('mainSquaId2')}
											extraClear={() => {
												// this.refs.sSubSquaIdDdi.refs.typeahead.getInstance().clear();
												this.props.setValue('sSubSquaId', null);
												this.props.setValue('sSubSquaName', null);
											}}
										/>
									</Col>
									<Col xs={12} sm={4}>
										<DropDownInput ref="sSubSquaIdDdi"
											id="sSubSquaId"
											label="分隊"
											required="true"
											options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
											selected={[{ label: (this.props.chip.get('sSubSquaName') != null ? this.props.chip.get('sSubSquaName') : ''), value: this.props.chip.get('sSubSquaId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('sSubSquaName', label);
												if (value) {
													this.props.findLocationList({ corpId: this.props.chip.get('corpId'), locationType: 1, subSquaId: value });
												} else {
													this.props.findLocationList({ corpId: this.props.chip.get('corpId'), locationType: 1, subSquaId: -1 });
												}
											}}
											validation={this.props.chipValidation.get('subSquaId2')}
											extraClear={() => {
												// this.refs.sLocationIdDdi.refs.typeahead.getInstance().clear();
												this.props.setValue('sLocationId', null);
												this.props.setValue('sLocationName', null);
											}}
										/>
									</Col>
									<Col xs={12} sm={4}>
										<DropDownInput ref="sLocationIdDdi"
											id="sLocationId"
											label="人員"
											required="true"
											options={this.props.locations.map((value, key) => ({ label: value.get('location_name'), value: value.get('location_id') })).toJS()}
											selected={[{ label: (this.props.chip.get('sLocationName') != null ? this.props.chip.get('sLocationName') : ''), value: this.props.chip.get('sLocationId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('sLocationName', label);
											}}
											validation={this.props.chipValidation.get('locationId2')}
										/>
									</Col>
								</Row>
							</div>
							<hr />
							<Row>
								<Col xs={12} sm={12}>
									{alertDiv}
								</Col>
								<Col xs={12} sm={12}>
									<ControlLabel>搜尋列表 :</ControlLabel>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={12}>
									<Table bordered condensed hover striped>
										<thead>
											<tr>
												<th style={{ width: 35 }}></th>
												<th>NFC晶片代碼</th>
												<th>編號</th>
												<th>人員</th>
												<th style={{ width: 135 }}>綁定時間</th>
											</tr>
										</thead>
										<tbody>
											{tableRows}
										</tbody>
									</Table>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey={2} title="晶片對應設定">
							<Col xs={12} sm={12} style={{ marginTop: 20 }}>
								{alertDiv}
							</Col>
							<Col xs={12} sm={12}>
								<Button onClick={(event) => {
									this.nfcMapping();
								}} bsStyle="primary">
									綁定晶片
					    		</Button>
							</Col>
							<Row>
								<Col xs={12} sm={6} style={{ marginTop: 20 }}>
									<FieldGroup
										id="nfcText"
										type="text"
										label="綁定NFC晶片代碼"
										value={this.props.chip.get('nfcText')}
										placeholder=""
										required="true"
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
										validation={this.props.chipValidation.get('nfcText')}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={6} style={{ marginTop: 20 }}>
									<RadioGroup
										id="mappingType"
										label="綁定方式"
										radioList={[{ label: "輸入編號", value: 1 }, { label: "選擇人員", value: 2 }]}
										value={this.props.chip.get('mappingType')}
										required="true"
										onChange={(id, value) => {
											this.props.setValue(id, parseInt(value, 10));
										}}
										validation={this.props.chipValidation.get('mappingType')}
									/>
								</Col>
							</Row>
							<div style={{ display: showArea1 }}>
								<Col xs={12} sm={6}>
									<FieldGroup
										id="locationNo"
										type="text"
										label="編號"
										value={this.props.chip.get('locationNo')}
										placeholder=""
										required="true"
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
										validation={this.props.chipValidation.get('locationNo1')}
									/>
								</Col>
							</div>
							<div style={{ display: showArea2 }}>
								<Col xs={12} sm={4}>
									<DropDownInput ref="mainSquaIdDdi"
										id="mainSquaId"
										label="大隊"
										required="true"
										options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
										selected={[{ label: (this.props.chip.get('mainSquaName') != null ? this.props.chip.get('mainSquaName') : ''), value: this.props.chip.get('mainSquaId') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('mainSquaName', label);
											if (value) {
												this.props.findSubSquaList({ corpId: this.props.chip.get('corpId'), parentId: value });
											} else {
												this.props.findSubSquaList({ corpId: this.props.chip.get('corpId'), parentId: -1 });
												this.props.findLocationList({ corpId: this.props.chip.get('corpId'), locationType: 1, subSquaId: -1 });
											}
										}}
										validation={this.props.chipValidation.get('mainSquaId2')}
										extraClear={() => {
											// this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
											this.props.setValue('subSquaId', null);
											this.props.setValue('subSquaName', null);
										}}
									/>
								</Col>
								<Col xs={12} sm={4}>
									<DropDownInput ref="subSquaIdDdi"
										id="subSquaId"
										label="分隊"
										required="true"
										options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
										selected={[{ label: (this.props.chip.get('subSquaName') != null ? this.props.chip.get('subSquaName') : ''), value: this.props.chip.get('subSquaId') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('subSquaName', label);
											if (value) {
												this.props.findLocationList({ corpId: this.props.chip.get('corpId'), locationType: 1, subSquaId: value });
											} else {
												this.props.findLocationList({ corpId: this.props.chip.get('corpId'), locationType: 1, subSquaId: -1 });
											}
										}}
										validation={this.props.chipValidation.get('subSquaId2')}
										extraClear={() => {
											// this.refs.locationIdDdi.refs.typeahead.getInstance().clear();
											this.props.setValue('locationId', null);
											this.props.setValue('locationName', null);
										}}
									/>
								</Col>
								<Col xs={12} sm={4}>
									<DropDownInput ref="locationIdDdi"
										id="locationId"
										label="人員"
										required="true"
										options={this.props.locations.map((value, key) => ({ label: value.get('location_name'), value: value.get('location_id') })).toJS()}
										selected={[{ label: (this.props.chip.get('locationName') != null ? this.props.chip.get('locationName') : ''), value: this.props.chip.get('locationId') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('locationName', label);
										}}
										validation={this.props.chipValidation.get('locationId2')}
									/>
								</Col>
							</div>
						</Tab>
					</Tabs>
				</Row>
			</div>
		)
	}
}