import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	category: null,
	alertStyle: null,
	alertMessage: null,
	userMessageUnreadCount:0
});

const announcementReducer = handleActions({
	SWITCH_CATEGORY: (state, { payload }) => {
		return state.merge({
			category: payload
		})
	},
	FIND_USER_MESSAGE_UNREAD_COUNT: (state, { payload }) => {
		return state.merge({
			userMessageUnreadCount: payload.data
		})
	}
}, initState);

export default announcementReducer;