import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findMessageList,
	deleteMessage,
	openMessageModal,
	findMessage,
	clearMessageListAlert
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['messageListReducer', 'list']),
	activePage: state.getIn(['messageListReducer', 'activePage']),
	itemPerPage: state.getIn(['messageListReducer', 'itemPerPage']),
	totalPage: state.getIn(['messageListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		send_to_corp_name: 'To 單位',
		send_from_user_id: '發送帳號',
		send_time_display: '發送時間',
		message_title: '訊息標題',
		display_time_start_display: '顯示開始時間',
		display_time_end_display: '顯示結束時間',
	},
	keys: ['message_id'],
	disableAdd: true,
	showCheckBox: true,
	edit0: {
		className: 'glyphicon glyphicon-eye-open',
		showText: '明細'
	},
	alertStyle: state.getIn(['messageListReducer', 'alertStyle']),
	alertMessage: state.getIn(['messageListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch) => ({
	refresh: (pagingData) => {
		dispatch(findMessageList(pagingData));
	},
	changeActivepage: (pagingData) => {
		dispatch(findMessageList(pagingData));
	},
	deleteItems: (deleteKey, pagingData) => {
		dispatch(deleteMessage(deleteKey, pagingData));
	},
	handleEdit: (pk) => {
		dispatch(findMessage(pk));
		dispatch(openMessageModal());
	},
	clearAlert: () => {
		dispatch(clearMessageListAlert());
	}
})

const MessageListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default MessageListContainer;