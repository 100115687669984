import {
	connect
} from 'react-redux'
import LocationEditComponent from '../components/LocationEditComponent.jsx';
import {
	changeLocationValue,
	addLocation,
	editLocation,
	findAllCorps,
	findMainSquaList,
	findSubSquaList,
	findEnabledUsersByCorpId
} from '../actions';

const mapStateToProps = (state) => ({
	location: state.getIn(['locationReducer', 'location']),
	locationValidation: state.getIn(['locationReducer', 'locationValidation']),
	alertStyle: state.getIn(['locationReducer', 'alertStyle']),
	alertMessage: state.getIn(['locationReducer', 'alertMessage']),
	forInsert: state.getIn(['locationReducer', 'forInsert']),
	corps: state.getIn(['commonReducer', 'corps']),
	mainSquas: state.getIn(['locationReducer', 'mainSquas']),
	subSquas: state.getIn(['locationReducer', 'subSquas']),
	users: state.getIn(['locationReducer', 'users']),

	activePage: state.getIn(['locationListReducer', 'activePage']),
	itemPerPage: state.getIn(['locationListReducer', 'itemPerPage']),

	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	editItem: (location, pagingData, forInsert) => {
		if (forInsert) {
			dispatch(addLocation(location, pagingData, ownProps.filter.toJS()));
		} else {
			dispatch(editLocation(location, pagingData, ownProps.filter.toJS()));
		}
	},
	setValue: (id, value) => {
		dispatch(changeLocationValue({
			id: id,
			value: value
		}))
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'LOCATION_EDIT'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'LOCATION_EDIT'));
	},
	findUserList: (payload) => {
		dispatch(findEnabledUsersByCorpId(payload, 'LOCATION_EDIT'));
	}
})

const LocationEditContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(LocationEditComponent)

export default LocationEditContainer;