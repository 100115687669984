import {
	connect
} from 'react-redux'
import WarrantyEditComponent from '../components/WarrantyEditComponent.jsx';
import {
	changeWarrantyValue,
	editWarranty
} from '../actions';

const mapStateToProps = (state) => ({
	warranty: state.getIn(['warrantyReducer', 'warranty']),
	warrantyValidation: state.getIn(['warrantyReducer', 'warrantyValidation']),
	alertStyle: state.getIn(['warrantyReducer', 'alertStyle']),
	alertMessage: state.getIn(['warrantyReducer', 'alertMessage']),

	activePage: state.getIn(['corp2ListReducer', 'activePage']),
	itemPerPage: state.getIn(['corp2ListReducer', 'itemPerPage'])
})

const mapDispatchToProps = (dispatch) => ({
	editItem: (warranty, pagingData) => {
		dispatch(editWarranty(warranty, pagingData));
	},
	setValue: (id, value) => {
		dispatch(changeWarrantyValue({
			id: id,
			value: value
		}))
	}
})

const WarrantyEditContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(WarrantyEditComponent)

export default WarrantyEditContainer;