import {
	connect
} from 'react-redux'
import ItemSearch1InnerComponent from '../components/ItemSearch1InnerComponent.jsx';
import {
	changeSearchBean1Value,
	findMainSquaList,
	findSubSquaList,
	findAllGearList,
	findAllLocationList,
	findItemStatus,
	searchItem1,
	changeShowSearchInput,
	findAllCorps,
	clearSearch1Input
} from '../actions';

const mapStateToProps = (state) => ({
	searchBean: state.getIn(['itemSearchReducer', 'searchBean1']),
	corps: state.getIn(['commonReducer', 'corps']),
	mainSquas: state.getIn(['itemSearchReducer', 'mainSquas']),
	subSquas: state.getIn(['itemSearchReducer', 'subSquas']),
	gears: state.getIn(['commonReducer', 'gears']),
	locations: state.getIn(['itemSearchReducer', 'locations']),
	itemStatus: state.getIn(['commonReducer', 'itemStatus']),
	alertStyle: state.getIn(['itemSearchReducer', 'alertStyle1']),
	alertMessage: state.getIn(['itemSearchReducer', 'alertMessage1']),
	showSearchInput: state.getIn(['itemSearchReducer', 'showSearchInput']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	setValue: (id, value) => {
		dispatch(changeSearchBean1Value({
			id: id,
			value: value
		}))
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'SEARCH1'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'SEARCH1'));
	},
	findAllGearList: (payload) => {
		dispatch(findAllGearList(payload));
	},
	findLocationList: (payload) => {
		dispatch(findAllLocationList(payload, 'SEARCH1'));
	},
	findItemStatus: (payload) => {
		dispatch(findItemStatus(payload));
	},
	search: (payload) => {
		dispatch(searchItem1(payload, { activePage: 1, itemPerPage: 10 }));
	},
	changeShowSearchInput: (payload) => {
		dispatch(changeShowSearchInput(payload));
	},
	findAllCorps: () => {
		dispatch(findAllCorps());
	},
	clearSearchInput: () => {
		dispatch(clearSearch1Input());
	}
})

const ItemSearch1InnerContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemSearch1InnerComponent)

export default ItemSearch1InnerContainer;