import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	announcement: emptyAnnouncement(),
	announcementValidation: {},
	alertStyle: null,
	alertMessage: null,
	announcementList:[]
});

const announcementReducer = handleActions({
	CHANGE_ANNOUNCEMENT_VALUE: (state, { payload }) => {
		let announcement = state.get('announcement');
		return state.merge({
			announcement: announcement.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_ANNOUNCEMENT: (state, { payload }) => {
		return state.merge({
			announcement: payload.data.bean,
			announcementValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	CLEAR_ANNOUNCEMENT_ALERT: (state, { payload }) => {
		return state.merge({
			announcementValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	FIND_ANNOUNCEMENT: (state, { payload }) => {
		return state.merge({
			announcement: payload.data
		})
	},
	FIND_ANNOUNCEMENT_LIST: (state, { payload }) => {
		return state.merge({
			announcementList: payload.data
		})
	}
}, initState);

function emptyAnnouncement() {
	return {
		isDisplay: 'N',
		annContent: null
	}
}

export default announcementReducer;