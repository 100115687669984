import {
	connect
} from 'react-redux'
import CorpsComponent from '../components/CorpsComponent.jsx';
import {
	closeCorpModal
} from '../actions';

const mapStateToProps = (state) => ({
	showModal: state.getIn(['corpReducer', 'showModal']),
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => {
		dispatch(closeCorpModal());
	},
})

const CorpsContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(CorpsComponent)

export default CorpsContainer;