import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findUserList, 
	deleteUser, 
	openUserModal, 
	findUser,
	clearUserListAlert
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['userListReducer', 'list']),
	activePage: state.getIn(['userListReducer', 'activePage']),
	itemPerPage: state.getIn(['userListReducer', 'itemPerPage']),
	totalPage: state.getIn(['userListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		user_id: '帳號',
		user_name: '名稱',
		squa_name: '分隊',
		role_display: '身份',
		status_display: '狀態',
		email_validation_display: 'Email認證',
		create_time_display: '建立時間'
	},
	keys: ['user_id'],
	showCheckBox: true,
	disableAdd: true,
	alertStyle: state.getIn(['userListReducer', 'alertStyle']),
	alertMessage: state.getIn(['userListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
		// dispatch(findUserList(pagingData, ownProps.filter));
	},
	changeActivepage: (pagingData) => {
		dispatch(findUserList(pagingData, ownProps.filter));
	},
	deleteItems: (deleteKey, pagingData) => {
		dispatch(deleteUser(deleteKey, pagingData, ownProps.filter));
	},
	handleAdd: () => {
		dispatch(openUserModal({ forInsert: true }));
	},
	handleEdit: (pk) => {
		dispatch(findUser(pk));
		dispatch(openUserModal({ forInsert: false }));
	},
	clearAlert: () => {
		dispatch(clearUserListAlert());
	}
})

const UserListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default UserListContainer;