import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const findActiveLocations = (payload) => (
  (dispatch) => {
    let corpId = payload.corpId;
    let minute = payload.minute;
    let initialCenterIndex = payload.initialCenterIndex;
    let url = '/userLocations/minute/' + minute;
    if (!isNaN(parseInt(initialCenterIndex, 10))) {
      url += '?initialCenterIndex=' + initialCenterIndex;
    } else {
      url += '?initialCenterIndex=0';
    }
    if (corpId) {
      url += '&corpId=' + corpId;
    }

    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_ACTIVE_LOCATIONS',
        payload: json
      });
    });
  }
);

export const changeKeepRefreshLocation = createAction('CHANGE_KEEP_REFRESH_LOCATION');
export const changeInitialCenterIndex = createAction('CHANGE_INITIAL_CENTER_INDEX');