import {
	connect
} from 'react-redux'
import MRecordToBeClosedComponent from '../components/MRecordToBeClosedComponent.jsx';
import {
	changeMRecordSearchBeanValue,
	searchMRecord,
	clearMRecordSearchInput,
	findMRecordUnClosedCount
} from '../actions';

const mapStateToProps = (state) => ({
	searchBean: state.getIn(['mRecordReducer', 'searchBean']),
	unClosedCount: state.getIn(['mRecordReducer', 'unClosedCount']),
	role: state.getIn(['authReducer', 'role']),
	filter: state.getIn(['mRecordListReducer', 'filter'])
})

const mapDispatchToProps = (dispatch) => ({
	setValue: (id, value) => {
		dispatch(changeMRecordSearchBeanValue({
			id: id,
			value: value
		}))
	},
	searchMRecord: (payload, pagingData) => {
		dispatch(searchMRecord(payload, pagingData));
	},
	clearSearchInput: () => {
		dispatch(clearMRecordSearchInput());
	},
	findMRecordUnClosedCount: (payload) => {
		dispatch(findMRecordUnClosedCount(payload));
	}
})

const MRecordToBeClosedContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MRecordToBeClosedComponent)

export default MRecordToBeClosedContainer;