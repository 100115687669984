import React from 'react';
import { Row, Col } from 'react-bootstrap';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';
import MRecordListContainer from '../containers/MRecordListContainer.jsx';

export default class MRecordToBeClosedComponent extends React.Component {

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findMRecordUnClosedCount({ corpId: corpId });
			this.props.searchMRecord({ status: 1, corpId: corpId }, { activePage: 1, itemPerPage: 10 });
		}
	}

	componentWillUnmount() {
		this.props.clearSearchInput();
	}

	render() {
		let showDiv = null;
		let unClosedCountMsg = null;
		let showMsg = 'none';
		if (this.props.unClosedCount) {
			unClosedCountMsg = ('尚有 ' + this.props.unClosedCount + ' 筆維護記錄未結案');
			showMsg = 'block';
		}
		showDiv = (
			<div className="well col-xs-12 col-sm-12" style={{ display: showMsg, marginTop: 10 }}>
				<Row>
					<Col xs={12} sm={12}>
						<span style={{ color: 'red' }}>{unClosedCountMsg}</span>
					</Col>
				</Row>
			</div>
		);

		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;維護待結案</span>
				</Row>
				{showDiv}
				<MRecordListContainer filter={{ corpId: this.props.searchBean.get("corpId"), status: 1 }} />
			</div>
		)
	}
}