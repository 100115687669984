import {
	connect
} from 'react-redux'
import MessagesComponent from '../components/MessagesComponent.jsx';
import {
	closeMessageModal
} from '../actions';

const mapStateToProps = (state) => ({
	showModal: state.getIn(['messageReducer', 'showModal']),
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => {
		dispatch(closeMessageModal());
	},
})

const MessagesContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MessagesComponent)

export default MessagesContainer;