import React from 'react';
import { Row, Col, Alert, Button, FormGroup, ControlLabel, FormControl, ButtonToolbar } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

export default class MpicUploadComponent extends React.Component {

  componentDidMount() {
    // let corpId = this.props.role.get('corp_id');
  }

  componentWillUnmount() {
    this.props.clearAlert();
  }

  handleUpload(picType) {
    let mRecordId = this.props.record.get('maintenanceRecordId');
    if (mRecordId != null) {
      this.props.uploadFile(this.picFile, mRecordId, picType);
    }
  }

  toViewMRecord() {
    this.props.findMRecord(
      { maintenance_record_id: this.props.record.get('maintenanceRecordId'), item_id: this.props.item.get('itemId') });
    hashHistory.push("/mRecordView");
  }

  deletePic(picId) {
    let r = window.confirm('確認刪除照片 ?');
    if (r) {
      let mRecordId = this.props.record.get('maintenanceRecordId');
      this.props.deleteMPic(picId, mRecordId);
    }
  }

  render() {

    let noRecordId = (this.props.record.get('maintenanceRecordId') == null);

    let alertDiv = null;
    if (this.props.alertStyle || this.props.alertMessage) {
      alertDiv = (
        <Row>
          <Col xs={12} sm={12}>
            <Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
              {this.props.alertMessage}
            </Alert>
          </Col>
        </Row>
      );
    }

    // 維修前照片
    let prePicObj = [];
    let prePicList = this.props.record.get('prePicList');
    if (prePicList) {
      for (let prePic of prePicList) {
        let picId = prePic.get('maintenance_pic_id');
        let picPath = prePic.get('pic_path');
        let altDesc = "維修前照片" + picId;
        prePicObj.push((
          <Col xs={6} sm={3} key={picId}>
            <img src={picPath} className="img-thumbnail" alt={altDesc} />
            <div style={{ textAlign: 'center' }}>
              <span className="glyphicon glyphicon-trash" style={{ marginTop: 5, color: '#a94442', cursor: 'pointer' }} onClick={(event) => {
                this.deletePic(picId);
              }} />
            </div>
          </Col>
        ))
      }
    }

    // 維修後照片
    let postPicObj = [];
    let postPicList = this.props.record.get('postPicList');
    if (postPicList) {
      for (let postPic of postPicList) {
        let picId = postPic.get('maintenance_pic_id');
        let picPath = postPic.get('pic_path');
        let altDesc = "維修後照片" + picId;
        postPicObj.push((
          <Col xs={6} sm={3} key={picId}>
            <img src={picPath} className="img-thumbnail" alt={altDesc} />
            <div style={{ textAlign: 'center' }}>
              <span className="glyphicon glyphicon-trash" style={{ marginTop: 5, color: '#a94442', cursor: 'pointer' }} onClick={(event) => {
                this.deletePic(picId);
              }} />
            </div>
          </Col>
        ))
      }
    }

    let showStatus = null;
    let mRecordStatus = this.props.record.get('status');
    if (mRecordStatus === 2) {
      showStatus = (
        <Row>
          <Col xs={12} sm={12}>
            <div className="well col-xs-12 col-sm-12">
              <label style={{ color: 'red' }}>該維護紀錄已結案</label>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <div>
        <CheckSelectCorp />
        <Row style={{ marginBottom: 15 }}>
          <span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;維護照片管理</span>
        </Row>
        <h4>維護紀錄序號: {this.props.record.get('maintenanceRecordId')}</h4>
        <div className="well col-xs-12 col-sm-12" style={{ paddingTop: 10, paddingBottom: 5 }}>
          <table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}><label className="control-label">裝備編號:</label> {this.props.item.get('itemNo')}</td>
                <td style={{ width: '50%' }}><label className="control-label">裝備類別:</label> {this.props.item.get('gearName')}</td>
              </tr>
              <tr>
                <td style={{ width: '50%' }}><label className="control-label">維護訂單:</label> {this.props.record.get('maintenanceOrderId')}</td>
                <td style={{ width: '50%' }}><label className="control-label">梯次編號:</label> {this.props.record.get('batchNo')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {alertDiv}
        <Row>
          <Col>
            <div>
              <ButtonToolbar>
                <Button onClick={(event) => {
                  this.toViewMRecord();
                }} bsStyle="default" disabled={noRecordId}>
                  <span className="glyphicon glyphicon-th-list" style={{ marginRight: 10 }} />維護明細
							</Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
        <br />
        {showStatus}
        <Row>
          <Col xs={12} sm={12}><label>維護前照片列表</label></Col>
          {prePicObj}
        </Row>
        <Row style={{ paddingTop: 10 }}>
          <Col xs={6} sm={6}>
            <FormGroup controlId="file_upload" >
              <ControlLabel>維護前照片上傳</ControlLabel>
              <FormControl
                type="file"
                onChange={(event) => {
                  this.picFile = event.target.files[0];
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6}>
            <ButtonToolbar>
              <Button bsStyle="primary" onClick={(event) => {
                this.handleUpload('PRE');
              }}>上傳</Button>
            </ButtonToolbar>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} sm={12}><label>維護後照片列表</label></Col>
          {postPicObj}
        </Row>
        <Row style={{ paddingTop: 10 }}>
          <Col xs={6} sm={6}>
            <FormGroup controlId="file_upload" >
              <ControlLabel>維護後照片上傳</ControlLabel>
              <FormControl
                type="file"
                onChange={(event) => {
                  this.picFile = event.target.files[0];
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6}>
            <ButtonToolbar>
              <Button bsStyle="primary" onClick={(event) => {
                this.handleUpload('POST');
              }}>上傳</Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </div>
    )
  }
};