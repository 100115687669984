import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const clearChipDisplay = createAction('CLEAR_CHIP_DISPLAY');
export const clearChipInput = createAction('CLEAR_CHIP_INPUT');
export const clearChipAlert = createAction('CLEAR_CHIP_ALERT');
export const changeChipValue = createAction('CHANGE_CHIP_VALUE');

export const findChipByNfcTask = (nfcText) => (
  (dispatch) => {
    if (nfcText && nfcText.length > 0) {
      let url = "/nfcChips/nfcText/" + nfcText;
      authFetch('GET', url, null, (json) => {
        dispatch({
          type: 'FIND_CHIP_BY_NFC_TEXT',
          payload: json
        });
      });
    } else {
      dispatch({
        type: 'FIND_CHIP_BY_NFC_TEXT',
        payload: { displayMessage: '請輸入NFC晶片代碼' }
      });
    }
  }
);

export const addNfcChipRecord = (payload) => (
  (dispatch) => {
    let url = "/nfcChipMapping";
    authFetch('POST', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'ADD_NFC_CHIP_RECORD',
        payload: json
      });
    });
  }
);

export const findChipsByLocation = (payload) => (
  (dispatch) => {
    let corpId = payload.corpId;
    let locationNo = (payload.sLocationNo) ? payload.sLocationNo : 0;
    let locationId = (payload.sLocationId) ? payload.sLocationId : 0;
    let url = '';
    if (payload.sMappingType === 1) {
      url = '/nfcChipMapping/corps/' + corpId + '/locationNo/' + locationNo;
    } else if (payload.sMappingType === 2) {
      url = '/nfcChipMapping/corps/' + corpId + '/locationId/' + locationId;
    }
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_CHIPS_BY_LOCATION',
        payload: json
      });
    });
  }
);

export const changeToUnactive = (payload) => (
  (dispatch) => {
    let nfcId = payload.nfcId;
    let url = "/nfcChipMapping/changeToUnactive/" + nfcId;
    authFetch('PUT', url, null, (json) => {
      dispatch({
        type: 'CHANGE_CHIP_TO_UNACTIVE',
        payload: json
      });
      dispatch(findChipsByLocation(payload));
    });
  }
);
