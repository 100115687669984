import {
	connect
} from 'react-redux'
import LoginComponent from '../components/LoginComponent.jsx';
import {
	authenticateUser
} from '../actions';

const mapStateToProps = (state) => ({
	alertStyle: state.getIn(['loginReducer', 'alertStyle']),
	alertMessage: state.getIn(['loginReducer', 'alertMessage']),
	loading: state.getIn(['loginReducer', 'loading'])
});

const mapDispatchToProps = (dispatch) => ({
	toAuthenticateUser: (username, password) => {
		dispatch(authenticateUser(username, password));
	}
});

const LoginContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginComponent)

export default LoginContainer;