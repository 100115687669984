import React from 'react';
import { Row, Col, Button, Collapse, Form } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';
import ItemSearch3ListContainer from '../containers/ItemSearch3ListContainer.jsx';

export default class ItemSearch3Component extends React.Component {

	componentDidMount() {
		this.props.findItemStatus();
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findAllGearList({ corpId: corpId });
		}
	}

	search() {
		this.props.search(this.props.searchBean.toJS());
	}

	clearSearchInput() {
		this.refs.gearIdDdi.refs.typeahead.getInstance().clear();
		this.refs.statusDdi.refs.typeahead.getInstance().clear();
		this.props.clearSearchInput();
	}

	changeShowSearchInput() {
		if (this.props.showSearchInput) {
			this.props.changeShowSearchInput(false);
		} else {
			this.props.changeShowSearchInput(true);
		}
	}

	render() {
		let iconClassName = "glyphicon glyphicon-plus";
		if (this.props.showSearchInput) {
			iconClassName = "glyphicon glyphicon-minus";
		}

		return (
			<div>
				<CheckSelectCorp />
				<div>
					<Button bsSize="xsmall" onClick={() => this.changeShowSearchInput()}>
						<span className={iconClassName} />
					</Button>
					<Collapse in={this.props.showSearchInput}>
						<div className="well col-xs-12 col-sm-12">
							<Form>
								<Row>
									<Col xs={10} sm={10}>
										<div style={{ marginBottom: 20 }}>
											<Button onClick={(event) => {
												this.search();
											}} bsStyle="default">
												<span className="glyphicon glyphicon-search" style={{ marginRight: 10 }} />搜尋
					    					</Button>
											<span style={{ marginLeft: 10 }}>組合式查詢，若不設條件可留空白</span>
										</div>
									</Col>
									<Col xs={2} sm={2}>
										<div style={{ marginBottom: 20, float: 'right' }}>
											<Button onClick={(event) => {
												this.clearSearchInput();
											}} bsStyle="default">
												清除
					    				</Button>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} sm={6}>
										<DropDownInput ref="gearIdDdi"
											id="gearId"
											label="裝備類別"
											options={this.props.gears.map((value, key) => ({ label: value.get('gear_name'), value: value.get('gear_id') })).toJS()}
											selected={[{ label: (this.props.searchBean.get('gearName') != null ? this.props.searchBean.get('gearName') : ''), value: this.props.searchBean.get('gearId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('gearName', label);
											}}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<DropDownInput ref="statusDdi"
											id="status"
											label="使用狀態"
											options={this.props.itemStatus.map((value, key) => ({ label: value.get('display'), value: value.get('code') })).toJS()}
											selected={[{ label: (this.props.searchBean.get('statusDisplay') != null ? this.props.searchBean.get('statusDisplay') : ''), value: this.props.searchBean.get('status') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('statusDisplay', label);
											}}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="minCnt"
											type="number"
											label="持有數量(少)"
											value={this.props.searchBean.get('minCnt')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="maxCnt"
											type="number"
											label="持有數量(多)"
											value={this.props.searchBean.get('maxCnt')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
								</Row>
							</Form>
						</div>
					</Collapse>
				</div>
				<div>
					<ItemSearch3ListContainer filter={this.props.filter} />
				</div>
			</div>
		)
	}
}