import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	message: emptyMessage(),
	messageValidation: {},
	alertStyle: null,
	alertMessage: null
});

const sendMessageReducer = handleActions({
	CHANGE_MESSAGE_VALUE: (state, { payload }) => {
		let message = state.get('message');
		return state.merge({
			message: message.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_MESSAGE: (state, { payload }) => {
		return state.merge({
			message: payload.data.bean,
			messageValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	CLEAR_MESSAGE_ALERT: (state, { payload }) => {
		return state.merge({
			messageValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	CLEAR_MESSAGE: (state, { payload }) => {
		return state.merge({
			message: emptyMessage(),
			messageValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
}, initState);

function emptyMessage() {
	return {
		sendToCorpId: null,
		sendToCorpName: null,
		messageTitle: null,
		messageContent: null,
		displayTimeStart: null,
		displayTimeEnd: null,
	}
}

export default sendMessageReducer;