import {
	connect
} from 'react-redux'
import SendMessageComponent from '../components/SendMessageComponent.jsx';
import {
	findAllCorps,
	changeMessageValue,
	sendMessage,
	findMessage,
	clearMessageAlert,
	clearMessage
} from '../actions';

const mapStateToProps = (state) => ({
	corps: state.getIn(['commonReducer', 'corps']),
	message: state.getIn(['sendMessageReducer', 'message']),
	messageValidation: state.getIn(['sendMessageReducer', 'messageValidation']),
	alertStyle: state.getIn(['sendMessageReducer', 'alertStyle']),
	alertMessage: state.getIn(['sendMessageReducer', 'alertMessage']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	sendMessage: (message) => {
		dispatch(sendMessage(message));
	},
	findMessage: (payload) => {
		dispatch(findMessage(payload))
	},
	setValue: (id, value) => {
		dispatch(changeMessageValue({
			id: id,
			value: value
		}))
	},
	clearAlert: () => {
		dispatch(clearMessageAlert());
	},
	clearMessage: () => {
		dispatch(clearMessage());
	}
})

const SendMessageContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(SendMessageComponent)

export default SendMessageContainer;