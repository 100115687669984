import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const changeWarrantyValue = createAction('CHANGE_WARRANTY_VALUE');

export const findWarrantyList = (pagingData) => (
  (dispatch) => {
    let url = '/warranty?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_WARRANTY_LIST',
        payload: json
      });
    });
  }
);

export const editWarranty = (warranty, pagingData) => (
  (dispatch) => {
    let url = '/warranty';
    authFetch('PUT', url, JSON.stringify(warranty), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_WARRANTY',
        payload: json
      });
      dispatch(findWarrantyList(pagingData));
    });
  }
);

export const findWarranty = (pk) => (
  (dispatch) => {
    let url = '/warranty/corpId/' + pk.corp_id;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_WARRANTY',
        payload: json
      });
    });
  }
);

export const openWarrantyModal = createAction('OPEN_WARRANTY_MODAL');
export const closeWarrantyModal = createAction('CLOSE_WARRANTY_MODAL');