import {
	connect
} from 'react-redux'
import ItemReplacementComponent from '../components/ItemReplacementComponent.jsx';
import {
	clearSearchReplacementInput,
	searchReplacementItemCount
} from '../actions';

const mapStateToProps = (state) => ({
	filter: state.getIn(['itemReplacementListReducer', 'filter']),
	remindReplacementItemCount: state.getIn(['itemReplacementListReducer', 'remindReplacementItemCount']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	clearSearchInput: () => {
		dispatch(clearSearchReplacementInput());
	},
	searchReplacementItemCount: (payload) => {
		dispatch(searchReplacementItemCount(payload));
	}
})

const ItemReplacementContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemReplacementComponent)

export default ItemReplacementContainer;