import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

import '../css/toggle.css';

export class EmergencyUserLocationComponent extends React.Component {

	constructor(props) {
		super(props);
		this.refreshLocation = this.refreshLocation.bind(this);
	}

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.findActiveLocations({ corpId: corpId, minute: 60, initialCenterIndex: 0 });
		}
		this.countdown = setInterval(this.refreshLocation, 1000 * 5);
	}

	componentWillUnmount() {
		clearInterval(this.countdown);
		this.props.changeKeepRefreshLocation(false);
	}

	onMarkerClick() {
	}

	refreshLocation() {
		let corpId = this.props.role.get('corp_id');
		let initialCenterIndex = this.props.initialCenterIndex;
		if (corpId) {
			if (this.props.keepRefreshing) {
				this.props.findActiveLocations({ corpId: corpId, minute: 60, initialCenterIndex: initialCenterIndex });
			}
		}
	}

	changeKeepRefreshLocation(keepRefreshing) {
		this.props.changeKeepRefreshLocation(keepRefreshing);
	}

	changeInitialCenterIndex() {
		let corpId = this.props.role.get('corp_id');
		let initialCenterIndex = this.props.initialCenterIndex;
		let newICI = initialCenterIndex + 1;
		this.props.changeInitialCenterIndex(newICI);
		if (corpId) {
			this.props.findActiveLocations({ corpId: corpId, minute: 60, initialCenterIndex: newICI });
		}
	}

	render() {

		let mapRender = [];
		let markerArray = [];
		let showDiv = null;

		for (let marker of this.props.markerArr) {
			markerArray.push(
				<Marker key={marker.get('name')}
					title={marker.get('title')}
					name={marker.get('name')}
					onClick={this.onMarkerClick}
					position={marker.get('position').toJS()}
				/>
			)
		}

		if (this.props.initialCenter) {
			let initialCenter = this.props.initialCenter.toJS();
			mapRender.push(
				<div key="map" style={{ width: '100%', height: 550 }}>
					<Map
						google={this.props.google}
						initialCenter={initialCenter}
						center={initialCenter}
						zoom={this.props.zoom}
					>
						{markerArray}
					</Map>
				</div>
			);
		}

		if (this.props.showNoRecordMsg) {
			showDiv = (
				<div className="well col-xs-12 col-sm-12" style={{ marginTop: 10 }}>
					<Row>
						<Col xs={12} sm={12}>
							<span style={{ color: 'red' }}>無定位座標資料</span>
						</Col>
					</Row>
				</div>
			);
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;火場管制&nbsp;&gt;&gt;&nbsp;火場定位</span>
				</Row>
				<Row>
					<Col xs={6} sm={6}>
						<label>自動更新定位座標 :</label>{' '}
						<label className="switch">
							<input type="checkbox" onClick={(event) => {
								if (this.props.keepRefreshing) {
									this.changeKeepRefreshLocation(false);
								} else {
									this.changeKeepRefreshLocation(true);
								}
							}} checked={this.props.keepRefreshing} />
							<span className="slider round"></span>
						</label>
					</Col>
					<Col xs={6} sm={6}>
						<label>切換座標點中心 :</label>{' '}
						<Button bsStyle="default" bsSize="small" onClick={(event) => {
							this.changeInitialCenterIndex();
						}}>
							<span className="glyphicon glyphicon-play" />
						</Button>
					</Col>
				</Row>
				{showDiv}
				<Row style={{ marginRight: 10, marginTop: 10 }}>
					<Col xs={12} sm={12}>
						{mapRender}
					</Col>
				</Row>
			</div>
		)
	}
}

export default GoogleApiWrapper({
	apiKey: ('AIzaSyDNnCUUUT1T931FBVU8gqpsTPkhGXOxUDQ')
})(EmergencyUserLocationComponent)