import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	itemTransferList: [],
	activePage: 1,
	itemPerPage: 10,
	totalPage: 1,
	filter: null
});

const itemTransferListReducer = handleActions({
	FIND_ITEM_TRANSFER_LIST: (state, { payload }) => {
		return state.merge({
			itemTransferList: payload.data.dataTableData,
			activePage: payload.data.activePage,
			totalPage: payload.data.totalPage,
			itemPerPage: payload.data.itemPerPage,
			filter: payload.data.filter
		})
	}
}, initState);

export default itemTransferListReducer;