import {
	connect
} from 'react-redux'
import UsersComponent from '../components/UsersComponent.jsx';
import {
	closeUserModal,
	changeSearchUserValue,
	findAllCorps,
	findAllManufacturers,
	changeShowSearchCorp,
	changeShowSearchManufacturer,
	findUserList,
	findUnVerifiedCount
} from '../actions';

const mapStateToProps = (state) => ({
	showModal: state.getIn(['userReducer', 'showModal']),
	corps: state.getIn(['commonReducer', 'corps']),
	manufacturers: state.getIn(['commonReducer', 'manufacturers']),
	searchBean: state.getIn(['userReducer', 'searchBean']),
	showCorp: state.getIn(['userReducer', 'showSearchCorp']),
	showManufacturer: state.getIn(['userReducer', 'showSearchManufacturer']),
	unVerifiedCount: state.getIn(['userReducer', 'unVerifiedCount']),
	role: state.getIn(['authReducer', 'role']),
	filter: (state.getIn(['userListReducer', 'filter'])) ? state.getIn(['userListReducer', 'filter']).toJS() : {}
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	closeModal: () => {
		dispatch(closeUserModal());
	},
	setValue: (id, value) => {
		dispatch(changeSearchUserValue({
			id: id,
			value: value
		}))
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	findAllManufacturers: () => {
		dispatch(findAllManufacturers());
	},
	changeShowCorp: (payload) => {
		dispatch(changeShowSearchCorp(payload));
	},
	changeShowManufacturer: (payload) => {
		dispatch(changeShowSearchManufacturer(payload));
	},
	searchUser: (searchBean) => {
		dispatch(findUserList({}, searchBean));
	},
	findUnVerifiedCount: (searchBean) => {
		dispatch(findUnVerifiedCount(searchBean));
	}
})

const UsersContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(UsersComponent)

export default UsersContainer;