import {
	connect
} from 'react-redux'
import ItemSearch2Component from '../components/ItemSearch2Component.jsx';
import {
	changeSearchBean2Value,
	findMainSquaList,
	findSubSquaList,
	findAllGearList,
	searchItem2,
	changeShowSearchInput,
	findAllCorps,
	clearSearch2Input
} from '../actions';

const mapStateToProps = (state) => ({
	searchBean: state.getIn(['itemSearchReducer', 'searchBean2']),
	corps: state.getIn(['commonReducer', 'corps']),
	mainSquas: state.getIn(['itemSearchReducer', 'mainSquas']),
	subSquas: state.getIn(['itemSearchReducer', 'subSquas']),
	gears: state.getIn(['commonReducer', 'gears']),
	showSearchInput: state.getIn(['itemSearchReducer', 'showSearchInput']),
	role: state.getIn(['authReducer', 'role']),
	filter: state.getIn(['itemSearch2ListReducer', 'filter']),
})

const mapDispatchToProps = (dispatch) => ({
	setValue: (id, value) => {
		dispatch(changeSearchBean2Value({
			id: id,
			value: value
		}))
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'SEARCH1'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'SEARCH1'));
	},
	findAllGearList: (payload) => {
		dispatch(findAllGearList(payload));
	},
	search: (payload) => {
		dispatch(searchItem2(payload, { activePage: 1, itemPerPage: 10 }));
	},
	changeShowSearchInput: (payload) => {
		dispatch(changeShowSearchInput(payload));
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	clearSearchInput: () => {
		dispatch(clearSearch2Input());
	}
})

const ItemSearch2Container = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemSearch2Component)

export default ItemSearch2Container;