import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	itemSearchList: [],
	activePage: 1,
	itemPerPage: 10,
	totalPage: 1,
	filter: null,
	alertStyle: null,
	alertMessage: null
});

const itemReplacementListReducer = handleActions({
	FIND_ITEM_USER_LIST: (state, { payload }) => {
		if (payload.data.dataTableData.length === 0) {
			return state.merge({
				itemSearchList: [],
				activePage: 1,
				itemPerPage: 10,
				totalPage: 1,
				filter: payload.data.filter,
				alertStyle: 'warning',
				alertMessage: '無個人裝備'
			})
		} else {
			return state.merge({
				itemSearchList: payload.data.dataTableData,
				activePage: payload.data.activePage,
				totalPage: payload.data.totalPage,
				itemPerPage: payload.data.itemPerPage,
				filter: payload.data.filter,
				alertStyle: null,
				alertMessage: null
			})
		}
	}
}, initState);

export default itemReplacementListReducer;