import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

import { dateToYMD } from '../../constants/commonFunction.js';

const initState = Immutable.fromJS({
	eventKey: 0,
	item: emptyItem(),
	orgItem: emptyItem(),
	itemValidation: {},
	itemTransfer: emptyItemTransfer(),
	itemTransferValidation: {},
	itemStatusChange: emptyItemStatusChange(),
	itemStatusChangeValidation: {},
	alertStyle: null,
	alertMessage: null,
	forInsert: true,
	mainSquas: [],
	subSquas: [],
	locations: []
});

const itemReducer = handleActions({
	CHANGE_ITEM_EVENT_KEY: (state, { payload }) => {
		return state.merge({
			eventKey: payload
		})
	},
	CHANGE_ITEM_VALUE: (state, { payload }) => {
		let item = state.get('item');
		return state.merge({
			item: item.set(payload.id, payload.value)
		})
	},
	FORM_SUBMIT_ITEM: (state, { payload }) => {
		return state.merge({
			item: payload.data.bean,
			itemValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage
		})
	},
	FORM_SUBMIT_ITEM_TRANSFER: (state, { payload }) => {
		return state.merge({
			itemTransfer: payload.data.bean,
			itemTransferValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage
		})
	},
	FORM_SUBMIT_ITEM_CHANGE_STATUS: (state, { payload }) => {
		return state.merge({
			itemStatusChange: payload.data.bean,
			itemStatusChangeValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage
		})
	},
	DELETE_ITEM: (state, { payload }) => {
		return state.merge({
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
			item: emptyItem(),
			itemValidation: {},
			subSquas: [],
			locations: []
		})
	},
	FIND_ITEM: (state, { payload }) => {
		return state.merge({
			item: payload.data,
			orgItem: payload.data,
			forInsert: false
		})
	},
	FIND_MAIN_SQUA_LIST_EDIT: (state, { payload }) => {
		return state.merge({
			mainSquas: payload.data
		})
	},
	FIND_SUB_SQUA_LIST_EDIT: (state, { payload }) => {
		return state.merge({
			subSquas: payload.data
		})
	},
	FIND_ALL_LOCATION_LIST_EDIT: (state, { payload }) => {
		return state.merge({
			locations: payload.data
		})
	},
	CLEAR_ITEM_INPUT: (state, { payload }) => {
		let item = state.get('item');
		return state.merge({
			item: emptyItem(item.toJS()),
			orgItem: emptyItem(item.toJS()),
			itemValidation: {},
			alertStyle: null,
			alertMessage: null,
			subSquas: []
		})
	},
	CLEAR_ITEM_ALERT: (state, { payload }) => {
		return state.merge({
			itemValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	CLEAR_ITEM_TRANSFER_INPUT: (state, { payload }) => {
		return state.merge({
			itemTransfer: emptyItemTransfer(),
			itemTransferValidation: {},
			alertStyle: null,
			alertMessage: null,
			subSquas: [],
			locations: []
		})
	},
	CLEAR_ITEM_TRANSFER_ALERT: (state, { payload }) => {
		return state.merge({
			itemTransferValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	CLEAR_ITEM_STATUS_CHANGE_INPUT: (state, { payload }) => {
		return state.merge({
			itemStatusChange: emptyItemStatusChange(),
			itemStatusChangeValidation: {},
			alertStyle: null,
			alertMessage: null,
			subSquas: []
		})
	},
	CLEAR_ITEM_STATUS_CHANGE_ALERT: (state, { payload }) => {
		return state.merge({
			itemStatusChangeValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	CHANGE_ITEM_TRANSFER_VALUE: (state, { payload }) => {
		let itemTransfer = state.get('itemTransfer');
		return state.merge({
			itemTransfer: itemTransfer.set(payload.id, payload.value)
		})
	},
	CHANGE_ITEM_STATUS_CHANGE_VALUE: (state, { payload }) => {
		let itemStatusChange = state.get('itemStatusChange');
		return state.merge({
			itemStatusChange: itemStatusChange.set(payload.id, payload.value)
		})
	}
}, initState);

function emptyItem(orgItem) {
	let nowDateDisplay = dateToYMD(new Date());
	let corpId = null;
	if (orgItem) {
		corpId = orgItem.corpId;
	}
	return {
		itemId: null,
		corpId: corpId,
		mainSquaId: null,
		mainSquaName: null,
		subSquaId: null,
		subSquaName: null,
		gearId: null,
		gearName: null,
		firefighterType: null,
		manufacturerId: null,
		manufacturerName: null,
		brand: null,
		model: null,
		manufactureYear: null,
		warrantyYear: null,
		sizing: null,
		color: null,
		itemNo: null,
		itemType: null,
		assetNo: null,
		purchaseDate: null,
		purchaseAmount: null,
		expiredDate: null,
		accessory: null,
		manufactureNo: null,
		rfidNo: null,
		locationType: null,
		locationId: null,
		locationName: null,
		status: null,
		statusDisplay: null,
		transferTime: nowDateDisplay,
		kkOrderId: null,
		kkLabelId: null,
		note: null,
		fromSquaId: null,
		orgLocationId: null,
		corpName: null,
		locationTypeDisplay: null,
		itemTypeDisplay: null
	}
}

function emptyItemTransfer() {
	let nowDateDisplay = dateToYMD(new Date());
	return {
		itemId: null,
		fromSquaId: null,
		toMainSquaId: null,
		toMainSquaName: '',
		toSubSquaId: null,
		toSubSquaName: '',
		newLocationType: null,
		orgLocationId: null,
		newLocationId: null,
		newLocationName: '',
		transferTime: nowDateDisplay
	}
}

function emptyItemStatusChange() {
	let nowDateDisplay = dateToYMD(new Date());
	return {
		itemId: null,
		status: null,
		statusDisplay: '',
		changeStatusTime: nowDateDisplay,
		lendMainSquaId: null,
		lendMainSquaName: '',
		lendSubSquaId: null,
		lendSubSquaName: ''
	}
}

export default itemReducer;