import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findEmergencyRecordList
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
	data: state.getIn(['emergencyRecordListReducer', 'list']),
	activePage: state.getIn(['emergencyRecordListReducer', 'activePage']),
	itemPerPage: state.getIn(['emergencyRecordListReducer', 'itemPerPage']),
	totalPage: state.getIn(['emergencyRecordListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: findFields(),
	keys: ['record_id'],
	showCheckBox: false,
	disableAdd: true,
	disableEdit: true
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
		// dispatch(findEmergencyRecordList(pagingData, ownProps.filter));
	},
	changeActivepage: (pagingData) => {
		dispatch(findEmergencyRecordList(pagingData, ownProps.filter));
	}
})

const EmergencyRecordListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

function findFields() {
	return {
		area_name: '位置',
		record_step_desc: '狀態',
		nfc_text_desc: '人員',
		create_time_char: '建立時間',
		nfc_text: '晶片'
	}
}

export default EmergencyRecordListContainer;