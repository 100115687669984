import {
	connect
} from 'react-redux'
import CorpEditComponent from '../components/CorpEditComponent.jsx';
import {
	changeCorpValue,
	addCorp,
	editCorp,
	findKkCustomers
} from '../actions';

const mapStateToProps = (state) => ({
	corp: state.getIn(['corpReducer', 'corp']),
	corpValidation: state.getIn(['corpReducer', 'corpValidation']),
	alertStyle: state.getIn(['corpReducer', 'alertStyle']),
	alertMessage: state.getIn(['corpReducer', 'alertMessage']),
	forInsert: state.getIn(['corpReducer', 'forInsert']),

	activePage: state.getIn(['corpListReducer', 'activePage']),
	itemPerPage: state.getIn(['corpListReducer', 'itemPerPage']),

	kkCustomers: state.getIn(['tkkDataReducer', 'kkCustomers'])
})

const mapDispatchToProps = (dispatch) => ({
	editItem: (corp, pagingData, forInsert) => {
		if (forInsert) {
			dispatch(addCorp(corp, pagingData));
		} else {
			dispatch(editCorp(corp, pagingData));
		}
	},
	setValue: (id, value) => {
		dispatch(changeCorpValue({
			id: id,
			value: value
		}))
	},
	findKkCustomers: () => {
		dispatch(findKkCustomers());
	}
})

const CorpEditContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(CorpEditComponent)

export default CorpEditContainer;