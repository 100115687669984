import {
	connect
} from 'react-redux'
import MRecordSearchComponent from '../components/MRecordSearchComponent.jsx';
import {
	changeMRecordSearchBeanValue,
	clearMRecordSearchInput,
	changeMRecordShowSearchInput,
	findMainSquaList,
	findSubSquaList,
	searchMRecord
} from '../actions';

const mapStateToProps = (state) => ({
	searchBean: state.getIn(['mRecordReducer', 'searchBean']),
	showSearchInput: state.getIn(['mRecordReducer', 'showSearchInput']),
	mainSquas: state.getIn(['mRecordReducer', 'mainSquas']),
	subSquas: state.getIn(['mRecordReducer', 'subSquas']),
	gears: state.getIn(['commonReducer', 'gears']),
	role: state.getIn(['authReducer', 'role']),
	filter: state.getIn(['mRecordListReducer', 'filter']),
})

const mapDispatchToProps = (dispatch) => ({
	setValue: (id, value) => {
		dispatch(changeMRecordSearchBeanValue({
			id: id,
			value: value
		}))
	},
	clearSearchInput: () => {
		dispatch(clearMRecordSearchInput());
	},
	changeShowSearchInput: (payload) => {
		dispatch(changeMRecordShowSearchInput(payload));
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'M_RECORD_SEARCH'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'M_RECORD_SEARCH'));
	},
	search: (payload) => {
		dispatch(searchMRecord(payload, { activePage: 1, itemPerPage: 10 }));
	}
})

const MRecordSearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MRecordSearchComponent)

export default MRecordSearchContainer;