import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
  mPicBean: {
    picFile: null
  },
  mPicValidation: {},
  alertStyle: null,
  alertMessage: null
});

const mPicUploadReducer = handleActions({
  BEFORE_UPLOAD_M_PIC_DATA: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: '檔案上傳中，請稍後。'
    })
  },
  AFTER_UPLOAD_M_PIC_DATA: (state, { payload }) => {
    return state.merge({
      alertStyle: payload.data.alertStyle,
      alertMessage: payload.data.alertMessage,
    })
  },
  CLEAR_M_PIC_UPLOAD_INPUT: (state, { payload }) => {
    return state.merge({
      mPicBean: {
        picFile: null
      },
      mPicValidation: {},
      alertStyle: null,
      alertMessage: null
    })
  },
  CLEAR_M_PIC_UPLOAD_ALERT: (state, { payload }) => {
    return state.merge({
      mPicValidation: {},
      alertStyle: null,
      alertMessage: null
    })
  },
}, initState);

export default mPicUploadReducer;