import React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

export default class CorpEditComponent extends React.Component {

	componentDidMount() {
		this.props.findKkCustomers();
	}

	editItem() {
		this.props.editItem(this.props.corp, {
			activePage: this.props.activePage,
			itemPerPage: this.props.itemPerPage
		}, this.props.forInsert);
	}

	render() {
		return (
			<div>
				<Row>
					<Col xs={2} sm={2}>
						<Button onClick={(event) => {
							this.editItem();
						}} bsStyle="primary">
							送出
					    </Button>
					</Col>
					<Col xs={10} sm={10}>
						<Alert bsStyle={this.props.alertStyle}>
							{this.props.alertMessage}
						</Alert>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} sm={12}>
						ID: {this.props.corp.get('corpId')}
					</Col>
					<Col xs={12} sm={12}>
						<FieldGroup
							id="corpName"
							type="text"
							label="單位名稱"
							value={this.props.corp.get('corpName')}
							placeholder=""
							required="true"
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.corpValidation.get('corpName')}
						/>
						<DropDownInput id="kkCustomerId"
							label="固巧快客戶代號"
							required="true"
							options={this.props.kkCustomers.map((value, key) => ({ label: value.get('display'), value: value.get('customer_id') })).toJS()}
							selected={[{ label: (this.props.corp.get('kkCustomerDisplay') != null ? this.props.corp.get('kkCustomerDisplay') : ''), value: this.props.corp.get('kkCustomerId') }]}
							onChange={(id, value, label) => {
								this.props.setValue(id, value);
								this.props.setValue('kkCustomerDisplay', label);
							}}
							validation={this.props.corpValidation.get('kkCustomerId')}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}
