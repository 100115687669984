import React from 'react';
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';

export default class RadioGroup extends React.Component {

  render() {

    let validationState = null;
    let helpBlock = null;

    if (this.props.validation) {
      validationState = this.props.validation.get('validationState');
      helpBlock = this.props.validation.get('helpBlock');
    }

    let showRequired = null;
    if (this.props.required) {
      showRequired = (<span className="text-danger" style={{ marginLeft: 5 }}>*</span>)
    }

    let inputArray = [];
    if (this.props.radioList) {
      for (let radioMap of this.props.radioList) {
        inputArray.push((
          <label className="radio-inline" key={radioMap.value}>
            <input type="radio" name={this.props.id} value={radioMap.value} onChange={(event) => {
              this.props.onChange(this.props.id, event.target.value);
            }} checked={this.props.value === radioMap.value} />{radioMap.label}
          </label>
        ));
      }
    }

    return (
      <FormGroup
        controlId={this.props.id}
        validationState={validationState} >
        <ControlLabel>{this.props.label}{showRequired}</ControlLabel><br />
        {inputArray}
        <HelpBlock>{helpBlock}</HelpBlock>
      </FormGroup>
    );
  }
}
