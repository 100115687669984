import React from 'react';
import { Modal, Row, Col, Button, ButtonToolbar, Table, Alert } from 'react-bootstrap';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';
import SquaEdit2Container from '../containers/SquaEdit2Container.jsx';

export default class Squas2Component extends React.Component {

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
		}
	}

	componentWillUnmount() {
		this.props.clearSelectedMainSqua();
	}

	deleteSqua(squaId, payload) {
		let r = window.confirm('確認刪除 ?');
		if (r === true) {
			let deleteKey = [{ squa_id: squaId }];
			this.props.deleteItems(deleteKey, payload);
		}
	}

	render() {
		let corpId = this.props.role.get('corp_id');

		let mainTableRows = [];
		let subTableRows = [];

		let selectedMainSquaName = '';
		let disableAddSub = true;
		if (this.props.selectedMainSqua) {
			selectedMainSquaName = this.props.selectedMainSqua.get('squa_name') + ', ';
			disableAddSub = false;
		}

		for (let i = 0; i < this.props.mainSquas.size; i++) {
			let row = this.props.mainSquas.get(i);
			let disableDelete = false;
			if (row.get('count')) {
				disableDelete = true;
			}
			mainTableRows.push(
				<tr key={row.get('squa_id')}>
					<td>
						<ButtonToolbar>
							<Button bsStyle="default" bsSize="small" onClick={(event) => {
								this.props.openEditModal({ squa_id: row.get('squa_id') });
							}}><span className="glyphicon glyphicon-edit" />
							</Button>
							<Button bsStyle="default" bsSize="small" onClick={(event) => {
								this.deleteSqua(row.get('squa_id'), { corpId: corpId, parentId: 0 });
							}} disabled={disableDelete}><span className="glyphicon glyphicon-trash" style={{ color: '#a94442' }} />
							</Button>
						</ButtonToolbar>
					</td>
					<td style={{ cursor: 'pointer' }} onClick={(event) => {
						this.props.changeSelectedMainSqua(row);
						this.props.findSubSquaList({ corpId: corpId, parentId: row.get('squa_id') });
					}}>{row.get('squa_name')}</td>
				</tr>
			)
		}

		for (let i = 0; i < this.props.subSquas.size; i++) {
			let row = this.props.subSquas.get(i);
			subTableRows.push(
				<tr key={row.get('squa_id')}>
					<td>
						<ButtonToolbar>
							<Button bsStyle="default" bsSize="small" onClick={(event) => {
								this.props.openEditModal({ squa_id: row.get('squa_id') });
							}}><span className="glyphicon glyphicon-edit" />
							</Button>
							<Button bsStyle="default" bsSize="small" onClick={(event) => {
								this.deleteSqua(row.get('squa_id'), { corpId: corpId, parentId: this.props.selectedMainSqua.get('squa_id') });
							}}><span className="glyphicon glyphicon-trash" style={{ color: '#a94442' }} />
							</Button>
						</ButtonToolbar>
					</td>
					<td>{row.get('squa_name')}</td>
				</tr>
			)
		}

		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Col xs={12} sm={12}>
					<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
						{this.props.alertMessage}
					</Alert>
				</Col>
			);
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;基本資料管理&nbsp;&gt;&gt;&nbsp;分隊管理</span>
				</Row>
				<Row>
					{alertDiv}
				</Row>
				<Row>
					<Col xs={6} sm={6}>
						<h4>大隊列表</h4>
						<div style={{ paddingTop: 10, paddingBottom: 10 }}>
							<Button bsStyle="default" onClick={(event) => {
								this.props.openAddModal({ corpId: corpId, parentId: 0 });
							}}><span className="glyphicon glyphicon-plus" style={{ marginRight: 10 }} />新增
							</Button>
						</div>
						<Table bordered condensed hover striped>
							<thead>
								<tr>
									<th style={{ width: 88 }}></th>
									<th>大隊名稱</th>
								</tr>
							</thead>
							<tbody>
								{mainTableRows}
							</tbody>
						</Table>
					</Col>
					<Col xs={6} sm={6}>
						<h4>{selectedMainSquaName}分隊列表</h4>
						<div style={{ paddingTop: 10, paddingBottom: 10 }}>
							<Button bsStyle="default" disabled={disableAddSub} onClick={(event) => {
								this.props.openAddModal({ corpId: corpId, parentId: this.props.selectedMainSqua.get('squa_id') });
							}}><span className="glyphicon glyphicon-plus" style={{ marginRight: 10 }} />新增
							</Button>
						</div>
						<Table bordered condensed hover striped>
							<thead>
								<tr>
									<th style={{ width: 88 }}></th>
									<th>分隊名稱</th>
								</tr>
							</thead>
							<tbody>
								{subTableRows}
							</tbody>
						</Table>
					</Col>
				</Row>
				<Modal show={this.props.showModal} onHide={this.props.closeModal}>
					<Modal.Header closeButton />
					<Modal.Body>
						<SquaEdit2Container />
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}