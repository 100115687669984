import {
	connect
} from 'react-redux'
import EmergencyUserLocationComponent from '../components/EmergencyUserLocationComponent.jsx';
import {
	findActiveLocations,
	changeKeepRefreshLocation,
	changeInitialCenterIndex
} from '../actions';

const mapStateToProps = (state) => ({
	zoom: state.getIn(['emergencyUserLocationReducer', 'zoom']),
	initialCenter: state.getIn(['emergencyUserLocationReducer', 'initialCenter']),
	markerArr: state.getIn(['emergencyUserLocationReducer', 'markerArr']),
	keepRefreshing: state.getIn(['emergencyUserLocationReducer', 'keepRefreshing']),
	initialCenterIndex: state.getIn(['emergencyUserLocationReducer', 'initialCenterIndex']),
	showNoRecordMsg: state.getIn(['emergencyUserLocationReducer', 'showNoRecordMsg']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	findActiveLocations: (payload) => {
		dispatch(findActiveLocations(payload));
	},
	changeKeepRefreshLocation: (payload) => {
		dispatch(changeKeepRefreshLocation(payload));
	},
	changeInitialCenterIndex: (payload) => {
		dispatch(changeInitialCenterIndex(payload));
	}
})

const EmergencyUserLocationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(EmergencyUserLocationComponent)

export default EmergencyUserLocationContainer;