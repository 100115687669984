import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	gear: emptyGear(),
	gearValidation: {},
	alertStyle: null,
	alertMessage: null,
	showModal: false,
	forInsert: true,
	searchCorpId: null
});

const gearReducer = handleActions({
	CHANGE_GEAR_VALUE: (state, { payload }) => {
		let gear = state.get('gear');
		return state.merge({
			gear: gear.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_GEAR: (state, { payload }) => {
		return state.merge({
			gear: payload.data.bean,
			gearValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	OPEN_GEAR_MODAL: (state, { payload }) => {
		return state.merge({
			forInsert: payload.forInsert,
			showModal: true
		})
	},
	CLOSE_GEAR_MODAL: (state, { payload }) => {
		return state.merge({
			gear: emptyGear(),
			gearValidation: {},
			alertStyle: null,
			alertMessage: null,
			showModal: false,
			forInsert: true
		})
	},
	FIND_GEAR: (state, { payload }) => {
		return state.merge({
			gear: payload.data
		})
	},
	CHANGE_SEARCH_GEAR_CORPID: (state, { payload }) => {
		return state.merge({
			searchCorpId: payload
		})
	},
	ASSIGN_GEAR: (state, { payload }) => {
		let gear = state.get('gear');
		gear = gear.set('corpId', payload.corpId);
		return state.merge({
			gear: gear
		})
	},
}, initState);

function emptyGear() {
	return {
		gearId: null,
		gearName: null,
		orgGearName: null,
		corpId: null,
		corpName: ''
	}
}

export default gearReducer;