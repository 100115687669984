import React from 'react';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';
import ItemSearch1InnerContainer from '../containers/ItemSearch1InnerContainer.jsx';
import ItemSearchListContainer from '../containers/ItemSearchListContainer.jsx';

export default class ItemSearch1Component extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
			<div>
				<CheckSelectCorp />
				<div>
					<ItemSearch1InnerContainer />
				</div>
				<div>
					<ItemSearchListContainer filter={this.props.filter} uiType='VIEW' />
				</div>
			</div>
		)
	}
}