import { connect } from 'react-redux'
import MRecordUploadComponent from '../components/MRecordUploadComponent.jsx';
import {
  uploadMRecordData,
  findKkMaintenanceCaseByCorpId,
  exportMRecordData,
  changeMRecordExportValue,
  changeMRecordExportBatchId,
  clearMRecordUploadAlert,
  clearMRecordUploadInput,
  findMRecordBatchRecords,
  deleteMRecordByBatchId
} from '../actions';

const mapStateToProps = (state) => ({
  exportBean: state.getIn(['mRecordUploadReducer', 'exportBean']),
  exportBeanValidation: state.getIn(['mRecordUploadReducer', 'exportBeanValidation']),
  corps: state.getIn(['commonReducer', 'corps']),
  alertStyle: state.getIn(['mRecordUploadReducer', 'alertStyle']),
  alertMessage: state.getIn(['mRecordUploadReducer', 'alertMessage']),
  kkMaintenanceCase: state.getIn(['tkkDataReducer', 'kkMaintenanceCase']),
  batchDeleteBean: state.getIn(['mRecordUploadReducer', 'batchDeleteBean']),
  batchDeleteBeanValidation: state.getIn(['mRecordUploadReducer', 'batchDeleteBeanValidation']),
  batchRecords: state.getIn(['mRecordUploadReducer', 'batchRecords']),
  role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (uploadFile, corpId) => {
    dispatch(uploadMRecordData(uploadFile, corpId));
  },
  findKkMaintenanceCases: (corpId) => {
    dispatch(findKkMaintenanceCaseByCorpId(corpId));
  },
  setValue: (id, value) => {
    dispatch(changeMRecordExportValue({
      id: id,
      value: value
    }))
  },
  changeBatchId: (value) => {
    dispatch(changeMRecordExportBatchId(value));
  },
  exportFile: (exportBean) => {
    dispatch(exportMRecordData(exportBean));
  },
  clearAlert: () => {
    dispatch(clearMRecordUploadAlert());
  },
  clearInput: () => {
    dispatch(clearMRecordUploadInput());
  },
  findBatchRecords: () => {
    dispatch(findMRecordBatchRecords())
  },
  deleteByBatchId: (payload) => {
    dispatch(deleteMRecordByBatchId(payload))
  }
})

const MRecordUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MRecordUploadComponent)

export default MRecordUploadContainer;