import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Immutable from 'immutable';
import 'react-bootstrap-typeahead/css/Typeahead.css'

export default class MultiDropDownInput extends React.Component {

    handleClear() {
        this.props.onChange(this.props.id, null, '');
    }

    render() {
        let divClassName = '';
        let iconClassName = '';
        let helpBlock = '';

        if (this.props.validation) {
            if (this.props.validation.get('validationState') === 'error') {
                divClassName = 'form-group has-feedback has-error';
                iconClassName = 'form-control-feedback glyphicon glyphicon-remove';
                helpBlock = this.props.validation.get('helpBlock');
            }
        }

        let showRequired = null;
        if (this.props.required) {
            showRequired = (<span className="text-danger" style={{ marginLeft: 5 }}>*</span>)
        }

        let extraDivStyle = {};
        if (!this.props.label) {
            extraDivStyle = ({ marginTop: -25 })
        }

        let selectedList = [];
        if (this.props.selected) {
            if (this.props.selected instanceof Immutable.List) {
                selectedList = this.props.selected.toJS();
            } else {
                selectedList = this.props.selected;
            }
        }

        return (
            <div className={divClassName} style={extraDivStyle}>
                <label className="control-label">{this.props.label}{showRequired}</label>
                <Typeahead ref="typeahead"
                    onChange={(event) => {
                        if (event[0]) {
                            this.props.onChange(this.props.id, event);
                        }
                    }}
                    options={this.props.options}
                    selected={selectedList}
                    disabled={this.props.disabled}
                    multiple={true}
                />
                <span className={iconClassName} />
                <span className="help-block">{helpBlock}</span>
            </div>
        );
    }
}
