import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
  alertStyle: null,
  alertMessage: null,
  loading: false
});

const loginReducer = handleActions({
  AWS_COGNITO_PASSWORD_CHANGE: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: null,
      loading: true
    });
  },
  AWS_COGNITO_PASSWORD_CHANGE_SUCCESS: (state, { payload }) => {
    return state.merge({
      alertStyle: 'success',
      alertMessage: '更換密碼成功',
      loading: false
    });
  },
  AWS_COGNITO_PASSWORD_CHANGE_ERROR: (state, { payload }) => {
    return state.merge({
      alertStyle: 'danger',
      alertMessage: payload,
      loading: false
    });
  },
  AWS_COGNITO_PASSWORD_CLEAR: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: null,
      loading: false
    });
  }
}, initState);

export default loginReducer;