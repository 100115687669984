import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	searchUserItem,
	clearItemSearchAlert,
	findItem,
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
	data: state.getIn(['itemUserListReducer', 'itemSearchList']),
	activePage: state.getIn(['itemUserListReducer', 'activePage']),
	itemPerPage: state.getIn(['itemUserListReducer', 'itemPerPage']),
	totalPage: state.getIn(['itemUserListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		squa_name: '大隊分隊',
		firefighter_type_display: '警義消',
		item_no: '裝備編號',
		asset_no: '財產編號',
		gear_name: '裝備類別',
		model: '型式',
		color: '顏色',
		sizing: '尺寸',
		manufacture_year: '年份',
		location_name: '保管人員地點',
		expired_date_display: '使用期限',
		status_display: '使用狀態'
	},
	keys: ['item_id', 'corp_id'],
	disableAdd: true,
	edit0: {
		className: 'glyphicon glyphicon-eye-open',
		showText: '裝備總覽'
	},
	alertStyle: state.getIn(['itemUserListReducer', 'alertStyle']),
	alertMessage: state.getIn(['itemUserListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
		dispatch(searchUserItem(pagingData));
	},
	changeActivepage: (pagingData) => {
		dispatch(searchUserItem(pagingData));
	},
	handleEdit: (pk) => {
		dispatch(findItem(pk, 'VIEW'));
	},
	clearAlert: () => {
		dispatch(clearItemSearchAlert());
	}
})

const ItemUserListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default ItemUserListContainer;