import React from 'react';
import { Modal, Row, Col, Button, Collapse } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';
import LocationListContainer from '../containers/LocationListContainer.jsx';
import LocationEditContainer from '../containers/LocationEditContainer.jsx';

export default class LocationsComponent extends React.Component {

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
			this.props.findUserList({ corpId: corpId });
		}
	}

	componentWillUnmount() {
		this.props.clearSearchInput();
	}

	searchLocation() {
		this.props.searchLocation(this.props.searchBean);
	}

	changeShowLocationSearchInput() {
		if (this.props.showSearchInput) {
			this.props.changeShowLocationSearchInput(false);
		} else {
			this.props.changeShowLocationSearchInput(true);
		}
	}

	clearSearchInput() {
		this.refs.userIdDdi.refs.typeahead.getInstance().clear();
		this.refs.mainSquaIdDdi.refs.typeahead.getInstance().clear();
		this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
		this.props.clearSearchInput();
	}

	render() {

		let iconClassName = "glyphicon glyphicon-plus";
		if (this.props.showSearchInput) {
			iconClassName = "glyphicon glyphicon-minus";
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;保管地點管理</span>
				</Row>
				<div style={{ paddingTop: 10 }}>
					<Button bsSize="xsmall" onClick={() => this.changeShowLocationSearchInput()}>
						<span className={iconClassName} />
					</Button>
				</div>
				<Collapse in={this.props.showSearchInput}>
					<div className="well col-xs-12 col-sm-12">
						<Row>
							<Col xs={10} sm={10}>
								<div style={{ marginBottom: 20 }}>
									<Button onClick={(event) => {
										this.searchLocation();
									}} bsStyle="default">
										<span className="glyphicon glyphicon-search" style={{ marginRight: 10 }} />搜尋
					    			</Button>
									<span style={{ marginLeft: 10 }}>組合式查詢，若不設條件可留空白</span>
								</div>
							</Col>
							<Col xs={2} sm={2}>
								<div style={{ marginBottom: 20, float: 'right' }}>
									<Button onClick={(event) => {
										this.clearSearchInput();
									}} bsStyle="default">
										清除
					    			</Button>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={6}>
								<FieldGroup
									id="locationName"
									type="text"
									label="名稱"
									value={this.props.searchBean.get('locationName')}
									placeholder=""
									onChange={(id, value) => {
										this.props.setValue(id, value);
									}}
								/>
							</Col>
							<Col xs={12} sm={6}>
								<RadioGroup
									id="sLocationType"
									label="類別"
									radioList={[{ label: "人員", value: 1 }, { label: "車輛", value: 2 }, { label: "倉庫", value: 3 }, { label: "其他", value: 4 }]}
									value={this.props.searchBean.get('sLocationType')}
									onChange={(id, value) => {
										this.props.setValue(id, parseInt(value, 10));
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={6}>
								<FieldGroup
									id="locationNo"
									type="text"
									label="編號"
									value={this.props.searchBean.get('locationNo')}
									placeholder=""
									onChange={(id, value) => {
										this.props.setValue(id, value);
									}}
								/>
							</Col>
							<Col xs={12} sm={6}>
								<DropDownInput ref="userIdDdi"
									id="userId"
									label="所屬帳號"
									options={this.props.users.map((value, key) => ({ label: (value.get('user_id') + '(' + value.get('user_name') + ')'), value: value.get('user_id') })).toJS()}
									selected={[{ label: (this.props.searchBean.get('userDisplay') != null ? this.props.searchBean.get('userDisplay') : ''), value: this.props.searchBean.get('userId') }]}
									onChange={(id, value, label) => {
										this.props.setValue(id, value);
										this.props.setValue('userDisplay', label);
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={6}>
								<DropDownInput ref="mainSquaIdDdi"
									id="mainSquaId"
									label="大隊"
									options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
									selected={[{ label: (this.props.searchBean.get('mainSquaName') != null ? this.props.searchBean.get('mainSquaName') : ''), value: this.props.searchBean.get('mainSquaId') }]}
									onChange={(id, value, label) => {
										this.props.setValue(id, value);
										this.props.setValue('mainSquaName', label);
										if (value) {
											this.props.findSubSquaList({ corpId: this.props.searchBean.get('corpId'), parentId: value });
										}
										else {
											this.props.findSubSquaList({ corpId: this.props.searchBean.get('corpId'), parentId: -1 });
										}
									}}
									extraClear={() => {
										// this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
										this.props.setValue('subSquaId', null);
										this.props.setValue('subSquaName', null);
									}}
								/>
							</Col>
							<Col xs={12} sm={6}>
								<DropDownInput ref="subSquaIdDdi"
									id="subSquaId"
									label="分隊"
									options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
									selected={[{ label: (this.props.searchBean.get('subSquaName') != null ? this.props.searchBean.get('subSquaName') : ''), value: this.props.searchBean.get('subSquaId') }]}
									onChange={(id, value, label) => {
										this.props.setValue(id, value);
										this.props.setValue('subSquaName', label);
									}}
								/>
							</Col>
						</Row>
					</div>
				</Collapse>
				<LocationListContainer filter={this.props.filter} />
				<Modal show={this.props.showModal} onHide={this.props.closeModal}>
					<Modal.Header closeButton />
					<Modal.Body>
						<LocationEditContainer filter={this.props.filter} />
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}