import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { cognitoUserPoolId, cognitoClientId } from '../config';

// Cognito user pool
export const poolData = {
    UserPoolId: cognitoUserPoolId,
    ClientId: cognitoClientId
};

export function findCognitoUserPool() {
    // console.log('findCognitoUserPool');
    return new CognitoUserPool(poolData);
}

export function findCurrentUser() {
    // console.log('findCurrentUser');
    let cognitoUser = null;
    let userPool = new CognitoUserPool(poolData);
    if (userPool != null) {
        cognitoUser = userPool.getCurrentUser();
    }
    return cognitoUser;
}

export function findCognitoUser(username) {
    // console.log('findCognitoUser:' + username);
    let userPool = new CognitoUserPool(poolData);
    let userData = {
        Username: username,
        Pool: userPool
    };
    let cognitoUser = new CognitoUser(userData);
    return cognitoUser;
}

export function findIdToken(cognitoUser, tokenCallBack) {
    // console.log('findIdToken');
    // console.log(cognitoUser);
    if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
            if (err) {
                console.log(err);
                return;
            }
            // console.log('session validity: ' + session.isValid());
            if (session.isValid()) {
                let token = session.getIdToken().getJwtToken();
                tokenCallBack(token);
                // console.log('id token:' + token);
            }
        });
    }
    return;
}

export function findCurrentIdToken(tokenCallBack) {
    let currentUser = findCurrentUser();
    if (currentUser != null) {
        findIdToken(currentUser, (result) => {
            tokenCallBack(result);
        });
    }
    return;
}
