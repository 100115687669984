import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';
import { hashHistory } from 'react-router';
import {
    findItem
} from './';

export const changeMaintenanceRecordValue = createAction('CHANGE_M_RECORD_VALUE');
export const clearMaintenanceRecordInput = createAction('CLEAR_M_RECORD_INPUT');
export const clearMaintenanceRecordAlert = createAction('CLEAR_M_RECORD_ALERT');

export const addMaintenanceRecord = (record, item) => (
    (dispatch) => {
        record = record.set("item", item);
        let url = '/maintenanceRecords';
        authFetch('POST', url, JSON.stringify(record), (json) => {
            assignDateString1(json);
            dispatch({
                type: 'FORM_SUBMIT_M_RECORD',
                payload: json
            });
        });
    }
);

export const editMaintenanceRecord = (record) => (
    (dispatch) => {
        let maintenanceRecordId = record.get("maintenanceRecordId");
        let url = '/maintenanceRecords/' + maintenanceRecordId;
        authFetch('PUT', url, JSON.stringify(record), (json) => {
            assignDateString1(json);
            dispatch({
                type: 'FORM_SUBMIT_M_RECORD',
                payload: json
            });
        });
    }
);

export const closeMaintenanceRecord = (record) => (
    (dispatch) => {
        let maintenanceRecordId = record.get("maintenanceRecordId");
        let url = '/maintenanceRecords/close/' + maintenanceRecordId;
        authFetch('PUT', url, JSON.stringify(record), (json) => {
            assignDateString1(json);
            dispatch({
                type: 'FORM_SUBMIT_M_RECORD',
                payload: json
            });
        });
    }
);

export const searchMRecord = (payload, pagingData) => (
    (dispatch) => {
        let url = '/maintenanceRecords/search?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
        authFetch('PUT', url, JSON.stringify(payload), (json) => {
            dispatch({
                type: 'FIND_M_RECORD_LIST',
                payload: json
            });
        });
    }
);

export const findMRecord = (pk, redirectPage) => (
    (dispatch) => {
        let url = '/maintenanceRecords/' + pk.maintenance_record_id;
        authFetch('GET', url, null, (json) => {
            assignDateString(json);
            dispatch({
                type: 'FIND_M_RECORD',
                payload: json
            });
        });
        dispatch(findItem({ item_id: pk.item_id }, "M_RECORD"));
        if (redirectPage === 'mPicUpload') {
            hashHistory.push("/mPicUpload");
        } else {
            hashHistory.push("/mRecordView");
        }
    }
);

export const findMRecordItem = (pk) => (
    (dispatch) => {
        let url = '/maintenanceRecords/itemId/' + pk.itemId;
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_M_RECORD',
                payload: json
            });
        });
    }
);

export const deleteMRecord = (deleteKey, pagingData, payload) => (
    (dispatch) => {
        let url = '/maintenanceRecords';
        authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
            dispatch({
                type: 'DELETE_M_RECORD',
                payload: json
            });
            if (json.statusCode === '0') {
                dispatch(searchMRecord(payload, pagingData));
            }
        });
    }
);

export const findMRecordUnClosedCount = (payload) => (
    (dispatch) => {
        let url = '/maintenanceRecords/corpId/' + payload.corpId + '/unClosedCount';
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_M_RECORD_UNCLOSED_COUNT',
                payload: json
            });
        });
    }
);

export const findMaintenanceItems = () => ( //TODO: 應該要改名
    (dispatch) => {
        let url = '/tkkData/kkCheckSets';
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_M_ITEMS',
                payload: json
            });
        });
    }
);

export const changeMRecordSearchBeanValue = createAction('CHANGE_M_RECORD_SEARCHBEAN_VALUE');
export const clearMRecordSearchInput = createAction('CLEAR_M_RECORD_SEARCH_INPUT');
export const clearMRecordSearchAlert = createAction('CLEAR_M_RECORD_SEARCH_ALERT');
export const changeMRecordShowSearchInput = createAction('CHANGE_M_RECORD_SHOW_SEARCH_INPUT');

// 置換日期顯示
function assignDateString(json) {
    if (json.data.sMaintenanceDate)
        json.data.maintenanceDate = json.data.sMaintenanceDate;
    if (json.data.sCloseMaintenanceDate)
        json.data.closeMaintenanceDate = json.data.sCloseMaintenanceDate;
}

// 置換日期顯示
function assignDateString1(json) {
    if (json.data.bean.sMaintenanceDate)
        json.data.bean.maintenanceDate = json.data.bean.sMaintenanceDate;
    if (json.data.bean.sCloseMaintenanceDate)
        json.data.bean.closeMaintenanceDate = json.data.bean.sCloseMaintenanceDate;
}