import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'

export default class DropDownInput extends React.Component {

    handleClear() {
        this.props.onChange(this.props.id, null, '');
        if (this.props.extraClear) {
            this.props.extraClear();
        }
    }

    render() {
        let divClassName = '';
        let iconClassName = '';
        let clearIconClassName = '';
        let helpBlock = '';

        if (this.props.validation) {
            if (this.props.validation.get('validationState') === 'error') {
                divClassName = 'form-group has-feedback has-error';
                iconClassName = 'form-control-feedback glyphicon glyphicon-remove';
                helpBlock = this.props.validation.get('helpBlock');
            }
        }

        if (this.props.selected && this.props.selected[0] && !(this.props.disabled) && (this.props.selected[0].value !== null)) {
            clearIconClassName = 'glyphicon glyphicon-remove';
        }

        let showRequired = null;
        if (this.props.required) {
            showRequired = (<span className="text-danger" style={{ marginLeft: 5 }}>*</span>)
        }

        let extraDivStyle = {};
        if (!this.props.label) {
            extraDivStyle = ({ marginTop: -25 })
        }

        return (
            <div className={divClassName} style={extraDivStyle}>
                <label className="control-label">{this.props.label}{showRequired}</label>
                <span className={clearIconClassName} style={{ cursor: 'pointer', marginTop: 10, float: 'right', fontSize: '0.7em' }} onClick={(event) => {
                    this.refs.typeahead.getInstance().clear();
                    this.handleClear();
                }} />
                <Typeahead
                    id={this.props.id}
                    ref="typeahead"
                    onInputChange={(event) => {
                        if (event === '') {
                            this.handleClear();
                        }
                    }}
                    onChange={(event) => {
                        if (event[0]) {
                            this.props.onChange(this.props.id, event[0].value, event[0].label);
                        }
                    }}
                    options={this.props.options}
                    selected={this.props.selected}
                    disabled={this.props.disabled}
                />
                <span className={iconClassName} />
                <span className="help-block">{helpBlock}</span>
            </div>
        );
    }
}
