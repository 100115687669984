import {
	connect
} from 'react-redux'
import ConfirmationComponent from '../components/ConfirmationComponent.jsx';
import {
	confirmRegisteredUser,
	resendConfirmationCode,
	signOut
} from '../actions';

const mapStateToProps = (state) => ({
	alertStyle: state.getIn(['confirmationReducer', 'alertStyle']),
	alertMessage: state.getIn(['confirmationReducer', 'alertMessage']),
	loading: state.getIn(['confirmationReducer', 'loading'])
});

const mapDispatchToProps = (dispatch) => ({
	toConfirmRegisteredUser: (username, confirmationCode) => {
		dispatch(confirmRegisteredUser(username, confirmationCode, 'confirmation'));
	},
	toResendConfirmationCode: (username) => {
		dispatch(resendConfirmationCode(username));
	},
	toSignOut: () => {
		dispatch(signOut());
	}
});

const ConfirmationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ConfirmationComponent)

export default ConfirmationContainer;