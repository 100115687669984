import React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';

export default class SquaEdit2Component extends React.Component {

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
		}
	}

	editItem() {
		this.props.editItem(this.props.squa, this.props.forInsert);
	}

	render() {

		let buttonDisplay = (this.props.forInsert) ? '新增' : '修改';
		let squaParentId = this.props.squa.get('parentId');
		let squaParentDiv = null;
		let squaLabel = '分隊名稱';
		if (squaParentId === 0) {
			squaLabel = '大隊名稱';
		} else {
			if (this.props.selectedMainSqua) {
				squaParentDiv = (
					<Col xs={12} sm={12}>
						<label className="control-label">大隊名稱:</label> {this.props.selectedMainSqua.get('squa_name')}
					</Col>
				);
			}
		}

		return (
			<div>
				<Row>
					<Col xs={2} sm={2}>
						<Button onClick={(event) => {
							this.editItem();
						}} bsStyle="primary">
							{buttonDisplay}
						</Button>
					</Col>
					<Col xs={10} sm={10}>
						<Alert bsStyle={this.props.alertStyle}>
							{this.props.alertMessage}
						</Alert>
					</Col>
				</Row>
				<br />
				<Row>
					{/* <Col xs={12} sm={12}>
						ID: {this.props.squa.get('squaId')}
					</Col> */}
					{squaParentDiv}
					<Col xs={12} sm={12}>
						<div style={{ marginTop: 20 }}>
							<FieldGroup
								id="squaName"
								type="text"
								label={squaLabel}
								value={this.props.squa.get('squaName')}
								placeholder=""
								required="true"
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.squaValidation.get('squaName')}
							/>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}