import {
	connect
} from 'react-redux'
import ItemEditComponent from '../components/ItemEditComponent.jsx';
import {
	changeItemValue,
	addItem,
	editItem,
	deleteItem,
	findMainSquaList,
	findSubSquaList,
	findAllGearList,
	findAllLocationList,
	findItemStatus,
	findAllManufacturers,
	findKkOrders,
	findKkOrdersByCorpId,
	clearItemInput,
	clearItemAlert,
	findAllCorps,
	changeItemEventKey
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
	item: state.getIn(['itemReducer', 'item']),
	orgItem: state.getIn(['itemReducer', 'orgItem']),
	itemValidation: state.getIn(['itemReducer', 'itemValidation']),
	alertStyle: state.getIn(['itemReducer', 'alertStyle']),
	alertMessage: state.getIn(['itemReducer', 'alertMessage']),
	forInsert: state.getIn(['itemReducer', 'forInsert']),
	corps: state.getIn(['commonReducer', 'corps']),
	mainSquas: state.getIn(['itemReducer', 'mainSquas']),
	subSquas: state.getIn(['itemReducer', 'subSquas']),
	locations: state.getIn(['itemReducer', 'locations']),
	gears: state.getIn(['commonReducer', 'gears']),
	itemStatus: state.getIn(['commonReducer', 'itemStatus']),
	manufacturers: state.getIn(['commonReducer', 'manufacturers']),
	kkOrders: state.getIn(['tkkDataReducer', 'kkOrders']),
	role: state.getIn(['authReducer', 'role']),
	editType: ownProps.params.editType
})

const mapDispatchToProps = (dispatch) => ({
	editItem: (item, forInsert) => {
		if (forInsert) {
			dispatch(addItem(item));
		} else {
			dispatch(editItem(item));
		}
	},
	deleteItem: (item) => {
		dispatch(deleteItem(item));
	},
	setValue: (id, value) => {
		dispatch(changeItemValue({
			id: id,
			value: value
		}))
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'EDIT'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'EDIT'));
	},
	findAllGearList: (payload) => {
		dispatch(findAllGearList(payload));
	},
	findLocationList: (payload) => {
		dispatch(findAllLocationList(payload, 'EDIT'));
	},
	findItemStatus: () => {
		dispatch(findItemStatus());
	},
	findAllManufacturers: () => {
		dispatch(findAllManufacturers());
	},
	findKkOrders: (payload) => {
		if (payload) {
			dispatch(findKkOrdersByCorpId(payload));
		} else {
			dispatch(findKkOrders());
		}
	},
	clearInput: () => {
		dispatch(clearItemInput());
	},
	clearAlert: () => {
		dispatch(clearItemAlert());
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	changeItemEventKey: (payload) => {
		dispatch(changeItemEventKey(payload));
	},
})

const ItemEditContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemEditComponent)

export default ItemEditContainer;