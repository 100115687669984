import {
	connect
} from 'react-redux'
import ItemView1Component from '../components/ItemView1Component.jsx';

const mapStateToProps = (state, ownProps) => ({
	item: ownProps.item,
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
})

const ItemView1Container = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemView1Component)

export default ItemView1Container;