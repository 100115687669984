import {
	combineReducers
} from 'redux-immutable';
import itemUploadReducer from './ui/itemUploadReducer';
import loginReducer from './ui/loginReducer';
import corpReducer from './ui/corpReducer';
import squa2Reducer from './ui/squa2Reducer';
import gearReducer from './ui/gearReducer';
import locationReducer from './ui/locationReducer';
import itemSearchReducer from './ui/itemSearchReducer';
import itemReducer from './ui/itemReducer';
import awsCognitoReducer from './data/awsCognitoReducer';
import tkkDataReducer from './data/tkkDataReducer';
import corpListReducer from './data/corpListReducer';
import gearListReducer from './data/gearListReducer';
import locationListReducer from './data/locationListReducer';
import itemSearchListReducer from './data/itemSearchListReducer';
import itemSearch2ListReducer from './data/itemSearch2ListReducer';
import itemSearch3ListReducer from './data/itemSearch3ListReducer';
import itemReplacementListReducer from './data/itemReplacementListReducer';
import itemUserListReducer from './data/itemUserListReducer';
import commonReducer from './data/commonReducer';
import userReducer from './ui/userReducer';
import userListReducer from './data/userListReducer';
import registerReducer from './ui/registerReducer';
import confirmationReducer from './ui/confirmationReducer';
import forgotPwdReducer from './ui/forgotPwdReducer';
import changePwdReducer from './ui/changePwdReducer';
import authReducer from './data/authReducer';
import itemTransferListReducer from './data/itemTransferListReducer';
import itemStatusChangeListReducer from './data/itemStatusChangeListReducer';
import emergencyEventReducer from './ui/emergencyEventReducer';
import emergencyRecordReducer from './ui/emergencyRecordReducer';
import emergencyRecordListReducer from './data/emergencyRecordListReducer';
import emergencyUserLocationReducer from './ui/emergencyUserLocationReducer';
import nfcChipReducer from './ui/nfcChipReducer';
import mRecordReducer from './ui/mRecordReducer';
import mRecordListReducer from './data/mRecordListReducer';
import mRecordUploadReducer from './ui/mRecordUploadReducer';
import announcementReducer from './ui/announcementReducer';
import mPicUploadReducer from './ui/mPicUploadReducer';
import globalReducer from './ui/globalReducer';
import locationUploadReducer from './ui/locationUploadReducer';
import warrantyReducer from './ui/warrantyReducer';
import warrantyListReducer from './data/warrantyListReducer';
import sendMessageReducer from './ui/sendMessageReducer';
import messageReducer from './ui/messageReducer';
import messageListReducer from './data/messageListReducer';
import userMessageReducer from './ui/userMessageReducer';
import userMessageListReducer from './data/userMessageListReducer';

const appReducer = combineReducers({
	itemUploadReducer,
	loginReducer,
	corpReducer,
	squa2Reducer,
	gearReducer,
	locationReducer,
	itemSearchReducer,
	itemReducer,
	awsCognitoReducer,
	tkkDataReducer,
	corpListReducer,
	gearListReducer,
	locationListReducer,
	itemSearchListReducer,
	itemSearch2ListReducer,
	itemSearch3ListReducer,
	itemReplacementListReducer,
	itemUserListReducer,
	commonReducer,
	userReducer,
	userListReducer,
	registerReducer,
	confirmationReducer,
	forgotPwdReducer,
	changePwdReducer,
	authReducer,
	itemTransferListReducer,
	itemStatusChangeListReducer,
	emergencyEventReducer,
	emergencyRecordReducer,
	emergencyRecordListReducer,
	emergencyUserLocationReducer,
	nfcChipReducer,
	mRecordReducer,
	mRecordListReducer,
	mRecordUploadReducer,
	announcementReducer,
	mPicUploadReducer,
	globalReducer,
	locationUploadReducer,
	warrantyReducer,
	warrantyListReducer,
	sendMessageReducer,
	messageReducer,
	messageListReducer,
	userMessageReducer,
	userMessageListReducer
})

const rootReducer = (state, action) => {
	if (action.type === 'AWS_COGNITO_SIGNOUT') {
		state = undefined;
	}
	return appReducer(state, action)
}

export default rootReducer;