import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const changeMessageValue = createAction('CHANGE_MESSAGE_VALUE');
export const clearMessageAlert = createAction('CLEAR_MESSAGE_ALERT');
export const clearMessage = createAction('CLEAR_MESSAGE');

export const findMessageList = (pagingData) => (
    (dispatch) => {
        let url = '/messages?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_MESSAGE_LIST',
                payload: json
            });
        });
    }
);

export const sendMessage = (message) => (
    (dispatch) => {
        let url = '/messages';
        authFetch('POST', url, JSON.stringify(message), (json) => {
            dispatch({
                type: 'FORM_SUBMIT_MESSAGE',
                payload: json
            });
        });
    }
);

export const deleteMessage = (deleteKey, pagingData) => (
    (dispatch) => {
      let url = '/messages';
      authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
        dispatch({
          type: 'DELETE_MESSAGE',
          payload: json
        });
        dispatch(findMessageList(pagingData));
      });
    }
  );

export const findMessage = (payload) => (
    (dispatch) => {
        let url = '/messages/' + payload.message_id;
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_MESSAGE',
                payload: json
            });
        });
    }
);
export const openMessageModal = createAction('OPEN_MESSAGE_MODAL');
export const closeMessageModal = createAction('CLOSE_MESSAGE_MODAL');
export const clearMessageListAlert = createAction('CLEAR_MESSAGE_LIST_ALERT');