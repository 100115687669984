import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	userMessage: emptyUserMessage(),
	showModal: false
});

const userMessageReducer = handleActions({
	OPEN_USER_MESSAGE_MODAL: (state, { payload }) => {
		return state.merge({
			showModal: true
		})
	},
	CLOSE_USER_MESSAGE_MODAL: (state, { payload }) => {
		return state.merge({
			userMessage: emptyUserMessage(),
			showModal: false
		})
	},
	FIND_USER_MESSAGE: (state, { payload }) => {
		return state.merge({
			userMessage: payload.data
		})
	}
}, initState);

function emptyUserMessage() {
	return {
	}
}

export default userMessageReducer;