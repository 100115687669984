import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import DropDownInput from './common/DropDownInput.jsx';

export default class ChoiceCorpComponent extends React.Component {

	componentDidMount() {
		let userRole = this.props.role.get('role');
		let userType = this.props.role.get('user_type');
		// 系統管理者 or 廠商
		if (userRole === 0 || userType === 2) {
			this.props.findAllCorps();
		}
		else if (!userRole) {
			hashHistory.push("/");
		}
		this.setState({
			corpId: null,
		})
	}

	changeCorpId() {
		this.props.findAuthRoleWithSelectCorp(this.state.corpId);
	}

	render() {

		let searchDiv = null;
		let userRole = -1;
		let userType = -1;
		if (this.props.role) {
			userRole = this.props.role.get('role');
			userType = this.props.role.get('user_type');
		}
		if (userRole === 0 || userType === 2) {
			searchDiv = (
				<Col xs={6} sm={3}>
					<DropDownInput id="corpId"
						label="單位"
						options={this.props.corps.map((value, key) => ({ label: value.get('corp_name'), value: value.get('corp_id') })).toJS()}
						onChange={(id, value, label) => {
							this.setState({ corpId: value, corpName: label });
						}}
					/>
				</Col>
			);
		}

		let corpDisplay = this.props.role.get('corp_name');

		return (
			<div>
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;選擇單位</span>
				</Row>
				<div className="well col-xs-12 col-sm-12" style={{marginTop: 15}}>
					<Row>
						{searchDiv}
						<Col xs={6} sm={3}>
							<div style={{ paddingTop: 25 }}>
								<Button onClick={(event) => {
									this.changeCorpId();
								}} bsStyle="default">
									<span style={{ marginRight: 5 }} /> 選擇單位
					    		</Button>
							</div>
						</Col>
						<Col xs={12} sm={6}>
							<label style={{ paddingTop: 30 }}>
								目前單位: {corpDisplay}
							</label>
						</Col>
					</Row>
				</div>
			</div>
		)
	}
}