import React from 'react';
import { Row, Col, Alert, ButtonToolbar, Button, Form } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import FieldGroup from './common/FieldGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

export default class ItemStatusChangeComponent extends React.Component {

	componentDidMount() {
		this.props.clearInput();
		this.props.findItemStatus();

		let userRole = this.props.role.get('role');
		let userType = this.props.role.get('user_type');
		// 系統管理者 or 廠商
		if (userRole === 0 || userType === 2) {
			this.props.findAllCorps();
			let corpId = this.props.item.get('corpId');
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
		}
		// 無資料
		else if (!userRole) {
			hashHistory.push("/");
		}
		// 單位管理者 & 一般使用者
		else {
			let corpId = this.props.role.get('corp_id');
			if (corpId) {
				this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
			} else {
				hashHistory.push("/");
			}
		}
	}

	componentWillUnmount() {
		this.props.clearAlert();
	}

	changeItemStatus() {
		this.props.changeItemStatus(this.props.itemStatusChange, this.props.item.get('itemId'));
	}

	toItemView() {
		this.props.changeItemEventKey(2);
		this.props.findItemStatusChangeList({ itemId: this.props.item.get('itemId') }
			, { activePage: 1, itemPerPage: 10 });
		hashHistory.push("/itemView");
	}

	render() {
		let showSqua = 'none';
		if (this.props.itemStatusChange.get('status') === 98) { //98:已借出
			showSqua = 'block';
		}

		let lendSquaView = null;
		if (this.props.orgItem.get('status') === 6) {
			lendSquaView = (
				<tr>
					<td style={{ width: '100%' }}><label className="control-label">借出分隊:</label> {this.props.orgItem.get('lendSquaName')}</td>
				</tr>
			);
		}

		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Col xs={12} sm={12}>
					<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
						{this.props.alertMessage}
					</Alert>
				</Col>
			);
		}

		return (
			<div>
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;裝備狀態更改</span>
				</Row>
				<Row>
					{alertDiv}
					<Col xs={12} sm={12}>
						<ButtonToolbar>
							<Button onClick={(event) => {
								this.changeItemStatus();
							}} bsStyle="primary">
								狀態更改
							</Button>
							<Button onClick={(event) => {
								this.toItemView();
							}} bsStyle="default">
								<span className="glyphicon glyphicon-eye-open" style={{ marginRight: 10 }} />裝備總覽
							</Button>
						</ButtonToolbar>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} sm={12}>
						<h4>系統裝備序號: {this.props.item.get('itemId')},  單位: {this.props.item.get('corpName')}</h4>
						<div className="well" style={{ paddingTop: 10, paddingBottom: 5 }}>
							<Row>
								<Col xs={12} sm={12}>
									<h4>From:</h4>
									<table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
										<tbody>
											<tr>
												<td style={{ width: '50%' }}><label className="control-label">使用狀態:</label> {this.props.orgItem.get('statusDisplay')}</td>
												<td style={{ width: '50%' }}><label className="control-label">狀態更改日期:</label> {this.props.orgItem.get('changeStatusTime')}</td>
											</tr>
											{lendSquaView}
										</tbody>
									</table>
								</Col>
							</Row>
						</div>
						<div className="well" style={{ backgroundColor: "white" }}>
							<Form>
								<Col xs={12} sm={12}>
									<h4>To:</h4>
								</Col>
								<Row>
									<Col xs={12} sm={6}>
										<DropDownInput id="status"
											label="使用狀態"
											required="true"
											options={this.props.itemStatus.map((value, key) => ({ label: value.get('display'), value: value.get('code') })).toJS()}
											selected={[{ label: (this.props.itemStatusChange.get('statusDisaply') != null ? this.props.itemStatusChange.get('statusDisaply') : ''), value: this.props.itemStatusChange.get('status') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('statusDisaply', label);
												if (value !== 98) {	// 不是「已借出」=> 清除大隊分隊資料
													this.refs.lendMainSquaIdDdi.refs.typeahead.getInstance().clear();
												}
											}}
											validation={this.props.itemStatusChangeValidation.get('status')}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="changeStatusTime"
											type="date"
											label="狀態更改日期"
											required="true"
											value={this.props.itemStatusChange.get('changeStatusTime')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
											validation={this.props.itemStatusChangeValidation.get('changeStatusTime')}
										/>
									</Col>
								</Row>
								<div style={{ display: showSqua }}>
									<Row>
										<Col xs={12} sm={6}>
											<DropDownInput ref="lendMainSquaIdDdi"
												id="lendMainSquaId"
												label="借出大隊"
												required="true"
												options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
												selected={[{ label: (this.props.itemStatusChange.get('lendMainSquaName') != null ? this.props.itemStatusChange.get('lendMainSquaName') : ''), value: this.props.itemStatusChange.get('lendMainSquaId') }]}
												onChange={(id, value, label) => {
													this.props.setValue(id, value);
													this.props.setValue('lendMainSquaName', label);
													if (value) {
														this.props.findSubSquaList({ corpId: this.props.item.get('corpId'), parentId: value });
													} else {
														this.props.findSubSquaList({ corpId: this.props.item.get('corpId'), parentId: -1 });
													}
												}}
												validation={this.props.itemStatusChangeValidation.get('checklendSquaId')}
												extraClear={() => {
													// this.refs.lendSubSquaIdDdi.refs.typeahead.getInstance().clear();
													this.props.setValue('lendSubSquaId', null);
													this.props.setValue('lendSubSquaName', null);
												}}
											/>
										</Col>
										<Col xs={12} sm={6}>
											<DropDownInput ref="lendSubSquaIdDdi"
												id="lendSubSquaId"
												label="借出分隊"
												options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
												selected={[{ label: (this.props.itemStatusChange.get('lendSubSquaName') != null ? this.props.itemStatusChange.get('lendSubSquaName') : ''), value: this.props.itemStatusChange.get('lendSubSquaId') }]}
												onChange={(id, value, label) => {
													this.props.setValue(id, value);
													this.props.setValue('lendSubSquaName', label);
												}}
												validation={this.props.itemStatusChangeValidation.get('checklendSquaId')}
											/>
										</Col>
									</Row>
								</div>
							</Form>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}