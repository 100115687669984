import React, { Component } from 'react';
import { Router, Route, hashHistory, IndexRoute } from 'react-router';
import { findCurrentUser } from './constants/awsCognito.js';
import MainContainer from './containers/MainContainer';
import HomeContainer from './containers/HomeContainer';
import LoginContainer from './containers/LoginContainer';
import RegisterContainer from './containers/RegisterContainer';
import ChangePwdContainer from './containers/ChangePwdContainer';
import ItemSearchContainer from './containers/ItemSearchContainer';
import CorpsContainer from './containers/CorpsContainer';
import WarrantyContainer from './containers/WarrantyContainer';
import Squas2Container from './containers/Squas2Container';
import ItemUploadContainer from './containers/ItemUploadContainer';
import ItemEditContainer from './containers/ItemEditContainer';
import ItemEditSearchContainer from './containers/ItemEditSearchContainer';
import ItemTransferContainer from './containers/ItemTransferContainer';
import ItemTransferSearchContainer from './containers/ItemTransferSearchContainer';
import ItemStatusChangeContainer from './containers/ItemStatusChangeContainer';
import ItemStatusChangeSearchContainer from './containers/ItemStatusChangeSearchContainer';
import UsersContainer from './containers/UsersContainer';
import ConfirmationContainer from './containers/ConfirmationContainer';
import ForgotPwdContainer from './containers/ForgotPwdContainer';
import ItemViewContainer from './containers/ItemViewContainer';
import GearsContainer from './containers/GearsContainer';
import LocationsContainer from './containers/LocationsContainer';
import LocationUploadContainer from './containers/LocationUploadContainer';
import ChoiceCorpContainer from './containers/ChoiceCorpContainer';
import EmergencyEventsContainer from './containers/EmergencyEventsContainer';
import EmergencyRecordsContainer from './containers/EmergencyRecordsContainer';
import EmergencyUserLocationContainer from './containers/EmergencyUserLocationContainer';
import NfcChipsContainer from './containers/NfcChipsContainer';
import MRecordEditSearchContainer from './containers/MRecordEditSearchContainer';
import MRecordEditContainer from './containers/MRecordEditContainer';
import MRecordSearchContainer from './containers/MRecordSearchContainer';
import MRecordToBeClosedContainer from './containers/MRecordToBeClosedContainer';
import MRecordCloseContainer from './containers/MRecordCloseContainer';
import MRecordViewContainer from './containers/MRecordViewContainer';
import MRecordUploadContainer from './containers/MRecordUploadContainer';
import AnnouncementEditContainer from './containers/AnnouncementEditContainer';
import MPicUploadContainer from './containers/MPicUploadContainer';
import SendMessageContainer from './containers/SendMessageContainer';
import MessagesContainer from './containers/MessagesContainer';
import UserMessagesContainer from './containers/UserMessagesContainer';
import ItemReplacementContainer from './containers/ItemReplacementContainer';
import ItemUserContainer from './containers/ItemUserContainer';

class AppRouter extends Component {

  render() {

    let requireAuth = (nextState, replace) => {
      if (findCurrentUser() == null) {
        replace({
          pathname: '/login'
        })
      }
    }

    return (
      <div>
        <Router history={hashHistory}>
          <Route path="/" component={MainContainer} >
            <IndexRoute component={HomeContainer} onEnter={requireAuth} />
            <Route path="/login" component={LoginContainer} />
            <Route path="/register" component={RegisterContainer} />
            <Route path="/confirmation" component={ConfirmationContainer} />
            <Route path="/forgotPwd" component={ForgotPwdContainer} />
            <Route path="/changePwd" component={ChangePwdContainer} onEnter={requireAuth} />
            <Route path="/itemSearch" component={ItemSearchContainer} onEnter={requireAuth} />
            <Route path="/corps" component={CorpsContainer} onEnter={requireAuth} />
            <Route path="/warranty" component={WarrantyContainer} onEnter={requireAuth} />
            <Route path="/squas" component={Squas2Container} onEnter={requireAuth} />
            <Route path="/itemUpload" component={ItemUploadContainer} onEnter={requireAuth} />
            <Route path="/itemEdit/:editType" component={ItemEditContainer} onEnter={requireAuth} />
            <Route path="/itemEditSearch" component={ItemEditSearchContainer} onEnter={requireAuth} />
            <Route path="/itemTransfer" component={ItemTransferContainer} onEnter={requireAuth} />
            <Route path="/itemTransferSearch" component={ItemTransferSearchContainer} onEnter={requireAuth} />
            <Route path="/itemStatusChange" component={ItemStatusChangeContainer} onEnter={requireAuth} />
            <Route path="/itemStatusChangeSearch" component={ItemStatusChangeSearchContainer} onEnter={requireAuth} />
            <Route path="/itemView" component={ItemViewContainer} onEnter={requireAuth} />
            <Route path="/users" component={UsersContainer} onEnter={requireAuth} />
            <Route path="/gears" component={GearsContainer} onEnter={requireAuth} />
            <Route path="/locations" component={LocationsContainer} onEnter={requireAuth} />
            <Route path="/locationUpload" component={LocationUploadContainer} onEnter={requireAuth} />
            <Route path="/choiceCorp" component={ChoiceCorpContainer} onEnter={requireAuth} />
            <Route path="/emergencyEvent" component={EmergencyEventsContainer} onEnter={requireAuth} />
            <Route path="/emergencyRecord" component={EmergencyRecordsContainer} onEnter={requireAuth} />
            <Route path="/emergencyUserLocation" component={EmergencyUserLocationContainer} onEnter={requireAuth} />
            <Route path="/nfcChips" component={NfcChipsContainer} onEnter={requireAuth} />
            <Route path="/mRecordEditSearch" component={MRecordEditSearchContainer} onEnter={requireAuth} />
            <Route path="/mRecordEdit" component={MRecordEditContainer} onEnter={requireAuth} />
            <Route path="/mRecordSearch" component={MRecordSearchContainer} onEnter={requireAuth} />
            <Route path="/mRecordToBeClosed" component={MRecordToBeClosedContainer} onEnter={requireAuth} />
            <Route path="/mRecordClose" component={MRecordCloseContainer} onEnter={requireAuth} />
            <Route path="/mRecordView" component={MRecordViewContainer} onEnter={requireAuth} />
            <Route path="/mRecordUpload" component={MRecordUploadContainer} onEnter={requireAuth} />
            <Route path="/announcementEdit" component={AnnouncementEditContainer} onEnter={requireAuth} />
            <Route path="/mPicUpload" component={MPicUploadContainer} onEnter={requireAuth} />
            <Route path="/sendMessage" component={SendMessageContainer} onEnter={requireAuth} />
            <Route path="/messages" component={MessagesContainer} onEnter={requireAuth} />
            <Route path="/userMessages" component={UserMessagesContainer} onEnter={requireAuth} />
            <Route path="/itemReplacement" component={ItemReplacementContainer} onEnter={requireAuth} />
            <Route path="/itemUser" component={ItemUserContainer} onEnter={requireAuth} />
          </Route>
        </Router>
      </div>
    );
  }
}

export default AppRouter;