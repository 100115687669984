import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	eventId: 0,
	eventName: '',
	eventKey: 0,
	events: [],
	areas: []
});

const emergencyRecordReducer = handleActions({
	CHANGE_EMERGENCY_EVENT: (state, { payload }) => {
		return state.merge({
			eventId: payload.eventId,
			eventName: payload.eventName,
		})
	},
	CHANGE_AREA_EVENT_KEY: (state, { payload }) => {
		return state.merge({
			eventKey: payload
		})
	},
	FIND_ACTIVE_EVENTS: (state, { payload }) => {
		return state.merge({
			events: payload.data
		})
	},
	FIND_AREAS: (state, { payload }) => {
		return state.merge({
			areas: payload.data
		})
	}
}, initState);

export default emergencyRecordReducer;