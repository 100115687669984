import {
	connect
} from 'react-redux'
import MRecordCloseComponent from '../components/MRecordCloseComponent.jsx';
import {
	changeMaintenanceRecordValue,
	closeMaintenanceRecord,
	searchMRecord,
	findMRecord,
	clearMaintenanceRecordInput,
	clearMaintenanceRecordAlert,
	findAllManufacturers,
	findAllCorps,
	changeItemEventKey,
	findMaintenanceItems
} from '../actions';

const mapStateToProps = (state) => ({
	item: state.getIn(['mRecordReducer', 'item']),
	record: state.getIn(['mRecordReducer', 'record']),
	recordValidation: state.getIn(['mRecordReducer', 'recordValidation']),
	maintenanceItems: state.getIn(['mRecordReducer', 'maintenanceItems']),
	alertStyle: state.getIn(['mRecordReducer', 'alertStyle']),
	alertMessage: state.getIn(['mRecordReducer', 'alertMessage']),
	forInsert: state.getIn(['mRecordReducer', 'forInsert']),

	corps: state.getIn(['commonReducer', 'corps']),
	manufacturers: state.getIn(['commonReducer', 'manufacturers']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	closeRecord: (record) => {
		dispatch(closeMaintenanceRecord(record));
	},
	setValue: (id, value) => {
		dispatch(changeMaintenanceRecordValue({
			id: id,
			value: value
		}))
	},
	findAllManufacturers: () => {
		dispatch(findAllManufacturers());
	},
	searchMRecord: (payload, pagingData) => {
		dispatch(searchMRecord(payload, pagingData));
	},
	findMRecord: (payload) => {
		dispatch(findMRecord(payload));
	},
	clearInput: () => {
		dispatch(clearMaintenanceRecordInput());
	},
	clearAlert: () => {
		dispatch(clearMaintenanceRecordAlert());
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	changeItemEventKey: (payload) => {
		dispatch(changeItemEventKey(payload));
	},
	findMaintenanceItems: () => {
		dispatch(findMaintenanceItems());
	}
})

const MRecordCloseContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MRecordCloseComponent)

export default MRecordCloseContainer;