import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	list: [],
	activePage: 1,
	itemPerPage: 10,
	totalPage: 1,
	filter: null,
	alertStyle: null,
	alertMessage: null
});

const locationListReducer = handleActions({
	FIND_LOCATION_LIST: (state, { payload }) => {
		if (payload.data.dataTableData.length === 0) {
			return state.merge({
				list: [],
				activePage: 1,
				itemPerPage: 10,
				totalPage: 1,
				filter: payload.data.filter,
				alertStyle: 'warning',
				alertMessage: '查無資料'
			})
		} else {
			return state.merge({
				list: payload.data.dataTableData,
				activePage: payload.data.activePage,
				totalPage: payload.data.totalPage,
				itemPerPage: payload.data.itemPerPage,
				filter: payload.data.filter,
				alertStyle: null,
				alertMessage: null
			})
		}
	},
	DELETE_LOCATION: (state, { payload }) => {
		let statusCode = payload.statusCode;
		if (statusCode && statusCode !== "0") {
			return state.merge({
				alertStyle: payload.data.alertStyle,
				alertMessage: payload.data.alertMessage
			})
		} else {
			return state;
		}
	},
	CLEAR_LOCATION_LIST_ALERT: (state, { payload }) => {
		return state.merge({
			alertStyle: null,
			alertMessage: null
		})
	},
	CLEAR_LOCATION_SEARCH_INPUT: (state, { payload }) => {
		return state.merge({
			list: [],
			activePage: 1,
			itemPerPage: 10,
			totalPage: 1,
			filter: null,
			alertStyle: null,
			alertMessage: null
		})
	}
}, initState);

export default locationListReducer;