import React from 'react';
import { Row, Col, Alert, ButtonToolbar, Button, Form } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

export default class ItemTransferComponent extends React.Component {

	componentDidMount() {
		this.props.clearInput();
		let userRole = this.props.role.get('role');
		let userType = this.props.role.get('user_type');
		// 系統管理者 or 廠商
		if (userRole === 0 || userType === 2) {
			this.props.findAllCorps();
			let corpId = this.props.item.get('corpId');
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
		}
		// 無資料
		else if (!userRole) {
			hashHistory.push("/");
		}
		// 單位管理者 & 一般使用者
		else {
			let corpId = this.props.role.get('corp_id');
			if (corpId) {
				this.props.setValue("corpId", corpId);
				this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
			} else {
				hashHistory.push("/");
			}
		}
	}

	componentWillUnmount() {
		this.props.clearAlert();
	}

	transferItem() {
		this.props.transferItem(this.props.itemTransfer, this.props.orgItem);
	}

	toItemView() {
		this.props.changeItemEventKey(1);
		this.props.findItemTransferList({ itemId: this.props.item.get('itemId') }
			, { activePage: 1, itemPerPage: 10 });
		hashHistory.push("/itemView");
	}

	render() {
		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Col xs={12} sm={12}>
					<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
						{this.props.alertMessage}
					</Alert>
				</Col>
			);
		}

		return (
			<div>
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;裝備移轉</span>
				</Row>
				<Row>
					{alertDiv}
					<Col xs={12} sm={12}>
						<ButtonToolbar>
							<Button onClick={(event) => {
								this.transferItem();
							}} bsStyle="primary">
								移轉
							</Button>
							<Button onClick={(event) => {
								this.toItemView();
							}} bsStyle="default">
								<span className="glyphicon glyphicon-eye-open" style={{ marginRight: 10 }} />裝備總覽
							</Button>
						</ButtonToolbar>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} sm={12}>
						<h4>系統裝備序號: {this.props.item.get('itemId')},  單位: {this.props.item.get('corpName')}</h4>
						<div className="well" style={{ paddingTop: 10, paddingBottom: 5 }}>
							<Row>
								<Col xs={12} sm={12}>
									<h4>From:</h4>
									<table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
										<tbody>
											<tr>
												<td style={{ width: '50%' }}><label className="control-label">大隊:</label> {this.props.orgItem.get('mainSquaName')}</td>
												<td style={{ width: '50%' }}><label className="control-label">分隊:</label> {this.props.orgItem.get('subSquaName')}</td>
											</tr>
											<tr>
												<td style={{ width: '50%' }}><label className="control-label">保管人員地點:</label> {this.props.orgItem.get('locationTypeDisplay')}</td>
												<td style={{ width: '50%' }}><label className="control-label">保管人員地點:</label> {this.props.orgItem.get('locationName')}</td>
											</tr>
										</tbody>
									</table>
								</Col>
							</Row>
						</div>
						<div className="well" style={{ backgroundColor: "white" }}>
							<Form>
								<Row>
									<Col xs={12} sm={12}>
										<h4>To:</h4>
									</Col>
									<Col xs={12} sm={6}>
										<DropDownInput ref="toMainSquaIdDdi"
											id="toMainSquaId"
											label="大隊"
											required="true"
											options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
											selected={[{ label: (this.props.itemTransfer.get('toMainSquaName') != null ? this.props.itemTransfer.get('toMainSquaName') : ''), value: this.props.itemTransfer.get('toMainSquaId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('toMainSquaName', label);
												if (value) {
													this.props.findSubSquaList({ corpId: this.props.item.get('corpId'), parentId: value });
													if (this.props.itemTransfer.get('newLocationType')) {
														this.props.findLocationList({
															corpId: this.props.item.get('corpId'), locationType: this.props.itemTransfer.get('newLocationType')
															, mainSquaId: value
														});
													}
												} else {
													this.props.findSubSquaList({ corpId: this.props.item.get('corpId'), parentId: -1 });
												}
											}}
											validation={this.props.itemTransferValidation.get('toMainSquaId')}
											extraClear={() => {
												// this.refs.toSubSquaIdDdi.refs.typeahead.getInstance().clear();
												this.props.setValue('toSubSquaId', null);
												this.props.setValue('toSubSquaName', null);
											}}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<DropDownInput ref="toSubSquaIdDdi"
											id="toSubSquaId"
											label="分隊"
											required="true"
											options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
											selected={[{ label: (this.props.itemTransfer.get('toSubSquaName') != null ? this.props.itemTransfer.get('toSubSquaName') : ''), value: this.props.itemTransfer.get('toSubSquaId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('toSquaId', value);
												this.props.setValue('toSubSquaName', label);
												if (value && this.props.itemTransfer.get('newLocationType')) {
													this.props.findLocationList({
														corpId: this.props.item.get('corpId'), locationType: this.props.itemTransfer.get('newLocationType')
														, subSquaId: value
													});
												}
											}}
											validation={this.props.itemTransferValidation.get('toSubSquaId')}
											extraClear={() => {
												// this.refs.newLocationIdDdi.refs.typeahead.getInstance().clear();
												this.props.setValue('newLocationId', null);
												this.props.setValue('newLocationName', null);
											}}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12} sm={6}>
										<RadioGroup
											id="newLocationType"
											label="保管人員地點"
											required="true"
											radioList={[{ label: "人員", value: 1 }, { label: "車輛", value: 2 }, { label: "倉庫", value: 3 }, { label: "其他", value: 4 }]}
											value={this.props.itemTransfer.get('newLocationType')}
											onChange={(id, value) => {
												this.props.setValue(id, parseInt(value, 10));
												this.props.findLocationList({
													corpId: this.props.item.get('corpId'), locationType: value
													, mainSquaId: this.props.itemTransfer.get('toMainSquaId'), subSquaId: this.props.itemTransfer.get('toSquaId')
												});
											}}
											validation={this.props.itemTransferValidation.get('newLocationType')}
										/>
										<DropDownInput ref="newLocationIdDdi"
											id="newLocationId"
											options={this.props.locations.map((value, key) => ({ label: value.get('display'), value: value.get('location_id') })).toJS()}
											selected={[{ label: (this.props.itemTransfer.get('newLocationName') != null ? this.props.itemTransfer.get('newLocationName') : ''), value: this.props.itemTransfer.get('newLocationId') }]}
											onChange={(id, value, label) => {
												this.props.setValue(id, value);
												this.props.setValue('newLocationName', label);
											}}
											validation={this.props.itemTransferValidation.get('newLocationId')}
										/>
									</Col>
									<Col xs={12} sm={6}>
										<FieldGroup
											id="transferTime"
											type="date"
											label="移轉日期"
											required="true"
											value={this.props.itemTransfer.get('transferTime')}
											placeholder=""
											onChange={(id, value) => {
												this.props.setValue(id, value);
											}}
										/>
									</Col>
								</Row>
							</Form>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}