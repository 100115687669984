import { createAction } from 'redux-actions';
import { authFetch, noAuthFetch } from '../AuthFetch';
import {
  findSubSquaList
} from './';

export const changeUserValue = createAction('CHANGE_USER_VALUE');
export const changeRegisterUserValue = createAction('CHANGE_REGISTER_USER_VALUE');
export const changeSearchUserValue = createAction('CHANGE_SEARCH_USER_VALUE');

export const findUserList = (pagingData, searchBean) => (
  (dispatch) => {
    //pagingData
    let activePage = 1;
    let itemPerPage = 10;
    if (pagingData && pagingData.activePage) {
      activePage = pagingData.activePage;
    }
    if (pagingData && pagingData.itemPerPage) {
      itemPerPage = pagingData.itemPerPage;
    }
    // searchBean
    let userType = 0;
    let id = 0;
    let verified = 'Z';
    if (searchBean && searchBean.userType) {
      userType = searchBean.userType;
      if (userType === 1 && searchBean.corpId) {
        id = searchBean.corpId;
      } else if (userType === 2 && searchBean.manufacturerId) {
        id = searchBean.manufacturerId;
      }
    }
    if (searchBean && searchBean.verified) {
      verified = searchBean.verified;
    }

    let url = '/users/userType/' + userType + '/id/' + id + '/verified/' + verified
      + '/?activePage=' + activePage + '&itemPerPage=' + itemPerPage;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_USER_LIST',
        payload: json
      });
    });
  }
);

export const findUnVerifiedCount = (searchBean) => (
  (dispatch) => {
    // searchBean
    let userType = 0;
    let id = 0;
    if (searchBean && searchBean.userType) {
      userType = searchBean.userType;
      if (userType === 1) {
        id = searchBean.corpId;
      } else if (userType === 2) {
        id = searchBean.manufacturerId;
      }
    }
    let url = '/users/userType/' + userType + '/id/' + id + '/unVerifiedCount/';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_USER_UNVERIFIED_COUNT',
        payload: json
      });
    });
  }
);

export const addUser = (user) => (
  (dispatch) => {
    let url = '/noAuth/addUser';
    noAuthFetch('POST', url, JSON.stringify(user), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_REGISTER_USER',
        payload: json
      });
    });
  }
);

export const editUser = (user, pagingData, searchBean) => (
  (dispatch) => {
    let url = '/users/' + user.get('userId');
    authFetch('PUT', url, JSON.stringify(user), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_USER',
        payload: json
      });
      dispatch(findUserList(pagingData, searchBean));
      dispatch(findUnVerifiedCount(searchBean));
    });
  }
);

export const deleteUser = (deleteKey, pagingData, searchBean) => (
  (dispatch) => {
    let url = '/users';
    authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
      dispatch({
        type: 'DELETE_USER',
        payload: json
      });
      dispatch(findUserList(pagingData, searchBean));
      dispatch(findUnVerifiedCount(searchBean));
    });
  }
);

export const findUser = (pk) => (
  (dispatch) => {
    let url = '/users/' + pk.user_id;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_USER',
        payload: json
      });
      if (json.data.corpId && json.data.mainSquaId) {
        dispatch(findSubSquaList({ corpId: json.data.corpId, parentId: json.data.mainSquaId }, 'USER'));
      }
    });
  }
);

export const openUserModal = createAction('OPEN_USER_MODAL');
export const closeUserModal = createAction('CLOSE_USER_MODAL');
export const changeShowCorpPage = createAction('CHANGE_SHOW_CORP');
export const changeShowManufacturer = createAction('CHANGE_SHOW_MANUFACTURER');

export const changeShowSearchCorp = createAction('CHANGE_SHOW_SEARCH_CORP');
export const changeShowSearchManufacturer = createAction('CHANGE_SHOW_SEARCH_MANUFACTURER');

export const findEnabledUsersByCorpId = (payload, uiType) => (
  (dispatch) => {
    let corpId = payload.corpId;
    let url = '/users/corps/' + corpId;

    let dispatchType = '';
    if (uiType === 'LOCATION_SEARCH') {
      dispatchType = 'FIND_ENABLED_USERS_LOCATION_SEARCH';
    } else if (uiType === 'LOCATION_EDIT') {
      dispatchType = 'FIND_ENABLED_USERS_LOCATION_EDIT';
    }
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: dispatchType,
        payload: json
      });
    });
  }
);

export const clearUserListAlert = createAction('CLEAR_USER_LIST_ALERT');

export const updateLastLoginTime = () => (
  (dispatch) => {
    let url = '/users/updateLastLoginTime';
    authFetch('PUT', url, null, (json) => {
    });
  }
);

export const passEmailValidationOnlyDisplay = (user) => (
  (dispatch) => {
    let url = '/noAuth/passEmailValidationOnlyDisplay';
    noAuthFetch('PUT', url, JSON.stringify(user), (json) => {
    });
  }
);