import React from 'react';
import { Row, Col, Alert, Button, Form } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';

export default class AnnouncementComponent extends React.Component {

	componentDidMount() {
		let userRole = this.props.role.get('role');
		if (!userRole && userRole !== 0) {
			hashHistory.push("/");
		}
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findAnnouncement({ corpId: corpId });
		} else {
			this.props.findAnnouncement({ corpId: 0 });
		}
	}

	editAnnouncement() {
		this.props.editAnnouncement(this.props.announcement);
	}

	componentWillUnmount() {
		this.props.clearAlert();
	}

	render() {

		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Col xs={12} sm={12}>
					<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
						{this.props.alertMessage}
					</Alert>
				</Col>
			);
		}

		return (
			<div>
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;基本資料管理&nbsp;&gt;&gt;&nbsp;系統公告管理</span>
				</Row>
				<Row>
					{alertDiv}
					<Col xs={12} sm={12}>
						<Button onClick={(event) => {
							this.editAnnouncement();
						}} bsStyle="primary">
							修改公告
					    </Button>
					</Col>
				</Row>
				<br />
				<Form>
					<Row>
						<Col xs={12} sm={12}>
							<RadioGroup
								id="isDisplay"
								label="是否顯示系統公告"
								radioList={[{ label: "是", value: 'Y' }, { label: "否", value: 'N' }]}
								value={this.props.announcement.get('isDisplay')}
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.announcementValidation.get('isDisplay')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12}>
							<FieldGroup
								id="annContent"
								type="text"
								label="系統公告內容"
								value={this.props.announcement.get('annContent')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								componentClass="textarea"
								rows="10"
								validation={this.props.announcementValidation.get('annContent')}
							/>
						</Col>
					</Row>
				</Form>
			</div>
		)
	}
}