import { noAuthFetch } from '../AuthFetch';

export const findAllCorps = () => (
  (dispatch) => {
    let url = '/noAuth/findAllCorps';
    noAuthFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_ALL_CORPS',
        payload: json
      });
    });
  }
);

export const findAllManufacturers = () => (
  (dispatch) => {
    let url = '/noAuth/findAllManufacturers';
    noAuthFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_ALL_MANUFACTURERS',
        payload: json
      });
    });
  }
);
