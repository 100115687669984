import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const changeEventValue = createAction('CHANGE_EVENT_VALUE');
export const changeEventSideListValue = createAction('CHANGE_EVENT_SIDE_LIST_VALUE');
export const clearEventAlert = createAction('CLEAR_EVENT_ALERT');
export const initEvent = createAction('INIT_EVENT');

export const findEvents = () => (
  (dispatch) => {
    let url = '/emergencyEvents';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_EVENTS',
        payload: json
      });
    });
  }
);

export const findEvent = (eventId) => (
  (dispatch) => {
    let url = '/emergencyEvents/' + eventId;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_EVENT',
        payload: json
      });
    });
  }
);

export const addEvent = (event) => (
  (dispatch) => {
    let url = '/emergencyEvents/';
    authFetch('POST', url, JSON.stringify(event), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_EVENT',
        payload: json
      });
      dispatch(findEvents());
      if (json.statusCode === '0') {
        dispatch(findEvent(json.data.bean.eventId));
      }
    });
  }
);

export const editEvent = (event) => (
  (dispatch) => {
    let url = '/emergencyEvents/';
    authFetch('PUT', url, JSON.stringify(event), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_EVENT',
        payload: json
      });
      dispatch(findEvents());
      if (json.statusCode === '0') {
        dispatch(findEvent(json.data.bean.eventId));
      }
    });
  }
);

export const deleteEvent = (eventId) => (
  (dispatch) => {
    let url = '/emergencyEvents/' + eventId;
    authFetch('DELETE', url, null, (json) => {
      if (json.statusCode === '0') {
        dispatch({
          type: '',
          payload: json
        });
        dispatch(findEvents());
      }
    });
  }
);

export const updateEventActive = (eventId, isActive) => (
  (dispatch) => {
    let url = '/emergencyEvents/' + eventId + '/isActive/' + isActive;
    authFetch('PUT', url, null, (json) => {
      if (json.statusCode === '0') {
        dispatch({
          type: '',
          payload: json
        });
        dispatch(findEvents());
      }
    });
  }
);
