import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	gears: [],
	itemStatus: [],
	corps: [],
	manufacturers: []
});

const commonReducer = handleActions({
	FIND_ALL_GEAR_LIST: (state, { payload }) => {
		return state.merge({
			gears: payload.data
		})
	},
	FIND_ITEM_STATUS: (state, { payload }) => {
		return state.merge({
			itemStatus: payload.data
		})
	},
	FIND_ALL_CORPS: (state, { payload }) => {
		return state.merge({
			corps: payload.data
		})
	},
	FIND_ALL_MANUFACTURERS: (state, { payload }) => {
		return state.merge({
			manufacturers: payload.data
		})
	}
}, initState);

export default commonReducer;