import { createAction } from 'redux-actions';
import { authUpload, authFetch } from '../AuthFetch';
import {
  findMRecord
} from './';

export const uploadMPic = (uploadFile, mRecordId, picType) => (
  (dispatch) => {
    let formData = new FormData();
    formData.append('uploadfile', uploadFile);
    if (uploadFile == null) {
      dispatch({
        type: 'AFTER_UPLOAD_M_PIC_DATA',
        payload: { statusCode: "102", displayMessage: "", data: { alertMessage: "未上傳任何檔案", alertStyle: "danger", bean: null } }
      });
      return;
    }
    dispatch({
      type: 'BEFORE_UPLOAD_M_PIC_DATA'
    });
    let url = '/maintenancePics/uploadMPicData/mRecordId/' + mRecordId + '/picType/' + picType;
    authUpload('POST', url, formData, (json) => {
      dispatch({
        type: 'AFTER_UPLOAD_M_PIC_DATA',
        payload: json
      });
      if (json.statusCode === '0') {
        dispatch(findMRecord({ maintenance_record_id: mRecordId }, 'mPicUpload'));
      }
    }, (err) => {
      dispatch({
        type: 'AFTER_UPLOAD_M_PIC_DATA',
        payload: { statusCode: "999", displayMessage: "", data: { alertMessage: err.stack, alertStyle: "danger", bean: null } }
      });
    });
  }
);

export const clearMPicUploadInput = createAction('CLEAR_M_PIC_UPLOAD_INPUT');
export const clearMPicUploadAlert = createAction('CLEAR_M_PIC_UPLOAD_ALERT');

export const deleteMPic = (mPicId, mRecordId) => (
  (dispatch) => {
    let url = '/maintenancePics/' + mPicId;
    authFetch('DELETE', url, null, (json) => {
      if (json.statusCode === '0') {
        dispatch({
          type: '',
          payload: json
        });
        dispatch(findMRecord({ maintenance_record_id: mRecordId }, 'mPicUpload'));
      }
    });
  }
);