import { connect } from 'react-redux'
import MPicUploadComponent from '../components/MPicUploadComponent.jsx';
import {
  uploadMPic,
  clearMPicUploadAlert,
  findMRecord,
  deleteMPic
} from '../actions';

const mapStateToProps = (state) => ({
  item: state.getIn(['mRecordReducer', 'item']),
  record: state.getIn(['mRecordReducer', 'record']),
  alertStyle: state.getIn(['mPicUploadReducer', 'alertStyle']),
  alertMessage: state.getIn(['mPicUploadReducer', 'alertMessage']),
  role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (uploadFile, mRecordId, picType) => {
    dispatch(uploadMPic(uploadFile, mRecordId, picType));
  },
  clearAlert: () => {
    dispatch(clearMPicUploadAlert());
  },
  findMRecord: (payload) => {
    dispatch(findMRecord(payload));
  },
  deleteMPic: (mPicId, mRecordId) => {
    dispatch(deleteMPic(mPicId, mRecordId));
  }
})

const MPicUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MPicUploadComponent)

export default MPicUploadContainer;