import React from 'react';
import { Row } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import ItemSearch1InnerContainer from '../containers/ItemSearch1InnerContainer.jsx';
import ItemSearchListContainer from '../containers/ItemSearchListContainer.jsx';
import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

export default class ItemStatusChangeSearchComponent extends React.Component {

	componentDidMount() {
		// this.props.clearSearchInput();
		let userRole = this.props.role.get('role');
		let userType = this.props.role.get('user_type');
		// 系統管理者 or 廠商
		if (userRole === 0 || userType === 2) {
		}
		// 無資料
		else if (!userRole) {
			hashHistory.push("/");
		}
		// 單位管理者 & 一般使用者
		else {
			let corpId = this.props.role.get('corp_id');
			if (corpId) {
			} else {
				hashHistory.push("/");
			}
		}
	}

	render() {
		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;裝備狀態更改</span>
				</Row>
				<div style={{ marginTop: 20 }}>
					<ItemSearch1InnerContainer />
					<ItemSearchListContainer filter={this.props.filter} uiType='STATUS_CHANGE' />
				</div>
			</div>
		)
	}
}