import {
	connect
} from 'react-redux'
import ItemStatusChangeComponent from '../components/ItemStatusChangeComponent.jsx';
import {
	changeItemStatusChangeValue,
	changeItemStatus,
	findMainSquaList,
	findSubSquaList,
	findItemStatus,
	clearItemStatusChangeInput,
	clearItemStatusChangeAlert,
	changeItemEventKey,
	findItemStatusChangeList,
	findAllCorps
} from '../actions';

const mapStateToProps = (state) => ({
	item: state.getIn(['itemReducer', 'item']),
	itemStatusChange: state.getIn(['itemReducer', 'itemStatusChange']),
	itemStatusChangeValidation: state.getIn(['itemReducer', 'itemStatusChangeValidation']),
	orgItem: state.getIn(['itemReducer', 'orgItem']),
	alertStyle: state.getIn(['itemReducer', 'alertStyle']),
	alertMessage: state.getIn(['itemReducer', 'alertMessage']),
	mainSquas: state.getIn(['itemReducer', 'mainSquas']),
	subSquas: state.getIn(['itemReducer', 'subSquas']),
	itemStatus: state.getIn(['commonReducer', 'itemStatus']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	changeItemStatus: (payload, itemId) => {
		payload = payload.set('itemId', itemId);
		dispatch(changeItemStatus(payload));
	},
	setValue: (id, value) => {
		dispatch(changeItemStatusChangeValue({
			id: id,
			value: value
		}))
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'EDIT'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'EDIT'));
	},
	findItemStatus: () => {
		dispatch(findItemStatus());
	},
	clearInput: () => {
		dispatch(clearItemStatusChangeInput());
	},
	clearAlert: () => {
		dispatch(clearItemStatusChangeAlert());
	},
	changeItemEventKey: (payload) => {
		dispatch(changeItemEventKey(payload));
	},
	findItemStatusChangeList: (payload, pagingData) => {
		dispatch(findItemStatusChangeList(payload, pagingData));
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	}
})

const ItemStatusChangeContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemStatusChangeComponent)

export default ItemStatusChangeContainer;