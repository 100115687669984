import {
	connect
} from 'react-redux'
import GearsComponent from '../components/GearsComponent.jsx';
import {
	closeGearModal,
	findAllCorps
} from '../actions';

const mapStateToProps = (state) => ({
	showModal: state.getIn(['gearReducer', 'showModal']),
	searchCorpId: state.getIn(['gearReducer', 'searchCorpId']),
	corps: state.getIn(['commonReducer', 'corps']),
	role: state.getIn(['authReducer', 'role']),
	filter: (state.getIn(['gearListReducer', 'filter'])) ? state.getIn(['gearListReducer', 'filter']).toJS() : {}
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => {
		dispatch(closeGearModal());
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	}
})

const GearsContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(GearsComponent)

export default GearsContainer;