import React from 'react';
import { Row, Col, Button, Collapse, Form } from 'react-bootstrap';

import RadioGroup from './common/RadioGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

export default class ItemSearch2InnerComponent extends React.Component {

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findAllGearList({ corpId: corpId });
		}
	}

	search() {
		this.props.search(this.props.searchBean.toJS());
	}

	clearSearchInput() {
		this.refs.gearIdDdi.refs.typeahead.getInstance().clear();
		this.props.clearSearchInput();
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.search({ corpId: corpId });
		}
	}

	changeShowFilterInput() {
		if (this.props.showFilterInput) {
			this.props.changeShowFilterInput(false);
		} else {
			this.props.changeShowFilterInput(true);
		}
	}

	render() {
		let iconClassName = "glyphicon glyphicon-plus";
		if (this.props.showFilterInput) {
			iconClassName = "glyphicon glyphicon-minus";
		}

		return (
			<div>
				<Button bsSize="xsmall" onClick={() => this.changeShowFilterInput()}>
					<span className={iconClassName} />
				</Button>
				<Collapse in={this.props.showFilterInput}>
					<div className="well col-xs-12 col-sm-12">
						<Form>
							<Row>
								<Col xs={10} sm={10}>
									<div style={{ marginBottom: 20 }}>
										<Button onClick={(event) => {
											this.search();
										}} bsStyle="default">
											<span className="glyphicon glyphicon-filter" style={{ marginRight: 10 }} />條件過濾
					    				</Button>
									</div>
								</Col>
								<Col xs={2} sm={2}>
									<div style={{ marginBottom: 20, float: 'right' }}>
										<Button onClick={(event) => {
											this.clearSearchInput();
										}} bsStyle="default">
											清除
					    				</Button>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={6}>
									<DropDownInput ref="gearIdDdi"
										id="gearId"
										label="裝備類別"
										options={this.props.gears.map((value, key) => ({ label: value.get('gear_name'), value: value.get('gear_id') })).toJS()}
										selected={[{ label: (this.props.searchBean.get('gearName') != null ? this.props.searchBean.get('gearName') : ''), value: this.props.searchBean.get('gearId') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('gearName', label);
										}}
									/>
								</Col>
								<Col xs={12} sm={6}>
									<RadioGroup
										id="warrantyExpired"
										label="逾使用年限"
										radioList={[{ label: "是", value: "Y" }, { label: "否", value: "N" }]}
										value={this.props.searchBean.get('warrantyExpired')}
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
									/>
								</Col>
							</Row>
						</Form>
					</div>
				</Collapse>
			</div>
		)
	}
}