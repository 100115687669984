import {
	connect
} from 'react-redux'
import GearEditComponent from '../components/GearEditComponent.jsx';
import {
	changeGearValue,
	addGear,
	editGear,
	findAllCorps
} from '../actions';

const mapStateToProps = (state) => ({
	gear: state.getIn(['gearReducer', 'gear']),
	gearValidation: state.getIn(['gearReducer', 'gearValidation']),
	corps: state.getIn(['commonReducer', 'corps']),
	alertStyle: state.getIn(['gearReducer', 'alertStyle']),
	alertMessage: state.getIn(['gearReducer', 'alertMessage']),
	forInsert: state.getIn(['gearReducer', 'forInsert']),

	activePage: state.getIn(['gearListReducer', 'activePage']),
	itemPerPage: state.getIn(['gearListReducer', 'itemPerPage']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	editItem: (gear, pagingData, forInsert) => {
		if (forInsert) {
			dispatch(addGear(gear, pagingData));
		} else {
			dispatch(editGear(gear, pagingData));
		}
	},
	setValue: (id, value) => {
		dispatch(changeGearValue({
			id: id,
			value: value
		}))
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	}
})

const GearEditContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(GearEditComponent)

export default GearEditContainer;