import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';
import { hashHistory } from 'react-router';
import {
  findSubSquaList,
  findLocationList,
  findMRecordItem
} from './';

export const changeItemValue = createAction('CHANGE_ITEM_VALUE');
export const changeItemEventKey = createAction('CHANGE_ITEM_EVENT_KEY');

export const addItem = (item) => (
  (dispatch) => {
    let url = '/items';
    authFetch('POST', url, JSON.stringify(item), (json) => {
      assignDateString1(json);
      dispatch({
        type: 'FORM_SUBMIT_ITEM',
        payload: json
      });
    });
  }
);

export const clearItemInput = createAction('CLEAR_ITEM_INPUT');
export const clearItemAlert = createAction('CLEAR_ITEM_ALERT');
export const clearItemTransferInput = createAction('CLEAR_ITEM_TRANSFER_INPUT');
export const clearItemTransferAlert = createAction('CLEAR_ITEM_TRANSFER_ALERT');
export const clearItemStatusChangeInput = createAction('CLEAR_ITEM_STATUS_CHANGE_INPUT');
export const clearItemStatusChangeAlert = createAction('CLEAR_ITEM_STATUS_CHANGE_ALERT');

export const editItem = (item) => (
  (dispatch) => {
    let url = '/items/' + item.get('itemId');
    authFetch('PUT', url, JSON.stringify(item), (json) => {
      assignDateString1(json);
      dispatch({
        type: 'FORM_SUBMIT_ITEM',
        payload: json
      });
    });
  }
);

export const deleteItem = (deleteKey) => (
  (dispatch) => {
    let url = '/items';
    authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
      dispatch({
        type: 'DELETE_ITEM',
        payload: json
      });
    });
  }
);

export const findItem = (pk, uiType) => (
  (dispatch) => {
    let url = '/items/' + pk.item_id;
    authFetch('GET', url, null, (json) => {
      assignDateString(json);
      dispatch({
        type: 'FIND_ITEM',
        payload: json
      });
      if (uiType === 'EDIT') {
        dispatch(findSubSquaList({ corpId: pk.corp_id, parentId: json.data.mainSquaId }, 'EDIT'));
        dispatch(findLocationList({ corpId: pk.corp_id, locationType: json.data.locationType }, 'EDIT'));
      }
      if (uiType === 'M_RECORD_EDIT') {
        dispatch(findMRecordItem({ corpId: pk.corp_id, itemId: pk.item_id }));
      }
    });

    if (uiType === 'EDIT') {
      hashHistory.push("/itemEdit/e");
    } else if (uiType === 'TRANSFER') {
      hashHistory.push("/itemTransfer");
    } else if (uiType === 'STATUS_CHANGE') {
      hashHistory.push("/itemStatusChange");
    } else if (uiType === 'M_RECORD_EDIT') {
      hashHistory.push("/mRecordEdit");
    } else {
      hashHistory.push("/itemView");
    }
    dispatch(changeItemEventKey(0));
  }
);

export const transferItem = (itemTransfer, orgItem) => (
  (dispatch) => {
    itemTransfer = itemTransfer.set('itemId', orgItem.get('itemId'));
    itemTransfer = itemTransfer.set('fromSquaId', orgItem.get('squaId'));
    itemTransfer = itemTransfer.set('orgLocationId', orgItem.get('locationId'));

    let url = '/items/itemsTransfer/';
    authFetch('POST', url, JSON.stringify(itemTransfer), (json) => {
      assignDateString1(json);
      dispatch({
        type: 'FORM_SUBMIT_ITEM_TRANSFER',
        payload: json
      });
      dispatch(findItem({ item_id: orgItem.get('itemId') }, 'TRANSFER'));
    });
  }
);

export const findItemTransferList = (payload, pagingData) => (
  (dispatch) => {
    let itemId = payload.itemId;
    let url = '/items/' + itemId + '/itemsTransfer/?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_ITEM_TRANSFER_LIST',
        payload: json
      });
    });
  }
);

export const changeItemTransferValue = createAction('CHANGE_ITEM_TRANSFER_VALUE');

export const changeItemStatusChangeValue = createAction('CHANGE_ITEM_STATUS_CHANGE_VALUE');

export const changeItemStatus = (payload) => (
  (dispatch) => {
    let url = '/items/itemsStatusChange/';
    authFetch('POST', url, JSON.stringify(payload), (json) => {
      assignDateString1(json);
      dispatch({
        type: 'FORM_SUBMIT_ITEM_CHANGE_STATUS',
        payload: json
      });
      dispatch(findItem({ item_id: payload.get('itemId') }, 'STATUS_CHANGE'));
    });
  }
);

export const findItemStatusChangeList = (payload, pagingData) => (
  (dispatch) => {
    let itemId = payload.itemId;
    let url = '/items/' + itemId + '/itemsStatusChange/?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_ITEM_STATUS_CHANGE_LIST',
        payload: json
      });
    });
  }
);

// 置換日期顯示
function assignDateString(json) {
  if (json.data.sPurchaseDate)
    json.data.purchaseDate = json.data.sPurchaseDate;
  if (json.data.sExpiredDate)
    json.data.expiredDate = json.data.sExpiredDate;
  if (json.data.sChangeStatusTime)
    json.data.changeStatusTime = json.data.sChangeStatusTime;
  if (json.data.sTransferTime)
    json.data.transferTime = json.data.sTransferTime;
}

// 置換日期顯示
function assignDateString1(json) {
  if (json.data.bean.sPurchaseDate)
    json.data.bean.purchaseDate = json.data.bean.sPurchaseDate;
  if (json.data.bean.sExpiredDate)
    json.data.bean.expiredDate = json.data.bean.sExpiredDate;
  if (json.data.bean.sChangeStatusTime)
    json.data.bean.changeStatusTime = json.data.bean.sChangeStatusTime;
  if (json.data.bean.sTransferTime)
    json.data.bean.transferTime = json.data.bean.sTransferTime;
}