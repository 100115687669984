import React from 'react';
import { Row, Col } from 'react-bootstrap';


export default class MessageViewComponent extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
			<Row>
				<Col>
					<table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
						<tbody>
							<tr>
								<td colSpan="4"><label className="control-label">To 單位:</label> {this.props.message.get('sendToCorpName')}</td>
							</tr>
							<tr>
								<td colSpan="2" style={{ width: '50%' }}><label className="control-label">發送帳號:</label> {this.props.message.get('sendFromUserId')}</td>
								<td colSpan="2" style={{ width: '50%' }}><label className="control-label">發送時間:</label> {this.props.message.get('sSendTime')}</td>
							</tr>
							<tr>
								<td colSpan="4"><label className="control-label">訊息標題:</label> {this.props.message.get('messageTitle')}</td>
							</tr>
							<tr>
								<td colSpan="4"><label className="control-label">訊息內容:</label>
									<pre style={{ border: 'none', backgroundColor: '#FFFFFF' }}>
										{this.props.message.get('messageContent')}
									</pre>
								</td>
							</tr>
							<tr>
								<td colSpan="2" style={{ width: '50%' }}><label className="control-label">顯示開始時間:</label> {this.props.message.get('sDisplayTimeStart')}</td>
								<td colSpan="2" style={{ width: '50%' }}><label className="control-label">顯示結束時間:</label> {this.props.message.get('sDisplayTimeEnd')}</td>
							</tr>
							<tr>
								<td style={{ width: '25%' }}><label className="control-label">發送總數:</label> {this.props.message.get('totalUserMessageCnt')}</td>
								<td style={{ width: '25%' }}><label className="control-label">未讀數:</label> {this.props.message.get('totalUnreadUserMessageCnt')}</td>
								<td style={{ width: '25%' }}><label className="control-label">已讀數:</label> {this.props.message.get('totalReadUserMessageCnt')}</td>
								<td style={{ width: '25%' }}><label className="control-label">已刪除數:</label> {this.props.message.get('totalDeletedUserMessageCnt')}</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</Row>
		)
	}
}
