import React from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';

import EmergencyRecordListContainer from '../containers/EmergencyRecordListContainer.jsx';
import DropDownInput from './common/DropDownInput.jsx';

export default class EmergencyRecordsComponent extends React.Component {

	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.triggerRefreshEmRecords = this.triggerRefreshEmRecords.bind(this);
	}

	componentDidMount() {
		this.props.findActiveEvents();
	}

	componentWillUnmount() {
		this.props.changeEmergencyEvent({ eventId: 0, eventName: null });
		this.props.findAreasByEvent({ eventId: 0 });
		this.props.findEmergencyRecordList({}, { eventId: 0 });
		this.props.wsDisconnect();
	}

	handleSelect(key) {
		if (this.props.eventId) {
			this.props.changeEventKey(key);
			if (key === 0) {
				this.props.findEmergencyRecordList({}, { eventId: this.props.eventId });
				this.props.wsConnect({ eventId: this.props.eventId });
			} else if (key === 1) {
				this.props.findEmergencyRecordList({}, { eventId: this.props.eventId, recordStep: 3 });
				this.props.wsConnect({ eventId: this.props.eventId, recordStep: 3 });
			} else if (key === 2) {
				this.props.findEmergencyRecordList({}, { eventId: this.props.eventId, recordStep: 2 });
				this.props.wsConnect({ eventId: this.props.eventId, recordStep: 2 });
			} else {
				let areaId = key;
				this.props.findEmergencyRecordList({}, { eventId: this.props.eventId, areaId: areaId });
				this.props.wsConnect({ eventId: this.props.eventId, areaId: areaId });
			}
		}
	}

	triggerRefreshEmRecords() {
		console.log("======triggerRefreshEmRecords=======");
		this.props.triggerRefreshEmRecords(this.props.filter);
	}

	render() {

		let tabArray = [];
		for (let area of this.props.areas) {
			let areaEvenKey = area.get('area_id');
			let areaTitle = area.get('area_name');
			let filterN = { eventId: this.props.filter.eventId, areaId: area.get('area_id') };
			tabArray.push(
				<Tab eventKey={areaEvenKey} title={areaTitle} key={areaEvenKey}>
					<div style={{ marginTop: 20 }}>
						<EmergencyRecordListContainer filter={filterN} />
					</div>
				</Tab>
			)
		}

		let filter0 = { eventId: this.props.filter.eventId };
		let filter1 = { eventId: this.props.filter.eventId, recordStep: 3 };
		let filter2 = { eventId: this.props.filter.eventId, recordStep: 2 };

		return (
			<div>
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;火場管制&nbsp;&gt;&gt;&nbsp;火場進出管制</span>
				</Row>
				<Row>
					<Col xs={12} sm={6}>
						<DropDownInput id="eventId"
							label="事件"
							options={this.props.events.map((value, key) => ({ label: value.get('event_name'), value: value.get('event_id') })).toJS()}
							onChange={(id, value, label) => {
								if (value) {
									this.props.changeEmergencyEvent({ eventId: value, eventName: label });
									this.props.findAreasByEvent({ eventId: value });
									this.props.findEmergencyRecordList({}, { eventId: value });
									this.props.wsConnect({ eventId: value });
									this.props.changeEventKey(0);
								}
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12}>
						<Tabs id="editTab" defaultActiveKey={this.props.eventKey} animation={false} onSelect={this.handleSelect}>
							<Tab eventKey={0} title="總表">
								<div style={{ marginTop: 20 }}>
									<EmergencyRecordListContainer filter={filter0} />
								</div>
							</Tab>
							<Tab eventKey={1} title="熱區">
								<div style={{ marginTop: 20 }}>
									<EmergencyRecordListContainer filter={filter1} />
								</div>
							</Tab>
							<Tab eventKey={2} title="待命">
								<div style={{ marginTop: 20 }}>
									<EmergencyRecordListContainer filter={filter2} />
								</div>
							</Tab>
							{tabArray}
						</Tabs>
					</Col>
				</Row>
			</div>
		)
	}
}