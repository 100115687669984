import React from 'react';

import ItemUserListContainer from '../containers/ItemUserListContainer.jsx';

export default class ItemUserComponent extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
			<div>
				<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;個人裝備</span>
				<ItemUserListContainer />
			</div>
		)
	}
}