import {
	connect
} from 'react-redux'
import NfcChipsComponent from '../components/NfcChipsComponent.jsx';
import {
	findChipByNfcTask,
	clearChipDisplay,
	clearChipInput,
	clearChipAlert,
	changeChipValue,
	findMainSquaList,
	findSubSquaList,
	findAllLocationList,
	addNfcChipRecord,
	findChipsByLocation,
	changeToUnactive
} from '../actions';

const mapStateToProps = (state) => ({
	alertStyle: state.getIn(['nfcChipReducer', 'alertStyle']),
	alertMessage: state.getIn(['nfcChipReducer', 'alertMessage']),
	chipSearchResult: state.getIn(['nfcChipReducer', 'chipSearchResult']),
	chip: state.getIn(['nfcChipReducer', 'chip']),
	chipValidation: state.getIn(['nfcChipReducer', 'chipValidation']),
	mainSquas: state.getIn(['nfcChipReducer', 'mainSquas']),
	subSquas: state.getIn(['nfcChipReducer', 'subSquas']),
	locations: state.getIn(['nfcChipReducer', 'locations']),
	chipRecordList: state.getIn(['nfcChipReducer', 'chipRecordList']),

	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	findChipByNfcTask: (nfcText) => {
		dispatch(findChipByNfcTask(nfcText));
	},
	clearChipDisplay: () => {
		dispatch(clearChipDisplay());
	},
	clearInput: () => {
		dispatch(clearChipInput());
	},
	clearAlert: () => {
		dispatch(clearChipAlert());
	},
	setValue: (id, value) => {
		dispatch(changeChipValue({
			id: id,
			value: value
		}))
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'CHIP_MAPPING'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'CHIP_MAPPING'));
	},
	findLocationList: (payload) => {
		dispatch(findAllLocationList(payload, 'CHIP_MAPPING'));
	},
	nfcMapping: (payload) => {
		dispatch(addNfcChipRecord(payload));
	},
	findChipsByLocation: (payload) => {
		dispatch(findChipsByLocation(payload));
	},
	changeToUnactive: (payload) => {
		dispatch(changeToUnactive(payload));
	}
})

const NfcChipsContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(NfcChipsComponent)

export default NfcChipsContainer;