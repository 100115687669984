import {
	connect
} from 'react-redux'
import ItemSearch3Component from '../components/ItemSearch3Component.jsx';
import {
	changeSearchBean3Value,
	findAllGearList,
	findItemStatus,
	searchItem3,
	changeShowSearchInput,
	findAllCorps,
	clearSearch3Input
} from '../actions';

const mapStateToProps = (state) => ({
	searchBean: state.getIn(['itemSearchReducer', 'searchBean3']),
	corps: state.getIn(['commonReducer', 'corps']),
	gears: state.getIn(['commonReducer', 'gears']),
	itemStatus: state.getIn(['commonReducer', 'itemStatus']),
	showSearchInput: state.getIn(['itemSearchReducer', 'showSearchInput']),
	role: state.getIn(['authReducer', 'role']),
	filter: state.getIn(['itemSearch3ListReducer', 'filter']),
})

const mapDispatchToProps = (dispatch) => ({
	setValue: (id, value) => {
		dispatch(changeSearchBean3Value({
			id: id,
			value: value
		}))
	},
	findAllGearList: (payload) => {
		dispatch(findAllGearList(payload));
	},
	findItemStatus: (payload) => {
		dispatch(findItemStatus(payload));
	},
	search: (payload) => {
		dispatch(searchItem3(payload, { activePage: 1, itemPerPage: 10 }));
	},
	changeShowSearchInput: (payload) => {
		dispatch(changeShowSearchInput(payload));
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	clearSearchInput: () => {
		dispatch(clearSearch3Input());
	}
})

const ItemSearch3Container = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemSearch3Component)

export default ItemSearch3Container;