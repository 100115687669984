import React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';

export default class GearEditComponent extends React.Component {

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
		}
	}

	editItem() {
		this.props.editItem(this.props.gear, {
			activePage: this.props.activePage,
			itemPerPage: this.props.itemPerPage
		}, this.props.forInsert);
	}

	render() {
		return (
			<div>
				<Row>
					<Col xs={2} sm={2}>
						<Button onClick={(event) => {
							this.editItem();
						}} bsStyle="primary">
							送出
					    </Button>
					</Col>
					<Col xs={10} sm={10}>
						<Alert bsStyle={this.props.alertStyle}>
							{this.props.alertMessage}
						</Alert>
					</Col>
				</Row>
				<br />
				<Row>
					{/* <Col xs={12} sm={12}>
						ID: {this.props.gear.get('gearId')}
					</Col> */}
					<Col xs={12} sm={12}>
						<FieldGroup
							id="gearName"
							type="text"
							label="裝備類別名稱"
							value={this.props.gear.get('gearName')}
							placeholder=""
							required="true"
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.gearValidation.get('gearName')}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}