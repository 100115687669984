import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findUserMessageList,
	deleteUserMessage,
	openUserMessageModal,
	findUserMessage,
	clearUserMessageListAlert
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['userMessageListReducer', 'list']),
	activePage: state.getIn(['userMessageListReducer', 'activePage']),
	itemPerPage: state.getIn(['userMessageListReducer', 'itemPerPage']),
	totalPage: state.getIn(['userMessageListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		message_title: '訊息標題',
		s_send_time: '發送時間'
	},
	keys: ['user_message_id'],
	disableAdd: true,
	showCheckBox: true,
	edit0: {
		className: 'glyphicon glyphicon-envelope',
		showText: '讀取'
	},
	alertStyle: state.getIn(['userMessageListReducer', 'alertStyle']),
	alertMessage: state.getIn(['userMessageListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch) => ({
	refresh: (pagingData) => {
		dispatch(findUserMessageList(pagingData));
	},
	changeActivepage: (pagingData) => {
		dispatch(findUserMessageList(pagingData));
	},
	deleteItems: (deleteKey, pagingData) => {
		dispatch(deleteUserMessage(deleteKey, pagingData));
	},
	handleEdit: (pk, pagingData) => {
		dispatch(findUserMessage(pk, pagingData));
		dispatch(openUserMessageModal());
	},
	clearAlert: () => {
		dispatch(clearUserMessageListAlert());
	}
})

const UserMessageListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default UserMessageListContainer;