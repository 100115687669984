import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	searchItem3,
	clearItemSearchAlert,
	searchItem1,
	changeItemSearchEventKey
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['itemSearch3ListReducer', 'itemSearchList']),
	activePage: state.getIn(['itemSearch3ListReducer', 'activePage']),
	itemPerPage: state.getIn(['itemSearch3ListReducer', 'itemPerPage']),
	totalPage: state.getIn(['itemSearch3ListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		squa_name: '分隊名稱',
		gear_name: '裝備類別',
		status_display: '使用狀態',
		cnt: '持有數量'
	},
	keys: ['corp_id', 'squa_id', 'gear_id', 'status'],
	disableAdd: true,
	edit0: {
		className: 'glyphicon glyphicon-list',
		showText: '裝備列表'
	},
	alertStyle: state.getIn(['itemSearch3ListReducer', 'alertStyle']),
	alertMessage: state.getIn(['itemSearch3ListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
	},
	changeActivepage: (pagingData) => {
		dispatch(searchItem3(ownProps.filter, pagingData));
	},
	handleEdit: (pk) => {
		dispatch(changeItemSearchEventKey(0));
		let payload = { corpId: pk.corp_id, subSquaId: pk.squa_id, gearId: pk.gear_id, status: pk.status };
		dispatch(searchItem1(payload, { activePage: 1, itemPerPage: 10 }));
	},
	clearAlert: () => {
		dispatch(clearItemSearchAlert());
	}
})

const ItemSearchListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default ItemSearchListContainer;