import React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';

export default class WarrantyEditComponent extends React.Component {

	componentDidMount() {
	}

	editItem() {
		this.props.editItem(this.props.warranty, {
			activePage: this.props.activePage,
			itemPerPage: this.props.itemPerPage
		});
	}

	render() {
		return (
			<div>
				<Row>
					<Col xs={2} sm={2}>
						<Button onClick={(event) => {
							this.editItem();
						}} bsStyle="primary">
							送出
					    </Button>
					</Col>
					<Col xs={10} sm={10}>
						<Alert bsStyle={this.props.alertStyle}>
							{this.props.alertMessage}
						</Alert>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} sm={12}>
						<b>單位名稱:</b> {this.props.warranty.get('corpName')}
					</Col>
					<Col xs={12} sm={12}>
						<FieldGroup
							id="warrantyYear"
							type="number"
							label="保固年限"
							value={this.props.warranty.get('warrantyYear')}
							placeholder=""
							required="true"
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.warrantyValidation.get('warrantyYear')}
						/>
					</Col>
					<Col xs={12} sm={12}>
						<FieldGroup
							id="replacementYear"
							type="number"
							label="汰換年限"
							value={this.props.warranty.get('replacementYear')}
							placeholder=""
							required="true"
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.warrantyValidation.get('replacementYear')}
						/>
					</Col>
					<Col xs={12} sm={12}>
						<RadioGroup
							id="remind"
							label="是否發訊息提醒"
							radioList={[{ label: "是", value: 'Y' }, { label: "否", value: 'N' }]}
							value={this.props.warranty.get('remind')}
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.warrantyValidation.get('remind')}
						/>
					</Col>
					<Col xs={12} sm={12}>
						<b>最後更新帳號:</b> {this.props.warranty.get('updateUser')}
					</Col>
					<Col xs={12} sm={12}>
						<b>最後更新日期:</b> {this.props.warranty.get('updateDate')}
					</Col>
				</Row>
			</div>
		)
	}
}
