import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	list: [],
	activePage: 1,
	itemPerPage: 10,
	totalPage: 1,
	filter: null
});

const emergencyRecordListReducer = handleActions({
	FIND_EMERGENCY_RECORD_LIST: (state, { payload }) => {
		return state.merge({
			list: payload.data.dataTableData,
			activePage: payload.data.activePage,
			totalPage: payload.data.totalPage,
			itemPerPage: payload.data.itemPerPage,
			filter: payload.data.filter
		})
	},
	CLEAR_EMERGENCY_LIST_RECORD: (state, { payload }) => {
		return state.merge({
			list: [],
			activePage: 1,
			itemPerPage: 10,
			totalPage: 1,
			filter: null
		})
	}
}, initState);

export default emergencyRecordListReducer;