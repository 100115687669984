import React from 'react';
import { Modal, Row } from 'react-bootstrap';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';
import GearListContainer from '../containers/GearListContainer.jsx';
import GearEditContainer from '../containers/GearEditContainer.jsx';

export default class GearsComponent extends React.Component {

	componentDidMount() {
	}

	render() {
		let corpId = this.props.role.get('corp_id');
		let filter = { corpId: corpId };

		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;裝備類別管理</span>
				</Row>
				<GearListContainer filter={filter} />
				<Modal show={this.props.showModal} onHide={this.props.closeModal}>
					<Modal.Header closeButton />
					<Modal.Body>
						<GearEditContainer />
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}