import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	list: [],
	activePage: 1,
	itemPerPage: 10,
	totalPage: 1,
	alertStyle: null,
	alertMessage: null
});

const userMessageListReducer = handleActions({
	FIND_USER_MESSAGE_LIST: (state, { payload }) => {
		if (payload.data.dataTableData.length === 0) {
			return state.merge({
				list: [],
				activePage: 1,
				itemPerPage: 10,
				totalPage: 1,
				alertStyle: 'warning',
				alertMessage: '無個人訊息'
			})
		} else {
			return state.merge({
				list: payload.data.dataTableData,
				activePage: payload.data.activePage,
				totalPage: payload.data.totalPage,
				itemPerPage: payload.data.itemPerPage,
				alertStyle: null,
				alertMessage: null
			})
		}
	},
	DELETE_USER_MESSAGE: (state, { payload }) => {
		let statusCode = payload.statusCode;
		if (statusCode && statusCode !== "0") {
			return state.merge({
				alertStyle: payload.data.alertStyle,
				alertMessage: payload.data.alertMessage
			})
		} else {
			return state;
		}
	},
	CLEAR_USER_MESSAGE_LIST_ALERT: (state, { payload }) => {
		return state.merge({
			alertStyle: null,
			alertMessage: null
		})
	}
}, initState);

export default userMessageListReducer;