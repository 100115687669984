import {
	connect
} from 'react-redux'
import ItemSearch2InnerComponent from '../components/ItemSearch2InnerComponent.jsx';
import {
	changeSearchReplacementBeanValue,
	findAllGearList,
	searchReplacementItem,
	changeShowFilterInput,
	findAllCorps,
	clearSearchReplacementInput
} from '../actions';

const mapStateToProps = (state) => ({
	searchBean: state.getIn(['itemSearchReducer', 'searchReplacementBean']),
	corps: state.getIn(['commonReducer', 'corps']),
	gears: state.getIn(['commonReducer', 'gears']),
	alertStyle: state.getIn(['itemSearchReducer', 'alertStyle1']),
	alertMessage: state.getIn(['itemSearchReducer', 'alertMessage1']),
	showFilterInput: state.getIn(['itemSearchReducer', 'showFilterInput']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	setValue: (id, value) => {
		dispatch(changeSearchReplacementBeanValue({
			id: id,
			value: value
		}))
	},
	findAllGearList: (payload) => {
		dispatch(findAllGearList(payload));
	},
	search: (payload) => {
		dispatch(searchReplacementItem(payload, { activePage: 1, itemPerPage: 10 }));
	},
	changeShowFilterInput: (payload) => {
		dispatch(changeShowFilterInput(payload));
	},
	findAllCorps: () => {
		dispatch(findAllCorps());
	},
	clearSearchInput: () => {
		dispatch(clearSearchReplacementInput());
	}
})

const ItemSearch2InnerContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemSearch2InnerComponent)

export default ItemSearch2InnerContainer;