import React from 'react';
import { Row, Tabs, Tab } from 'react-bootstrap';

import ItemSearch1Container from '../containers/ItemSearch1Container.jsx';
import ItemSearch2Container from '../containers/ItemSearch2Container.jsx';
import ItemSearch3Container from '../containers/ItemSearch3Container.jsx';

export default class ItemSearchComponent extends React.Component {

	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		// this.props.clearSearchInput();
	}

	handleSelect(key) {
		this.props.changeItemSearchEventKey(key);
	}

	render() {
		return (
			<div>
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;裝備查詢</span>
				</Row>
				<Row>
					<Tabs id="itemSearchTab" activeKey={this.props.eventKey} style={{ marginTop: 20 }} onSelect={this.handleSelect}>
						<Tab eventKey={0} title="一般查詢">
							<ItemSearch1Container />
						</Tab>
						<Tab eventKey={1} title="借調查詢">
							<ItemSearch2Container />
						</Tab>
						<Tab eventKey={2} title="反向查詢">
							<ItemSearch3Container />
						</Tab>
					</Tabs>
				</Row>
			</div>
		)
	}
}