import {
	connect
} from 'react-redux'
import EmergencyRecordsComponent from '../components/EmergencyRecordsComponent.jsx';
import {
	findActiveEvents,
	findAreasByEvent,
	findEmergencyRecordList,
	changeEmergencyEvent,
	changeAreaEventKey
} from '../actions';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { apiHost } from '../config';

var stompClient = null;

const mapStateToProps = (state) => ({
	eventId: state.getIn(['emergencyRecordReducer', 'eventId']),
	eventName: state.getIn(['emergencyRecordReducer', 'eventName']),
	eventKey: state.getIn(['emergencyRecordReducer', 'eventKey']),
	events: state.getIn(['emergencyRecordReducer', 'events']),
	areas: state.getIn(['emergencyRecordReducer', 'areas']),
	filter: (state.getIn(['emergencyRecordListReducer', 'filter'])) ? state.getIn(['emergencyRecordListReducer', 'filter']).toJS() : {},
	activePage: state.getIn(['emergencyListRecordReducer', 'activePage']),
	itemPerPage: state.getIn(['emergencyListRecordReducer', 'itemPerPage'])
})

const mapDispatchToProps = (dispatch) => ({
	changeEmergencyEvent: (payload) => {
		dispatch(changeEmergencyEvent(payload));
	},
	changeEventKey: (key) => {
		dispatch(changeAreaEventKey(key));
	},
	findActiveEvents: () => {
		dispatch(findActiveEvents());
	},
	findAreasByEvent: (payload) => {
		dispatch(findAreasByEvent(payload));
	},
	findEmergencyRecordList: (pagingData, payload) => {
		dispatch(findEmergencyRecordList(pagingData, payload));
	},
	wsConnect: (filter) => {
		let header = new Headers({
			'Accept': 'application/json;charset=UTF-8',
			'Content-Type': 'application/json;charset=UTF-8'
		});

		if (stompClient !== null) {
			console.log("execute Disconnected");
			stompClient.disconnect();
		}

		let socket = new SockJS(apiHost + '/register-websocket');
		stompClient = Stomp.over(socket);
		stompClient.connect(header, function (frame) {
			console.log('Connected: ' + frame);
			stompClient.subscribe('/wsTopic/refreshEmergencyRecords', function () {
				console.log('execute refresh emergency records');
				dispatch(findEmergencyRecordList({}, filter));
			});
		});
	},
	wsDisconnect: () => {
		if (stompClient !== null) {
			console.log("execute Disconnected");
			stompClient.disconnect();
			stompClient = null;
		}
	},
	triggerRefreshEmRecords: () => {
		if (stompClient != null) {
			console.log('trigger refresh emergency records');
			stompClient.send("/wsPrefixes/refreshEmergencyRecords", {}, {});
		} else {
			console.log('stompClient is null');
		}
	}
})

const EmergencyRecordsContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(EmergencyRecordsComponent)

export default EmergencyRecordsContainer;