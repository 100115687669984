import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
  alertStyle: null,
  alertMessage: null,
  loading: false
});

const confirmationReducer = handleActions({
  AWS_COGNITO_CONFIRM_REGISTERED_USER_CONFIRMATION: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: null,
      loading: true
    });
  },
  AWS_COGNITO_CONFIRM_REGISTERED_USER_SUCCESS_CONFIRMATION: (state, { payload }) => {
    return state.merge({
      alertStyle: 'success',
      alertMessage: '認證成功',
      loading: false
    });
  },
  AWS_COGNITO_CONFIRM_REGISTERED_USER_ERROR_CONFIRMATION: (state, { payload }) => {
    return state.merge({
      alertStyle: 'danger',
      alertMessage: payload,
      loading: false
    });
  },
  AWS_COGNITO_RESEND_CONFIRMATION_CODE: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: null
    });
  },
  AWS_COGNITO_RESEND_CONFIRMATION_CODE_SUCCESS: (state, { payload }) => {
    return state.merge({
      alertStyle: 'success',
      alertMessage: '重發認證信成功'
    });
  },
  AWS_COGNITO_RESEND_CONFIRMATION_CODE_ERROR: (state, { payload }) => {
    return state.merge({
      alertStyle: 'danger',
      alertMessage: payload
    });
  }
}, initState);

export default confirmationReducer;