import React from 'react';
import { Modal } from 'react-bootstrap';

import WarrantyListContainer from '../containers/WarrantyListContainer.jsx';
import WarrantyEditContainer from '../containers/WarrantyEditContainer.jsx';

export default class WarrantyComponent extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
			<div>
				<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;基本資料管理&nbsp;&gt;&gt;&nbsp;保固年限設定</span>
				<WarrantyListContainer />
				<Modal show={this.props.showModal} onHide={this.props.closeModal}>
					<Modal.Header closeButton />
					<Modal.Body>
						<WarrantyEditContainer />
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}