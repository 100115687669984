import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const findAllGearList = (payload) => (
  (dispatch) => {
    let corpId = payload.corpId;
    let url = '/gears/findAll/corps/' + corpId;
    if (corpId) {
      authFetch('GET', url, null, (json) => {
        dispatch({
          type: 'FIND_ALL_GEAR_LIST',
          payload: json
        });
      });
    }
  }
);

export const changeSearchGearCorpId = createAction('CHANGE_SEARCH_GEAR_CORPID');

export const findGearList = (pagingData, clause) => (
  (dispatch) => {
    let corpId = clause.corpId;
    let url = '/gears/corps/' + corpId + '/?activePage='
      + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    if (corpId) {
      authFetch('GET', url, null, (json) => {
        dispatch({
          type: 'FIND_GEAR_LIST',
          payload: json
        });
      });
    }
  }
);

export const openGearModal = createAction('OPEN_GEAR_MODAL');
export const closeGearModal = createAction('CLOSE_GEAR_MODAL');
export const changeGearValue = createAction('CHANGE_GEAR_VALUE');
export const assignGear = createAction('ASSIGN_GEAR');

export const deleteGear = (deleteKey, pagingData, payload) => (
  (dispatch) => {
    let url = '/gears';
    authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
      dispatch({
        type: 'DELETE_GEAR',
        payload: json
      });
      dispatch(findGearList(pagingData, payload));
    });
  }
);

export const addGear = (gear, pagingData) => (
  (dispatch) => {
    let payload = { corpId: gear.get('corpId'), parentId: gear.get('parentId') };
    let url = '/gears';
    authFetch('POST', url, JSON.stringify(gear), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_GEAR',
        payload: json
      });
      dispatch(findGearList(pagingData, payload));
    });
  }
);

export const editGear = (gear, pagingData) => (
  (dispatch) => {
    let payload = { corpId: gear.get('corpId'), parentId: gear.get('parentId') };
    let url = '/gears/' + gear.get('gearId');
    authFetch('PUT', url, JSON.stringify(gear), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_GEAR',
        payload: json
      });
      dispatch(findGearList(pagingData, payload));
    });
  }
);

export const findGear = (pk) => (
  (dispatch) => {
    let url = '/gears/' + pk.gear_id;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_GEAR',
        payload: json
      });
    });
  }
);

export const clearGearListAlert = createAction('CLEAR_GEAR_LIST_ALERT');
