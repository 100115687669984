import React from 'react';
import { Row, Col, Alert, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import DropDownInput from './common/DropDownInput.jsx';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

export default class LocationUploadComponent extends React.Component {

  componentDidMount() {
    let corpId = this.props.role.get('corp_id');
    if (corpId) {
      this.setState({ corpId: corpId });
      this.props.findKkOrders(corpId);
    }
    this.props.findBatchRecords();
  }

  componentWillUnmount() {
    this.props.clearInput();
  }

  handleImport() {
    if (this.state.corpId) {
      this.props.uploadFile(this.uploadFile, this.state.corpId);
    }
  }

  handleExport() {
    this.props.exportFile(this.props.exportBean.toJS());
  }

  handleDelete() {
    this.props.deleteByBatchId(this.props.batchDeleteBean);
  }

  render() {
    let downloadLinkStyle = {
      display: this.props.exportBean.get('outputFile') === '' ? 'none' : 'block'
    };

    let openFile = () => {
      window.open(this.props.exportBean.get('outputFile'));
    };

    let openSampleFile = () => {
      window.open('/location_sample.xls');
    };

    let alertDiv = null;
    if (this.props.alertStyle || this.props.alertMessage) {
      alertDiv = (
        <Row>
          <Col xs={12} sm={12}>
            <Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
              {this.props.alertMessage}
            </Alert>
          </Col>
        </Row>
      );
    }

    let showExport = 'none';
    if (this.props.role) {
      let userType = this.props.role.get('user_type');
      showExport = (userType === 2) ? 'block' : 'none';
    }

    return (
      <div>
        <CheckSelectCorp />
        <Row style={{ marginBottom: 15 }}>
          <span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤匯入&nbsp;&gt;&gt;&nbsp;保管地點匯入</span>
        </Row>
        <div className="card center-block" style={{ width: "30rem" }}>
          <div className="card-body">
            {alertDiv}
            <Row>
              <Col xs={12}>
                <FormGroup controlId="file_upload" >
                  <ControlLabel>保管地點上傳匯入</ControlLabel>
                  <FormControl
                    type="file"
                    onChange={(event) => {
                      this.uploadFile = event.target.files[0];
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button block bsStyle="primary" onClick={(event) => {
                  this.handleImport();
                }}>上傳</Button>
              </Col>
              <Col xs={6}></Col>
              <Col xs={6}>
                <div style={{ textAlign: 'right', paddingTop: 10 }}>
                  <span style={{ cursor: "pointer" }} onClick={openSampleFile}>下載範例檔</span>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12}>
                <label>刪除批次匯入資料</label>
                <DropDownInput id="batchId"
                  label="批次匯入記錄 (三十日內)"
                  options={this.props.batchRecords.map((value, key) => ({ label: value.get('create_time') + ' (' + value.get('batch_id') + ')', value: value.get('batch_id') })).toJS()}
                  onChange={(id, value, label) => {
                    this.props.changeBatchId(value);
                  }}
                  validation={this.props.batchDeleteBeanValidation.get('batchId')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button block bsStyle="primary" onClick={(event) => {
                  this.handleDelete();
                }}>刪除</Button>
              </Col>
            </Row>
            <div style={{ display: showExport }}>
              <hr />
              <Row>
                <Col xs={12}>
                  <label>訂單保管地點匯出</label>
                  <DropDownInput id="orderId"
                    label="固巧快訂單"
                    options={this.props.kkOrders.map((value, key) => ({ label: value.get('order_id') + ' (' + value.get('shipment_display') + ')', value: value.get('order_id') })).toJS()}
                    onChange={(id, value, label) => {
                      this.props.setValue(id, value);
                    }}
                    validation={this.props.exportBeanValidation.get('orderId')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button block bsStyle="primary" onClick={(event) => {
                    this.handleExport();
                  }}>匯出檔案</Button>
                  <Button block bsStyle="default" onClick={openFile} style={downloadLinkStyle}>下載XLS檔</Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    )
  }
};