import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
  user: emptyUser(),
  userValidation: emptyUserValidation(),
  alertStyle: null,
  alertMessage: null,
  showModal: false,
  corps: [],
  manufacturers: [],
  showCorp: 'none',
  showManufacturer: 'none',
  showConfirmationArea: 'none',
  loading: false,
  loading2: false
});

const registerReducer = handleActions({
  AWS_COGNITO_REGISTER_USER: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: null,
      loading: true
    });
  },
  AWS_COGNITO_REGISTER_USER_ERROR: (state, { payload }) => {
    return state.merge({
      alertStyle: 'danger',
      alertMessage: payload,
      loading: false
    });
  },
  AWS_COGNITO_CONFIRM_REGISTERED_USER_REGISTER: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: null,
      loading2: true
    });
  },
  AWS_COGNITO_CONFIRM_REGISTERED_USER_SUCCESS_REGISTER: (state, { payload }) => {
    return state.merge({
      alertStyle: 'success',
      alertMessage: '認證成功',
      loading2: false
    });
  },
  AWS_COGNITO_CONFIRM_REGISTERED_USER_ERROR_REGISTER: (state, { payload }) => {
    return state.merge({
      alertStyle: 'danger',
      alertMessage: payload,
      loading2: false
    });
  },
  CHANGE_REGISTER_USER_VALUE: (state, { payload }) => {
    let user = state.get('user');
    return state.merge({
      user: user.set(payload.id, payload.value),
    })
  },
  FORM_SUBMIT_REGISTER_USER: (state, { payload }) => {
    let sca = 'none';
    if (payload.data.alertStyle === 'success') {
      sca = 'block';
    }
    return state.merge({
      user: payload.data.bean,
      userValidation: payload.data.valBean,
      alertStyle: payload.data.alertStyle,
      alertMessage: payload.data.alertMessage,
      loading: false,
      showConfirmationArea: sca
    })
  },
  CHANGE_SHOW_CORP: (state, { payload }) => {
    return state.merge({
      showCorp: payload
    })
  },
  CHANGE_SHOW_MANUFACTURER: (state, { payload }) => {
    return state.merge({
      showManufacturer: payload
    })
  }
}, initState);

function emptyUser() {
  return {
    userId: null,
    userName: null,
    password: null,
    confirmPassword: null,
    email: null,
    userType: null,
    corpId: null,
    corpName: null,
    manufacturerId: null,
    manufacturerName: null,
    confirmationCode: null
  }
}

function emptyUserValidation() {
  return {
    userId: {
      validationState: null,
      helpBlock: null
    },
    userName: {
      validationState: null,
      helpBlock: null
    },
    password: {
      validationState: null,
      helpBlock: null
    },
    confirmPassword: {
      validationState: null,
      helpBlock: null
    },
    email: {
      validationState: null,
      helpBlock: null
    },
    userType: {
      validationState: null,
      helpBlock: null
    },
    corpId: {
      validationState: null,
      helpBlock: null
    },
    manufacturerId: {
      validationState: null,
      helpBlock: null
    }
  }
}

export default registerReducer;