import {
	connect
} from 'react-redux'
import HomeComponent from '../components/HomeComponent.jsx';
import {
	findAnnouncementList
} from '../actions';

const mapStateToProps = (state) => ({
	announcementList: state.getIn(['announcementReducer', 'announcementList']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	findAnnouncementList: () => {
		dispatch(findAnnouncementList());
	}
})

const HomeContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeComponent)

export default HomeContainer;