import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';
import {
  findSubSquaList
} from './';

export const findAllLocationList = (payload, uiType) => (
  (dispatch) => {
    let corpId = payload.corpId;
    let locationType = payload.locationType;
    let mainSquaId = payload.mainSquaId;
    let subSquaId = payload.subSquaId;
    let dispatchType = '';
    if (uiType === 'SEARCH1') {
      dispatchType = 'FIND_ALL_LOCATION_LIST_SEARCH1';
    } else if (uiType === 'EDIT') {
      dispatchType = 'FIND_ALL_LOCATION_LIST_EDIT';
    } else if (uiType === 'CHIP_MAPPING') {
      dispatchType = 'FIND_ALL_LOCATION_LIST_CHIP_MAPPING';
    }
    let url = '/locations/corps/' + corpId + '/locationTypes/' + locationType;
    if (subSquaId) {
      url += '/squas/' + subSquaId;
    } else if (mainSquaId) {
      url += '/squas/' + mainSquaId;
    }
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: dispatchType,
        payload: json
      });
    });
  }
);

export const openLocationModal = createAction('OPEN_LOCATION_MODAL');
export const closeLocationModal = createAction('CLOSE_LOCATION_MODAL');
export const changeLocationValue = createAction('CHANGE_LOCATION_VALUE');
export const changeSearchLocationValue = createAction('CHANGE_SEARCH_LOCATION_VALUE');

export const findLocationList = (pagingData, clause) => (
  (dispatch) => {
    // console.log(clause);
    let corpId = clause.corpId;
    let locationType = clause.sLocationType;
    let mainSquaId = clause.mainSquaId;
    let subSquaId = clause.subSquaId;
    let squaId = clause.squaId;
    let userId = clause.userId;
    let locationNo = clause.locationNo;
    let locationName = clause.locationName;
    let url = '/locations/?corpId=' + corpId
    if (locationType) {
      url += '&locationType=' + locationType;
    }
    if (mainSquaId) {
      url += '&mainSquaId=' + mainSquaId;
    }
    if (subSquaId) {
      url += '&subSquaId=' + subSquaId;
    }
    if (squaId) {
      url += '&squaId=' + squaId;
    }
    if (userId) {
      url += '&userId=' + userId;
    }
    if (locationNo) {
      url += '&locationNo=' + locationNo;
    }
    if (locationName) {
      url += '&locationName=' + locationName;
    }
    url += '&activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    if (corpId) {
      authFetch('GET', url, null, (json) => {
        dispatch({
          type: 'FIND_LOCATION_LIST',
          payload: json
        });
      });
    }
  }
);

export const deleteLocation = (deleteKey, pagingData, filter) => (
  (dispatch) => {
    let url = '/locations';
    authFetch('DELETE', url, JSON.stringify(deleteKey), (json) => {
      dispatch({
        type: 'DELETE_LOCATION',
        payload: json
      });
      if (json.statusCode === '0') {
        dispatch(findLocationList(pagingData, filter));
      };
    });
  }
);

export const addLocation = (location, pagingData, filter) => (
  (dispatch) => {
    let url = '/locations';
    authFetch('POST', url, JSON.stringify(location), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_LOCATION',
        payload: json
      });
      dispatch(findLocationList(pagingData, filter));
    });
  }
);

export const editLocation = (location, pagingData, filter) => (
  (dispatch) => {
    let url = '/locations/' + location.get('locationId');
    authFetch('PUT', url, JSON.stringify(location), (json) => {
      dispatch({
        type: 'FORM_SUBMIT_LOCATION',
        payload: json
      });
      dispatch(findLocationList(pagingData, filter));
    });
  }
);

export const findLocation = (pk) => (
  (dispatch) => {
    let url = '/locations/' + pk.location_id;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_LOCATION',
        payload: json
      });
      dispatch(findSubSquaList({ corpId: json.data.corpId, parentId: json.data.mainSquaId }, 'LOCATION_EDIT'));
    });
  }
);

export const assignLocation = createAction('ASSIGN_LOCATION');
export const clearLocationListAlert = createAction('CLEAR_LOCATION_LIST_ALERT');
export const changeShowLocationSearchInput = createAction('CHANGE_SHOW_LOCATION_SEARCH_INPUT');
export const clearLocationSearchInput = createAction('CLEAR_LOCATION_SEARCH_INPUT');