import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
  exportBean: {
    maintenanceCaseId: null,
    outputFile: ''
  },
  exportBeanValidation: {},
  alertStyle: null,
  alertMessage: null,
  batchDeleteBean: {
    batchId: null
  },
  batchDeleteBeanValidation: {},
  batchRecords: []
});

const mRecordUploadReducer = handleActions({
  BEFORE_UPLOAD_M_RECORD_DATA: (state, { payload }) => {
    return state.merge({
      alertStyle: null,
      alertMessage: '資料處理中，請稍後',
      exportBean: {
        maintenanceCaseId: null,
        outputFile: ''
      },
      exportBeanValidation: {},
      batchDeleteBean: {
        batchId: null
      },
      batchDeleteBeanValidation: {}
    })
  },
  AFTER_UPLOAD_M_RECORD_DATA: (state, { payload }) => {
    return state.merge({
      alertStyle: payload.data.alertStyle,
      alertMessage: payload.data.alertMessage,
    })
  },
  CHANGE_M_RECORD_EXPORT_VALUE: (state, { payload }) => {
    let exportBean = state.get('exportBean');
    return state.merge({
      exportBean: exportBean.set(payload.id, payload.value),
    })
  },
  CHANGE_M_RECORD_EXPORT_BATCH_ID: (state, { payload }) => {
    let batchDeleteBean = state.get('batchDeleteBean');
    return state.merge({
      batchDeleteBean: batchDeleteBean.set('batchId', payload),
    })
  },
  GENERATE_M_RECORD_XLS: (state, { payload }) => {
    return state.merge({
      exportBean: payload.data.bean,
      exportBeanValidation: payload.data.valBean,
      alertStyle: payload.data.alertStyle,
      alertMessage: payload.data.alertMessage,
    })
  },
  CLEAR_M_RECORD_UPLOAD_INPUT: (state, { payload }) => {
    return state.merge({
      exportBean: {
        maintenanceCaseId: null,
        outputFile: ''
      },
      exportBeanValidation: {},
      batchDeleteBean: {
        batchId: null
      },
      batchDeleteBeanValidation: {},
      alertStyle: null,
      alertMessage: null
    })
  },
  CLEAR_M_RECORD_UPLOAD_ALERT: (state, { payload }) => {
    return state.merge({
      exportBeanValidation: {},
      batchDeleteBeanValidation: {},
      alertStyle: null,
      alertMessage: null
    })
  },
  FIND_M_RECORD_BATCH_RECORDS: (state, { payload }) => {
    return state.merge({
      batchRecords: payload.data
    });
  },
  DELETE_M_RECORDS_BY_BATCHID: (state, { payload }) => {
    return state.merge({
      batchDeleteBean: payload.data.bean,
      batchDeleteBeanValidation: payload.data.valBean,
      alertStyle: payload.data.alertStyle,
      alertMessage: payload.data.alertMessage,
    })
  }
}, initState);

export default mRecordUploadReducer;