import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	alertStyle: null,
	alertMessage: null,
	chipSearchResult: null,
	chip: emptyChip(),
	chipValidation: {},
	mainSquas: [],
	subSquas: [],
	locations: [],
	chipRecordList: []
});

const nfcChipReducer = handleActions({
	FIND_CHIP_BY_NFC_TEXT: (state, { payload }) => {
		return state.merge({
			chipSearchResult: payload
		})
	},
	CLEAR_CHIP_DISPLAY: (state, { payload }) => {
		return state.merge({
			chipSearchResult: null
		})
	},
	CLEAR_CHIP_INPUT: (state, { payload }) => {
		let chip = state.get('chip');
		return state.merge({
			chip: emptyChip(chip.toJS()),
			chipValidation: {},
			subSquas: [],
			locations: [],
			chipRecordList: [],
			chipSearchResult: null,
			alertStyle: null,
			alertMessage: null
		})
	},
	CLEAR_CHIP_ALERT: (state, { payload }) => {
		return state.merge({
			chipValidation: {},
			alertStyle: null,
			alertMessage: null
		})
	},
	CHANGE_CHIP_VALUE: (state, { payload }) => {
		let chip = state.get('chip');
		return state.merge({
			chip: chip.set(payload.id, payload.value),
		})
	},
	FIND_MAIN_SQUA_LIST_CHIP_MAPPING: (state, { payload }) => {
		return state.merge({
			mainSquas: payload.data
		})
	},
	FIND_SUB_SQUA_LIST_CHIP_MAPPING: (state, { payload }) => {
		return state.merge({
			subSquas: payload.data
		})
	},
	FIND_ALL_LOCATION_LIST_CHIP_MAPPING: (state, { payload }) => {
		return state.merge({
			locations: payload.data
		})
	},
	ADD_NFC_CHIP_RECORD: (state, { payload }) => {
		return state.merge({
			chip: payload.data.bean,
			chipValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	FIND_CHIPS_BY_LOCATION: (state, { payload }) => {
		if (payload.data.length === 0) {
			return state.merge({
				chipRecordList: payload.data,
				alertStyle: 'warning',
				alertMessage: '查無資料'
			})
		} else {
			return state.merge({
				chipRecordList: payload.data,
				alertStyle: null,
				alertMessage: null
			})
		}
	}
}, initState);

function emptyChip(orgChip) {
	let corpId = null;
	if (orgChip) {
		corpId = orgChip.corpId;
	}
	return {
		corpId: corpId,
		from: 2, //WEB
		nfcText: null,
		mappingType: 1,
		locationNo: null,
		mainSquaId: null,
		mainSquaName: '',
		subSquaId: null,
		subSquaName: '',
		locationId: null,
		locationName: '',
		sMappingType: 1,
		sLocationNo: null,
		sMainSquaId: null,
		sMainSquaName: '',
		sSubSquaId: null,
		sSubSquaName: '',
		sLocationId: null,
		sLocationName: ''
	}
}

export default nfcChipReducer;