import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
  userName: null
});

const awsCognitoReducer = handleActions({
  AWS_COGNITO_RETRIEVE_CURRENT_USER: (state, { payload }) => {
    return state.merge({
      userName: payload
    });
  }
}, initState);

export default awsCognitoReducer;