import React from 'react';
import { Row, Col, ControlLabel, Form, ButtonToolbar, Button, Alert, Table } from 'react-bootstrap';
import Immutable from 'immutable';

import FieldGroup from './common/FieldGroup.jsx';
import { random8 } from '../constants/commonFunction.js';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

export default class EmergencyEventsComponent extends React.Component {

	componentDidMount() {
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
		}
		this.props.findEvents();
	}

	componentWillUnmount() {
		this.props.initEvent(this.props.event.get('corpId'));
	}

	addArea(sideNum, areaName) {
		let listName = 'side'.concat(sideNum).concat('List');
		let sideList = this.props.event.get(listName);
		let sid = random8();

		if (sideList.size < 8) {
			sideList = sideList.push(Immutable.Map({ areaId: sid, areaName: areaName }));
			this.props.setValue(listName, sideList);
		}
	}

	subtractArea(listName, areaId) {
		let sideList = this.props.event.get(listName);
		let i = 0;
		if (sideList) {
			for (let side of this.props.event.get(listName).toJS()) {
				if (areaId === side.areaId) {
					break;
				}
				i++;
			}
		}
		sideList = sideList.delete(i);
		this.props.setValue(listName, sideList);
	}

	addEvent() {
		this.props.addEvent(this.props.event);
	}

	editEvent() {
		this.props.editEvent(this.props.event);
	}

	findEvent(eventId) {
		this.props.clearAlert();
		this.props.findEvent(eventId);
	}

	deleteEvent(eventId) {
		let r = window.confirm('確認刪除 ?');
		if (r === true) {
			this.props.deleteEvent(eventId);
			this.props.initEvent(this.props.event.get('corpId'));
		}
	}

	updateEventActive(eventId, isActive) {
		this.props.clearAlert();
		this.props.updateEventActive(eventId, isActive);
	}

	render() {

		let sideList = [];
		for (let i = 0; i < 4; i++) {
			let listName = 'side'.concat(i + 1).concat('List');
			let subList = [];
			if (this.props.event.get(listName)) {
				for (let side of this.props.event.get(listName).toJS()) {
					subList.push(
						<span key={side.areaId}>
							<FieldGroup
								id={side.areaId}
								type="text"
								label=""
								value={side.areaName}
								placeholder=""
								onChange={(id, value) => {
									this.props.setListValue(listName, id, value);
								}}
							/>
							<Button bsSize="xsmall" onClick={() => this.subtractArea(listName, side.areaId)}>
								<span className="glyphicon glyphicon-minus" />
							</Button>
							{' '}
						</span>
					);
					sideList[i] = subList;
				}
			}
		}

		let noEventId = (this.props.event.get('eventId') == null);

		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Col xs={12} sm={12}>
					<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
						{this.props.alertMessage}
					</Alert>
				</Col>
			);
		}

		let tableRows = [];
		if (this.props.list) {
			for (let i = 0; i < this.props.list.size; i++) {
				let row = this.props.list.get(i);
				let editDisable = (row.get('can_edit') !== 'Y');
				let deleteDisable = (row.get('can_delete') !== 'Y');
				if (row.get('is_active') === 'Y') {
					tableRows.push(
						<tr key={row.get('event_id')}>
							<td>
								<ButtonToolbar>
									<Button bsStyle="default" bsSize="xsmall" disabled={editDisable} onClick={(event) => {
										this.updateEventActive(row.get('event_id'), 'N');
									}}><span style={{ color: '#a94442' }}>關閉</span>
									</Button>
									<Button bsStyle="default" bsSize="xsmall" disabled={editDisable} onClick={(event) => {
										this.findEvent(row.get('event_id'));
									}}><span className="glyphicon glyphicon-edit" />
									</Button>
									<Button bsStyle="default" bsSize="xsmall" disabled={deleteDisable} onClick={(event) => {
										this.deleteEvent(row.get('event_id'));
									}}><span className="glyphicon glyphicon-trash" style={{ color: '#a94442' }} />
									</Button>
								</ButtonToolbar>
							</td>
							<td>{row.get('event_name')}</td>
							<td>{row.get('is_active_display')}</td>
							<td>{row.get('corp_name')}</td>
							<td>{row.get('create_time')}</td>
						</tr>
					);
				}
				else {
					tableRows.push(
						<tr key={row.get('event_id')}>
							<td>
								<ButtonToolbar>
									<Button bsStyle="default" bsSize="xsmall" disabled={editDisable} onClick={(event) => {
										this.updateEventActive(row.get('event_id'), 'Y');
									}}><span>開啟</span>
									</Button>
									<Button bsStyle="default" bsSize="xsmall" disabled={true} onClick={(event) => {
										this.findEvent(row.get('event_id'));
									}}><span className="glyphicon glyphicon-edit" />
									</Button>
									<Button bsStyle="default" bsSize="xsmall" disabled={deleteDisable} onClick={(event) => {
										this.deleteEvent(row.get('event_id'));
									}}><span className="glyphicon glyphicon-trash" style={{ color: '#a94442' }} />
									</Button>
								</ButtonToolbar>
							</td>
							<td><span style={{ color: '#CCC' }}>{row.get('event_name')}</span></td>
							<td><span style={{ color: '#CCC' }}>{row.get('is_active_display')}</span></td>
							<td><span style={{ color: '#CCC' }}>{row.get('corp_name')}</span></td>
							<td><span style={{ color: '#CCC' }}>{row.get('create_time')}</span></td>
						</tr>
					);
				}
			}
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;火場管制&nbsp;&gt;&gt;&nbsp;事件建立</span>
				</Row>
				<Row>
					{alertDiv}
					<Col xs={9} sm={10}>
						<ButtonToolbar>
							<Button onClick={(event) => {
								this.addEvent();
							}} bsStyle="primary">
								新增
							</Button>
							<Button onClick={(event) => {
								this.editEvent();
							}} bsStyle="primary" disabled={noEventId}>
								修改
							</Button>
						</ButtonToolbar>
					</Col>
					<Col xs={3} sm={2}>
						<div style={{ float: 'right' }}>
							<Button onClick={(event) => {
								this.props.initEvent(this.props.event.get('corpId'));
							}} bsStyle="default">
								清除
					    	</Button>
						</div>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} sm={6}>
						<FieldGroup
							id="eventName"
							type="text"
							label="事件名稱"
							value={this.props.event.get('eventName')}
							placeholder=""
							required="true"
							onChange={(id, value) => {
								this.props.setValue(id, value);
							}}
							validation={this.props.eventValidation.get('eventName')}
						/>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} sm={12}>
						<ControlLabel>管制區域 :</ControlLabel>
					</Col>
				</Row>
				<Row>
					<Col xs={2} sm={2}>
						<ControlLabel></ControlLabel>
					</Col>
					<Col xs={10} sm={10}>
						<Form inline>
							<FieldGroup
								id={this.props.event.get('side0List').get(0).get('areaId')}
								type="text"
								label=""
								value={this.props.event.get('side0List').get(0).get('areaName')}
								placeholder=""
								disabled={true}
								onChange={(id, value) => {
								}}
							/>
							<Button bsSize="xsmall" disabled={true}>
								<span className="glyphicon glyphicon-minus" />
							</Button>
							{' '}
							<FieldGroup
								id={this.props.event.get('side0List').get(1).get('areaId')}
								type="text"
								label=""
								value={this.props.event.get('side0List').get(1).get('areaName')}
								placeholder=""
								disabled={true}
								onChange={(id, value) => {
								}}
							/>
							<Button bsSize="xsmall" disabled={true}>
								<span className="glyphicon glyphicon-minus" />
							</Button>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col xs={2} sm={2}>
						<ControlLabel>第一面 :</ControlLabel>
					</Col>
					<Col xs={10} sm={10}>
						<Form inline>
							{sideList[0]}
							{' '}
							<Button bsSize="xsmall" onClick={() => this.addArea(1, '')}>
								<span className="glyphicon glyphicon-plus" />
							</Button>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col xs={2} sm={2}>
						<ControlLabel>第二面 :</ControlLabel>
					</Col>
					<Col xs={10} sm={10}>
						<Form inline>
							{sideList[1]}
							{' '}
							<Button bsSize="xsmall" onClick={() => this.addArea(2, '')}>
								<span className="glyphicon glyphicon-plus" />
							</Button>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col xs={2} sm={2}>
						<ControlLabel>第三面 :</ControlLabel>
					</Col>
					<Col xs={10} sm={10}>
						<Form inline>
							{sideList[2]}
							{' '}
							<Button bsSize="xsmall" onClick={() => this.addArea(3, '')}>
								<span className="glyphicon glyphicon-plus" />
							</Button>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col xs={2} sm={2}>
						<ControlLabel>第四面 :</ControlLabel>
					</Col>
					<Col xs={10} sm={10}>
						<Form inline>
							{sideList[3]}
							{' '}
							<Button bsSize="xsmall" onClick={() => this.addArea(4, '')}>
								<span className="glyphicon glyphicon-plus" />
							</Button>
						</Form>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col xs={12} sm={12}>
						<ControlLabel>事件列表 :</ControlLabel>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12}>
						<Table bordered condensed hover striped>
							<thead>
								<tr>
									<th style={{ width: 110 }}></th>
									<th>事件名稱</th>
									<th style={{ width: 55 }}>狀態</th>
									<th>建立單位</th>
									<th style={{ width: 135 }}>建立時間</th>
								</tr>
							</thead>
							<tbody>
								{tableRows}
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		)
	}
}