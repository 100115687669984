import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	warranty: emptyWarranty(),
	warrantyValidation: {},
	alertStyle: null,
	alertMessage: null,
	showModal: false
});

const warrantyReducer = handleActions({
	CHANGE_WARRANTY_VALUE: (state, { payload }) => {
		let warranty = state.get('warranty');
		return state.merge({
			warranty: warranty.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_WARRANTY: (state, { payload }) => {
		return state.merge({
			warranty: payload.data.bean,
			warrantyValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	OPEN_WARRANTY_MODAL: (state, { payload }) => {
		return state.merge({
			forInsert: payload.forInsert,
			showModal: true
		})
	},
	CLOSE_WARRANTY_MODAL: (state, { payload }) => {
		return state.merge({
			warranty: emptyWarranty(),
			warrantyValidation: {},
			alertStyle: null,
			alertMessage: null,
			showModal: false
		})
	},
	FIND_WARRANTY: (state, { payload }) => {
		return state.merge({
			warranty: payload.data
		})
	}
}, initState);

function emptyWarranty() {
	return {
		warrantyId: null,
		warrantyYear: null,
		replacementYear: null,
		remind: null
	}
}

export default warrantyReducer;