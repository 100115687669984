import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const changeSearchBean1Value = createAction('CHANGE_SEARCHBEAN1_VALUE');
export const changeSearchBean2Value = createAction('CHANGE_SEARCHBEAN2_VALUE');
export const changeSearchBean3Value = createAction('CHANGE_SEARCHBEAN3_VALUE');
export const changeSearchReplacementBeanValue = createAction('CHANGE_SEARCH_REPLACEMENT_BEAN_VALUE');

export const findItemStatus = () => (
  (dispatch) => {
    let url = '/items/itemStatus';
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_ITEM_STATUS',
        payload: json
      });
    });
  }
);

export const searchItem1 = (payload, pagingData) => (
  (dispatch) => {
    let url = '/items/search1?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('PUT', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'FIND_ITEM_SEARCH_LIST',
        payload: json
      });
    });
  }
);

export const searchItem2 = (payload, pagingData) => (
  (dispatch) => {
    let url = '/items/search2?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('PUT', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'FIND_ITEM_SEARCH2_LIST',
        payload: json
      });
    });
  }
);

export const searchItem3 = (payload, pagingData) => (
  (dispatch) => {
    let url = '/items/search3?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('PUT', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'FIND_ITEM_SEARCH3_LIST',
        payload: json
      });
    });
  }
);

export const searchReplacementItem = (payload, pagingData) => (
  (dispatch) => {
    let url = '/items/searchReplacement?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('PUT', url, JSON.stringify(payload), (json) => {
      dispatch({
        type: 'FIND_ITEM_SEARCH_REPLACEMENT_LIST',
        payload: json
      });
    });
  }
);

export const searchReplacementItemCount = (payload) => (
  (dispatch) => {
    let corpId = payload.corpId;
    let url = '/items/searchReplacementItemCount/' + corpId;
    authFetch('GET', url, null, (json) => {
      dispatch({
        type: 'FIND_REMIND_REPLACEMENT_ITEM_COUNT',
        payload: json
      });
    });
  }
);

export const searchUserItem = (pagingData) => (
  (dispatch) => {
    let url = '/items/searchUserItem?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
    authFetch('PUT', url, null, (json) => {
      dispatch({
        type: 'FIND_ITEM_USER_LIST',
        payload: json
      });
    });
  }
);

export const changeShowSearchInput = createAction('CHANGE_SHOW_SEARCH_INPUT');
export const changeShowFilterInput = createAction('CHANGE_SHOW_FILTER_INPUT');

export const clearSearch1Input = createAction('CLEAR_SEARCH1_INPUT');
export const clearSearch2Input = createAction('CLEAR_SEARCH2_INPUT');
export const clearSearch3Input = createAction('CLEAR_SEARCH3_INPUT');
export const clearSearchReplacementInput = createAction('CLEAR_SEARCH_REPLACEMENT_INPUT');
export const clearItemSearchAlert = createAction('CLEAR_ITEM_SEARCH_ALERT');
export const changeItemSearchEventKey = createAction('CHANGE_ITEM_SEARCH_EVENT_KEY');