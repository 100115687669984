import {
	connect
} from 'react-redux'
import MRecordEditComponent from '../components/MRecordEditComponent.jsx';
import {
	changeMaintenanceRecordValue,
	addMaintenanceRecord,
	editMaintenanceRecord,
	searchMRecord,
	findMRecord,
	// clearMaintenanceRecordInput,
	clearMaintenanceRecordAlert,
	findAllManufacturers,
	findAllCorps,
	changeItemEventKey
} from '../actions';

const mapStateToProps = (state) => ({
	item: state.getIn(['mRecordReducer', 'item']),
	record: state.getIn(['mRecordReducer', 'record']),
	recordValidation: state.getIn(['mRecordReducer', 'recordValidation']),
	alertStyle: state.getIn(['mRecordReducer', 'alertStyle']),
	alertMessage: state.getIn(['mRecordReducer', 'alertMessage']),
	forInsert: state.getIn(['mRecordReducer', 'forInsert']),

	corps: state.getIn(['commonReducer', 'corps']),
	manufacturers: state.getIn(['commonReducer', 'manufacturers']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	editRecord: (record, item, forInsert) => {
		if (forInsert) {
			dispatch(addMaintenanceRecord(record, item));
		} else {
			dispatch(editMaintenanceRecord(record));
		}
	},
	setValue: (id, value) => {
		dispatch(changeMaintenanceRecordValue({
			id: id,
			value: value
		}))
	},
	findAllManufacturers: () => {
		dispatch(findAllManufacturers());
	},
	searchMRecord: (payload, pagingData) => {
		dispatch(searchMRecord(payload, pagingData));
	},
	findMRecord: (payload) => {
		dispatch(findMRecord(payload));
	},
	clearInput: () => {
		// dispatch(clearMaintenanceRecordInput());
	},
	clearAlert: () => {
		dispatch(clearMaintenanceRecordAlert());
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	changeItemEventKey: (payload) => {
		dispatch(changeItemEventKey(payload));
	}
})

const MRecordEditContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MRecordEditComponent)

export default MRecordEditContainer;