import {
	connect
} from 'react-redux'
import MainComponent from '../components/MainComponent.jsx';
import {
	signOut,
	findAuthRole,
	findUserMessageUnreadCount,
	switchCategory
} from '../actions';

const mapStateToProps = (state) => ({
	role: state.getIn(['authReducer', 'role']),
	category: state.getIn(['globalReducer', 'category']),
	userMessageUnreadCount: state.getIn(['globalReducer', 'userMessageUnreadCount'])
})

const mapDispatchToProps = (dispatch) => ({
	toSignOut: () => {
		dispatch(signOut());
	},
	findAuthRole: () => {
		dispatch(findAuthRole());
	},
	findUserMessageUnreadCount: () => {
		dispatch(findUserMessageUnreadCount());
	},
	switchCategory: (category) => {
		dispatch(switchCategory(category));
	}
})

const MainContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MainComponent)

export default MainContainer;