import {
	connect
} from 'react-redux'
import SquaEdit2Component from '../components/SquaEdit2Component.jsx';
import {
	changeSquaValue,
	addSqua,
	editSqua
} from '../actions';

const mapStateToProps = (state) => ({
	squa: state.getIn(['squa2Reducer', 'squa']),
	squaValidation: state.getIn(['squa2Reducer', 'squaValidation']),
	selectedMainSqua: state.getIn(['squa2Reducer', 'selectedMainSqua']),
	alertStyle: state.getIn(['squa2Reducer', 'alertStyleEdit']),
	alertMessage: state.getIn(['squa2Reducer', 'alertMessageEdit']),
	forInsert: state.getIn(['squa2Reducer', 'forInsert']),

	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	editItem: (squa, forInsert) => {
		if (forInsert) {
			dispatch(addSqua(squa));
		} else {
			dispatch(editSqua(squa));
		}
	},
	setValue: (id, value) => {
		dispatch(changeSquaValue({
			id: id,
			value: value
		}))
	}
})

const SquaEdit2Container = connect(
	mapStateToProps,
	mapDispatchToProps
)(SquaEdit2Component)

export default SquaEdit2Container;