import {
	connect
} from 'react-redux'
import UserEditComponent from '../components/UserEditComponent.jsx';
import {
	changeUserValue,
	addUser,
	editUser,
	findAllCorps,
	findAllManufacturers,
	findMainSquaList,
	findSubSquaList
} from '../actions';

const mapStateToProps = (state) => ({
	user: state.getIn(['userReducer', 'user']),
	userValidation: state.getIn(['userReducer', 'userValidation']),
	alertStyle: state.getIn(['userReducer', 'alertStyle']),
	alertMessage: state.getIn(['userReducer', 'alertMessage']),
	forInsert: state.getIn(['userReducer', 'forInsert']),
	corps: state.getIn(['commonReducer', 'corps']),
	manufacturers: state.getIn(['commonReducer', 'manufacturers']),
	mainSquas: state.getIn(['userReducer', 'mainSquas']),
	subSquas: state.getIn(['userReducer', 'subSquas']),
	showCorp: state.getIn(['userReducer', 'showCorp']),
	showManufacturer: state.getIn(['userReducer', 'showManufacturer']),
	searchBean: state.getIn(['userReducer', 'searchBean']),
	role: state.getIn(['authReducer', 'role']),

	activePage: state.getIn(['userListReducer', 'activePage']),
	itemPerPage: state.getIn(['userListReducer', 'itemPerPage'])
})

const mapDispatchToProps = (dispatch) => ({
	editItem: (user, pagingData, forInsert, searchBean) => {
		if (forInsert) {
			dispatch(addUser(user, pagingData, searchBean));
		} else {
			dispatch(editUser(user, pagingData, searchBean));
		}
	},
	setValue: (id, value) => {
		dispatch(changeUserValue({
			id: id,
			value: value
		}))
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	findAllManufacturers: () => {
		dispatch(findAllManufacturers());
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'USER'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'USER'));
	}
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
	user: stateProps.user,
	userValidation: stateProps.userValidation,
	alertStyle: stateProps.alertStyle,
	alertMessage: stateProps.alertMessage,
	forInsert: stateProps.forInsert,
	corps: stateProps.corps,
	manufacturers: stateProps.manufacturers,
	mainSquas: stateProps.mainSquas,
	subSquas: stateProps.subSquas,
	showCorp: stateProps.showCorp,
	showManufacturer: stateProps.showManufacturer,
	searchBean: stateProps.searchBean,
	role: stateProps.role,
	activePage: stateProps.activePage,
	itemPerPage: stateProps.itemPerPage,

	editItem: (user, pagingData, forInsert) => dispatchProps.editItem(
		user, pagingData, forInsert, stateProps.searchBean.toJS()),
	setValue: dispatchProps.setValue,
	findAllCorps: dispatchProps.findAllCorps,
	findAllManufacturers: dispatchProps.findAllManufacturers,
	findMainSquaList: dispatchProps.findMainSquaList,
	findSubSquaList: dispatchProps.findSubSquaList,
	changeShowCorpPage: dispatchProps.changeShowCorpPage,
	changeShowManufacturer: dispatchProps.changeShowManufacturer
})

const UserEditContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps
)(UserEditComponent)

export default UserEditContainer;