import {
	connect
} from 'react-redux'
import ChangePwdComponent from '../components/ChangePwdComponent.jsx';
import {
	changePassword,
	awsCognitoPasswordClear
} from '../actions';

const mapStateToProps = (state) => ({
	alertStyle: state.getIn(['changePwdReducer', 'alertStyle']),
	alertMessage: state.getIn(['changePwdReducer', 'alertMessage']),
	loading: state.getIn(['changePwdReducer', 'loading'])
});

const mapDispatchToProps = (dispatch) => ({
	toChangePwd: (oldPassword, newPassword, confirmPassword) => {
		dispatch(changePassword(oldPassword, newPassword, confirmPassword));
	},
	clearAlert: () => {
		dispatch(awsCognitoPasswordClear());
	}
});

const ChangePwdContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ChangePwdComponent)

export default ChangePwdContainer;