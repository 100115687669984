import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findCorpList,
	deleteCorp,
	openCorpModal,
	findCorp,
	clearCorpListAlert
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['corpListReducer', 'list']),
	activePage: state.getIn(['corpListReducer', 'activePage']),
	itemPerPage: state.getIn(['corpListReducer', 'itemPerPage']),
	totalPage: state.getIn(['corpListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		corp_name: '單位名稱',
		kk_customer_id: '固巧快客戶代號'
	},
	keys: ['corp_id'],
	showCheckBox: true,
	alertStyle: state.getIn(['corpListReducer', 'alertStyle']),
	alertMessage: state.getIn(['corpListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch) => ({
	refresh: (pagingData) => {
		dispatch(findCorpList(pagingData));
	},
	changeActivepage: (pagingData) => {
		dispatch(findCorpList(pagingData));
	},
	deleteItems: (deleteKey, pagingData) => {
		dispatch(deleteCorp(deleteKey, pagingData));
	},
	handleAdd: () => {
		dispatch(openCorpModal({ forInsert: true }));
	},
	handleEdit: (pk) => {
		dispatch(findCorp(pk));
		dispatch(openCorpModal({ forInsert: false }));
	},
	clearAlert: () => {
		dispatch(clearCorpListAlert());
	}
})

const CorpListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default CorpListContainer;