import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findItemTransferList
} from '../actions';

const mapStateToProps = (state) => ({
	data: state.getIn(['itemTransferListReducer', 'itemTransferList']),
	activePage: state.getIn(['itemTransferListReducer', 'activePage']),
	itemPerPage: state.getIn(['itemTransferListReducer', 'itemPerPage']),
	totalPage: state.getIn(['itemTransferListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: {
		from_squa_name: '移轉前分隊',
		org_location_name: '移轉前保管人員地點',
		to_squa_name: '移轉後分隊',
		new_location_name: '移轉後保管人員地點',
		s_transfer_time: '移轉時間',
		create_user: '登錄者'
	},
	keys: ['item_transfer_id'],
	disableAdd: true,
	disableEdit: true
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
		// dispatch(findItemTransferList({ itemId: ownProps.item.get('itemId') }, pagingData));
	},
	changeActivepage: (pagingData) => {
		dispatch(findItemTransferList(ownProps.filter, pagingData));
	}
})

const ItemTransferListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

export default ItemTransferListContainer;