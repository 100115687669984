import {
	connect
} from 'react-redux'
import UserMessageViewComponent from '../components/UserMessageViewComponent.jsx';
import {
} from '../actions';

const mapStateToProps = (state) => ({
	userMessage: state.getIn(['userMessageReducer', 'userMessage'])
})

const mapDispatchToProps = (dispatch) => ({
})

const UserMessageViewContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(UserMessageViewComponent)

export default UserMessageViewContainer;