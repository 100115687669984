import React from 'react';
import { Row, Col, Alert, ButtonToolbar, Button, Form } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

export default class ItemEditComponent extends React.Component {

	componentDidMount() {
		this.props.findAllManufacturers();
		this.props.findItemStatus();

		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
			this.props.findAllGearList({ corpId: corpId });
			this.props.findKkOrders(corpId);
		}
		if (this.props.editType === 'i') {
			this.clearInput();
		}
	}

	componentWillUnmount() {
		this.props.clearAlert();
	}

	addItem() {
		this.props.editItem(this.props.item, true);
	}

	editItem() {
		this.props.editItem(this.props.item, false);
	}

	deleteItem() {
		let r = window.confirm('確認刪除 ?');
		if (r === true) {
			this.props.deleteItem([{ item_id: this.props.item.get('itemId') }]);
		}
	}

	toViewItem() {
		this.props.changeItemEventKey(0);
		hashHistory.push("/itemView");
	}

	clearInput() {
		this.refs.mainSquaIdDdi.refs.typeahead.getInstance().clear();
		this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
		this.refs.gearIdDdi.refs.typeahead.getInstance().clear();
		this.refs.manufacturerIdDdi.refs.typeahead.getInstance().clear();
		this.refs.locationIdDdi.refs.typeahead.getInstance().clear();
		this.refs.kkOrderIdDdi.refs.typeahead.getInstance().clear();

		this.props.clearInput();
	}

	render() {
		let noItemId = (this.props.item.get('itemId') == null);

		let showWithNoItemId = 'none';
		if (noItemId) {
			showWithNoItemId = 'block';
		}

		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Col xs={12} sm={12}>
					<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
						{this.props.alertMessage}
					</Alert>
				</Col>
			);
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;裝備新增修改</span>
				</Row>
				<Row>
					{alertDiv}
					<Col xs={9} sm={10}>
						<ButtonToolbar>
							<Button onClick={(event) => {
								this.addItem();
							}} bsStyle="primary">
								新增
							</Button>
							<Button onClick={(event) => {
								this.editItem();
							}} bsStyle="primary" disabled={noItemId}>
								修改
							</Button>
							<Button onClick={(event) => {
								this.deleteItem();
							}} bsStyle="default" disabled={noItemId}>
								<span className="glyphicon glyphicon-trash" style={{ marginRight: 10, color: '#a94442' }} />
								<span style={{ color: '#a94442' }}>刪除</span>
							</Button>
							<Button onClick={(event) => {
								this.toViewItem();
							}} bsStyle="default" disabled={noItemId}>
								<span className="glyphicon glyphicon-eye-open" style={{ marginRight: 10 }} />裝備總覽
							</Button>
						</ButtonToolbar>
					</Col>
					<Col xs={3} sm={2}>
						<div style={{ float: 'right' }}>
							<Button onClick={(event) => {
								this.clearInput();
							}} bsStyle="default">
								清除
					    	</Button>
						</div>
					</Col>
				</Row>
				<br />
				<Form>
					<Row>
						<Col xs={12} sm={12}>
							<h4>系統裝備序號: {this.props.item.get('itemId')}</h4><h5 style={{ color: "#eb7134" }}>(新增:將該序號資料複製一份, 修改:針對該序號做修改)</h5>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<DropDownInput ref="mainSquaIdDdi"
								id="mainSquaId"
								label="大隊"
								required="true"
								disabled={!noItemId}
								options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
								selected={[{ label: (this.props.item.get('mainSquaName') != null ? this.props.item.get('mainSquaName') : ''), value: this.props.item.get('mainSquaId') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
									this.props.setValue('mainSquaName', label);
									if (this.props.item.get('corpId')) {
										if (value) {
											this.props.findSubSquaList({ corpId: this.props.item.get('corpId'), parentId: value });
										} else {
											this.props.findSubSquaList({ corpId: this.props.item.get('corpId'), parentId: -1 });
										}
									}
								}}
								validation={this.props.itemValidation.get('mainSquaId')}
								extraClear={() => {
									// this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
									this.props.setValue('subSquaId', null);
									this.props.setValue('subSquaName', null);
								}}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<DropDownInput ref="subSquaIdDdi"
								id="subSquaId"
								label="分隊"
								required="true"
								disabled={!noItemId}
								options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
								selected={[{ label: (this.props.item.get('subSquaName') != null ? this.props.item.get('subSquaName') : ''), value: this.props.item.get('subSquaId') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
									this.props.setValue('subSquaName', label);
								}}
								validation={this.props.itemValidation.get('subSquaId')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<RadioGroup
								id="firefighterType"
								label="警義消"
								radioList={[{ label: "警消", value: "Officer" }, { label: "義消", value: "Volunteer" }]}
								value={this.props.item.get('firefighterType')}
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('firefighterType')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="itemNo"
								type="text"
								label="裝備編號"
								value={this.props.item.get('itemNo')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('itemNo')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="assetNo"
								type="text"
								label="財產編號"
								value={this.props.item.get('assetNo')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('assetNo')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<DropDownInput ref="gearIdDdi"
								id="gearId"
								label="裝備類別"
								required="true"
								disabled={!noItemId}
								options={this.props.gears.map((value, key) => ({ label: value.get('gear_name'), value: value.get('gear_id') })).toJS()}
								selected={[{ label: (this.props.item.get('gearName') != null ? this.props.item.get('gearName') : ''), value: this.props.item.get('gearId') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
									this.props.setValue('gearName', label);
								}}
								validation={this.props.itemValidation.get('gearId')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="model"
								type="text"
								label="型式"
								value={this.props.item.get('model')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('model')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="color"
								type="text"
								label="顏色"
								value={this.props.item.get('color')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('color')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="sizing"
								type="text"
								label="尺寸"
								value={this.props.item.get('sizing')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('sizing')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<DropDownInput ref="manufacturerIdDdi"
								id="manufacturerId"
								label="廠商"
								disabled={!noItemId}
								options={this.props.manufacturers.map((value, key) => ({ label: value.get('manufacturer_name'), value: value.get('manufacturer_id') })).toJS()}
								selected={[{ label: (this.props.item.get('manufacturerName') != null ? this.props.item.get('manufacturerName') : ''), value: this.props.item.get('manufacturerId') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
									this.props.setValue('manufacturerName', label);
									// this.props.setValue('brand', label);
								}}
								validation={this.props.itemValidation.get('manufacturerId')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="brand"
								type="text"
								label="廠牌"
								value={this.props.item.get('brand')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('brand')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="manufactureYear"
								type="text"
								label="年份"
								value={this.props.item.get('manufactureYear')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('manufactureYear')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="warrantyYear"
								type="number"
								min="0"
								step="1"
								label="保固年限"
								value={this.props.item.get('warrantyYear')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('warrantyYear')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<RadioGroup
								id="itemType"
								label="財產物品別"
								radioList={[{ label: "財產", value: 1 }, { label: "物品", value: 2 }, { label: "無財產物品帳", value: 3 }]}
								value={this.props.item.get('itemType')}
								onChange={(id, value) => {
									this.props.setValue(id, parseInt(value, 10));
								}}
								validation={this.props.itemValidation.get('itemType')}
							/>
						</Col>
						<div style={{ display: showWithNoItemId }}>
							<Col xs={12} sm={6}>
								<RadioGroup
									id="locationType"
									label="保管人員地點"
									radioList={[{ label: "人員", value: 1 }, { label: "車輛", value: 2 }, { label: "倉庫", value: 3 }, { label: "其他", value: 4 }]}
									value={this.props.item.get('locationType')}
									onChange={(id, value) => {
										this.props.setValue(id, parseInt(value, 10));
										if (this.props.item.get('corpId')) {
											this.props.findLocationList({ corpId: this.props.item.get('corpId'), locationType: value });
										}
									}}
									validation={this.props.itemValidation.get('locationType')}
								/>
								<DropDownInput ref="locationIdDdi"
									id="locationId"
									options={this.props.locations.map((value, key) => ({ label: value.get('display'), value: value.get('location_id') })).toJS()}
									selected={[{ label: (this.props.item.get('locationName') != null ? this.props.item.get('locationName') : ''), value: this.props.item.get('locationId') }]}
									onChange={(id, value, label) => {
										this.props.setValue(id, value);
										this.props.setValue('locationName', label);
									}}
									validation={this.props.itemValidation.get('locationId')}
								/>
							</Col>
						</div>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="purchaseDate"
								type="date"
								label="購買日期"
								value={this.props.item.get('purchaseDate')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('purchaseDate')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="purchaseAmount"
								type="number"
								min="0"
								step="1"
								label="購買金額"
								value={this.props.item.get('purchaseAmount')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('purchaseAmount')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="expiredDate"
								type="date"
								label="使用期限"
								value={this.props.item.get('expiredDate')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('expired')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="accessory"
								type="text"
								label="配件"
								value={this.props.item.get('accessory')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('accessory')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="manufactureNo"
								type="text"
								label="廠商製造序號"
								value={this.props.item.get('manufactureNo')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('manufactureNo')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="rfidNo"
								type="text"
								label="RFID編號"
								value={this.props.item.get('rfidNo')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('rfidNo')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<DropDownInput ref="kkOrderIdDdi"
								id="kkOrderId"
								label="固巧快訂單"
								options={this.props.kkOrders.map((value, key) => ({ label: value.get('order_id'), value: value.get('order_id') })).toJS()}
								selected={[{ label: (this.props.orgItem.get('kkOrderId') != null ? this.props.orgItem.get('kkOrderId') : ''), value: this.props.orgItem.get('kkOrderId') }]}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('kkOrderId')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="kkLabelId"
								type="text"
								label="固巧快編號"
								value={this.props.item.get('kkLabelId')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('kkLabelId')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12}>
							<FieldGroup
								id="note"
								type="text"
								label="備註"
								value={this.props.item.get('note')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.itemValidation.get('note')}
								componentClass="textarea"
								rows="3"
							/>
						</Col>
					</Row>
				</Form>
			</div>
		)
	}
}