import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	itemSearchList: [],
	activePage: 1,
	itemPerPage: 10,
	totalPage: 1,
	filter: null,
	alertStyle: null,
	alertMessage: null,
	remindReplacementItemCount: 0
});

const itemReplacementListReducer = handleActions({
	FIND_ITEM_SEARCH_REPLACEMENT_LIST: (state, { payload }) => {
		if (payload.data.dataTableData.length === 0) {
			return state.merge({
				itemSearchList: [],
				activePage: 1,
				itemPerPage: 10,
				totalPage: 1,
				filter: payload.data.filter,
				alertStyle: 'warning',
				alertMessage: '查無資料'
			})
		} else {
			return state.merge({
				itemSearchList: payload.data.dataTableData,
				activePage: payload.data.activePage,
				totalPage: payload.data.totalPage,
				itemPerPage: payload.data.itemPerPage,
				filter: payload.data.filter,
				alertStyle: null,
				alertMessage: null
			})
		}
	},
	CLEAR_ITEM_SEARCH_ALERT: (state, { payload }) => {
		return state.merge({
			alertStyle: null,
			alertMessage: null
		})
	},
	FIND_REMIND_REPLACEMENT_ITEM_COUNT: (state, { payload }) => {
		return state.merge({
			remindReplacementItemCount: payload.data
		})
	},
}, initState);

export default itemReplacementListReducer;