export function dateToYMD(date) {
	var d = date.getDate();
	var m = date.getMonth() + 1;
	var y = date.getFullYear();
	return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
		return v.toString(16);
	});
}

export function random8() { //修改 from uuidv4()
	return 'xxxxxxxx'.replace(/[x]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
		return v.toString(16);
	});
}