import React from 'react';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

export default class FieldGroup extends React.Component {

  render() {

    let validationState = null;
    let helpBlock = null;

    if (this.props.validation) {
      validationState = this.props.validation.get('validationState');
      helpBlock = this.props.validation.get('helpBlock');
    }

    let showRequired = null;
    if (this.props.required) {
      showRequired = (<span className="text-danger" style={{ marginLeft: 5 }}>*</span>)
    }

    let value = this.props.value;
    if (value == null) {
      value = '';
    }

    return (
      <FormGroup
        controlId={this.props.id}
        validationState={validationState} >
        <ControlLabel>{this.props.label}{showRequired}</ControlLabel>{' '}
        <FormControl
          type={this.props.type}
          value={value}
          onChange={(event) => {
            this.props.onChange(this.props.id, event.target.value);
          }}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          maxLength={this.props.maxLength}
          max={this.props.max}
          min={this.props.min}
          step={this.props.step}
          componentClass={this.props.componentClass}
          rows={this.props.rows}
        />
        <FormControl.Feedback />
        <HelpBlock>{helpBlock}</HelpBlock>
      </FormGroup>
    );
  }
}
