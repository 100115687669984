import { createAction } from 'redux-actions';
import { authFetch, authUpload } from '../AuthFetch';

export const findMRecordBatchRecords = () => (
    (dispatch) => {
        let url = '/maintenanceRecordBatch/batchRecords';
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_M_RECORD_BATCH_RECORDS',
                payload: json
            });
        });
    }
);

export const uploadMRecordData = (uploadFile, corpId) => (
    (dispatch) => {
        let formData = new FormData();
        formData.append('uploadfile', uploadFile);
        if (uploadFile == null) {
            dispatch({
                type: 'AFTER_UPLOAD_M_RECORD_DATA',
                payload: { statusCode: "102", displayMessage: "", data: { alertMessage: "未上傳任何檔案", alertStyle: "danger", bean: null } }
            });
            return;
        }
        dispatch({
            type: 'BEFORE_UPLOAD_M_RECORD_DATA'
        });
        let url = '/maintenanceRecordBatch/uploadMRecordData/corps/' + corpId;
        authUpload('POST', url, formData, (json) => {
            dispatch({
                type: 'AFTER_UPLOAD_M_RECORD_DATA',
                payload: json
            });
            dispatch(findMRecordBatchRecords());
        }, (err) => {
            dispatch({
                type: 'AFTER_UPLOAD_M_RECORD_DATA',
                payload: { statusCode: "999", displayMessage: "", data: { alertMessage: err.stack, alertStyle: "danger", bean: null } }
            });
        });
    }
);

export const findKkMaintenanceCaseByCorpId = (corpId) => (
    (dispatch) => {
        let url = '/tkkData/kkMaintenanceCases/corps/' + corpId;
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_KK_M_CASES',
                payload: json
            });
        });
    }
);

export const changeMRecordExportValue = createAction('CHANGE_M_RECORD_EXPORT_VALUE');
export const changeMRecordExportBatchId = createAction('CHANGE_M_RECORD_EXPORT_BATCH_ID');

export const exportMRecordData = (payload) => (
    (dispatch) => {
        dispatch({
            type: 'BEFORE_UPLOAD_M_RECORD_DATA'
        });
        let url = '/maintenanceRecordBatch/exportMRecordData/';
        authFetch('PUT', url, JSON.stringify(payload), (json) => {
            dispatch({
                type: 'GENERATE_M_RECORD_XLS',
                payload: json
            });
        });
    }
);

export const clearMRecordUploadInput = createAction('CLEAR_M_RECORD_UPLOAD_INPUT');
export const clearMRecordUploadAlert = createAction('CLEAR_M_RECORD_UPLOAD_ALERT');

export const deleteMRecordByBatchId = (payload) => (
    (dispatch) => {
        let url = '/maintenanceRecordBatch/deleteByBatchId';
        authFetch('DELETE', url, JSON.stringify(payload), (json) => {
            dispatch({
                type: 'DELETE_M_RECORDS_BY_BATCHID',
                payload: json
            });
            dispatch(findMRecordBatchRecords());
        });
    }
);