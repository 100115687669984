import {
	connect
} from 'react-redux'
import ItemTransferComponent from '../components/ItemTransferComponent.jsx';
import {
	changeItemTransferValue,
	transferItem,
	findMainSquaList,
	findSubSquaList,
	findAllLocationList,
	clearItemTransferInput,
	clearItemTransferAlert,
	changeItemEventKey,
	findItemTransferList,
	findAllCorps
} from '../actions';

const mapStateToProps = (state) => ({
	item: state.getIn(['itemReducer', 'item']),
	itemTransfer: state.getIn(['itemReducer', 'itemTransfer']),
	itemTransferValidation: state.getIn(['itemReducer', 'itemTransferValidation']),
	orgItem: state.getIn(['itemReducer', 'orgItem']),
	alertStyle: state.getIn(['itemReducer', 'alertStyle']),
	alertMessage: state.getIn(['itemReducer', 'alertMessage']),
	mainSquas: state.getIn(['itemReducer', 'mainSquas']),
	subSquas: state.getIn(['itemReducer', 'subSquas']),
	locations: state.getIn(['itemReducer', 'locations']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	transferItem: (itemTransfer, orgItem) => {
		dispatch(transferItem(itemTransfer, orgItem));
	},
	setValue: (id, value) => {
		dispatch(changeItemTransferValue({
			id: id,
			value: value
		}))
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'EDIT'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'EDIT'));
	},
	findLocationList: (payload) => {
		dispatch(findAllLocationList(payload, 'EDIT'));
	},
	clearInput: () => {
		dispatch(clearItemTransferInput());
	},
	clearAlert: () => {
		dispatch(clearItemTransferAlert());
	},
	changeItemEventKey: (payload) => {
		dispatch(changeItemEventKey(payload));
	},
	findItemTransferList: (payload, pagingData) => {
		dispatch(findItemTransferList(payload, pagingData));
	},
	findAllCorps: () => {
		dispatch(findAllCorps())
	}
})

const ItemTransferContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemTransferComponent)

export default ItemTransferContainer;