import {
	connect
} from 'react-redux'
import AnnouncementEditComponent from '../components/AnnouncementEditComponent.jsx';
import {
	changeAnnouncementValue,
	editAnnouncement,
	findAnnouncement,
	clearAnnouncementAlert
} from '../actions';

const mapStateToProps = (state) => ({
	announcement: state.getIn(['announcementReducer', 'announcement']),
	announcementValidation: state.getIn(['announcementReducer', 'announcementValidation']),
	alertStyle: state.getIn(['announcementReducer', 'alertStyle']),
	alertMessage: state.getIn(['announcementReducer', 'alertMessage']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	editAnnouncement: (announcement) => {
		dispatch(editAnnouncement(announcement));
	},
	findAnnouncement: (payload) => {
		dispatch(findAnnouncement(payload))
	},
	setValue: (id, value) => {
		dispatch(changeAnnouncementValue({
			id: id,
			value: value
		}))
	},
	clearAlert: () => {
		dispatch(clearAnnouncementAlert());
	}
})

const AnnouncementEditContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(AnnouncementEditComponent)

export default AnnouncementEditContainer;