import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	user: emptyUser(),
	userValidation: {},
	alertStyle: null,
	alertMessage: null,
	showModal: false,
	forInsert: true,
	corps: [],
	manufacturers: [],
	mainSquas: [],
	subSquas: [],
	showCorp: 'none',
	showManufacturer: 'none',
	// for search
	searchBean: {
		userType: null,
		corpId: null,
		manufacturerId: null,
		verified: null
	},
	showSearchCorp: 'none',
	showSearchManufacturer: 'none',
	unVerifiedCount: 0
});

const userReducer = handleActions({
	CHANGE_USER_VALUE: (state, { payload }) => {
		let user = state.get('user');
		return state.merge({
			user: user.set(payload.id, payload.value),
		})
	},
	CHANGE_SEARCH_USER_VALUE: (state, { payload }) => {
		let searchBean = state.get('searchBean');
		return state.merge({
			searchBean: searchBean.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_USER: (state, { payload }) => {
		return state.merge({
			user: payload.data.bean,
			userValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	OPEN_USER_MODAL: (state, { payload }) => {
		return state.merge({
			forInsert: payload.forInsert,
			showModal: true
		})
	},
	CLOSE_USER_MODAL: (state, { payload }) => {
		return state.merge({
			user: emptyUser(),
			userValidation: {},
			alertStyle: null,
			alertMessage: null,
			showModal: false,
			forInsert: true,
			corps: [],
			manufacturers: [],
			mainSquas: [],
			subSquas: [],
			showCorp: 'none',
			showManufacturer: 'none'
		})
	},
	FIND_USER: (state, { payload }) => {
		return state.merge({
			user: payload.data
		})
	},
	FIND_MAIN_SQUA_LIST_USER: (state, { payload }) => {
		return state.merge({
			mainSquas: payload.data
		})
	},
	FIND_SUB_SQUA_LIST_USER: (state, { payload }) => {
		return state.merge({
			subSquas: payload.data
		})
	},
	CHANGE_SHOW_CORP: (state, { payload }) => {
		return state.merge({
			showCorp: payload
		})
	},
	CHANGE_SHOW_MANUFACTURER: (state, { payload }) => {
		return state.merge({
			showManufacturer: payload
		})
	},
	CHANGE_SHOW_SEARCH_CORP: (state, { payload }) => {
		return state.merge({
			showSearchCorp: payload
		})
	},
	CHANGE_SHOW_SEARCH_MANUFACTURER: (state, { payload }) => {
		return state.merge({
			showSearchManufacturer: payload
		})
	},
	FIND_USER_UNVERIFIED_COUNT: (state, { payload }) => {
		return state.merge({
			unVerifiedCount: payload.data
		})
	}
}, initState);

function emptyUser() {
	return {
		userId: null,
		userName: null,
		userType: null,
		corpId: null,
		corpName: null,
		manufacturerId: null,
		manufacturerName: null,
		mainSquaId: null,
		mainSquaName: null,
		subSquaId: null,
		subSquaName: null,
		role: null,
		status: null
	}
}

export default userReducer;