import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import ItemSearch2InnerContainer from '../containers/ItemSearch2InnerContainer.jsx';
import ItemReplacementListContainer from '../containers/ItemReplacementListContainer.jsx';
import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

export default class ItemReplacementComponent extends React.Component {

	componentDidMount() {
		// this.props.clearSearchInput();
		let userRole = this.props.role.get('role');
		let userType = this.props.role.get('user_type');
		// 系統管理者 or 廠商
		if (userRole === 0 || userType === 2) {
		}
		// 無資料
		else if (!userRole) {
			hashHistory.push("/");
		}
		// 單位管理者 & 一般使用者
		else {
			let corpId = this.props.role.get('corp_id');
			if (corpId) {
			} else {
				hashHistory.push("/");
			}
		}

		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.searchReplacementItemCount({ corpId: corpId });
		}
	}

	initFilter() {
		if (!(this.props.filter && this.props.filter.get('corpId'))) {
			return { corpId: this.props.role.get('corp_id') }
		} else {
			return this.props.filter;
		}
	}

	render() {

		let showDiv = null;
		let remindReplacementItemCountMsg = null;
		let showMsg = 'none';
		if (this.props.remindReplacementItemCount) {
			remindReplacementItemCountMsg = (this.props.remindReplacementItemCount + ' 筆裝備將達或已逾汰換年限，請記得汰換裝備 !!');
			showMsg = 'block';
		}
		showDiv = (
			<div className="well col-xs-12 col-sm-12" style={{ display: showMsg, marginTop: 10 }}>
				<Row>
					<Col xs={12} sm={12}>
						<span style={{ color: 'red' }}>{remindReplacementItemCountMsg}</span>
					</Col>
				</Row>
			</div>
		);

		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;裝備汰換提醒</span>
				</Row>
				{showDiv}
				<div style={{ marginTop: 20 }}>
					<ItemSearch2InnerContainer />
					<ItemReplacementListContainer filter={this.initFilter()} />
				</div>
			</div>
		)
	}
}