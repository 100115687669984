import React from 'react';
import { Row, Col, Tabs, Tab, Button } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import ItemView1Container from '../containers/ItemView1Container.jsx';
import ItemTransferListContainer from '../containers/ItemTransferListContainer.jsx';
import ItemStatusChangeListContainer from '../containers/ItemStatusChangeListContainer.jsx';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';
import MRecordListContainer from '../containers/MRecordListContainer.jsx';

export default class ItemViewComponent extends React.Component {

	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
	}

	toTransferItem() {
		hashHistory.push('/itemTransfer');
	}

	toChangeItemStatus() {
		hashHistory.push('/itemStatusChange');
	}

	toAddMRecord() {
		this.props.findMRecordItem({ itemId: this.props.item.get('itemId') });
		hashHistory.push('/mRecordEdit');
	}

	handleSelect(key) {
		this.props.changeItemEventKey(key);
		//裝備明細
		if (key === 0) {
			this.props.findItem({ itemId: this.props.item.get('itemId') });
		}
		//移轉記錄
		else if (key === 1) {
			this.props.findItemTransferList({ itemId: this.props.item.get('itemId') }
				, { activePage: 1, itemPerPage: 10 });
		}
		//狀態更改記錄
		else if (key === 2) {
			this.props.findItemStatusChangeList({ itemId: this.props.item.get('itemId') }
				, { activePage: 1, itemPerPage: 10 });
		}
		//維護記錄
		else if (key === 3) {
			let corpId = this.props.role.get('corp_id');
			this.props.searchMRecord({ itemId: this.props.item.get('itemId'), corpId: corpId }
				, { activePage: 1, itemPerPage: 10 });
		}
	}

	render() {
		let noItemId = (this.props.item.get('itemId') == null);
		let lendSquaView = null;
		if (this.props.item.get('status') === 98) { //98:已借出
			lendSquaView = (
				<tr>
					<td style={{ width: '100%' }} colSpan={2}><label className="control-label">借出分隊:</label> {this.props.item.get('lendSquaName')}</td>
				</tr>
			);
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;裝備總覽</span>
				</Row>
				<Row>
					<Tabs id="searchTab" defaultActiveKey={this.props.eventKey} style={{ marginTop: 20 }} onSelect={this.handleSelect}>
						<Tab eventKey={0} title="裝備明細">
							<ItemView1Container item={this.props.item} />
						</Tab>
						<Tab eventKey={1} title="移轉記錄">
							<Row style={{ paddingTop: 10, paddingBottom: 10 }}>
								<Col xs={2} sm={2} xsOffset={10} smOffset={10}>
									<div style={{ float: 'right' }}>
										<Button onClick={(event) => {
											this.toTransferItem();
										}} bsStyle="default" disabled={noItemId}>
											<span className="glyphicon glyphicon-transfer" style={{ marginRight: 10 }} />移轉
										</Button>
									</div>
								</Col>
							</Row>
							<div className="well col-xs-12 col-sm-12" style={{ paddingTop: 10, paddingBottom: 0 }}>
								<table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
									<tbody>
										<tr>
											<td style={{ width: '50%' }}><label className="control-label">大隊:</label> {this.props.item.get('mainSquaName')}</td>
											<td style={{ width: '50%' }}><label className="control-label">分隊:</label> {this.props.item.get('subSquaName')}</td>
										</tr>
										<tr>
											<td style={{ width: '50%' }}><label className="control-label">保管人員地點:</label> {this.props.item.get('locationTypeDisplay')}</td>
											<td style={{ width: '50%' }}><label className="control-label">保管人員地點:</label> {this.props.item.get('locationName')}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<ItemTransferListContainer filter={this.props.transferFilter} />
						</Tab>
						<Tab eventKey={2} title="狀態更改記錄">
							<Row style={{ paddingTop: 10, paddingBottom: 10 }}>
								<Col xs={2} sm={2} xsOffset={10} smOffset={10}>
									<div style={{ float: 'right' }}>
										<Button onClick={(event) => {
											this.toChangeItemStatus();
										}} bsStyle="default" disabled={noItemId}>
											<span className="glyphicon glyphicon-adjust" style={{ marginRight: 10 }} />狀態更改
										</Button>
									</div>
								</Col>
							</Row>
							<div className="well col-xs-12 col-sm-12" style={{ paddingTop: 10, paddingBottom: 0 }}>
								<table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
									<tbody>
										<tr>
											<td style={{ width: '50%' }}><label className="control-label">使用狀態:</label> {this.props.item.get('statusDisplay')}</td>
											<td style={{ width: '50%' }}><label className="control-label">狀態更改日期:</label> {this.props.item.get('changeStatusTime')}</td>
										</tr>
										{lendSquaView}
									</tbody>
								</table>
							</div>
							<ItemStatusChangeListContainer filter={this.props.statusChangeFilter} />
						</Tab>
						<Tab eventKey={3} title="維護記錄">
							<Row style={{ paddingTop: 10, paddingBottom: 10 }}>
								<Col xs={2} sm={2} xsOffset={10} smOffset={10}>
									<div style={{ float: 'right' }}>
										<Button onClick={(event) => {
											this.toAddMRecord();
										}} bsStyle="default" disabled={noItemId}>
											<span className="glyphicon glyphicon-plus" style={{ marginRight: 10 }} />新增維護
										</Button>
									</div>
								</Col>
							</Row>
							<div className="well col-xs-12 col-sm-12" style={{ paddingTop: 10, paddingBottom: 0 }}>
								<table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
									<tbody>
										<tr>
											<td style={{ width: '50%' }}><label className="control-label">目前單位:</label> {this.props.item.get('subSquaName')}</td>
											<td style={{ width: '50%' }}><label className="control-label">裝備名稱:</label> {this.props.item.get('gearName')}</td>
										</tr>
										<tr>
											<td style={{ width: '50%' }}><label className="control-label">裝備編號:</label> {this.props.item.get('itemNo')}</td>
											<td style={{ width: '50%' }}><label className="control-label">財產編號:</label> {this.props.item.get('assetNo')}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<MRecordListContainer filter={{ itemId: this.props.item.get("itemId"), corpId: this.props.item.get("corpId") }} type="byItem" />
						</Tab>
					</Tabs>
				</Row>
			</div>
		)
	}
}