import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	role: {}
});

const authReducer = handleActions({
	FIND_AUTH_ROLE: (state, { payload }) => {
		return state.merge({
			role: payload.data
		})
	}
}, initState);

export default authReducer;