import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	corp: emptyCorp(),
	corpValidation: {},
	alertStyle: null,
	alertMessage: null,
	showModal: false,
	forInsert: true
});

const corpReducer = handleActions({
	CHANGE_CORP_VALUE: (state, { payload }) => {
		let corp = state.get('corp');
		return state.merge({
			corp: corp.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_CORP: (state, { payload }) => {
		return state.merge({
			corp: payload.data.bean,
			corpValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	OPEN_CORP_MODAL: (state, { payload }) => {
		return state.merge({
			forInsert: payload.forInsert,
			showModal: true
		})
	},
	CLOSE_CORP_MODAL: (state, { payload }) => {
		return state.merge({
			corp: emptyCorp(),
			corpValidation: {},
			alertStyle: null,
			alertMessage: null,
			showModal: false,
			forInsert: true
		})
	},
	FIND_CORP: (state, { payload }) => {
		return state.merge({
			corp: payload.data
		})
	}
}, initState);

function emptyCorp() {
	return {
		corpId: null,
		corpName: null,
		kkCustomerId: null,
		kkCustomerName: null,
		kkCustomerDisplay: null,
		orgCorpName: null,
		orgKkCustomerId: null
	}
}

export default corpReducer;