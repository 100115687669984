import { connect } from 'react-redux'
import ItemUploadComponent from '../components/ItemUploadComponent.jsx';
import {
  uploadItemData,
  findKkOrders,
  findKkOrdersByCorpId,
  exportItemData,
  changeItemExportValue,
  changeItemExportBatchId,
  clearItemUploadAlert,
  clearItemUploadInput,
  findItemBatchRecords,
  deleteItemByBatchId
} from '../actions';

const mapStateToProps = (state) => ({
  exportBean: state.getIn(['itemUploadReducer', 'exportBean']),
  exportBeanValidation: state.getIn(['itemUploadReducer', 'exportBeanValidation']),
  corps: state.getIn(['commonReducer', 'corps']),
  alertStyle: state.getIn(['itemUploadReducer', 'alertStyle']),
  alertMessage: state.getIn(['itemUploadReducer', 'alertMessage']),
  kkOrders: state.getIn(['tkkDataReducer', 'kkOrders']),
  batchDeleteBean: state.getIn(['itemUploadReducer', 'batchDeleteBean']),
  batchDeleteBeanValidation: state.getIn(['itemUploadReducer', 'batchDeleteBeanValidation']),
  batchRecords: state.getIn(['itemUploadReducer', 'batchRecords']),
  role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (uploadFile, corpId) => {
    dispatch(uploadItemData(uploadFile, corpId));
  },
  findKkOrders: (corpId) => {
    if (corpId) {
      dispatch(findKkOrdersByCorpId(corpId));
    } else {
      dispatch(findKkOrders());
    }
  },
  setValue: (id, value) => {
    dispatch(changeItemExportValue({
      id: id,
      value: value
    }))
  },
  changeBatchId: (value) => {
    dispatch(changeItemExportBatchId(value));
  },
  exportFile: (exportBean) => {
    dispatch(exportItemData(exportBean));
  },
  clearAlert: () => {
    dispatch(clearItemUploadAlert());
  },
  clearInput: () => {
    dispatch(clearItemUploadInput());
  },
  findBatchRecords: () => {
    dispatch(findItemBatchRecords())
  },
  deleteByBatchId: (payload) => {
    dispatch(deleteItemByBatchId(payload))
  }
})

const ItemUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemUploadComponent)

export default ItemUploadContainer;