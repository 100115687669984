import { createAction } from 'redux-actions';
import { authFetch } from '../AuthFetch';

export const changeEmergencyEvent = createAction('CHANGE_EMERGENCY_EVENT');
export const changeAreaEventKey = createAction('CHANGE_AREA_EVENT_KEY');

export const findActiveEvents = () => (
    (dispatch) => {
        let url = '/emergencyEvents/findActiveEvents';
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_ACTIVE_EVENTS',
                payload: json
            });
        });
    }
);

export const findAreasByEvent = (payload) => (
    (dispatch) => {
        let eventId = payload.eventId;
        let url = '/emergencyAreas/events/' + eventId;
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_AREAS',
                payload: json
            });
        });
    }
);

export const findEmergencyRecordList = (pagingData, payload) => (
    (dispatch) => {
        let url = '/emergencyRecords?activePage=' + pagingData.activePage + '&itemPerPage=' + pagingData.itemPerPage;
        if (payload.eventId) {
            url += '&eventId=' + payload.eventId;
        }
        if (payload.areaId) {
            url += '&areaId=' + payload.areaId;
        }
        if (payload.recordStep) {
            url += '&recordStep=' + payload.recordStep;
        }
        // console.log(url);
        authFetch('GET', url, null, (json) => {
            dispatch({
                type: 'FIND_EMERGENCY_RECORD_LIST',
                payload: json
            });
        });
    }
);

export const clearEmergencyListRecord = createAction('CLEAR_EMERGENCY_LIST_RECORD');