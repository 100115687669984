import React from 'react';
import { Row, Col, Alert, ButtonToolbar, Button, Form } from 'react-bootstrap';
import { hashHistory } from 'react-router';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import MultiDropDownInput from './common/MultiDropDownInput.jsx';

import CheckSelectCorp from '../containers/CheckSelectCorp.jsx';

export default class MRecordCloseComponent extends React.Component {

	componentDidMount() {
		this.props.findAllManufacturers();
		this.props.findMaintenanceItems();
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
		}
	}

	componentWillUnmount() {
		this.props.clearAlert();
	}

	closeRecord() {
		let mRecordStatus = this.props.record.get('status');

		// 已結案
		if (mRecordStatus === 2) {
			let r = window.confirm('該維護紀錄已結案, 確認要重新結案 ?');
			if (r === true) {
				this.props.closeRecord(this.props.record);
			}
		}
		// 未結案
		else {
			this.props.closeRecord(this.props.record);
		}
	}

	toViewMRecord() {
		this.props.findMRecord(
			{ maintenance_record_id: this.props.record.get('maintenanceRecordId'), item_id: this.props.item.get('itemId') });
		hashHistory.push("/mRecordView");
	}

	toViewItem() {
		this.props.changeItemEventKey(3);
		let corpId = this.props.role.get('corp_id');
		this.props.searchMRecord({ itemId: this.props.item.get('itemId'), corpId: corpId }
			, { activePage: 1, itemPerPage: 10 });
		hashHistory.push("/itemView");
	}

	clearInput() {
		this.props.clearInput();
	}

	render() {
		let noRecordId = (this.props.record.get('maintenanceRecordId') == null);
		let noItemId = (this.props.item.get('itemId') == null);

		let alertDiv = null;
		if (this.props.alertStyle || this.props.alertMessage) {
			alertDiv = (
				<Col xs={12} sm={12}>
					<Alert bsStyle={this.props.alertStyle} onDismiss={this.props.clearAlert}>
						{this.props.alertMessage}
					</Alert>
				</Col>
			);
		}

		let showStatus = null;
		let mRecordStatus = this.props.record.get('status');
		if (mRecordStatus === 2) {
			showStatus = (
				<Row>
					<Col xs={12} sm={12}>
						<div className="well col-xs-12 col-sm-12">
							<label style={{ color: 'red' }}>該維護紀錄已結案</label>
						</div>
					</Col>
				</Row>
			);
		}

		return (
			<div>
				<CheckSelectCorp />
				<Row style={{ marginBottom: 15 }}>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;維護記錄結案</span>
				</Row>
				<h4>系統裝備序號: {this.props.item.get('itemId')}</h4>
				<div className="well col-xs-12 col-sm-12" style={{ paddingTop: 10, paddingBottom: 5 }}>
					<table className="table table-condensed" style={{ tableLayout: 'fixed' }}>
						<tbody>
							<tr>
								<td style={{ width: '50%' }}><label className="control-label">裝備編號:</label> {this.props.item.get('itemNo')}</td>
								<td style={{ width: '50%' }}><label className="control-label">裝備類別:</label> {this.props.item.get('gearName')}</td>
							</tr>
							<tr>
								<td style={{ width: '50%' }}><label className="control-label">放置分隊:</label> {this.props.item.get('subSquaName')}</td>
								<td style={{ width: '50%' }}><label className="control-label">保管人員地點:</label> {this.props.item.get('locationName')}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<Row>
					{alertDiv}
					<Col xs={9} sm={10}>
						<ButtonToolbar>
							<Button onClick={(event) => {
								this.closeRecord();
							}} bsStyle="primary" disabled={noRecordId}>
								結案
							</Button>
							<Button onClick={(event) => {
								this.toViewMRecord();
							}} bsStyle="default" disabled={noRecordId}>
								<span className="glyphicon glyphicon-th-list" style={{ marginRight: 10 }} />維護明細
							</Button>
							<Button style={{ display: 'none' }} onClick={(event) => {
								this.toViewItem();
							}} bsStyle="default" disabled={noItemId}>
								<span className="glyphicon glyphicon-eye-open" style={{ marginRight: 10 }} />裝備總覽
							</Button>
						</ButtonToolbar>
					</Col>
					<Col xs={3} sm={2}>
						<div style={{ float: 'right', display: 'none' }}>
							<Button onClick={(event) => {
								this.clearInput();
							}} bsStyle="default">
								清除
					    	</Button>
						</div>
					</Col>
				</Row>
				<br />
				{showStatus}
				<Form>
					<Row>
						<Col xs={12} sm={6}>
							<RadioGroup
								id="maintenanceType"
								label="維護種類"
								required="true"
								radioList={[{ label: "清洗", value: 1 }, { label: "維修", value: 2 }]}
								value={this.props.record.get('maintenanceType')}
								onChange={(id, value) => {
									this.props.setValue(id, parseInt(value, 10));
								}}
								validation={this.props.recordValidation.get('maintenanceType')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<FieldGroup
								id="closeMaintenanceDate"
								type="date"
								label="結案(送回)日期"
								required="true"
								value={this.props.record.get('closeMaintenanceDate')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.recordValidation.get('closeMaintenanceDate')}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<RadioGroup
								id="suggestMaintain"
								label="建議維護"
								required="true"
								radioList={[{ label: "是", value: "Y" }, { label: "否", value: "N" }]}
								value={this.props.record.get('suggestMaintain')}
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.recordValidation.get('suggestMaintain')}
							/>
						</Col>
						<Col xs={12} sm={6}>
							<MultiDropDownInput ref="maintenanceItemsDdi"
								id="maintenanceItemList"
								label="維護項目"
								options={this.props.maintenanceItems.map((value, key) => ({ label: value.get('description'), value: value.get('description') })).toJS()}
								selected={this.props.record.get('maintenanceItemList')}
								onChange={(id, value, label) => {
									this.props.setValue(id, value);
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12}>
							<FieldGroup
								id="maintenanceDesc"
								type="text"
								label="維護說明"
								value={this.props.record.get('maintenanceDesc')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.recordValidation.get('maintenanceDesc')}
								componentClass="textarea"
								rows="3"
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={6}>
							<RadioGroup
								id="suggestScrap"
								label="建議報廢"
								required="true"
								radioList={[{ label: "是", value: "Y" }, { label: "否", value: "N" }]}
								value={this.props.record.get('suggestScrap')}
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.recordValidation.get('suggestScrap')}
							/>
						</Col>
						<Col xs={12} sm={6}>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12}>
							<FieldGroup
								id="scrapReason"
								type="text"
								label="建議報廢原因"
								value={this.props.record.get('scrapReason')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.recordValidation.get('scrapReason')}
								componentClass="textarea"
								rows="3"
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12}>
							<FieldGroup
								id="note"
								type="text"
								label="備註"
								value={this.props.record.get('note')}
								placeholder=""
								onChange={(id, value) => {
									this.props.setValue(id, value);
								}}
								validation={this.props.recordValidation.get('note')}
								componentClass="textarea"
								rows="3"
							/>
						</Col>
					</Row>
				</Form>
			</div>
		)
	}
}