import {
	connect
} from 'react-redux'
import WarrantyComponent from '../components/WarrantyComponent.jsx';
import {
	closeWarrantyModal
} from '../actions';

const mapStateToProps = (state) => ({
	showModal: state.getIn(['warrantyReducer', 'showModal']),
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => {
		dispatch(closeWarrantyModal());
	},
})

const WarrantyContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(WarrantyComponent)

export default WarrantyContainer;