import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { hashHistory } from 'react-router';

export default class ItemView1Component extends React.Component {

	componentDidMount() {
	}

	toEditItem() {
		hashHistory.push('/itemEdit/e');
	}

	render() {
		let noItemId = (this.props.item.get('itemId') == null);
		let statusFieldGroup = null;
		if (this.props.item.get('status') === 3) {
			statusFieldGroup = (
				<span><label className="control-label">報修日期:</label> {this.props.item.get('noticeRepairTime')}</span>
			);
		} else if (this.props.item.get('status') === 4) {
			statusFieldGroup = (
				<span><label className="control-label">送維護日期:</label> {this.props.item.get('maintenanceTime')}</span>
			);
		} else if (this.props.item.get('status') === 5) {
			statusFieldGroup = (
				<span><label className="control-label">簽核日期:</label> {this.props.item.get('scrapTime')}</span>
			);
		}

		return (
			<div>
				<Row style={{ paddingTop: 10, paddingBottom: 10 }}>
					<Col xs={2} sm={2} xsOffset={10} smOffset={10}>
						<div style={{ float: 'right' }}>
							<Button onClick={(event) => {
								this.toEditItem();
							}} bsStyle="default" disabled={noItemId}>
								<span className="glyphicon glyphicon-edit" style={{ marginRight: 10 }} />修改
							</Button>
						</div>
					</Col>
				</Row>
				<div className="col-xs-12 col-sm-12">
					<table className="table table-striped table-condensed" style={{ tableLayout: 'fixed' }}>
						<tbody>
							<tr>
								<td style={{ width: '50%' }}><label className="control-label">系統裝備序號:</label> {this.props.item.get('itemId')}</td>
								<td style={{ width: '50%' }}><label className="control-label">單位:</label> {this.props.item.get('corpName')}</td>
							</tr>
							<tr>
								<td><label className="control-label">大隊:</label> {this.props.item.get('mainSquaName')}</td>
								<td><label className="control-label">分隊:</label> {this.props.item.get('subSquaName')}</td>
							</tr>
							<tr>
								<td><label className="control-label">警義消:</label> {this.props.item.get('firefighterTypeDisplay')}</td>
								<td></td>
							</tr>
							<tr>
								<td><label className="control-label">裝備編號:</label> {this.props.item.get('itemNo')}</td>
								<td><label className="control-label">財產編號:</label> {this.props.item.get('assetNo')}</td>
							</tr>
							<tr>
								<td><label className="control-label">裝備類別:</label> {this.props.item.get('gearName')}</td>
								<td><label className="control-label">型式:</label> {this.props.item.get('model')}</td>
							</tr>
							<tr>
								<td><label className="control-label">顏色:</label> {this.props.item.get('color')}</td>
								<td><label className="control-label">尺寸:</label> {this.props.item.get('sizing')}</td>
							</tr>
							<tr>
								<td><label className="control-label">廠商:</label> {this.props.item.get('manufacturerName')}</td>
								<td><label className="control-label">廠牌:</label> {this.props.item.get('brand')}</td>
							</tr>
							<tr>
								<td><label className="control-label">年份:</label> {this.props.item.get('manufactureYear')}</td>
								<td><label className="control-label">保固年限:</label> {this.props.item.get('warrantyYear')}</td>
							</tr>
							<tr>
								<td><label className="control-label">財產物品別:</label> {this.props.item.get('itemTypeDisplay')}</td>
								<td></td>
							</tr>
							<tr>
								<td><label className="control-label">購買日期:</label> {this.props.item.get('purchaseDateDisplay')}</td>
								<td><label className="control-label">購買金額:</label> {this.props.item.get('purchaseAmount')}</td>
							</tr>
							<tr>
								<td><label className="control-label">使用期限:</label> {this.props.item.get('expiredDateDisplay')}</td>
								<td><label className="control-label">配件:</label> {this.props.item.get('accessory')}</td>
							</tr>
							<tr>
								<td><label className="control-label">廠商製造序號:</label> {this.props.item.get('manufactureNo')}</td>
								<td><label className="control-label">RFID編號:</label> {this.props.item.get('rfidNo')}</td>
							</tr>
							<tr>
								<td><label className="control-label">保管類別:</label> {this.props.item.get('locationTypeDisplay')}</td>
								<td><label className="control-label">保管人員地點:</label> {this.props.item.get('locationName')}</td>
							</tr>
							<tr>
								<td><label className="control-label">使用狀態:</label> {this.props.item.get('statusDisplay')}</td>
								<td>{statusFieldGroup}</td>
							</tr>
							<tr>
								<td><label className="control-label">固巧快訂單:</label> {this.props.item.get('kkOrderId')}</td>
								<td><label className="control-label">固巧快編號:</label> {this.props.item.get('kkLabelId')}</td>
							</tr>
							<tr>
								<td colSpan="2">
									<label className="control-label">備註:</label><br />
									<pre style={{ border: 'none', backgroundColor: '#FFFFFF' }}>
										{this.props.item.get('note')}
									</pre>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}