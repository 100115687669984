import {
	connect
} from 'react-redux'
import Squas2Component from '../components/Squas2Component.jsx';
import {
	findAllCorps,
	findMainSquaList,
	findSubSquaList,
	changeSelectedMainSqua,
	assignSqua,
	openSquaModal,
	closeSquaModal,
	deleteSqua,
	findSqua,
	clearSelectedMainSqua,
	clearSqua2Alert
} from '../actions';

const mapStateToProps = (state) => ({
	showModal: state.getIn(['squa2Reducer', 'showModal']),
	corps: state.getIn(['commonReducer', 'corps']),
	mainSquas: state.getIn(['squa2Reducer', 'mainSquas']),
	subSquas: state.getIn(['squa2Reducer', 'subSquas']),
	selectedMainSqua: state.getIn(['squa2Reducer', 'selectedMainSqua']),
	alertStyle: state.getIn(['squa2Reducer', 'alertStyle']),
	alertMessage: state.getIn(['squa2Reducer', 'alertMessage']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => {
		dispatch(closeSquaModal());
	},
	findAllCorps: () => {
		dispatch(findAllCorps());
	},
	findMainSquaList: (payload) => {
		dispatch(findMainSquaList(payload, 'SQUA_LIST'));
	},
	findSubSquaList: (payload) => {
		dispatch(findSubSquaList(payload, 'SQUA_LIST'));
	},
	changeSelectedMainSqua: (mainSqua) => {
		dispatch(changeSelectedMainSqua(mainSqua));
	},
	openAddModal: (payload) => {
		dispatch(assignSqua(payload));
		dispatch(openSquaModal({ forInsert: true }));
	},
	openEditModal: (pk) => {
		dispatch(findSqua(pk));
		dispatch(openSquaModal({ forInsert: false }));
	},
	deleteItems: (deleteKey, payload) => {
		dispatch(deleteSqua(deleteKey, payload));
	},
	clearSelectedMainSqua: () => {
		dispatch(clearSelectedMainSqua());
	},
	clearAlert: () => {
		dispatch(clearSqua2Alert());
	},
})

const Squas2Container = connect(
	mapStateToProps,
	mapDispatchToProps
)(Squas2Component)

export default Squas2Container;