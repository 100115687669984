import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	location: emptyLocation(),
	locationValidation: {},
	mainSquas: [],
	subSquas: [],
	users: [],
	alertStyle: null,
	alertMessage: null,
	showModal: false,
	forInsert: true,
	searchBean: emptySearchLocation(),
	searchMainSquas: [],
	searchSubSquas: [],
	searchUsers: [],
	showSearchInput: true
});

const locationReducer = handleActions({
	CHANGE_LOCATION_VALUE: (state, { payload }) => {
		let location = state.get('location');
		return state.merge({
			location: location.set(payload.id, payload.value),
		})
	},
	CHANGE_SEARCH_LOCATION_VALUE: (state, { payload }) => {
		let searchBean = state.get('searchBean');
		return state.merge({
			searchBean: searchBean.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_LOCATION: (state, { payload }) => {
		return state.merge({
			location: payload.data.bean,
			locationValidation: payload.data.valBean,
			alertStyle: payload.data.alertStyle,
			alertMessage: payload.data.alertMessage,
		})
	},
	OPEN_LOCATION_MODAL: (state, { payload }) => {
		return state.merge({
			forInsert: payload.forInsert,
			showModal: true
		})
	},
	CLOSE_LOCATION_MODAL: (state, { payload }) => {
		return state.merge({
			location: emptyLocation(),
			locationValidation: {},
			alertStyle: null,
			alertMessage: null,
			showModal: false,
			forInsert: true
		})
	},
	FIND_LOCATION: (state, { payload }) => {
		return state.merge({
			location: payload.data
		})
	},
	ASSIGN_LOCATION: (state, { payload }) => {
		let location = state.get('location');
		location = location.set('corpId', payload.corpId);
		return state.merge({
			location: location
		})
	},
	FIND_MAIN_SQUA_LIST_LOCATION_SEARCH: (state, { payload }) => {
		return state.merge({
			searchMainSquas: payload.data
		})
	},
	FIND_SUB_SQUA_LIST_LOCATION_SEARCH: (state, { payload }) => {
		return state.merge({
			searchSubSquas: payload.data
		})
	},
	FIND_ENABLED_USERS_LOCATION_SEARCH: (state, { payload }) => {
		return state.merge({
			searchUsers: payload.data
		})
	},
	FIND_MAIN_SQUA_LIST_LOCATION_EDIT: (state, { payload }) => {
		return state.merge({
			mainSquas: payload.data
		})
	},
	FIND_SUB_SQUA_LIST_LOCATION_EDIT: (state, { payload }) => {
		return state.merge({
			subSquas: payload.data
		})
	},
	FIND_ENABLED_USERS_LOCATION_EDIT: (state, { payload }) => {
		return state.merge({
			users: payload.data
		})
	},
	CHANGE_SHOW_LOCATION_SEARCH_INPUT: (state, { payload }) => {
		return state.merge({
			showSearchInput: payload
		})
	},
	CLEAR_LOCATION_SEARCH_INPUT: (state, { payload }) => {
		let searchBean = state.get('searchBean');
		return state.merge({
			searchBean: emptySearchLocation(searchBean.toJS()),
			alertStyle: null,
			alertMessage: null,
			showSearchInput: true,
			searchSubSquas: []
		})
	}
}, initState);

function emptyLocation() {
	return {
		locationId: null,
		locationName: null,
		orgLocationName: null,
		locationNo: null,
		locationType: null,
		corpId: null,
		corpName: '',
		squaId: null,
		userId: null,
		mainSquaId: null,
		mainSquaName: '',
		subSquaId: null,
		subSquaName: ''
	}
}

function emptySearchLocation(orgSearchBean) {
	let corpId = null;
	if (orgSearchBean) {
		corpId = orgSearchBean.corpId;
	}
	return {
		locationNo: null,
		locationName: null,
		sLocationType: null,	//Radio參數名稱相同(search和edit)會有問題，所以改名
		corpId: corpId,
		mainSquaId: null,
		mainSquaName: '',
		subSquaId: null,
		subSquaName: '',
		userId: null,
		userDisplay: ''
	}
}

export default locationReducer;