import React from 'react';
import { Row, Col, Button, Form, FormGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { findCurrentUser } from '../constants/awsCognito.js';

import FieldGroup from './common/FieldGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import AlertGroup from './common/AlertGroup.jsx';

export default class RegisterComponent extends React.Component {

	componentDidMount() {
		if (findCurrentUser() != null) {
			this.props.toSignOut();
			this.props.router.push('/register');
		}
		this.props.findAllCorps();
		this.props.findAllManufacturers();
	}

	onRegisterUser() {
		this.props.registerUser(this.props.user);
	}

	onConfirmRegisteredUser() {
		this.props.confirmRegisteredUser(this.props.user);
	}

	render() {

		let { alertStyle, alertMessage, loading, loading2 } = this.props;

		let showLoading = (loading) ? '' : 'none';

		let showLoading2 = (loading2) ? '' : 'none';

		let userType = this.props.user.get('userType');
		if (userType === 1) {
			this.props.changeShowCorpPage('block');
			this.props.changeShowManufacturer('none');
		} else if (userType === 2) {
			this.props.changeShowCorpPage('none');
			this.props.changeShowManufacturer('block');
		} else {
			this.props.changeShowCorpPage('none');
			this.props.changeShowManufacturer('none');
		}

		let toLoginPage = () => {
			this.props.router.push('/login');
		};

		return (
			<div className="card center-block" style={{ width: "30rem" }}>
				<div className="card-body">
					<Row>
						<Col xs={12}><h3><b>建立帳號</b></h3></Col>
						<Col xs={12}>
							<AlertGroup
								alertStyle={alertStyle}
								alertMessage={alertMessage}
							/>
						</Col>
						<Col xs={12}><hr /></Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Form>
								<FieldGroup
									id="userId"
									type="text"
									label="帳號"
									value={this.props.user.get('userId')}
									placeholder=""
									required="true"
									onChange={(id, value) => {
										this.props.setValue(id, value);
									}}
									validation={this.props.userValidation.get('userId')}
								/>
								<FieldGroup
									id="password"
									type="password"
									label="密碼"
									value={this.props.user.get('password')}
									placeholder=""
									required="true"
									onChange={(id, value) => {
										this.props.setValue(id, value);
									}}
									validation={this.props.userValidation.get('password')}
								/>
								<div style={{fontSize: 12, marginBottom: 15, marginTop: -15}}>請混合使用 8 個字元以上的英文字母、數字和符號</div>
								<FieldGroup
									id="confirmPassword"
									type="password"
									label="確認密碼"
									value={this.props.user.get('confirmPassword')}
									placeholder=""
									required="true"
									onChange={(id, value) => {
										this.props.setValue(id, value);
									}}
									validation={this.props.userValidation.get('confirmPassword')}
								/>
								<FieldGroup
									id="email"
									type="text"
									label="Email"
									value={this.props.user.get('email')}
									placeholder=""
									required="true"
									onChange={(id, value) => {
										this.props.setValue(id, value);
									}}
									validation={this.props.userValidation.get('email')}
								/>
								<FieldGroup
									id="userName"
									type="text"
									label="顯示名稱"
									value={this.props.user.get('userName')}
									placeholder=""
									required="true"
									onChange={(id, value) => {
										this.props.setValue(id, value);
									}}
									validation={this.props.userValidation.get('userName')}
								/>
								<RadioGroup
									id="userType"
									label="使用者類型"
									required="true"
									radioList={[{ label: "消防員", value: 1 }, { label: "廠商", value: 2 }]}
									value={this.props.user.get('userType')}
									onChange={(id, value) => {
										this.props.setValue(id, parseInt(value, 10));
									}}
									validation={this.props.userValidation.get('userType')}
								/>
								<div style={{ display: this.props.showCorp }}>
									<DropDownInput id="corpId"
										label="單位"
										required="true"
										options={this.props.corps.map((value, key) => ({ label: value.get('corp_name'), value: value.get('corp_id') })).toJS()}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('corpName', label);
										}}
										validation={this.props.userValidation.get('userTypeVal')}
									/>
								</div>
								<div style={{ display: this.props.showManufacturer }}>
									<DropDownInput id="manufacturerId"
										label="廠商"
										required="true"
										options={this.props.manufacturers.map((value, key) => ({ label: value.get('manufacturer_name'), value: value.get('manufacturer_id') })).toJS()}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('manufacturerName', label);
										}}
										validation={this.props.userValidation.get('userTypeVal')}
									/>
								</div>
								<FormGroup>
									<Button block bsStyle="primary" disabled={loading} onClick={(event) => {
										this.onRegisterUser();
									}}>
										建立帳號<span style={{ display: showLoading }} className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
									</Button>
								</FormGroup>
								<div style={{ display: this.props.showConfirmationArea }}>
									<h5>系統已將認證碼寄至您的信箱，請輸入認證碼以通過身份確認!!</h5>
									<FieldGroup
										id="confirmationCode"
										type="text"
										label="認證碼"
										value={this.props.user.get('confirmationCode')}
										placeholder=""
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
									/>
									<FormGroup>
										<Button block bsStyle="primary" disabled={loading2} onClick={(event) => {
											this.onConfirmRegisteredUser();
										}}>
											認證<span style={{ display: showLoading2 }} className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
										</Button>
									</FormGroup>
								</div>
							</Form>
						</Col>
						<Col xs={6}></Col>
						<Col xs={6}>
							<div style={{ textAlign: 'right' }}>
								<span style={{ cursor: "pointer" }} onClick={toLoginPage}>登入</span>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		)
	}
}