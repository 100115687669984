import {
	connect
} from 'react-redux'
import DataTable from '../components/common/DataTable.jsx';
import {
	findLocationList,
	deleteLocation,
	openLocationModal,
	assignLocation,
	findLocation,
	clearLocationListAlert
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
	data: state.getIn(['locationListReducer', 'list']),
	activePage: state.getIn(['locationListReducer', 'activePage']),
	itemPerPage: state.getIn(['locationListReducer', 'itemPerPage']),
	totalPage: state.getIn(['locationListReducer', 'totalPage']),
	pagingMaxButtons: 5,
	fields: findFields(ownProps),
	keys: ['location_id'],
	showCheckBox: true,
	alertStyle: state.getIn(['locationListReducer', 'alertStyle']),
	alertMessage: state.getIn(['locationListReducer', 'alertMessage'])
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	refresh: (pagingData) => {
	},
	changeActivepage: (pagingData) => {
		dispatch(findLocationList(pagingData, ownProps.filter.toJS()));
	},
	deleteItems: (deleteKey, pagingData) => {
		dispatch(deleteLocation(deleteKey, pagingData, ownProps.filter.toJS()));
	},
	handleAdd: () => {
		dispatch(assignLocation(ownProps.filter));
		dispatch(openLocationModal({ forInsert: true }));
	},
	handleEdit: (pk) => {
		dispatch(findLocation(pk));
		dispatch(openLocationModal({ forInsert: false }));
	},
	clearAlert: () => {
		dispatch(clearLocationListAlert());
	}
})

const LocationListContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DataTable)

function findFields(ownProps) {
	return {
		location_name: '名稱',
		location_type_display: '類別',
		squa_name: '分隊',
		location_no: '編號',
		user_id: '所屬帳號'
	}
}

export default LocationListContainer;