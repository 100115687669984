import React from 'react';
import { Row, Col, Button, Collapse, Form } from 'react-bootstrap';

import FieldGroup from './common/FieldGroup.jsx';
import RadioGroup from './common/RadioGroup.jsx';
import DropDownInput from './common/DropDownInput.jsx';

export default class ItemSearch1InnerComponent extends React.Component {

	componentDidMount() {
		this.props.findItemStatus();
		let corpId = this.props.role.get('corp_id');
		if (corpId) {
			this.props.setValue("corpId", corpId);
			this.props.findMainSquaList({ corpId: corpId, parentId: 0 });
			this.props.findAllGearList({ corpId: corpId });
		}
	}

	search() {
		this.props.search(this.props.searchBean.toJS());
	}

	clearSearchInput() {
		this.refs.gearIdDdi.refs.typeahead.getInstance().clear();
		this.refs.mainSquaIdDdi.refs.typeahead.getInstance().clear();
		this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
		this.refs.locationIdDdi.refs.typeahead.getInstance().clear();
		this.refs.statusDdi.refs.typeahead.getInstance().clear();
		this.props.clearSearchInput();
	}

	changeShowSearchInput() {
		if (this.props.showSearchInput) {
			this.props.changeShowSearchInput(false);
		} else {
			this.props.changeShowSearchInput(true);
		}
	}

	render() {
		let iconClassName = "glyphicon glyphicon-plus";
		if (this.props.showSearchInput) {
			iconClassName = "glyphicon glyphicon-minus";
		}

		return (
			<div>
				<Button bsSize="xsmall" onClick={() => this.changeShowSearchInput()}>
					<span className={iconClassName} />
				</Button>
				<Collapse in={this.props.showSearchInput}>
					<div className="well col-xs-12 col-sm-12">
						<Form>
							<Row>
								<Col xs={10} sm={10}>
									<div style={{ marginBottom: 20 }}>
										<Button onClick={(event) => {
											this.search();
										}} bsStyle="default">
											<span className="glyphicon glyphicon-search" style={{ marginRight: 10 }} />搜尋
					    				</Button>
										<span style={{ marginLeft: 10 }}>組合式查詢，若不設條件可留空白</span>
									</div>
								</Col>
								<Col xs={2} sm={2}>
									<div style={{ marginBottom: 20, float: 'right' }}>
										<Button onClick={(event) => {
											this.clearSearchInput();
										}} bsStyle="default">
											清除
					    				</Button>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={6}>
									<FieldGroup
										id="itemNo"
										type="text"
										label="裝備編號"
										value={this.props.searchBean.get('itemNo')}
										placeholder=""
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
									/>
								</Col>
								<Col xs={12} sm={6}>
									<FieldGroup
										id="assetNo"
										type="text"
										label="財產編號"
										value={this.props.searchBean.get('assetNo')}
										placeholder=""
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={6}>
									<DropDownInput ref="gearIdDdi"
										id="gearId"
										label="裝備類別"
										options={this.props.gears.map((value, key) => ({ label: value.get('gear_name'), value: value.get('gear_id') })).toJS()}
										selected={[{ label: (this.props.searchBean.get('gearName') != null ? this.props.searchBean.get('gearName') : ''), value: this.props.searchBean.get('gearId') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('gearName', label);
										}}
									/>
								</Col>
								<Col xs={12} sm={6}>
									<FieldGroup
										id="manufactureYear"
										type="text"
										label="年份"
										value={this.props.searchBean.get('manufactureYear')}
										placeholder=""
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={6}>
									<DropDownInput ref="mainSquaIdDdi"
										id="mainSquaId"
										label="大隊"
										options={this.props.mainSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
										selected={[{ label: (this.props.searchBean.get('mainSquaName') != null ? this.props.searchBean.get('mainSquaName') : ''), value: this.props.searchBean.get('mainSquaId') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('mainSquaName', label);
											if (value) {
												this.props.findSubSquaList({ corpId: this.props.searchBean.get('corpId'), parentId: value });
											}
											else {
												this.props.findSubSquaList({ corpId: this.props.searchBean.get('corpId'), parentId: -1 });
											}
										}}
										extraClear={() => {
											// this.refs.subSquaIdDdi.refs.typeahead.getInstance().clear();
											this.props.setValue('subSquaId', null);
											this.props.setValue('subSquaName', null);
										}}
									/>
								</Col>
								<Col xs={12} sm={6}>
									<DropDownInput ref="subSquaIdDdi"
										id="subSquaId"
										label="分隊"
										options={this.props.subSquas.map((value, key) => ({ label: value.get('squa_name'), value: value.get('squa_id') })).toJS()}
										selected={[{ label: (this.props.searchBean.get('subSquaName') != null ? this.props.searchBean.get('subSquaName') : ''), value: this.props.searchBean.get('subSquaId') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('subSquaName', label);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={6}>
									<RadioGroup
										id="firefighterType"
										label="警義消"
										radioList={[{ label: "警消", value: "Officer" }, { label: "義消", value: "Volunteer" }]}
										value={this.props.searchBean.get('firefighterType')}
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
									/>
								</Col>
								<Col xs={12} sm={6}>
									<RadioGroup
										id="locationType"
										label="保管人員地點"
										radioList={[{ label: "人員", value: 1 }, { label: "車輛", value: 2 }, { label: "倉庫", value: 3 }, { label: "其他", value: 4 }]}
										value={this.props.searchBean.get('locationType')}
										onChange={(id, value) => {
											this.props.setValue(id, parseInt(value, 10));
											if (this.props.searchBean.get('corpId')) {
												this.props.findLocationList({ corpId: this.props.searchBean.get('corpId'), locationType: value });
											}
										}}
									/>
									<DropDownInput ref="locationIdDdi"
										id="locationId"
										options={this.props.locations.map((value, key) => ({ label: value.get('display'), value: value.get('location_id') })).toJS()}
										selected={[{ label: (this.props.searchBean.get('locationName') != null ? this.props.searchBean.get('locationName') : ''), value: this.props.searchBean.get('locationId') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('locationName', label);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={6}>
									<RadioGroup
										id="warrantyExpired"
										label="逾使用年限"
										radioList={[{ label: "是", value: "Y" }, { label: "否", value: "N" }]}
										value={this.props.searchBean.get('warrantyExpired')}
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
									/>
								</Col>
								<Col xs={12} sm={6}>
									<DropDownInput ref="statusDdi"
										id="status"
										label="使用狀態"
										options={this.props.itemStatus.map((value, key) => ({ label: value.get('display'), value: value.get('code') })).toJS()}
										selected={[{ label: (this.props.searchBean.get('statusDisplay') != null ? this.props.searchBean.get('statusDisplay') : ''), value: this.props.searchBean.get('status') }]}
										onChange={(id, value, label) => {
											this.props.setValue(id, value);
											this.props.setValue('statusDisplay', label);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={6}>
									<FieldGroup
										id="rfidNo"
										type="text"
										label="RFID編號"
										value={this.props.searchBean.get('rfidNo')}
										placeholder=""
										onChange={(id, value) => {
											this.props.setValue(id, value);
										}}
									/>
								</Col>
							</Row>
						</Form>
					</div>
				</Collapse>
			</div>
		)
	}
}