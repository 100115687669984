import {
	connect
} from 'react-redux'
import MessageViewComponent from '../components/MessageViewComponent.jsx';
import {
} from '../actions';

const mapStateToProps = (state) => ({
	message: state.getIn(['messageReducer', 'message'])
})

const mapDispatchToProps = (dispatch) => ({
})

const MessageViewContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(MessageViewComponent)

export default MessageViewContainer;