import {
	connect
} from 'react-redux'
import ItemSearchComponent from '../components/ItemSearchComponent.jsx';
import {
	clearSearch1Input,
	clearSearch2Input,
	clearSearch3Input,
	changeItemSearchEventKey
} from '../actions';

const mapStateToProps = (state) => ({
	eventKey: state.getIn(['itemSearchReducer', 'eventKey']),
})

const mapDispatchToProps = (dispatch) => ({
	clearSearchInput: () => {
		dispatch(clearSearch1Input());
		dispatch(clearSearch2Input());
		dispatch(clearSearch3Input());
	},
	changeItemSearchEventKey: (payload) => {
		dispatch(changeItemSearchEventKey(payload));
	}
})

const ItemSearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemSearchComponent)

export default ItemSearchContainer;