import {
	connect
} from 'react-redux'
import ChoiceCorpComponent from '../components/ChoiceCorpComponent.jsx';
import {
	findAllCorps,
	findAuthRoleWithSelectCorp
} from '../actions';

const mapStateToProps = (state) => ({
	corps: state.getIn(['commonReducer', 'corps']),
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
	findAllCorps: () => {
		dispatch(findAllCorps())
	},
	findAuthRoleWithSelectCorp: (selectCorpId) => {
		dispatch(findAuthRoleWithSelectCorp(selectCorpId))
	}
})

const ChoiceCorpContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ChoiceCorpComponent)

export default ChoiceCorpContainer;