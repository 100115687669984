import {
	handleActions
} from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	squa: emptySqua(),
	squaValidation: {},
	mainSquas: [],
	subSquas: [],
	selectedMainSqua: null,
	alertStyle: null,
	alertMessage: null,
	alertStyleEdit: null,
	alertMessageEdit: null,
	showModal: false,
	forInsert: true
});

const squa2Reducer = handleActions({
	CHANGE_SQUA_VALUE: (state, { payload }) => {
		let squa = state.get('squa');
		return state.merge({
			squa: squa.set(payload.id, payload.value),
		})
	},
	FORM_SUBMIT_SQUA: (state, { payload }) => {
		return state.merge({
			squa: payload.data.bean,
			squaValidation: payload.data.valBean,
			alertStyleEdit: payload.data.alertStyle,
			alertMessageEdit: payload.data.alertMessage,
		})
	},
	OPEN_SQUA_MODAL: (state, { payload }) => {
		return state.merge({
			forInsert: payload.forInsert,
			showModal: true
		})
	},
	CLOSE_SQUA_MODAL: (state, { payload }) => {
		return state.merge({
			squa: emptySqua(),
			squaValidation: {},
			alertStyleEdit: null,
			alertMessageEdit: null,
			showModal: false,
			forInsert: true
		})
	},
	FIND_SQUA: (state, { payload }) => {
		return state.merge({
			squa: payload.data
		})
	},
	ASSIGN_SQUA: (state, { payload }) => {
		let squa = state.get('squa');
		squa = squa.set('corpId', payload.corpId);
		squa = squa.set('parentId', payload.parentId);
		squa = squa.set('parentName', payload.parentName);
		return state.merge({
			squa: squa
		})
	},
	FIND_MAIN_SQUA_LIST_SQUA_LIST: (state, { payload }) => {
		return state.merge({
			mainSquas: payload.data
		})
	},
	FIND_SUB_SQUA_LIST_SQUA_LIST: (state, { payload }) => {
		return state.merge({
			subSquas: payload.data
		})
	},
	CHANGE_SELECTED_MAIN_SQUA: (state, { payload }) => {
		return state.merge({
			selectedMainSqua: payload
		})
	},
	CLEAR_SELECTED_MAIN_SQUA: (state, { payload }) => {
		return state.merge({
			subSquas: [],
			selectedMainSqua: null
		})
	},
	DELETE_SQUA: (state, { payload }) => {
		let statusCode = payload.statusCode;
		if (statusCode && statusCode !== "0") {
			return state.merge({
				alertStyle: payload.data.alertStyle,
				alertMessage: payload.data.alertMessage
			})
		} else {
			return state;
		}
	},
	CLEAR_SQUA2_ALERT: (state, { payload }) => {
		return state.merge({
			alertStyle: null,
			alertMessage: null
		})
	}
}, initState);

function emptySqua() {
	return {
		squaId: null,
		squaName: null,
		corpId: null,
		corpName: '',
		parentId: null,
		parentName: ''
	}
}

export default squa2Reducer;