import React from 'react';
import { Modal } from 'react-bootstrap';

import UserMessageListContainer from '../containers/UserMessageListContainer.jsx';
import UserMessageViewContainer from '../containers/UserMessageViewContainer.jsx';

export default class UserMessagesComponent extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
			<div>
				<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;個人訊息</span>
				<UserMessageListContainer />
				<Modal show={this.props.showModal} onHide={this.props.closeModal}>
					<Modal.Header closeButton />
					<Modal.Body>
						<UserMessageViewContainer />
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}