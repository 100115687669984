import {
	connect
} from 'react-redux'
import ItemUserComponent from '../components/ItemUserComponent.jsx';
import {
} from '../actions';

const mapStateToProps = (state) => ({
	role: state.getIn(['authReducer', 'role'])
})

const mapDispatchToProps = (dispatch) => ({
})

const ItemUserContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ItemUserComponent)

export default ItemUserContainer;