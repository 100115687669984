import React from 'react';
import { Row, Col, Button, Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { findCurrentUser } from '../constants/awsCognito.js';

import AlertGroup from './common/AlertGroup.jsx';

export default class ConfirmationComponent extends React.Component {

	componentDidMount() {
		if (findCurrentUser() != null) {
			this.props.toSignOut();
			this.props.router.push('/confirmation');
		}
		this.setState({
			username: '',
			confirmationCode: ''
		})
	}

	render() {
		let { toConfirmRegisteredUser, toResendConfirmationCode, alertStyle, alertMessage, loading } = this.props;

		let onUserNameChange = (e) => {
			this.setState({ username: e.target.value });
		};
		let onConfirmationCodeChange = (e) => {
			this.setState({ confirmationCode: e.target.value });
		};
		let onConfirmRegisteredUser = () => {
			let { username, confirmationCode } = this.state;
			toConfirmRegisteredUser(username, confirmationCode);
		};
		let onResendConfirmationCode = () => {
			let { username } = this.state;
			toResendConfirmationCode(username);
		};

		let showLoading = (loading) ? '' : 'none';

		let toLoginPage = () => {
			this.props.router.push('/login');
		};

		return (
			<div className="card center-block" style={{ width: "30rem" }}>
				<div className="card-body">
					<Row>
						<Col xs={12}><h3><b>認證</b></h3></Col>
						<Col xs={12}>
							<AlertGroup
								alertStyle={alertStyle}
								alertMessage={alertMessage}
							/>
						</Col>
						<Col xs={12}><hr /></Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Form>
								<FormGroup controlId="userName">
									<ControlLabel>帳號</ControlLabel>
									<FormControl type="text" placeholder="User Name" onChange={onUserNameChange} />
								</FormGroup>
								<FormGroup controlId="confirmationCode">
									<ControlLabel>認證碼</ControlLabel>
									<FormControl type="text" placeholder="Confirmation Code" onChange={onConfirmationCodeChange} />
								</FormGroup>
								<FormGroup>
									<Button block bsStyle="primary" disabled={loading} onClick={onConfirmRegisteredUser}>
										認證<span style={{ display: showLoading }} className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
									</Button>
									{' '}
									<Button block bsStyle="default" onClick={onResendConfirmationCode}>
										重發認證碼
                					</Button>
								</FormGroup>
							</Form>
						</Col>
						<Col xs={6}></Col>
						<Col xs={6}>
							<div style={{ textAlign: 'right' }}>
								<span style={{ cursor: "pointer" }} onClick={toLoginPage}>登入</span>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		)
	}
}