import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
  kkCustomers: [],
  kkOrders: [],
  kkMaintenanceCase: []
});

const tkkDataReducer = handleActions({
  FIND_KK_CUSTOMERS: (state, { payload }) => {
    return state.merge({
      kkCustomers: payload.data
    });
  },
  FIND_KK_ORDERS: (state, { payload }) => {
    return state.merge({
      kkOrders: payload.data
    });
  },
  FIND_KK_M_CASES: (state, { payload }) => {
    return state.merge({
      kkMaintenanceCase: payload.data
    });
  }
}, initState);

export default tkkDataReducer;