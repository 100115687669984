import React from 'react';
import { Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { hashHistory } from 'react-router';

export default class MRecordViewComponent extends React.Component {

	componentDidMount() {
	}

	toEditMRecord() {
		hashHistory.push('/mRecordEdit');
	}

	toCloseRecord() {
		hashHistory.push('/mRecordClose');
	}

	toUploadPic() {
		hashHistory.push('/mPicUpload');
	}

	toViewItem() {
		this.props.changeItemEventKey(3);
		let corpId = this.props.role.get('corp_id');
		this.props.searchMRecord({ itemId: this.props.item.get('itemId'), corpId: corpId }
			, { activePage: 1, itemPerPage: 10 });
		hashHistory.push("/itemView");
	}

	render() {
		let noMRecordId = (this.props.record.get('maintenanceRecordId') == null);
		let noItemId = (this.props.item.get('itemId') == null);

		// 維修前照片
		let prePicObj = [];
		let prePicList = this.props.record.get('prePicList');
		if (prePicList) {
			for (let prePic of prePicList) {
				let picId = prePic.get('maintenance_pic_id');
				let picPath = prePic.get('pic_path');
				let altDesc = "維修前照片" + picId;
				prePicObj.push((
					<Col xs={12} sm={6} key={picId}>
						<a href={picPath} target="_blank" rel="noopener noreferrer">
							<img src={picPath} className="img-thumbnail" alt={altDesc} />
						</a>
					</Col>
				))
			}
		}

		// 維修後照片
		let postPicObj = [];
		let postPicList = this.props.record.get('postPicList');
		if (postPicList) {
			for (let postPic of postPicList) {
				let picId = postPic.get('maintenance_pic_id');
				let picPath = postPic.get('pic_path');
				let altDesc = "維修後照片" + picId;
				postPicObj.push((
					<Col xs={12} sm={6} key={picId}>
						<a href={picPath} target="_blank" rel="noopener noreferrer">
							<img src={picPath} className="img-thumbnail" alt={altDesc} />
						</a>
					</Col>
				))
			}
		}

		return (
			<div>
				<Row>
					<span style={{ color: '#7F8C8D' }}>&gt;&gt;&nbsp;裝備後勤管理&nbsp;&gt;&gt;&nbsp;維護明細</span>
				</Row>
				<Row style={{ paddingTop: 10, paddingBottom: 10 }}>
					<Col xs={12} sm={3}>
						<label>狀態:</label>{'  '}<span style={{ color: 'blue', fontWeight: 'bold', fontSize: 20 }}>{this.props.record.get('statusDisplay')}</span>
					</Col>
					<Col xs={12} sm={9}>
						<div style={{ float: 'right' }}>
							<ButtonToolbar>
								<Button onClick={(event) => {
									this.toEditMRecord();
								}} bsStyle="default" disabled={noMRecordId}>
									<span className="glyphicon glyphicon-edit" style={{ marginRight: 10 }} />修改
								</Button>
								<Button onClick={(event) => {
									this.toCloseRecord();
								}} bsStyle="default" disabled={noMRecordId}>
									<span className="glyphicon glyphicon-off" style={{ marginRight: 10 }} />結案
								</Button>
								<Button onClick={(event) => {
									this.toUploadPic();
								}} bsStyle="default" disabled={noMRecordId}>
									<span className="glyphicon glyphicon-picture" style={{ marginRight: 10 }} />照片管理
								</Button>
								<Button onClick={(event) => {
									this.toViewItem();
								}} bsStyle="default" disabled={noItemId}>
									<span className="glyphicon glyphicon-eye-open" style={{ marginRight: 10 }} />裝備總覽
								</Button>
							</ButtonToolbar>
						</div>
					</Col>
				</Row>
				<div className="col-xs-12 col-sm-12">
					<table className="table table-striped table-condensed" style={{ tableLayout: 'fixed' }}>
						<tbody>
							<tr>
								<td><label className="control-label">系統維護序號:</label> {this.props.record.get('maintenanceRecordId')}</td>
								<td><label className="control-label">維護種類:</label> {this.props.record.get('maintenanceTypeDisplay')}</td>
							</tr>
							<tr>
								<td style={{ width: '50%' }}><label className="control-label">裝備編號:</label> {this.props.item.get('itemNo')}</td>
								<td style={{ width: '50%' }}><label className="control-label">裝備類別:</label> {this.props.item.get('gearName')}</td>
							</tr>
							<tr>
								<td><label className="control-label">維護時單位:</label> {this.props.item.get('subSquaName')}</td>
								<td><label className="control-label">維護時姓名:</label> {this.props.item.get('locationName')}</td>
							</tr>
							<tr>
								<td><label className="control-label">維護廠商:</label> {this.props.record.get('manufacturerName')}</td>
								<td><label className="control-label">維護日期:</label> {this.props.record.get('maintenanceDateDisplay')}</td>
							</tr>
							<tr>
								<td><label className="control-label">維護訂單:</label> {this.props.record.get('maintenanceOrderId')}</td>
								<td><label className="control-label">梯次編號:</label> {this.props.record.get('batchNo')}</td>
							</tr>
							<tr>
								<td><label className="control-label">建議維護:</label> {this.props.record.get('suggestMaintainDisplay')}</td>
								<td><label className="control-label">品管者:</label> {this.props.record.get('qaUser')}</td>
							</tr>
							<tr>
								<td><label className="control-label">維護項目:</label> {this.props.record.get('maintenanceItems')}</td>
								<td><label className="control-label">維護說明:</label> <pre style={{ border: 'none', backgroundColor: '#F9F9F9' }}>{this.props.record.get('maintenanceDesc')}</pre></td>
							</tr>
							<tr>
								<td><label className="control-label">建議報廢:</label> {this.props.record.get('suggestScrapDisplay')}</td>
								<td><label className="control-label">建議報廢原因:</label> <pre style={{ border: 'none', backgroundColor: 'white' }}>{this.props.record.get('scrapReason')}</pre></td>
							</tr>
							<tr>
								<td><label className="control-label">結案(送回)日期:</label> {this.props.record.get('closeMaintenanceDateDisplay')}</td>
								<td><label className="control-label">備註:</label> <pre style={{ border: 'none', backgroundColor: '#F9F9F9' }}>{this.props.record.get('note')}</pre></td>
							</tr>
							<tr>
								<td><label className="control-label">建檔時間:</label> {this.props.record.get('createRecordTimeDisplay')}</td>
								<td><label className="control-label">建檔者:</label> {this.props.record.get('createRecordUser')}</td>
							</tr>
							<tr>
								<td><label className="control-label">結案時間:</label> {this.props.record.get('closeRecordTimeDisplay')}</td>
								<td><label className="control-label">結案者:</label> {this.props.record.get('closeRecordUser')}</td>
							</tr>
						</tbody>
					</table>
					<Row>
						<Col xs={12} sm={12}><label>維護<font style={{ color: 'red' }}>前</font>外觀照片</label></Col>
						{prePicObj}
					</Row>
					<Row style={{ marginTop: 20 }}>
						<Col xs={12} sm={12}><label>維護<font style={{ color: 'red' }}>後</font>外觀照片</label></Col>
						{postPicObj}
					</Row>
				</div>
			</div>
		)
	}
}