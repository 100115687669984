import {
	connect
} from 'react-redux'
import UserMessagesComponent from '../components/UserMessagesComponent.jsx';
import {
	closeUserMessageModal
} from '../actions';

const mapStateToProps = (state) => ({
	showModal: state.getIn(['userMessageReducer', 'showModal']),
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => {
		dispatch(closeUserMessageModal());
	},
})

const UserMessagesContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(UserMessagesComponent)

export default UserMessagesContainer;